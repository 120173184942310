import React from 'react'
import {Card, Container} from 'react-bootstrap'

function SectionTitle(props) {
    return (
        <div>
            
            
            <Card style={{
                    border: '1px solid #FFFFFF',
                    borderRadius: 0
                }}>
                    <Card.Body style={{
                        borderLeft:'5px solid #161F92', 
                        borderTop:'3px solid #FFD700',
                        backgroundColor:'#FFFFFF',
                        color:'#161F92',
                        fontWeight:'700',
                        fontSize:19,
                        borderRadius: '0px !important'
                    }}>
                        
                        {props.sectionTitle}

                    </Card.Body>
                </Card>
        
         
        </div>
    )
}

export default SectionTitle
