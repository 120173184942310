import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/Api';
import qs from 'querystring';
import {cloneDeep } from 'lodash';



const initialState = {
 
  isLoading:false,
  shopRatings:{},
  productReviews:[],
  ratingsAverage:0,
  differenceAverage:5,
}


export const fetchRateReviews = createAsyncThunk(
  'fetch/rates_reviews',
  async (query, {rejectWithValue, dispatch}) => {
    try {
        const queryString = qs.stringify({...query});
      const response = await api.get(`/seller/shop_reviews?${queryString}`);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)


//Add Seller Comments
export const addSellerComment = createAsyncThunk(
  'seller/product_reviews/add_comment',
  async ({reviewId,comment}, {rejectWithValue, dispatch}) => {
    try {
        const response = await api.post(`/seller/comment/review/${reviewId}`,
        {
            comment
        }
        )
        return response.data.data
    } catch(err){
        if(!err.response){
            throw err
        }
        return rejectWithValue(err.response.data)
    }
  })








export const RatingsReviewsSlice = createSlice({
  name:"rates_reviews",
  initialState: initialState,
  extraReducers: (builder) => {

          builder.addCase(fetchRateReviews.pending, (state, action) => {
              state.isLoading = true
          })
          builder.addCase(fetchRateReviews.fulfilled, (state, action) => {

              const rateReviewsData = action.payload?.data || {}

              state.shopRatings = rateReviewsData || {}
              if(state.shopRatings && Object.keys(state.shopRatings).length > 0 ){
                  const ratingsAverage = 
                  // parseInt(state.shopRatings['5_star']) + parseInt(state.shopRatings['4_star'])/state.shopRatings['total'] * 5
                  (parseInt(state.shopRatings['5_star'])*5) + 
                  (parseInt(state.shopRatings['4_star'])*4)+
                  (parseInt(state.shopRatings['3_star'])*3)+
                  (parseInt(state.shopRatings['2_star'])*2)+
                  (parseInt(state.shopRatings['1_star'])*1)
                  /state.shopRatings['total']
                  const averageRoundedOff = parseFloat(ratingsAverage.toFixed(2))
                  const differenceAverage = 5 - averageRoundedOff 

                  state.shopRatings.ratingsAverage = averageRoundedOff || 0
                  state.shopRatings.differenceAverage = differenceAverage || 5
              }

              state.productReviews = rateReviewsData?.reviews || []
              state.isLoading = false
          })
          builder.addCase(fetchRateReviews.rejected, (state, action) => {
              state.isLoading = false
          }) 
    
    


            //Add Seller Comment
            builder.addCase(addSellerComment.pending, (state, action) => {
                state.isLoading = true
            })
            builder.addCase(addSellerComment.fulfilled, (state, action) => {
            const comment =  action.payload || {}
            const shopRatingsClone = cloneDeep(state.shopRatings);
                const index = shopRatingsClone?.reviews.findIndex(
                (item) => item.id === comment.id
                );
                state.shopRatings.reviews[index].comments = comment.comments;
                state.isLoading = false
            })
            builder.addCase(addSellerComment.rejected, (state, action) => {
                state.isLoading = false
            })


            






},

})


export default RatingsReviewsSlice.reducer;