import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Form, Button } from 'react-bootstrap'
import Footer from '../components/buyer/Footer/Footer'
import MainHeader from '../components/buyer/Header/MainHeader'
import SubHeader from '../components/buyer/Header/SubHeader'
import { Link, useHistory } from 'react-router-dom'
import { LogIn, selectUser, ClearLoginErrorMessage } from '../features/UserSlice';
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import Cookies from 'universal-cookie';

function Login() {
    
    const history = useHistory();
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const { userDetails, catchErrorLogin } = useSelector(selectUser) || {}
    const firebase_generated_token = useSelector((state) => state.user.firebaseGeneratedToken)

    //const errorLogin = useSelector((state) => state.user.catchErrorLogin);

    useEffect(() => {
        dispatch(ClearLoginErrorMessage())
    }, [])

    const validationLogin = {
        phone_number: Yup.string()
        .required("Mobile Number is Required")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Mobile Number is not valid"
        ),
        password: Yup.string().required("Password is required"),
    }
    const formik = useFormik({

        initialValues: {
            phone_number: "",
            password: "",
            device_token: firebase_generated_token,
        },
        validationSchema: Yup.object(validationLogin),
        onSubmit: (values) => {
            const payload = {
                ...values,
            };
            dispatch(LogIn(payload)).then((resp) => {
                if (resp.payload?.message == 'Successfully logged in!') {

                    cookies.set('access_token', resp.payload.data?.token, { path: '/', maxAge: 100000000 });

                    if (resp.payload.data.seller) {
                        cookies.set('shop_id', resp.payload.data.seller.shop_id, { path: '/', maxAge: 100000000 });
                    }

                    if (cookies.get('access_token') == resp.payload.data?.token) {
                        window.location.replace('/');
                    }
                }
            })

            if (Number(userDetails.status_code) === 200) {
                history.push('/');
            }
        },
    });

    // useEffect(() => {
    //     if(sessionStorage.getItem('accessToken')) {
    //         history.push('/');
    //     }
    // }, [userDetails])

    return (
        <div>
            <SubHeader />
            <div className="p-5">
                <Container>
                    <Row>
                        <Col md={1}></Col>
                        <Col xs={12} sm={4} md={6}>
                            <img className="img-fluid" style={{ width: 450 }} src="/assets/buyer/images/ISNBoxLogo.png" />
                        </Col>

                        <Col md={5}>
                            <Card className="shadow">
                                <Card.Body className="p-5">
                                    <Card.Title>Log In</Card.Title>
                                    {
                                        catchErrorLogin ? (
                                            <label style={{ fontSize: 14, color: '#CF0E0E' }}>{catchErrorLogin}</label>
                                        ) : (
                                            <></>
                                        )
                                    }

                                    <form onSubmit={formik.handleSubmit}>
                                        <div className='input-container mb-3 mt-5'>
                                            <input className='customInput form-control' id='phone_number' name='phone_number' type='text' placeholder='Mobile Number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phone_number} />
                                            {formik.touched.phone_number && formik.errors.phone_number ? <p className='validation'>{formik.errors.phone_number}</p> : null}
                                            {/* {errorLogin ? <p className='validation'>{errorLogin}</p> : null} */}
                                        </div>
                                        <div className='input-container mb-3 mt-5'>
                                            <input className='customInput form-control' id='password' name='password' type='password' placeholder='Password' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password} />
                                            {formik.touched.password && formik.errors.password ? <p className='validation'>{formik.errors.password}</p> : null}
                                        </div>
                                        <br />
                                        <div className="d-grid gap-2">
                                            <Button type="submit" variant="primary" className="mt-3 default-btn" type="submit">LOG IN</Button>
                                        </div>
                                        <Card.Link align="right" style={{ textDecoration: 'none', color: '#161F92', fontWeight: '300' }}>
                                            <p className="mt-3"><Link to="/forgot_password" style={{ textDecoration: 'none', color: '#000', fontWeight: '300' }}>Forgot Password </Link></p>
                                        </Card.Link>
                                        <br />
                                        <p style={{ textAlign: 'center' }}>
                                            Don’t have an account? <Link to="/sign_up" style={{ textDecoration: 'none', color: '#161F92', fontWeight: '700' }}>Sign Up</Link>
                                        </p>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Login
