import {initializeApp} from 'firebase/app';
import {getDatabase} from 'firebase/database'
import { getMessaging,getToken, onMessage} from "firebase/messaging";
import {
  apiKey, 
  authDomain,
  databaseURL,
  projectId, 
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
  publicKey
} from  '../config'

const config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};
const app = initializeApp(config);
const messaging = getMessaging(app);


export const getTokens = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await getToken(messaging,{ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true)
    } else {
      setTokenFound(false)
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging,(payload) => {
      resolve(payload);
    });
});

export const db = getDatabase(app)
