import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';
import qs from 'querystring';

const InitialState = {
  isLoading: false,
  ErrorHandler: '',
  categoryList:[],
  categories: [],
  subCategory: [],
  productCategory: [],
}

export const fetchCategory = createAsyncThunk(
  'seller/categories',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/categories')
      return response.data.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const fetchCategoryProducts = createAsyncThunk(
  'fetch/categories',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get(`/category/${_}/products`)
      return response.data.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)


export const fetchSubCategories = createAsyncThunk(
  'fetch/sub_category',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get(`/category/${_}`)
      return response.data.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const getSubCategoryByCategoryIds = createAsyncThunk(
  'fetch/sub_category/list',
  async (categoryIds, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.post(`/categories/sub_categories`,categoryIds)
      return response.data.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const fetchSubCategory = createAsyncThunk(
  'fetch/category_products',
  async (_, {rejectWithValue, dispatch}) => {

    try {
      const response = await api.get(`/category/${_}/products`)
      return response.data.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const CategoriesSlice = createSlice({
    name:"categories",
    initialState: InitialState,
    extraReducers: (builder) => {
              builder.addCase(fetchCategory.pending, (state, action) => {
                  state.isLoading = true
              })
              builder.addCase(fetchCategory.fulfilled, (state, action) => {
                  state.categories = action.payload
                  state.categoryList = action?.payload || []
                  state.isLoading = false
              })
              builder.addCase(fetchCategory.rejected, (state, action) => {
                  state.isLoading = false
              })  

              builder.addCase(fetchSubCategories.pending, (state, action) => {
                state.isLoading = true
              })
              builder.addCase(fetchSubCategories.fulfilled, (state, action) => {
                  state.categories = action.payload
                  state.subCategory = action?.payload || []
                  state.isLoading = false
              })
              builder.addCase(fetchSubCategories.rejected, (state, action) => {
                  state.isLoading = false
            })  
              

            //Fetch Sub-category by category [ids]
              builder.addCase(getSubCategoryByCategoryIds.pending, (state, action) => {
                  state.isLoading = true
              })
              builder.addCase(getSubCategoryByCategoryIds.fulfilled, (state, action) => {
                  state.subCategoryList = action?.payload || []
                  state.isLoading = false
              })
              builder.addCase(getSubCategoryByCategoryIds.rejected, (state, action) => {
                  state.isLoading = false
              })

              builder.addCase(fetchCategoryProducts.pending, (state, action) => {
                state.isLoading = true
              })
              builder.addCase(fetchCategoryProducts.fulfilled, (state, action) => {
                  state.productCategory = action?.payload || []
                  state.isLoading = false
              })
              builder.addCase(fetchCategoryProducts.rejected, (state, action) => {
                  state.isLoading = false
              })
              
    }
})

export const selectCategories = state => state.categories.categories;

export default CategoriesSlice.reducer;