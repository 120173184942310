import React, { useState, useEffect } from 'react'
import {Modal, Button, Row, Col, Container} from 'react-bootstrap'

import { useFormik, Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { toast } from "react-toastify";
import { imageUpload } from '../../../features/UserSlice';
// import ChangeProfilePic from '../../components/buyer/Profile/ChangeProfilePic';
import ChangeProfilePic from '../../buyer/Profile/ChangeProfilePic';
import { useDispatch, useSelector } from 'react-redux';

function ProfilePic(props) {
    const dispatch = useDispatch();
    const FILE_SIZE = 1000000;
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/png"
    ];
    return (
        <div>
            <Modal
            {...props}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                <Modal.Title>Change Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Formik
                        enableReinitialize
                        initialValues={{ file: null }}
                        validationSchema={Yup.object().shape({
                            file: Yup
                              .mixed()
                              .required("Image file is required")
                              .test(
                                "fileSize",
                                "File too large",
                                value => value && value.size <= FILE_SIZE
                              )
                              .test(
                                "fileFormat",
                                "Unsupported Format",
                                value => value && SUPPORTED_FORMATS.includes(value.type)
                              )
                          })}
                        onSubmit={(values, {resetForm}) => {
                            const formData = new FormData();
                            formData.append(
                                "image",
                                values.file,
                                values.file.name,
                            );
                            dispatch(imageUpload(formData));
                            setTimeout(function() {
                                window.location.href = window.location;
                            }, 3000);
                            // dispatch(props.refreshData());
                        }}
                    >
                        {({ errors, values, handleBlur, handleChange, handleSubmit, setFieldValue, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label for="file">Image upload</label>
                                <input id="file" name="file" type="file" onChange={(event) => {
                                    setFieldValue('file', event.target.files[0]);
                                    }} className="form-control" />
                                {touched.file && errors.file ? <span className='validation'>{errors.file}</span> : null}
                            </div>
                            <br/>
                            <br/>
                            <Button type="submit" className="btn btn-primary">submit</Button>   
                        </Form>
                        )}
                </Formik>
                   
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProfilePic
