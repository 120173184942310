import React, { useState,useEffect } from 'react';
import CreatableSelect  from 'react-select/creatable';


const CreatableMultiSelect = ({
    setOptions,
    defaultValues,
    name,
    setState,
    setFieldValue,
    loadOption,
    touched,
    errors
}) =>{


    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
      });
      
    const defaultOptions = [
      ];

    const [formState, setFormState] = useState(
        {
            isLoading: false,
            options: [...defaultOptions],
            value:defaultValues || [],
        })

        
        
        // useEffect(() => {
        //     if(defaultValues){
        //         setOptions(formState.value)
        //     }
        // }, [defaultValues])
        useEffect(() => {
            if(formState.value){
                let updatedList = formState.value.map((item,index)=> {
                    //   if (item.label === item.value){
                        return {
                            label:item.label,
                            value:index,
                            id:item.value,
                        }
                    // }
                    //   return item; // else return unmodified item 
                  });
                  setOptions(updatedList)

                  loadOption(true)
            }

        }, [formState.value])


      const handleChange = (
            newValue,
            {action=false}
      ) => {
            console.log(newValue)
            if(action === "pop-value") return
        
            if(action === "remove-value") {
                setFormState((prev)=>{
                    prev.value = newValue
                    prev.options = newValue
                    return {...prev}
                })

                setState((prev)=>{
                    const arrayData =[]
                    formState.value.forEach((item)=>{
                        if(defaultValues){
                            arrayData.push(item.label)
                        }else{
                            arrayData.push(item.value)
                        }
                       
                    })
                    prev[name] = [...arrayData]
                    return {...prev}
                })

                setFieldValue((prev) => {
                    console.log(prev)
                })
                setFieldValue && setFieldValue([name],newValue) //For state validation using Formik
                return setOptions(formState.value)
            }
            
            if(action && action === "clear") {
                setFormState((prev)=>{
                    prev.value = []
                    prev.options = []
                    return {...prev}
                })
                setState((prev)=>{
                    prev[name] = []
                    return {...prev}
                })
                setFieldValue([name],[])
                return setOptions([])  
            }
       
            setFormState((prev)=>{
                prev.value = [...prev.value,newValue]
                return {...prev}
              })
           
              setFieldValue && setFieldValue([name],[...formState.value,newValue])  //For state validation using Formik
            setState((prev)=>{
                const arrayData =[]
                formState.value.forEach((item)=>{
                    arrayData.push(item.value)
                })
                prev[name] = [...arrayData]
                return {...prev}
            })
          };
        
      const handleCreate = (inputValue) => {
              const newOption = createOption(inputValue);
              if(inputValue === "pop-value") return
              setFormState((prev)=>{
                prev.isloading = false
                prev.options = [...prev.options,newOption]
                prev.value = [...prev.value,newOption]
                return {...prev}
              })
              setFieldValue && setFieldValue([name],[...formState.value,newOption])  //For state validation using Formik
              setState((prev)=>{
                const arrayData =[]
                formState.value.forEach((item)=>{
                    if(defaultValues){
                        arrayData.push(item.label)
                    }
                    else{
                        arrayData.push(item.value)
                    }
                    
                })
                prev[name] = [...arrayData]
                return {...prev}
            })
              setOptions(formState.options)
          };
    return (
        <React.Fragment>
            <CreatableSelect
                className={touched[name] && errors[name] ? "error" : null}
                isClearable
                isMulti
                placeholder="Options.."
                // isDisabled={isLoading}
                // isLoading={isLoading}
                onChange={handleChange}
                onCreateOption={handleCreate}
                value={formState.value}
            />
        </React.Fragment>  
    )
}

export default CreatableMultiSelect;
