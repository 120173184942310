import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Table, Form, ButtonGroup, Button, Row, Col } from 'react-bootstrap'
import { getCheckoutPaymentStatus } from '../../../features/CartSlice';
import { useParams } from 'react-router-dom'

function CheckOutPaymentStatus(props) {
    const { transaction_id } = useParams();
    const dispatch = useDispatch();
    const [isPaid, setPaid] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [order, setOrder] = useState([]);
    useEffect(() => {
        dispatch(getCheckoutPaymentStatus(transaction_id))
    }, []);

    let checkoutPaymentData = useSelector((state) => state.cart.checkoutPaymentData);
    useEffect(() => {
        if (checkoutPaymentData) {
            console.log('~~', checkoutPaymentData);
            if (checkoutPaymentData.is_paid == 1) {
                setPaid(true)
                setTransactionData(checkoutPaymentData);
                setOrder(checkoutPaymentData.order)
            } else {
                setPaid(false)
            }
        }
    }, [checkoutPaymentData])

    const orderItems = (res) => {
        return (<Table responsive style={{ fontSize: '14px' }} >
            <tbody>
                <tr >
                    <td style={{ padding: '0 0 0 15px' }}><b>Order ID:</b></td>
                    <td style={{ padding: 0 }}>{res.rc_id}</td>
                </tr>
                <tr >
                    <td style={{ padding: '0 0 0 15px' }}><b>Total:</b></td>
                    <td style={{ padding: 0 }}>{res.total}</td>
                </tr>
            </tbody>
        </Table>)
    }

    return (
        <div>
            <Container fluid>
                <div style={{
                    backgroundColor: '#FFFF',
                    width: '100%',
                    padding: 5,
                    backgroundColor: '#FFFF',
                    borderTopColor: '#FFFF',
                    borderRightColor: '#FFFF',
                    borderLeftColor: '#FFFF',
                    borderBottomColor: 'yellow',
                    borderStyle: 'solid'
                }}>
                    <div>
                        <label className="mb-5 mt-5" style={{ paddingLeft: 10 }}>
                            {isPaid ?
                                <b>{`Transaction id: ${transactionData.id} is successfully paid.`}</b> :
                                'No Payment Status'
                            }
                        </label>
                    </div>
                    {transactionData && (
                        <>
                            <div>
                                <label className="mb-1 mt-2" style={{ paddingLeft: 10 }}>
                                    <b>Transaction Details</b>
                                </label>
                            </div>
                            <div>
                                <Table responsive className="cartTable" border="1" >
                                    <tbody >
                                        <tr>
                                            <td><b>Checkout ID:</b></td>
                                            <td>{transactionData.checkout_id}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Date Purchase:</b></td>
                                            <td>{transactionData.created_at}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"><b>Orders:</b></td>
                                        </tr>
                                        <tr >
                                            <td colSpan="2">{order.map((res) => orderItems(res))}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </>)
                    }
                </div>
            </Container>
        </div>
    )
}

export default CheckOutPaymentStatus
