import React, {useEffect, useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container,Row, Col, Card, Pagination } from 'react-bootstrap';
import { RatingView } from 'react-simple-star-rating'
import {Link} from 'react-router-dom'
import ProductCard from '../../common/ProductCard'
import { getProductList } from '../../../features/ProductSlice';
import { useDispatch, useSelector } from 'react-redux';

function ForYouItems() {
    const dispatch = useDispatch();
    const [Product, setProduct] = useState([])
    const [forYouItemPerPage, setForYouItemPerPage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [pageNumbers, setPageNumbers] = useState([]);

    useEffect(() => {
        dispatch(getProductList(currentPage)).then((resp) => {
            SlideDisplay(resp.payload)
            setPages(resp.payload.last_page)
        })
    }, [currentPage])


    const checkImage = (result) => {
        try {
            if(result.latest_variety){
                const latest_variety = result.latest_variety
                if(latest_variety.latest_image){
                    const latest_image = latest_variety.latest_image
                    if(latest_image.path){
                        return true
                    }
                }
            }
            return false
        } catch (e){
            return false
        }

    }

    const SlideDisplay = (resp) => {
        setForYouItemPerPage(resp?.per_page || 1)
        setCurrentPage(resp?.current_page || 1)
        const res_product = resp?.data || {}
        if(Object.keys(res_product).length){
            const setProductList = []
            res_product.map((result)=>{

                let rating = 0;
                if (result.reviews_count != 0) {
                  rating = result.reviews_sum_rating / result.reviews_count;
                }
                setProductList.push({
                    'id': result.id,
                    'is_idol_mall':result?.seller?.shop?.is_idol_mall || false,
                    'item_image': checkImage(result) ? result.latest_variety?.latest_image?.path : '/assets/buyer/images/noImage.jpg',
                    'item_title': result.product_name,
                    'item_original_price': result.latest_variety?.price,
                    'item_discount_price': result.latest_variety?.discounted,
                    'item_rating': rating,
                })
            })
            setProduct(setProductList)
         }
    }
    
    // Logic for displaying current todos
    const renderTodos = Product.map((data, index) => {

      let rating = "0";

      if (data.item_rating != 0) {
        rating = data.item_rating;
      }

        return <Col md={2} className="mt-5">
            <Link to={`/product/${data.id}`} style={{textDecoration:'none',color:"#000"}} key={index}>
                    <ProductCard
                        isIdolMall={!!data?.is_idol_mall || false}
                        className="sliderEventDataCategory"
                        productName={data.item_title}
                        productImage={data.item_image}
                        reviewsRating={rating}
                        discounted={data.item_discount_price}
                        price={data.item_original_price}
                    />
            </Link>
        </Col>;
    });

    useEffect(() => {
        const page_array = [];
        for (let i = 1; i <= pages; i++) {
            page_array.push(i);
        }

        setPageNumbers(page_array)

    },[pages])

    const handlePrevArrowPagination = () => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
    }

    const handleNextArrowPagination = () => {
        setCurrentPage(pages == currentPage ? pages : currentPage + 1)
    }

    const handleClickPagination = (e) =>  {
        console.log(e.target.id);
        setCurrentPage(Number(e.target.id))
    }
    
    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <div>

                <Pagination.Item
                    style={{backgroundColor: currentPage == number ? '#161F92' : 'none'}}
                    key={number}
                    id={number}
                    active={currentPage == number ? true : false}
                    onClick={handleClickPagination}
                >
                    {number}
                </Pagination.Item>

            </div>
        );
    });

    return (
        <div className="mt-1">
            <Container fluid>
                   <Row>
                        {renderTodos}
                    </Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>

                        <Pagination  style={{marginTop:'10%'}}>
                            <Pagination.Prev onClick={() => handlePrevArrowPagination(currentPage)}/>
                                {renderPageNumbers}
                            <Pagination.Next onClick={() => handleNextArrowPagination(currentPage)} />
                        </Pagination>
                    </div>
            </Container>
        </div>
    )
}

export default ForYouItems
