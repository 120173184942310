import React from 'react'
import {Card, Container} from 'react-bootstrap'
export const NoProduct = (props) => {
    return (
        <div className="productPreviewContainer">
            <Container className="productPreview" style={{alignItems:'center', display:'flex', justifyContent:'center', height:250}}>
                <div >
                    <p>No product yet</p>
                </div>
            </Container>
        </div>
    )
}

export const WaitingApproval = (props) => {
    return (
        <div className="wait_approval_box" style={{alignItems:'center', 
        backgroundColor:'white', borderWidth:2, 
        borderColor:'#3E6AE3', border:'2px solid #3E6AE3', display:'flex',  justifyContent:'center', textAlign:'center', flexDirection:'row'}}>
            <p style={{textAlign:'center', marginTop:15, color:'#3E6AE3'}}>Waiting for approval</p>
        </div>
    )
}


