import React from 'react'
import { Container,  Row, Col, Nav } from 'react-bootstrap'
import NoOrder from '../../service/NoOrder';
import { Link } from 'react-router-dom';
import OrderItem from '../../common/OrderItem';


function ShippedList({toShipOrderList}) {

    return (
        <div className="mt-2">

            
        <div id="to_ship_container"> 


                    { toShipOrderList && toShipOrderList.length === 0 ?
                        <NoOrder/> :
                            toShipOrderList.length !== 0 && toShipOrderList.map((order)=>(
                                    
                                <div className="productPreviewContainer" key={order.shop_id} id={"transaction_"+order.shop_id}>
            
                                

                       <React.Fragment>
                                        <Container className="salesShopName">
                                        <Row>
                                                <Col xs={6} style={{textAlign: "left"}}>
                                                        <img 
                                                            alt=""
                                                            src="/seller_icons/store_icon_1.png" 
                                                            style={{
                                                                height: "17px", 
                                                                marginRight: "10px"}}    
                                                                />
                                                                {order?.shop?.name || ""}
                                                </Col>
                                                <Col xs={6} style={{ textAlign: "right" }}>
                                                        <Nav.Link as={Link} to={`/order_details/${order.id}`}  
                                                            style={{
                                                                color:'#161F92', 
                                                                cursor:'pointer'}}> 
                                                                 View &nbsp; | &nbsp;
                                                                <label 
                                                                style={{
                                                                    color:'#535353', 
                                                                    fontWeight:300}}
                                                                    >ORDER ID# {order.rc_id}
                                                                </label>
                                                        </Nav.Link> 
                                                </Col>
                                        </Row>
                                        </Container>


                                <Container className="salesProductPreview">

                                {order.order_items.length !== 0 && order.order_items.map((item)=>(
                                                        <OrderItem 
                                                             item={item} 
                                                        />
                                        ))}


                                        <div className="transactionContainer">
                                                <Row>
                                                        <Col xs={6} style={{textAlign: "left"}}>
                                                                <h6>Transaction ID</h6>
                                                        </Col>
                                                        <Col xs={6} style={{textAlign: "right"}}>
                                                                <h6>#{order?.transaction_id}</h6>
                                                        </Col>
                                                </Row>
                                        </div>
                                        <div className="transactionShipContainer">
                                                Waiting to receive the products by receive by {order?.formattedplaceorderdate?.estimated_date_from } - {order?.formattedplaceorderdate?.estimated_date_to }
                                        </div>
                                </Container>
                        </React.Fragment>
                                
                                </div>
                                )
                            )      
                        }
                    </div>

            </div>

    )
}

export default ShippedList
