import React,{ useState, useEffect } from 'react'
import {Card, Row, Col, Container, Form, Button} from 'react-bootstrap'
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changePass, LogOut } from '../../../features/UserSlice';
import { toast } from "react-toastify";
function ChangePassword(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const validationAddresses = {
        current_password: Yup.string().min(4, 'Minimum of 4 character').required("Current Password is required"),
        new_password: Yup.string().min(4, 'Minimum of 4 character').required("New Password is required"),
    }
    const catch_error_change_pass = useSelector((state) => state.user.catchErrorChangePass)
    console.log(catch_error_change_pass)
    const formik = useFormik({
        validationSchema: Yup.object(validationAddresses),
        enableReinitialize: true,
        initialValues:{
            current_password: "",
            new_password: "",
        },
        onSubmit: (values) => {
            dispatch(changePass(values))
            if(!catch_error_change_pass){
                setTimeout(function () {
                    props.cancelChangePass()
                }, 3000);
            }
            // dispatch(LogOut({
            //     email: null,
            //     password: null,
            //     role: null,
            //     name: null,
            //     token: null,
            //     loggedIn: false,
            // }))
            // ;
            // toast.success('Successfully Changed Password, Please Login Again', {
            //     position: "bottom-left",
            // })
            // setTimeout(function () {
            //     history.push('/login');
            //     localStorage.removeItem('userId')
            //     localStorage.removeItem('shop_id')
            // }, 3000);
            
        },
    });

    return (
        <Card.Body>
            <Container fluid className="mt-3">     
                <div >
                <img onClick={props.cancelChangePass} src="/assets/buyer/icons/BackArrowIcon.png" style={{width:40, height:40, marginTop:10, zIndex:2222222}}/>
                    <h5>Change Password</h5>
                    <Row>
                        <Col md={11}>
                            <label style={{fontWeight:300, fontSize:13}}>Keep your account safe, Please verify your identify by entering your password.</label>
                        </Col>
                    </Row>
                </div>
                <hr />  
                <form onSubmit={formik.handleSubmit}>      
                    <Row className="formGroup">
                        <Col xs={4} md={3} className="formTitle">
                            Current Password
                        </Col>
                        <Col xs={12} md={6} className="formDataReadOnly">
                            <Form.Control className='customInput form-control' 
                                id='current_password' 
                                name='current_password' 
                                type='password' 
                                placeholder='Password' 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                value={formik.values.current_password} 
                            />
                            {formik.touched.current_password && formik.errors.current_password ? <p className='validation'>{formik.errors.current_password}</p> : null}
                        </Col>
                    </Row>
                    <Row className="formGroup">
                        <Col xs={6} md={3} className="formTitle">
                            New Password
                        </Col>
                        <Col xs={12} md={6} className="formDataReadOnly">
                            <Form.Control className='customInput form-control' 
                                id='new_password' 
                                name='new_password' 
                                type='password' 
                                placeholder='New Password' 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                value={formik.values.password_confirmation} 
                            />
                            {formik.touched.new_password && formik.errors.new_password ? <p className='validation'>{formik.errors.new_password}</p> : null}
                        </Col>
                    </Row>
                    <Row className="formGroup">
                        <Col md={12} className="formDataReadOnly">
                            <center><Button type="submit" variant="primary" style={{marginLeft: '5px', marginRight: '5px', width: '550px'}}>Submit</Button></center>
                        </Col>
                    </Row>
                </form>  
            </Container>
        </Card.Body>
    )
}

export default ChangePassword
