import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/Api';
import { apiURL } from '../config';
import { toast } from "react-toastify";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const InitialState = {
    isLoading: false,
    response_message: '',
    isSuccess: false,
    shopList: {}
}

export const updateShop = createAsyncThunk(
  'seller/shop_update',
  async (values, {rejectWithValue, dispatch}) => {
    // console.log(values)
  try {
      const response = await api.post('/seller/shop',{
          name: values.shopname,
          description: values.shopdescription,
          bank_id: values.bankaccount
      })
      console.log(response)
      return response.data
  } catch(err){
      if(!err.response){
          throw err
      }
      return rejectWithValue(err.response.data)
  }
})

export const imageUploadShop = createAsyncThunk(
  'seller_upload_image_shop/upload_image_shop',
  async (values, {rejectWithValue, dispatch}) => {
  try {

    return fetch(`${apiURL}/seller/shop/pic`, {
        'method': 'POST',
        headers: {
            'Authorization': 'Bearer ' + cookies.get('access_token')
        },
        body:values
    })
    .then(reps => reps.json())
    .then(reps => {
      return reps
    })
  } catch(err){
      if(!err.response){
          throw err
      }
      return rejectWithValue(err.response.data)
  }
})

export const getShop = createAsyncThunk(
  'view/viewShop',
  async (_, {rejectWithValue, dispatch}) => {
      try {
          const {data} = await api.get(`/shop/view/${cookies.get('shop_id')}`);
          return data.data;
      } catch (err) {
          if (!err.response) {
              throw err
          }
      }

  }
)

// export const SellerShopRegistration = createAsyncThunk(
//     'seller/shop_registration',
//     async ({store_info, doc_files}, {rejectWithValue, dispatch}) => {
//     console.log(store_info);
//     try {
//         const response = await api.post(`/shop/add`,{
//             shop_name: store_info.inp_shop_name,
//             tin: store_info.inp_shop_tin
//         })
//         console.log(response);
//         return response.data
//     } catch(err){
//         if(!err.response){
//             throw err
//         }
//         return rejectWithValue(err.response.data)
//     }
// })

export const SellerShopRegistration = createAsyncThunk(
  'seller/shop_registration',
  async (values, {rejectWithValue, dispatch}) => {
  try {
    return fetch(`${apiURL}/shop/add`, {
        'method': 'POST',
        headers: {
            'Authorization': 'Bearer ' + cookies.get('access_token')
        },
        body:values
    })
    .then(reps => reps.json())
    .then(reps => {
      return reps
    })
  } catch(err){
      if(!err.response){
          throw err
      }
      return rejectWithValue(err.response.data)
  }
})

export const startSellingSlice = createSlice({
    name:"start_selling",
    initialState:InitialState,
    extraReducers: (builder) => {
      
      builder.addCase(imageUploadShop.pending, (state, action) => {
        state.isLoading = true
      })
      builder.addCase(imageUploadShop.fulfilled, (state, action) => {
        toast.success('Successfully Upload Shop Picture', {
          position: "bottom-left",
        });
          state.isLoading = false
      })
      builder.addCase(imageUploadShop.rejected, (state, action) => {
          state.isLoading = true
      })

      builder.addCase(updateShop.pending, (state, action) => {
        state.isLoading = true
      })
      builder.addCase(updateShop.fulfilled, (state, action) => {
          state.isLoading = false
      })
      builder.addCase(updateShop.rejected, (state, action) => {
          state.isLoading = true
      })

      builder.addCase(getShop.pending, (state, action) => {
        state.isLoading = true
      })
      builder.addCase(getShop.fulfilled, (state, action) => {
          state.shopList = action.payload
          state.isLoading = false
      })
      builder.addCase(getShop.rejected, (state, action) => {
          state.isLoading = true
      })

      builder.addCase(SellerShopRegistration.pending, (state, action) => {
        state.isLoading = true
        state.isSuccess = false
      })
      builder.addCase(SellerShopRegistration.fulfilled, (state, action) => {
        state.isLoading = false
        state.response_message = 'Successfully Register'
        state.isSuccess = true
        if(action.payload.success == true) {
          toast.success("Successfully Created Shop", {
              position: "bottom-left",
          });
          cookies.set('shop_id', action.payload.data.id, { path: '/', maxAge: 100000000 })
          window.location.replace('/seller/products')
        }else {
          state.response_message = 'There is an problem on your data!';
        }
        // window.location.replace('/seller/products/add_product')
      })
      builder.addCase(SellerShopRegistration.rejected, (state, action) => {
        state.isLoading = false
        state.response_message = 'There is an problem on your data!';
        state.isSuccess = false
      })
    },
})

export const selectStartSellect = state => state.start_selling;
export default startSellingSlice.reducer;