import Resizer from "react-image-file-resizer";

export const checkMimeType = (file) => {
    const image_pattern = '/jpg/jpeg/png/gif'
    const file_type = file && file.split('/')[1]
    if (!(image_pattern).match(file_type.toString())) {
        return false;
    }
    return true;
}

export const checkFileSize = (file) => {
    const size_limit = 60000
    const file_size = Math.round(file.size / 1024)    
    if (file_size > size_limit) {
        return false;
    }
    return true;
}


export const checkResolution = async (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
          file, // Is the file of the image which will resized.
          189,  // Is the maxWidth of the resized new image.
          189,  // Is the maxHeight of the resized new image.
          "JPEG", // Is the compressFormat of the resized new image.
          100,  // Is the quality of the resized new image.
          0, // Is the degree of clockwise rotation to apply to uploaded image.
          (uri) => {
            resolve(uri); // Is the callBack function of the resized new image URI.
          },
          "file", // Is the output type of the resized new image.
          189, // Is the minWidth of the resized new image.
          189 // Is the minHeight of the resized new image.
        );
    }) 
}


export const checkFileCount = (file) => {
    const count_limit = 4
    if (file.length > count_limit) {
        return false;
    }
    return true;
}
