import React , {useRef} from 'react'
import {Card, Form, Button, Row, Col, ButtonGroup} from 'react-bootstrap'

function CheckOutItem(props) {
    const addRef = useRef(0)
    const minusRef = useRef(0)
    const checkRef = useRef(0)

    const {
        index,
        order,
        item,
        quantityHandler,
        setSelectedItem,
        setModalShowDelete,
        checkHandler,
        activePriceDisplay
    } = props
    
    
    const productTitle = {
        color: 'rgb(22, 31, 146)'
    }

    const visibilityChecker = () => {
        if (index === 0) {
            return false
        } else {
            return true
        }
    }

    const ifChecked = () => {
       return item.status === 'cart_checked'
    }

    const actionQuantity = (action) => {
        addRef.current.disabled = true
        minusRef.current.disabled = true

        var select = {
            'action' : action,
            'order_item_id' : item.id,
            'order_id' : order.id,
            'quantity' : item.quantity
        }

        setSelectedItem(select)

        if (action === 'sub' && item.quantity === 1) {
            setModalShowDelete(true)

            addRef.current.disabled = false
            minusRef.current.disabled = false
        } else {
            var request = quantityHandler(select)

            request.then(() => {
                addRef.current.disabled = false
                minusRef.current.disabled = false
            })
        }
    }

    const check = () => {
        checkHandler(item, 'item', checkRef)
    }

    const priceDisplay = () => {
        return (
            <>
            {
                activePriceDisplay && activePriceDisplay === "Discounted Price" ?
                item.variety.discounted && item.variety.discounted !== 0 && item.variety.discounted !== '0'
                    ? <>
                            <label><del>P{item.variety.price || "0.00"}</del> </label>
                            <label className="ms-3 mt-5">P{item.variety.discounted || "0.00"}</label>
                    </>
                    : <>
                            <label>P{item.variety.price}</label>
                    </>
                :
                <>
                        <label>P{item.variety.price} </label>
                </>
            }
            </>
        )
    }

    const priceTotal = () => {

        if (activePriceDisplay && activePriceDisplay === "Discounted Price" && item.variety.discounted !== 0) {
            return item.variety.discounted * item.quantity
        } else {
            return item.variety.price * item.quantity
        }
    }

    return (
        <Card.Body className="pt-3">
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={2}>
                            <Row>
                                <Col sm={12} className="d-flex justify-content-center">
                                    <img className="img-fluid mx-3" style={{maxHeight: '150px'}} src={item.variety.latest_image.path}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={10} className="">
                            <Row className="mt-2">
                                <Col sm={6} className="d-flex justify-content-start">
                                    <Card.Title className="" as="h5" style={productTitle}>{item.product.product_name}</Card.Title>
                                </Col>
                                <Col sm={2} className="d-flex justify-content-center">
                                    <Card.Title className="" hidden={visibilityChecker(index)} as="h5" style={productTitle}>Price</Card.Title>
                                </Col>
                                <Col sm={1} className="d-flex justify-content-center">
                                    <Card.Title className="" hidden={visibilityChecker(index)} as="h5" style={productTitle}>Quantity</Card.Title>
                                </Col>
                                <Col sm={3} className="d-flex justify-content-center">
                                    <Card.Title className="" hidden={visibilityChecker(index)} as="h5" style={productTitle}>Item Subtotal</Card.Title>
                                </Col>
                            </Row>
                            <Row style={{ height: '6rem'}}>
                                <Col sm={6} className="d-flex align-items-end justify-content-start">
                                
                                </Col>
                                <Col sm={2} className="d-flex align-items-end justify-content-center">
                                    {
                                        priceDisplay()
                                    }
                                </Col>
                                <Col sm={1} className="d-flex align-items-end justify-content-center">
                                    <label>
                                        { item.quantity }
                                    </label>
                                </Col>
                                <Col sm={3} className="d-flex align-items-end justify-content-center">
                                    <label>
                                        { 
                                            priceTotal()
                                        }
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card.Body>
    )
}

export default CheckOutItem
