import React, {useRef, useState,useEffect} from 'react';
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainHeader from '../../../components/buyer/Header/MainHeader';
import SellerMenu from '../seller_menu';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import CreatableMultiSelect from '../../../components/seller/SellerProducts/CreatableMultiselect'
import VarietyContainer from '../../../components/seller/SellerProducts/VarietyContainer';

import { useHistory,useParams  } from 'react-router-dom';
import {fetchCategory,getSubCategoryByCategoryIds} from '../../../features/CategoriesSlice'
import { Formik, Field, FieldArray} from "formik";
import {updateProduct,getProductbyId} from "../../../features/ProductSlice"
import {PRODUCT_VALIDATION_SCHEMA} from "../../seller/products/validationSchema"
import { toast } from "react-toastify";
import FocusError from "./focus_error";

const  UpdateProduct = () =>{
    const dispatch = useDispatch();
    const history = useHistory();
    const { id:productId} = useParams();
    const { categoryList, isLoading, subCategoryList} = useSelector((state) => state.categories);
    const {  isLoading:savingProduct, productUpdateDetails } = useSelector((state) => state.product)
    const [optionOne, setOptionOne] = useState([])
    const [optionTwo, setOptionTwo] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [categoryIds, setCategoryIds] = useState({ids:[]})
    const [isSaving, setIsSaving] = useState(false);
    const [isPublishing,setIsPublishing]= useState(false);
    const [submitter,setSubmitter]= useState(0);
    const [updateFormState, setUpdateFormState] = useState(false)
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [loadSpecOption1, setLoadSpecOption1] = useState(false);
    const [loadSpecOption2, setLoadSpecOption2] = useState(false);

        useEffect(() => {
            setIsPageLoading(true)
            if(productId){
                dispatch(getProductbyId({productId})).then((resp) => {
                    callbackSetData(resp.payload)
                })
            }
            
        },[productId])

        useEffect(() => {
            if(subCategoryList){
                const options = subCategoryList.map((category) => ({
                    value: category.id,
                    label: category.category_name,
                }));
                setSubCategoryOptions(options)
            }
        }, [subCategoryList])

        useEffect(() => {
            const options = categoryList.map((category) => ({
                value: category.id,
                label: category.category_name,
            }));
            setCategoryOptions(options)
            
        }, [categoryList])

        const callbackSetData = (productUpdateDetails) => {
            if(productUpdateDetails && Object.keys(productUpdateDetails).length > 0) {
            const update = {  
                    product_name:productUpdateDetails.product_name || "",
                    category: productUpdateDetails.categories,
                    sub_category: productUpdateDetails.sub_categories, //sub-category
                    status:productUpdateDetails.status,
                    variation_1_id:productUpdateDetails.variations[0]?.id || null,
                    product_description: productUpdateDetails.product_description,
                    variation_name_1:productUpdateDetails.variations[0]?.variation_name,//spec1,
                    spec1_options: productUpdateDetails.variations[0]?.options,
                    variation_name_2:productUpdateDetails.variations[1]?.variation_name || null,//spec2
                    variation_2_id:productUpdateDetails.variations[1]?.id || null,
                    spec2_options: productUpdateDetails.variations[1]?.options || [],
                    shipping_fee: productUpdateDetails.shipping_fee,
                    varieties: setDefaultVarities(productUpdateDetails.variations[0]?.options, productUpdateDetails.variations[1]?.options || [], productUpdateDetails.varieties) || [],
                    deleted_variation_id: [],
                }
                
                setUpdateFormState(update)
                
                setIsPageLoading(false)
                
                dispatch(fetchCategory()).then(() => {
                    dispatch(getSubCategoryByCategoryIds({ids: [productUpdateDetails?.categories[0].id]}))
                })
            }
        }

        const getDefaultOptions =(items) => {
            const options = items && items.map((item) => ({
                value: item.id,
                label: item.category_name || item.option || item.label,
            }));

            return options.length > 0 ? options : null;
        }


        const getOptionsIndex =(items,id) => {
            
            const options = items && items.findIndex((item) => {
               return item.id === id
            });

            if(options < 0) return 0;
            return options;
        }

        const setDefaultVarities =(options1, options2, items) => {
            const varieties = items && items.map((item) => (
                {
                    id:item.id || null,
                    specs1_option: getOptionsIndex(options1,item.option_variety?.option1_id) || 0,
                    specs2_option: getOptionsIndex(options2,item.option_variety?.option2_id) || 0,
                    new_specs2_option:0,
                    wt:item.wt || 0, //weight
                    l:item.l || 0,  //length
                    h:item.h ||0, //height
                    w:item.w || 0, //width
                    stocks:item.stocks ||  0,
                    price:item.price ||  0,
                    discounted_price: item.discounted || 0,
                    img: item.images || [], //images
                    defaultImages: item.images || [],
                } 
            ));

            return varieties ;
        }

        const getDefaultValueCat = (category) => {
            return {
                value: category[0].id,
                label: category[0].category_name
            }
        }

        const handleCategoryId = (value,setFieldValue)=>{
            let data = []
            if(value) {

                data = [{
                    id:value.value
                }]

                dispatch(getSubCategoryByCategoryIds({ids: [value]}))

                setFieldValue('category',data)
            }
        }

        const handleSubCategoryId = (value,setFieldValue)=>{
            if(value){
                const data = [{
                    id:value.value
                }]

                setFieldValue('sub_category',data)
            }
        }

        const addVariety= (e,  values, setValues)=>{
            const newVar = {
                id: null,
                key: Math.floor(Math.random() * 100000),
                specs1_option: 0,
                specs2_option: 0,
                new_specs1_option: 0,
                new_specs2_option: 0,
                wt: null, //weight
                l: null,  //length
                h: null, //height
                w: null, //width
                stocks:  null,
                price:  null,
                discounted_price: 0,
                img: [] //images
            }

            const newValues = {...values}

            newValues.varieties = [...values.varieties, newVar]

            setValues(newValues)
        }
        
        const removeVariety = (e, values, setValues, setFieldValue)=> {
            
            const removedVarieties = values.varieties.filter((item, index) => index === e);
            
            const removed = [...values.varieties].filter((item, index) => index !== e);

            if(removedVarieties && removedVarieties[0]?.id){
                
                setValues((prev)=> {
                    const removedArray = [...prev['deleted_variation_id']]
                    removedArray.push(removedVarieties[0]?.id)
                    prev['deleted_variation_id'] = removedArray
                    return {...prev}
                })
            }

            setFieldValue('varieties', removed)
        }

        const handleSwitch = (e, setFieldValue) => {
            const {checked,name} = e.target

            setFieldValue('shipping_fee', checked ? 1 : 0)
        };

        const isChecked = (value) => {
            return value === 1 ? true : false
        }

        const handleSubmitProduct = (e) =>{
            const formData = new FormData();

            if(submitter === 1){
                formData.append(`status` , 'Draft')
            }else if(submitter === 2){
                formData.append(`status` , 'For Approval')
            }else if(submitter === 3){
                formData.append(`status` , 'Active')
            }

            for (const field in e) {
                if (e[field] != null) {
                    if(field === 'product_name' 
                    || field === 'product_description' 
                    || field === 'variation_name_1'
                    || field === 'variation_1_id'
                    || field === 'variation_name_2'
                    || field === 'shipping_fee'
                    ) {
                        formData.append(`${field}` , e[field])
                    }
                    if(field === 'category' || field === 'sub_category'){
                        e[field].forEach((item,x)=>{formData.append(`${field}[${x}][id]`,item.id)})
                    }

                    if (field === 'variation_2_id') {
                        formData.append(`${field}` , e[field])
                    }

                    if(field === 'spec1_options'){
                        e[field].forEach((item,x) => { 
                            if (typeof item === 'object') {
                                formData.append(`${field}[${x}]`,item.option)
                            } else {
                                formData.append(`${field}[${x}]`,item)
                            }
                        })
                    }

                    if(field === 'spec2_options'){
                        if (e['variation_name_2'] !== null || e['variation_name_2'] !== '') {
                            e[field].forEach((item,x) => { 
                                if (typeof item === 'object') {
                                    formData.append(`${field}[${x}]`,item.option || item.value)
                                } else {
                                    formData.append(`${field}[${x}]`,item)
                                }
                            })
                        }
                    }

                    if(field === 'deleted_variation_id') {
                        e[field].forEach((item,x) => { 
                            formData.append(`${field}[${x}]`,item)
                        })
                    }

                    if(field === 'varieties'){
                        for(const index in e[field]){
                            for(const index1 in e[field][index]){

                                if (index1 === "img") {
                                    if(e[field][index][index1].length >0){
                                        e[field][index][index1].forEach((item,x)=>{
                                                formData.append(`${field}[${index}][${index1}][${x}]`,item)
                                            }
                                        )
                                    }
                                } else {
                                    formData.append(`${field}[${index}][${index1}]` , e[field][index][index1])
                                }
                            }
                        }
                    }
                } 
            }

            if(formData){
                dispatch(updateProduct({productId,formData}))
                    .then((res)=>{
                        var message = 'Saved';
                        if(submitter === 1 || submitter === 3){
                            setIsSaving(false)
                        }else if(submitter === 2){
                            message = 'Published';
                            setIsPublishing(false)
                        }

                        dispatch(getProductbyId({productId}))
                        if (res.payload.status === 200) {
                            toast.success('Successfully ' + message + ' Product', {
                                position: "bottom-left",
                            });
                            history.push("/seller/products")
                        } else {
                            toast.error(res.payload.message, {
                                position: "bottom-left",
                            });
                        }

                    })
            }
            e.preventDefault()
        }
        

        return (
            <div>
                <MainHeader />
                <Container className="mainContainer">
                    {
                        !isPageLoading &&
                        <Row>
                            <Col xs={2} className="leftContainer">
                                <SellerMenu />
                            </Col>
                            <Col xs={10} className="rightContainer" >
                            {updateFormState && Object.keys(updateFormState).length > 0 &&
                                <Formik 
                                    initialValues={updateFormState}
                                    validationSchema={PRODUCT_VALIDATION_SCHEMA}
                                    onSubmit={(e)=>handleSubmitProduct(e)}
                                    >
                                {( {values,
                                    errors,
                                    touched,
                                    setValues,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    isSubmitting }) => (

                                    <Form onSubmit={handleSubmit}>
                                        <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                                        
                                            <Row style={{marginBottom: "15px"}}>
                                                <Col xs={12} style={{textAlign: "left"}}>
                                                    <h4>Update Product</h4>
                                                </Col>
                                            </Row>
                                            
                                            <Row style={{marginBottom: "30px"}}>
                                                <Form.Label for="product_name" style={{textAlign: "right"}} column xs={2}><span style={{color: "red"}}>*</span>Product Name</Form.Label>
                                                <Col xs={10}>
                                                <Form.Control
                                                    type="text" 
                                                    defaultValue={updateFormState.product_name}
                                                    className={touched.product_name && errors.product_name ? "error" : null}
                                                    name="product_name"
                                                    onChange={(e)=>{handleChange(e)}}
                                                    style={{background: "#F4F4F4"}}/>
                                                </Col>
                                                {errors && errors.product_name &&
                                                    <p className="text-warning">{errors.product_name}</p>
                                                }
                                            </Row>
                                            <Row style={{marginBottom: "30px"}}>
                                                <Form.Label for="category" style={{textAlign: "right"}} column xs={2}><span style={{color: "red"}}>*</span>Product Category</Form.Label>
                                                <Col xs={10}>

                                                <Select
                                                    isLoading={isLoading}
                                                    isDisabled={isLoading}
                                                    style={{background: "#F4F4F4"}}
                                                    placeholder="Categories"
                                                    name="category"
                                                    onChange={(e)=>handleCategoryId(e,setFieldValue)}
                                                    options={categoryOptions}
                                                    defaultValue={getDefaultValueCat(values.category)}
                                                    className={touched.category && errors.category ? " error" : null }
                                                    classNamePrefix="select"
                                                />

                                                </Col>
                                                    {errors && errors.category &&
                                                    <p className="text-warning">{errors.category}</p>
                                                    }
                                            </Row>
                                            <Row style={{marginBottom: "30px"}}>
                                                <Form.Label for="sub_category" style={{textAlign: "right"}} column xs={2}><span style={{color: "red"}}>*</span>Lvl 2 Category</Form.Label>
                                                <Col xs={10}>
                                                    <Select
                                                        className={touched.category && errors.category ? "error" : null}
                                                        
                                                        isLoading={isLoading}
                                                        isDisabled={isLoading}
                                                        placeholder="Sub-categories"
                                                        onChange={(e)=>handleSubCategoryId(e,setFieldValue)}
                                                        style={{backGroundColor: "#F4F4F4"}}
                                                        name="sub_category"
                                                        options={subCategoryOptions}
                                                        defaultValue={getDefaultValueCat(values.sub_category)}
                                                        classNamePrefix="select"
                                                    />
                                                </Col>
                                                {errors && errors.sub_category &&
                                                    <p className="text-warning">{errors.sub_category}</p>
                                                    }
                                            </Row>
                                            <Row style={{marginBottom: "30px"}}>
                                                <Form.Label for="product_description" style={{textAlign: "right"}} column xs={2}><span style={{color: "red"}}>*</span>Product Description</Form.Label>
                                                <Col xs={10}>
                                                    <Form.Control as="textarea" 
                                                        name="product_description"
                                                        defaultValue={updateFormState.product_description}
                                                        className={touched.product_description && errors.product_description ? "error" : null}
                                                        onChange={(e)=>{handleChange(e)}}
                                                        style={{ 
                                                            height: '100px', 
                                                            background: "#F4F4F4" ,
                                                            resize:'none'
                                                                }} 
                                                        />
                                                </Col>
                                                {errors && errors.product_description &&
                                                    <p className="text-warning">{errors.product_description}</p>
                                                    }
                                            </Row>
                                        </Container>
                                        <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                                            <Row style={{marginBottom: "30px"}}>
                                                <Form.Label for="variation_name_1" style={{textAlign: "right"}} column xs={2}>Variation 1</Form.Label>
                                                <Col xs={10}>
                                                    <Form.Control 
                                                        type="text" 
                                                        defaultValue={updateFormState.variation_name_1}
                                                        className={touched.product_description && errors.product_description ? "error" : null}
                                                        name="variation_name_1"
                                                        onChange={(e)=>{handleChange(e)}}
                                                        style={{background: "#F4F4F4"}}/>
                                                </Col>
                                                {errors && errors.variation_name_1 &&
                                                    <p className="text-warning">{errors.variation_name_1}</p>
                                                    }
                                            </Row>
                                            <Row style={{marginBottom: "30px"}}>
                                                <Form.Label for="spec1_options" style={{textAlign: "right"}} column xs={2}>Options</Form.Label>
                                                <Col xs={10}> 
                                                    <CreatableMultiSelect
                                                        values={values}
                                                        defaultValues={getDefaultOptions(values.spec1_options)}
                                                        name="spec1_options"
                                                        setOptions={setOptionOne}
                                                        setState={setValues}
                                                        setFieldValue={setFieldValue}
                                                        loadOption={setLoadSpecOption1}
                                                        touched={touched}
                                                        errors={errors}
                                                        /> 
                                                </Col>
                                                {errors && errors.spec1_options &&
                                                    <p className="text-warning">{errors.spec1_options}</p>
                                                    }
                                            </Row>
                                            <Row style={{marginBottom: "30px"}}>
                                                <Form.Label style={{textAlign: "right"}} column xs={2}>Variation 2 (Optional)</Form.Label>
                                                <Col xs={10}>
                                                    <Form.Control 
                                                        type="text" 
                                                        name="variation_name_2"
                                                        defaultValue={updateFormState.variation_name_2}
                                                        onChange={(e)=>handleChange(e)}
                                                        placeholder="Type here" 
                                                        style={{background: "#F4F4F4"}}
                                                        />
                                                </Col>
                                            </Row>
                                            <Row style={{marginBottom: "30px"}}>
                                                <Form.Label style={{textAlign: "right"}} column xs={2}>Options (Optional)</Form.Label>
                                                <Col xs={10}> 
                                                    <CreatableMultiSelect
                                                        values={values}
                                                        name="spec2_options" 
                                                        defaultValues={getDefaultOptions(values.spec2_options)}
                                                        setState={setValues}
                                                        setOptions={setOptionTwo}
                                                        setFieldValue={setFieldValue}
                                                        loadOption={setLoadSpecOption2}
                                                        touched={touched}
                                                        errors={errors}
                                                        />
                                                </Col>
                                            </Row>
                                        </Container>

                                        <div id="variations_container" className="variations_">
                                        </div>
                                        {
                                            loadSpecOption1 && loadSpecOption2 &&
                                                <FieldArray name="varieties">
                                                    {() => (values.varieties.map((item, index) => {
                                                        const ticketErrors = errors.varieties?.length && errors.varieties[index] || {};
                                                        const ticketTouched = touched.varieties?.length && touched.varieties[index] || {};
                                                        return(
                                                            <VarietyContainer
                                                                key={item.key}
                                                                var_count={index+1} 
                                                                optionOne={optionOne}
                                                                optionTwo={optionTwo}
                                                                index={index}
                                                                varData={item}
                                                                removeVariety={removeVariety}
                                                                values={values}
                                                                setValues={setValues}
                                                                setFieldValue={setFieldValue}
                                                                ticketTouched={ticketTouched}
                                                                ticketErrors={ticketErrors}
                                                                />
                                                        )
                                                    }))}
                                                </FieldArray>
                                        }
                                            
                                        
                                        <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                                        <Row style={{marginBottom: "15px"}}>
                                                <Col xs={24}>
                                                    <Row>
                                                        <Button 
                                                            for="varieties"
                                                            onClick={(e) => addVariety(e, values, setValues)} 
                                                            className={errors.varieties && !Array.isArray(errors.varieties) ? "error addVariantBtn" : "addVariantBtn"}
                                                            variant="outline-primary" 
                                                            style={{
                                                                border: "3px solid #3167E9", 
                                                                borderRadius: "50px", 
                                                                width: "100%"}}>
                                                            <img alt='' src="/seller_icons/add_icon.png" style={{width:"20px", marginRight: "10px"}} />
                                                            Add Variety
                                                        </Button>
                                                        { 
                                                            errors.varieties && !Array.isArray(errors.varieties) &&
                                                            <small className="text-danger">{errors.varieties}</small>
                                                        }
                                                    </Row>
                                                    <Row align="left">
                                                   
                                                    </Row>
                                                </Col>
                                            </Row>

                                        </Container>
                                        <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                                        <Row>
                                            <Col xs={4}>
                                                <Form.Check
                                                    onChange={(e)=>handleSwitch(e, setFieldValue)}
                                                    name="shipping_fee"
                                                    id="default-checkbox"
                                                    type='checkbox'
                                                    checked={isChecked(values.shipping_fee)}
                                                    label={"I will cover the Shipping Fee"}
                                                />
                                            </Col>
                                            </Row>

                                        </Container>
                                        <div>
                                            { updateFormState && updateFormState.status !== 'Active' &&
                                                <Row>
                                                    <Col xs={12}>
                                                        <Button 
                                                            rounded={true}
                                                            name="Draft"
                                                            type="submit"
                                                            variant="outline-primary"
                                                            disabled={savingProduct}
                                                            onClick={()=>{
                                                                setSubmitter(updateFormState.status === "Active"? 3 :1);
                                                                setIsSaving(true)
                                                            }}
                                                            size="md"
                                                            style={{
                                                                borderRadius:"20px !important",
                                                                width: "100%",
                                                                fontSize:18,
                                                                fontWeight:"bold",
                                                                }}>
                                                            {isSaving?
                                                            <div>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    />&nbsp;Saving Product...
                                                            </div>
                                                            :'Save'}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row 
                                                style={{
                                                    marginTop:15,
                                                    marginBottom:150
                                                }}>
                                                <Col xs={12}>
                                                    <Button 
                                                        name="For Approval"
                                                        variant="primary" 
                                                        type="submit"
                                                        rounded={true}
                                                        size="md" 
                                                        disabled={savingProduct}
                                                        onClick={()=>{
                                                            setSubmitter(2);
                                                            setIsPublishing(true);
                                                        }}
                                                        style={{
                                                            borderRadius:"20px !important",
                                                            width: "100%",
                                                            fontSize:18,
                                                            fontWeight:"bold"
                                                            }}>
                                                        {isPublishing?
                                                        <div>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                />&nbsp;Publishing Product...
                                                        </div>:
                                                        'Publish'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    <FocusError setIsSaving={setIsSaving} setIsPublishing={setIsPublishing}/>
                                    </Form>
                                        )}
                                </Formik>
                            }
                            
                            </Col>
                        </Row>
                    }
                        
                    </Container>
                
            </div>
        );
    }

export default UpdateProduct;