import React, {useEffect, useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container,Row, Col, Card, Button } from 'react-bootstrap';
import { RatingView } from 'react-simple-star-rating'
import SectionTitle from '../Section/SectionTitle';
import { getEventsList } from '../../../features/EventsSlice';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../../common/ProductCard'
import { Link } from 'react-router-dom';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img onClick={onClick} className={className} src="/assets/buyer/icons/NextArrowIcon.png" style={{width:40, height:40, marginTop:10}}/>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img onClick={onClick} className={className} src="/assets/buyer/icons/BackArrowIcon.png" style={{width:40, height:40, marginTop:10, zIndex:2222222}}/>
  );
}



function EventsSectionSlider() {
    const dispatch = useDispatch();
    const [Event, setEvent] = useState([])
    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 300,
        slidesPerRow: 6,
        dots:false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2,
                slidesPerRow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2,
                slidesPerRow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2,
                slidesPerRow: 2,
              }
            }
        ]
    };

    useEffect(() => {
        dispatch(getEventsList()).then(resp => {
            SlideDisplay(resp.payload)
        })
    }, [])

    const checkImage = (result) => {
        try{
          if(result.latest_variety){
              const latest_variety = result.latest_variety
              if(latest_variety.latest_image){
                  const latest_image = latest_variety.latest_image
                  if(latest_image.path){
                      return true
                  }
              }
          }
          return false
        } catch(e) {
          console.log(e)
          return false
        }


    }
    const SlideDisplay = (resp) => {
        const res_event = resp?.data || {}
        const res_categories = res_event.sub_categories
        if(res_categories){
            const setEventList = []
            res_categories.map((result)=>{
                result.products.map((product_res)=>{

                  let rating = 0;
                  if (product_res.reviews_count != 0) {
                    rating = product_res.reviews_sum_rating / product_res.reviews_count;
                  }

                    setEventList.push({
                        'id': product_res.id,
                        'is_idol_mall':product_res?.seller?.shop?.is_idol_mall || false,
                        'item_image': checkImage(product_res) ? product_res?.latest_variety?.latest_image?.path : '/assets/buyer/images/noImage.jpg',
                        'item_title': product_res.product_name,
                        'item_original_price': product_res.latest_variety?.price,
                        'item_discount_price': product_res.latest_variety?.discounted,
                        'item_rating': rating,
                    })
                })
            })
                 const uniqueIds = [];

                  const uniqueList = setEventList.filter(product => {
                    const isDuplicate = uniqueIds.includes(product.id);
                    if (!isDuplicate) {
                      uniqueIds.push(product.id);

                      return true; //The filter method only adds an element to the results array if the function passed to the method returns a truthy value.
                    }
                  });
            setEvent(uniqueList)
         }
    }

    return (
        <div className="mt-5">
            <Container fluid>
                <Slider className="mb-5"  style={{paddingTop:20}} {...settings}>
                {
                        Event.map((data) => {

                          let rating = "0";
                          if (data.item_rating != 0) {
                            rating = data.item_rating;
                          }

                            return (
                                <div className="sliderEventDataCategory space-cat">

                                <Link to={`/product/${data.id}`} style={{textDecoration:'none',color:"#000"}}>
                                    <ProductCard
                                        isIdolMall={!!data?.is_idol_mall || false}
                                        productName={data.item_title}
                                        productImage={data.item_image}
                                        reviewsRating={rating}
                                        discounted={data.item_discount_price}
                                        price={data.item_original_price}
                                    />
                                    </Link>
                                </div>
                            )
                        })
                    }
                </Slider>

            <SectionTitle sectionTitle="Just For You"/>
            </Container>

        </div>
    )
}

export default EventsSectionSlider
