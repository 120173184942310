import React from 'react'
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import SellerMenu from '../seller_menu';
import MainHeader from '../../../components/buyer/Header/MainHeader';
// import AddressList from '../../components/seller/Addresses/AddressListSeller'
import AddressList from '../../../components/seller/Addresses/AddressListSeller'
function BuyerAddresses() {
    return (
        <div> 
            <MainHeader />
            <Container className="mainContainer">
                <Row>
                    <Col xs={2} className="leftContainer">
                        <SellerMenu />
                    </Col>
                    <Col >
                        <AddressList/>
                    </Col>
                </Row>
            </Container>
            
        </div>
    )
}


export default BuyerAddresses
