import React, { useEffect,useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import MainHeader from '../../components/buyer/Header/MainHeader';
import ProductCommentSection from '../../components/buyer/ProductView/ProductCommentSection';
import ProductStoreRatings from '../../components/buyer/ProductView/ProductStoreRatings';
import ProductViewDescription from '../../components/buyer/ProductView/ProductViewDescription';

import RelatedProducts from '../../components/buyer/ProductView/RelatedProducts';
import SectionTitle from '../../components/buyer/Section/SectionTitle';
import { ProductView, getRelatedProducts } from '../../features/ProductSlice';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

function BuyerProductView() {
    const params = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState(0);
    const {relatedProductList} = useSelector((state) => state.product);
    
    useEffect(() => {
        dispatch(ProductView(params.item))        
    }, [params.item])

   

    const ProductViewDetails = useSelector((state) => state.product.viewProduct);

    useEffect(() => {
        if(ProductViewDetails && Object.keys(ProductViewDetails).length > 0){
            if(ProductViewDetails?.categories 
                && ProductViewDetails?.categories.length > 0) {
                dispatch(getRelatedProducts(ProductViewDetails?.categories[0].id))  
            }
               
        }
          
    }, [ProductViewDetails])
    
    return (
        <div>
            <MainHeader count={data} setData={setData}/>
            <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30,
            }}>
                
                <Container fluid>
                    {/* Image Zoom */}
                    <Card>
                        <div className="p-3">
                            
                                <ProductViewDescription key={ProductViewDetails.id} ProductViewDetails={ProductViewDetails} count={data} setData={setData}/>
                                {/* <Col md={4}>
                                    <ProductZoom ProductViewDetails={ProductViewDetails}/>
                                </Col> */}
                                {/* <Col md={8}>
                                    <ProductViewDescription ProductViewDetails={ProductViewDetails}/>
                                </Col> */}
                            {/* </Row> */}
                        </div>
                    </Card>

                    <ProductStoreRatings ProductViewDetails={ProductViewDetails}/>

                    <SectionTitle sectionTitle="Related Items"/>

                        <RelatedProducts 
                            productDetails={relatedProductList}
                        />

                    <ProductCommentSection ProductViewDetails={ProductViewDetails}/>
                    
                </Container>
            </div>
            
        </div>
    )
}

export default BuyerProductView
