import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {Link, Route, useLocation} from 'react-router-dom'

function Footer() {

    const location = useLocation();

    return (
        <div className="">
        {
            location.pathname != '/check_out/payment_successful' ?
       
            <div style={{
                
                width:'100%',
                // height:'400px',
                backgroundColor:'#FFFF',
                borderTopColor:'yellow',
                borderRightColor:'#FFFF',
                borderLeftColor:'#FFFF',
                borderBottomColor:'#FFFF',
                borderStyle:'solid'
            }}>

                <div className="footerContent" style={{padding:60, margin:30}}>
                    <Container>
                        <Row>
                            <Col md={3} style={{marginRight:10}}>
                                <div style={{bottom:30, position:'relative'}}>
                                    <img
                                        src="/assets/buyer/images/logo_landscape.png"
                                        className="d-inline-block align-top img-fluid"
                                        alt="React Bootstrap logo"
                                        width="250"
                                    />

                                    <p style={{marginLeft:10,marginRight:10, textAlign:'justify'}}>
                                    Idol Shopping Network or ISN is a social e-commerce platform tailored for Filipino merchants and consumers; providing them a secure, one-stop online shopping experience. It was established and launched in the Philippines in 2021, creating a wide selection of products from local sellers.
                                    </p>
                                
                                </div>
                                <br/>
                            </Col>
                            <Col md={2} style={{marginTop:10}}>
                                <h6 style={{bottom:10, position:'relative'}}>CUSTOMER SERVICE</h6>
                                
                                    {/* <li >Help Center</li> */}
                                    {/* <br/> */}
                                    {/* <li >Payment Methods</li>
                                    <br/>
                                    <li >Track Order</li>
                                    <br/> */}
                                    <div style={{marginTop:15,}}>
                                    <li >Mobile number: 0968-8000-266</li>
                                    <li >Landline: (02) 8242-6369</li>
                                    </div>
                                    {/* <br/>
                                    <li >Contact Us</li> */}
                              
                                
                                <br/>
                            </Col>
                            <Col md={1} style={{padding: 0,marginRight:35, marginTop:10}}>
                                <h6 style={{bottom:10, position:'relative'}}>ABOUT ISN</h6>
                                <div style={{marginTop:15}}>
                                    <li><Link to="/about_us" style={{ListStyleType:'none', textDecoration:'none', color:'#000000'}}>About US</Link></li>
                                    
                                    <li><Link to="/privacy_policy" style={{ListStyleType:'none', textDecoration:'none', color:'#000000'}}>Privacy Policy</Link></li>
                                    {/* <br/>
                                    <li style={{ListStyleType:'none'}}>Idol Mall</li> */}
                               </div>
                            </Col>

                            

                            <Col md={1} style={{padding: 0, margin: 0, marginRight: 20, marginTop:10}}>
                                <h6 style={{bottom:10, position:'relative'}}>FOLLOW US</h6>
                                <Row style={{marginTop:15}}>
                                    <Col>
                                        <img
                                            src="/assets/buyer/icons/FBBlue.png"
                                            className="img-fluid"
                                            width="24"
                                        />
                                        <a style={{textDecoration:'none', color:'#000'}} target="_blank" href="https://www.facebook.com/idolshoppingnetworkofficial"><label> &nbsp; Facebook</label></a>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:15}}>
                                    <Col>
                                        <img
                                            src="/assets/buyer/icons/IGC2.png"
                                            className="img-fluid"
                                            width="24"
                                        />
                                        <a style={{textDecoration:'none', color:'#000'}} target="_blank" href="https://www.instagram.com/idolshoppingnetworkofficial/"><label> &nbsp; Instagram</label></a>
                                    </Col>
                                </Row>
                                <br/>
                            </Col>
                            <Col md={2}  style={{padding: 0, marginTop:10, marginRight:0}}>

                                <h6 style={{bottom:10, position:'relative'}}>PAYMENT OPTIONS</h6>
                                <Row>
                                <div>
                                    <img
                                        src="/assets/buyer/icons/mastercard.svg"
                                        className="img-fluid"
                                        width="50"
                                    />
                                </div>
                                <div style={{marginTop:5}}>
                                    <img
                                        src="/assets/buyer/icons/jcb.png"
                                        className="img-fluid"
                                        width="45"
                                        style={{height:30, paddingLeft:5}}
                                    />
                                </div>
                                <div style={{marginTop:5}}>
                                    <img
                                        src="/assets/buyer/icons/amex.png"
                                        className="img-fluid"
                                        width="45"
                                        style={{height:30, paddingLeft:5}}
                                    />
                                </div>
                                <div style={{marginTop:5}}>
                                    <img
                                        src="/assets/buyer/icons/VisaIcon.png"
                                        className="img-fluid"
                                        width="50"
                                    />
                                </div>
                                <div style={{marginTop:10}}>
                                    <img
                                        src="/assets/buyer/icons/CODIcon.png"
                                        className="img-fluid"
                                        width="60"
                                        style={{paddingLeft:5}}
                                    />
                                </div>
                                <div style={{marginTop:15}}>
                                    <img
                                        src="/assets/buyer/icons/gcash.svg"
                                        className="img-fluid"
                                        width="70"
                                        sizes='sm'
                                        style={{paddingLeft:5}}
                                    />
                                </div>
                                <div style={{marginTop:10}}>
                                    <img
                                        src="/assets/buyer/icons/grabpay-logo-1.png"
                                        className="img-fluid"
                                        width="70"
                                        sizes='sm'
                                        style={{paddingLeft:5}}
                                    />
                                </div>
                                </Row>
                            </Col>
                            <Col md={2} style={{padding: 0, marginTop:10}}>

                                <h6 style={{bottom:10, position:'relative'}}>SHIPPING PARTNERS</h6>
                                <img
                                    src="/assets/buyer/icons/NinjaVanIcon.png"
                                    className="img-fluid"
                                    width="100"
                                />
                                {/* <br/><br/>
                                <img
                                    src="/assets/buyer/icons/LacerIcon.png"
                                    className="img-fluid"
                                    width="50"
                                />
                                <br/><br/>
                                <img
                                    src="/assets/buyer/icons/ReselleIcon.png"
                                    className="img-fluid"
                                    width="100"
                                    
                                />
                                <br/><br/> */}
                            </Col>
                            
                        </Row>


                        {/* <Row>
                            
                            <Col md={8}></Col>
                            
                        </Row> */}
                        
                    </Container>
                    <br/>
                    <hr/>
                    <p style={{marginTop:'4%'}}>© 2021 Idol Shopping Network. All Rights Reserved.</p>
                </div>

                
            </div>
            :

            <div>
            </div>
        }

        </div>
    )
}

export default Footer
