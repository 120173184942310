import React from 'react'
import CheckOutPaymentStatus from '../../components/buyer/CheckOut/CheckOutPaymentStatus'
import MainHeader from '../../components/buyer/Header/MainHeader'
import SubSectionTitle from '../../components/buyer/Section/SubSectionTitle'

function BuyerPaymentStatus() {
    return (
        <div>
            <MainHeader />

            <div style={{
                backgroundColor: '#F4F4F4',
                minHeight: '100vh',
                padding: 30
            }}>

                <SubSectionTitle SubSectionTitle="Checkout Payment Status" />

                <CheckOutPaymentStatus />

            </div>
        </div>
    )
}

export default BuyerPaymentStatus
