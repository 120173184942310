import * as Yup from 'yup';

const digitsOnly = (value) => /(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/.test(value)

function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
      return null;
    }
    return value;
  }

const VarietySchema = Yup.object().shape({
    wt: Yup.string().required('weight is required').nullable()
    .test('Digits only', 'The field should have digits only', digitsOnly),
    l: Yup.string().required('length is required').nullable()
    .test('Digits only', 'The field should have digits only', digitsOnly),
    h: Yup.string().required('height price is required').nullable()
    .test('Digits only', 'The field should have digits only', digitsOnly),
    w: Yup.string().required('weight price is required').nullable()
    .test('Digits only', 'The field should have digits only', digitsOnly),
    stocks: Yup.string().required('stocks is required').nullable()
    .test('Digits only', 'The field should have digits only', digitsOnly),
    price: Yup.string().required('price is required').nullable()
    .test('Digits only', 'The field should have digits only', digitsOnly),
    discounted_price: Yup.string()
    .nullable()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/, {
        message: 'The field should have digits only',
        excludeEmptyString: true,
    })
    .transform(emptyStringToNull),
    img:Yup.array().required('image is required').min(1,'image is required'),
    
})

export const PRODUCT_VALIDATION_SCHEMA = Yup.object().shape({
    product_name: Yup.string()
        .max(255, 'product name is too long!')
        .required("product name is required")
        .nullable(),
    category:Yup.array()
        .min(1,'product category is required'),
    sub_category:Yup.array()
        .min(1,'sub category is required'),
    product_description: Yup.string()
        .nullable()
        .required("product description is required"),
    variation_name_1: Yup.string()
        .required("variation name is required"),
    spec1_options:Yup.array()
        .min(1,'variation option is required'),
    varieties: Yup.array()
        .of(VarietySchema).min(1, "At least 1 variety is required").required('Required'),
 })