import React from 'react'
import AddressList from '../../components/buyer/Addresses/AddressList'
import MainHeader from '../../components/buyer/Header/MainHeader'
import SubSectionTitle from '../../components/buyer/Section/SubSectionTitle'
function BuyerAddresses() {
    return (
        <div>
            <MainHeader/>
        
            <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30}}>
                
                <AddressList/>
                
            </div>
        </div>
    )
}


export default BuyerAddresses
