import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container,Row, Col } from 'react-bootstrap';
import SectionTitle from '../Section/SectionTitle';
import { Link } from 'react-router-dom';
import { fetchCategory, selectCategories} from '../../../features/CategoriesSlice';

import { useDispatch, useSelector } from 'react-redux';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img onClick={onClick} className={className} src="/assets/buyer/icons/NextArrowIcon.png" style={{width:40, height:40, marginTop:10}}/>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}

function ShopCategorySlider(props) {

    const dispatch = useDispatch();
    const categories = useSelector(selectCategories)
    const [ isLoading, setLoading ] = useState(false);
    const [ shopCategory, setShopCategory ] = useState([]);


    useEffect(() => {
        dispatch(fetchCategory()).then(resp => {
            setLoading(true);
        })
    }, [dispatch])

    useEffect(() => {
        if(categories && categories.length > 0){
            setShopCategory(categories)
        }
    }, [categories])

    useEffect(() => {
        if(shopCategory && shopCategory.length > 0){
           const filter = shopCategory.find((category)=>{
            return category.category_name === "Idol Mall"
           })

           if(!filter){
            const idolMall = {
                category_name:"Idol Mall",
                image:{
                    path:"/assets/buyer/images/idol_mall.png"
                }
            }
            setShopCategory((prev)=>{
                const categoryClone = [...prev,idolMall]
                prev = categoryClone.reverse()
                return [...prev]
            })
           }
            
        }
    }, [shopCategory])
   
    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 300,
        rows: 2,
        slidesPerRow: 4,
        dots:true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    const checkImage = (result) => {
        try{
            if(result.image){
                const image = result.image
                if(image){
                    if(image.path){
                        return true
                    }
                }
            }
            return false  
        } catch(e){
            console.log(e)
            return false
        }
        
    }
    return (
        <div>
            <Container fluid>
                <br/><br/>
                <SectionTitle sectionTitle="Shop By Category"/>
                {isLoading &&
                    <Slider style={{ margin: '0 auto'}} {...settings}>
                        {
                            shopCategory && shopCategory.length > 0 && shopCategory.map((data) => {
                                return (
                                    <Link key={data.id} to={data.category_name === "Idol Mall" ? "/idolmall" :`/product_cat/${data.id}`}>
                                        <div className="sliderDataCategory" style={{marginTop:20}}>
                                            <h6>
                                                <Container>
                                                    <Row>
                                                        <Col md={3}>
                                                            {checkImage(data) 
                                                                ? <img className="img-fluid" src={data.image.path} style={{ height:45, width:45 }} /> 
                                                                : <img className="img-fluid" src='/assets/buyer/images/noImage.jpg' style={{ height:45, width:45 }} />
                                                            }
                                                        </Col>
                                                        <Col >
                                                            <label>{data.category_name}</label>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </h6>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </Slider>
                }
            </Container>
            
        </div>
    )
}

export default ShopCategorySlider
