import React,{useEffect, useState} from 'react'
import { Container, Row, Col, Nav, Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from 'moment';
import BankForm from '../Setting/BankForm'
import { getBank,
    deleteBank
} from '../../../features/BankSlice';

import { getShop, updateShop
} from '../../../features/StartSellingSlice';

import ChangeShopPic from '../../../components/seller/Setting/ChangeShopPic';
function Shop() {
    const dispatch = useDispatch();
    
    const [bank_view_bank, setViewBank] = useState(false);
    const [bank, setBank] = useState(false);
    const [edit_bank, setEditBank] = useState(false);
    const [edit_bank_list, setEditBankList] = useState([]);
    const [bank_list, setBankList] = useState([]);
    const [account_name, setAccountName] = useState('');
    const [account_num, setAccountNum] = useState('');
    const [account_type, setAccounttype] = useState('');
    const [bank_name, setBankName] = useState('');
    const [is_active, setIsActive] = useState(1);
    
    const [open_modal, setOpenModalChangeShopPic] = useState(false);

    useEffect(() => {
        dispatch(getBank())
        dispatch(getShop())
        
    }, [dispatch])

    const shopList = useSelector((state) => state.start_selling?.shopList);
    const bankList = useSelector((state) => state.bank?.BankList)

    useEffect(() => {
        if(Object.keys(shopList).length !== 0){
            if(shopList.bank_details  && shopList.bank_details != null){
                setAccountName(shopList.bank_details ? shopList.bank_details?.account_name : '')
                setAccountNum(shopList.bank_details ? shopList.bank_details?.account_num : '')
                setAccounttype(shopList.bank_details ? shopList.bank_details?.account_type : '')
                setBankName(shopList.bank_details ? shopList.bank_details?.bank_name : '')
                setIsActive(shopList.bank_details ? shopList.bank_details?.is_active : 0)
            }
        }
        
    }, [shopList])

    const handleChangeShopPic = () => {
        setOpenModalChangeShopPic(true)
    }

    const handleChangeShopPicClose = () => {
        setOpenModalChangeShopPic(false)
    }
    const validationProfile = {
        shopname: Yup.string().required("Shop Name is required"),
        // shopdescription: Yup.string().required("Shop Description is required"),
        bankaccount: Yup.string().required("Bank Account is required"),
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues:{
            shopname: shopList?.seller_name !== "" ? shopList?.seller_name : "",
            shopdescription: shopList?.description !== "" ? shopList?.description : "",
            bankaccount: bank_name ? bank_name : "",
        },
        validationSchema: Yup.object(validationProfile),
        onSubmit: (values) => {
            dispatch(updateShop(values))
            dispatch(getShop())
            setTimeout(function() {
                window.location.href = window.location;
            }, 3000);
        },
    });

    const selectBank = () => {
        setBank(true)
        setEditBank(false)
    }

    const selectViewBank = () => {
        setViewBank(true)
    }
    
    const setEditOff = () => {
        setBank(false)
        setEditBank(false)
        dispatch(getBank())
    }

    const setDeleteBank = (id) => {
        dispatch(deleteBank(id))
        dispatch(getBank())

    } 

    const setEditOn = (res) => {
        setBank(true)
        setEditBank(true)
        setEditBankList(res)
    }

    const setAddOff = () => {
        setTimeout(function() {
            window.location.href = window.location;
        }, 1000);
        // setBank(false)
        // setViewBank(false)
        // dispatch(getBank())
        // dispatch(getShop())
    }

    const shopSettingDisplay = () => {
        return(
            <form onSubmit={formik.handleSubmit}>
                                    <div >
                                        <h5>Shop Setting</h5>
                                        <Row>
                                            <Col md={11}>
                                                <label style={{fontWeight:300, fontSize:13}}>Manage and protect your shop</label>
                                            </Col>
                                            <Col >
                                                <Button className="float-end" type="submit" variant="primary">Save</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', textAlign:'center', justifyContent:'center', marginTop:20}}>
                                        <img onClick={handleChangeShopPic} src={shopList.image ? shopList.image.path : '/assets/buyer/images/noImage.jpg'} className="profilePicDisplay profilePicLG"/>
                                    </div>

                                    <Card.Body>
                                    <Container fluid className="mt-3">
                                    
                                        <Row className="formGroup">
                                            <Col xs={4} md={3} className="formTitle">
                                                Shop Name
                                            </Col>
                                            <Col xs={12} md={6} className="formDataReadOnly">
                                                <Form.Control className='customInput form-control' 
                                                    id='shopname' 
                                                    name='shopname' 
                                                    type='text' 
                                                    placeholder='Shop Name' 
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.shopname} 
                                                />
                                                {formik.touched.shopname && formik.errors.shopname ? <p className='validation'>{formik.errors.shopname}</p> : null}
                                            </Col>
                                        </Row>
                                        <Row className="formGroup">
                                            <Col xs={6} md={3} className="formTitle">
                                                Shop Description
                                            </Col>
                                            <Col xs={12} md={6} className="formDataReadOnly">
                                                <Form.Control className='customInput form-control' 
                                                    id='shopdescription' 
                                                    name='shopdescription' 
                                                    type='text' 
                                                    placeholder='Shop Description' 
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.shopdescription} 
                                                />
                                                {/* {formik.touched.shopdescription && formik.errors.shopdescription ? <p className='validation'>{formik.errors.shopdescription}</p> : null} */}
                                            </Col>
                                        </Row>
                                        <Row className="formGroup">
                                            <Col xs={6} md={3} className="formTitle">
                                                Status
                                            </Col>
                                            <Col xs={12} md={6} className="formDataReadOnly">
                                                {shopList.status === 0 ? <span style={{color: 'Red'}}>Inactive</span> : <span style={{color: 'Blue'}}>Active</span>}
                                            </Col>
                                        </Row>
                                        <Row className="formGroup">
                                            <Col xs={6} md={3} className="formTitle">
                                                My Bank Account
                                            </Col>
                                            <Col xs={12} md={6} className="formDataReadOnly">
                                                { bank_name
                                                    ? <>
                                                        
                                                        <Form.Control className='customInput form-control' 
                                                            id='bankaccount' 
                                                            name='bankaccount' 
                                                            type='text' 
                                                            placeholder={bank_name} 
                                                            onChange={formik.handleChange} 
                                                            onBlur={formik.handleBlur} 
                                                            value={formik.values.bankaccount} 
                                                            onClick={selectViewBank}
                                                        />
                                                        {/* <Form.Select 
                                                            id="bankaccount" 
                                                            name="bankaccount"
                                                            onChange={formik.handleChange} 
                                                            onBlur={formik.handleBlur} 
                                                            value={formik.values.bankaccount} >
                                                            
                                                            {
                                                                bank_list && bank_list.map((res) => {
                                                                    if(res.is_active === 1){
                                                                        return(
                                                                            <>
                                                                                <option>Select Bank Account</option>
                                                                                <option Selected value={res.id}>{res.bank_name}</option>
                                                                            </>
                                                                        )
                                                                        
                                                                    }else{
                                                                        return(
                                                                            <>
                                                                                <option selected>Select Bank Account</option>
                                                                                <option value={res.id}>{res.bank_name}</option>
                                                                            </>
                                                                        )
                                                                    }
                                                                    // return(
                                                                    //     <option value={res.id}>{res.bank_name}</option>
                                                                    // ) 
                                                                })
                                                            }
                                                        </Form.Select>
                                                        <br/>
                                                        <Button type="submit" variant="primary" className="btn-space" size="sm" onClick={selectViewBank}>View Bank List</Button>
                                                        <Button type="submit" variant="primary" className="btn-space" size="sm" onClick={selectBank}>Add Bank</Button> */}
                                                    </>
                                                    : <>
                                                        <Form.Control className='customInput form-control' 
                                                            id='bankaccount' 
                                                            name='bankaccount' 
                                                            type='text' 
                                                            placeholder='No Account Yet' 
                                                            onChange={formik.handleChange} 
                                                            onBlur={formik.handleBlur} 
                                                            value={formik.values.bankaccount} 
                                                            onClick={selectBank}
                                                        />
                                                        
                                                    </>
                                                }
                                                {formik.touched.bankaccount && formik.errors.bankaccount ? <><br/><p className='validation'>{formik.errors.bankaccount}</p></> : null}
                                            </Col>
                                        </Row>
                                    </Container>
                                    </Card.Body>
                                </form>
        )
    }
    const displayBank = () => {
        return(
            <Container >
                <Row>
                    <Col >
                        <Card style={{marginBottom:10, padding:30, borderStyle:'none'}}>
                                <div >
                                    <Row>
                                        <Col>
                                            <h5><img onClick={setAddOff} src="/assets/buyer/icons/BackArrowIcon.png" style={{width:40, height:40, zIndex:2222222}}/>My Bank Account</h5>
                                        </Col>
                                        <Row>
                                            <Col md={11}>
                                                
                                            </Col>
                                            <Col >
                                                <Button className="float-end" type="submit" variant="primary" onClick={setAddOff}>Back</Button>
                                            </Col>
                                        </Row>
                                    </Row>
                                </div>
                                <hr />
                                <Card.Body>

                                                <Row className="formGroup">
                                                    <Col md={8} lg={10} xs={10} sm={10}>
                                                            
                                                    </Col>
                                                    <Col md={2}>
                                                    {bankList ? 
                                                        <label style={{textAlign:'right', color:'#3E6AE3'}}>
                                                            <span onClick={() => setEditOn(bankList)}>
                                                                <strong style={{fontWeight:'700'}}>
                                                                    Edit
                                                                </strong>
                                                            </span> 
                                                            | 
                                                            <span onClick={() => setDeleteBank(bankList?.id)}>
                                                                <strong style={{fontWeight:'700'}}>
                                                                    Delete
                                                                </strong>
                                                            </span>
                                                        </label>
                                                        :
                                                        null
                                                    }
                                                    </Col>
                                                    <Col md={12}>
                                                        <strong style={{fontWeight:'700'}}>{bankList?.bank_name}</strong>
                                                    </Col>
                                                    <Col md={12}>                                            
                                                    {bankList?.account_number ? '*********' + bankList?.account_number.toString().substr(-4) : null}
                                                    </Col>
                                                    <Col md={12}>                                            
                                                        {bankList?.account_name}
                                                    </Col>
                                                </Row>
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <div> 
            <Container >
                <Row>
                    <Col >
                        <Card style={{marginBottom:10, padding:30, borderStyle:'none'}}>
                            {bank ?
                                <BankForm 
                                    edit_bank={edit_bank}
                                    edit_bank_list={edit_bank_list}
                                    setEditOff={setEditOff}
                                    setAddOff={setAddOff}
                                    />
                            : <>
                                {bank_view_bank 
                                ?
                                    displayBank()

                                : 
                                    shopSettingDisplay()
                                }
                                
                            </>
                        }
                        </Card>
                        
                    </Col>
                </Row>
            </Container>
            <ChangeShopPic
                show={open_modal}
                onHide={() => setOpenModalChangeShopPic(false)}
                handleChangeShopPicClose={handleChangeShopPicClose}/>
        </div>
    )
}

export default Shop
