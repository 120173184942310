import React, { useEffect, useState } from 'react'
import { Navbar, Dropdown, Container, Nav, NavDropdown, InputGroup, FormControl, Button, Row, Col, Badge } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { clearCart } from '../../../features/CartSlice';
import { getNotifications, LogOut, selectUser } from '../../../features/UserSlice';
import ListNotification from '../Notifications/ListNotification';
import Cookies from 'universal-cookie';

function SubHeader() {
    const history = useHistory();
    const dispatch = useDispatch();
    const cookie = new Cookies();

    let name = useSelector((state) => state.user.login?.name);
    const { isLoggedIn } = useSelector(selectUser) || {}
    const [showDropdownNotif, setShowDropdownNotif] = useState(false);
    const notifications = useSelector((state) => state.user.notifications);
    const [showDropdown, setShowDropdown] = useState(false);

    const LogoutHandler = () => {
        dispatch(LogOut()).then((resp) => {
            history.push('/login');
            cookie.remove('userId')
            cookie.remove('shop_id')
        });

        // dispatch(clearCart());
    }

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getNotifications())
        }
        
    }, [])

    const UserIconMenu = () => {
        return (
            <>
                <img src="/assets/buyer/icons/MenuUserIcon.png" className="img-fluid mb-1" width={20} />
                {name || ""}
            </>
        )
    }

    const NotificationIconMenu = () => {
        return (
            <>
                <img src="/assets/buyer/icons/NotificationIcon.png" id="dropdown-basic" className="img-fluid mb-1" width={20} /><label>
                    <img src="/assets/buyer/icons/StatusCircleIcon.png" style={{ width: 12, position: 'relative', right: 7, bottom: 8 }} className="img-fluid" /></label>
                <label style={{ position: 'relative', right: '5px' }}>Notification</label>
            </>
        )
    }


    const NotificationMenu = () => {
        return (
            <NavDropdown
                onMouseOver={() => setShowDropdownNotif(true)}
                onMouseLeave={() =>
                    setTimeout(() => setShowDropdownNotif(false), "700")
                }
                show={showDropdownNotif}
                className="rightNavMenuClass"
                title={NotificationIconMenu()}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                align="end"
                flip
            >
                <Container style={{width: '40rem'}}>
                    <Row>
                        <Col md={8} >
                            <h5 style={{ fontWeight: 300, fontSize: 18, marginTop: '15px' }}>Received Notification</h5>
                        </Col>
                        <Col md={4} align="end">
                            <Link to="/notifications"><label style={{ fontSize: 13, color: '#161F92', cursor: 'pointer', marginTop: '15px' }}>{'View all(' + notifications.length + ')'}</label></Link>
                        </Col>
                    </Row>
                    <hr style={{ marginTop: 0 }} />

                    <ListNotification data={notifications} />
                </Container>
            </NavDropdown>
        )
    }

    const SettingMenu = () => {
        return (
            <Nav
                onMouseOver={() => setShowDropdown(true)}
                onMouseLeave={() =>
                    setTimeout(() => setShowDropdown(false), "500")
                }
            >
                <NavDropdown
                    show={showDropdown}
                    className="rightNavMenuClass"
                    title={UserIconMenu()}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    align="end"
                    flip

                >
                    <NavDropdown.Item className="dropdownItemClass" as={Link} to="/my_account">
                        My Account
                    </NavDropdown.Item>
                    <NavDropdown.Item className="dropdownItemClass" as={Link} to="/my_purchase">
                        My Purchase
                    </NavDropdown.Item>
                    <NavDropdown.Item className="dropdownItemClass" onClick={() => LogoutHandler()}>
                        Logout
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>
        )
    }

    const RightNavMenu = () => {
        return (
            <>
                <Nav.Link as={Link} to="/" >
                    <img src="/assets/buyer/icons/HomeIcon.png" className="img-fluid mb-1" width={20} /> Home
                </Nav.Link>
                {NotificationMenu()}
                {SettingMenu()}
            </>
        )
    }

    return (
        <div>
            <Navbar className="buyer-navbar-custom" collapseOnSelect expand="lg" variant="light">
                <Container >
                    <Navbar.Brand href="#home">Follow ISN |</Navbar.Brand>
                    <Navbar.Toggle className="customMenuToggler" aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="https://www.instagram.com/idolshoppingnetworkofficial/" target="_blank">
                                <img
                                    src="/assets/buyer/icons/MenuInstaIcon.png"
                                    className="d-inline-block align-top"
                                    width="24"
                                />
                            </Nav.Link>
                            <Nav.Link href="https://www.facebook.com/idolshoppingnetworkofficial" target="_blank">
                                <img
                                    src="/assets/buyer/icons/MenuFBIcon.png"
                                    className="d-inline-block align-top"
                                    width="24"
                                />
                            </Nav.Link>
                        </Nav>
                        {
                            isLoggedIn ? (
                                <Nav>
                                    {RightNavMenu()}
                                </Nav>
                            ) : (
                                <Nav>
                                    <Nav.Link >Don’t have an account? <b><Link to="/sign_up" className="text-white" style={{ textDecoration: 'none' }}>Register</Link></b> &nbsp;|</Nav.Link>
                                    <Nav.Link  >
                                        <b><Link to="/login" className="text-white" style={{ textDecoration: 'none' }}>Login</Link> &nbsp; <img
                                            src="/assets/buyer/icons/LoginIconHeader.png"
                                            className="d-inline-block align-top mt-1"
                                            width="20"
                                        /></b>
                                    </Nav.Link>
                                </Nav>
                            )
                        }

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default SubHeader
