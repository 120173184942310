import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Row, Form, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import SubHeader from '../../components/buyer/Header/SubHeader';
import { ForgotPassword } from '../../features/UserSlice';
import { useDispatch, useSelector } from 'react-redux';

function BuyerForgotPassword() {
    const dispatch = useDispatch();   
    const FPResult = useSelector((state) => state.user.userForgotPass);
    const validationSignUp = {
        phone_number: Yup.string()
        .required("Phone Number is Required")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Phone number is not valid"
        ),
    }
    const formik = useFormik({
        initialValues:{
            phone_number: "",
        },
        validationSchema: Yup.object(validationSignUp),
        onSubmit: (values) => {
            dispatch(ForgotPassword(values))
        },
    });
    const validation_forgot_pass = () =>{
        if(Number(FPResult.status_code) === 201){
            return(<p style={{color: 'green'}}>{FPResult.message}</p>)
        }else if(Number(FPResult.status_code) === 401){
            return(<p style={{color: 'red'}}>{FPResult.message}</p>)
        }
    }
    return (
        <div>
            <SubHeader/>
            <div className="p-5">
                <Container>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={6}>
                            <img className="img-fluid" style={{width:450}} src="/assets/buyer/images/ISNBoxLogo.png"/>
                        </Col>
                        
                        <Col md={5}>
                            <Card className="shadow">
                                <Card.Body className="p-5">
                                    <Card.Title>Forgot Password</Card.Title>
                                    {validation_forgot_pass()}

                                    <form onSubmit={formik.handleSubmit}>
                                        <div className='input-container mb-3 mt-5'>
                                            <input className='customInput form-control' 
                                                id='phone_number' 
                                                name='phone_number' 
                                                type='text' 
                                                placeholder='Phone Number'
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.phone_number}
                                                style={{borderRadius:'0', backgroundColor:'#F4F4F4 !important', border:'none'}}
                                            />
                                            {formik.touched.phone_number && formik.errors.phone_number ? <p className='validation'>{formik.errors.phone_number}</p> : null}
                                        </div>
                                        <br/>
                                        <div className="d-grid gap-2">
                                            <Button type="submit" style={{backgroundColor:'#FFD700', borderColor:'#FFD700', borderRadius:28}} variant="primary" className="mt-3" type="submit">Forgot Password</Button>
                                        </div>
                                        <br/>
                                        <p style={{textAlign:'center'}}>
                                            I’m already a member, <Link style={{textDecoration:'none',color:'#161F92',fontWeight:'700'}} to="/login">Log In</Link>
                                        </p>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default BuyerForgotPassword
