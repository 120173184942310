import React from 'react'
import MainHeader from '../../../components/buyer/Header/MainHeader'
import Shop from '../../../components/seller/Setting/Shop'
import SellerMenu from '../seller_menu';
import { Container, Row, Col } from "react-bootstrap";
function ShopSetting() {
    return (
        <div>   
            <MainHeader/>   
            <Container className="mainContainer">
                <Row>
                    <Col xs={2} className="leftContainer">
                        <SellerMenu />
                    </Col>
                    <Col xs={10} className="rightContainer" >
                        <Shop/>
                        {/* <AddressList/> */}
                    </Col>
                </Row>
            </Container>  
        </div>
    )
}


export default ShopSetting
