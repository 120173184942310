import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';
import qs from 'qs';

const InitialState = {
  isLoading: false,
  Product:[],
  Shop: [],
  Pagination: []
}

export const fetchShopProduct = createAsyncThunk(
    'fetch/product_shop',
    async (id, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.get(`/store/view/${id}`)
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
)

export const fetchPagination = createAsyncThunk(
  'fetch/pagination',
  async (id,{rejectWithValue, dispatch}) => {
    try {
      const response = await api.get(`/my_shop_products/${id}`)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

  

export const ShopSlice = createSlice({
    name:"shop",
    initialState: InitialState,
    extraReducers: (builder) => {

              builder.addCase(fetchShopProduct.pending, (state, action) => {
                  state.isLoading = true
              })
              builder.addCase(fetchShopProduct.fulfilled, (state, action) => {
                  state.Product = action?.payload?.products || []
                  state.Shop = action?.payload?.shop || []
                  state.isLoading = false
              })
              builder.addCase(fetchShopProduct.rejected, (state, action) => {
                  state.isLoading = false
              })  
              builder.addCase(fetchPagination.pending, (state, action) => {
                state.isLoading = true
              })
              builder.addCase(fetchPagination.fulfilled, (state, action) => {
                  state.Pagination = action?.payload
                  state.isLoading = false
              })
              builder.addCase(fetchPagination.rejected, (state, action) => {
                  state.isLoading = false
              })  

    }
})



export default ShopSlice.reducer;