import React, { useEffect, useState } from 'react'
import {Container, Carousel, Row} from 'react-bootstrap'
import { getAdsList, selectWebAds } from '../../../features/AdsSlice';
import { useDispatch, useSelector } from 'react-redux';

function AdsSection() {

    const dispatch = useDispatch();

    const [ isLoading, setLoading ] = useState(false);

    useEffect(() => {
        dispatch(getAdsList()).then(resp => {
            setLoading(true);
        })
    }, [dispatch])

    const ads = useSelector(selectWebAds)
    const { webHomeAdsList} = useSelector((state) => state.ads);
    return (
        <div>
            {isLoading &&
               
                        <Carousel controls={webHomeAdsList.length > 1} variant="dark" style={{ width:'100%' }}>
                            {
                                webHomeAdsList.map((data) => {
                                    return (
                                        <Carousel.Item style={{ width:'100%'}}>
                                            <img alt="" src={data?.image?.path} style={{height: '100%',width:'100%'}}/>
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                
            }
        </div>
    )
}

export default AdsSection
