import { relativeTimeRounding } from 'moment';
import React,{ useState, useEffect } from 'react'
import { Col, Row, Container, Button, ButtonGroup } from 'react-bootstrap'
import { RatingView } from 'react-simple-star-rating'
import { getOptions } from '../../../features/ProductSlice';
import { addCart, ViewCart} from '../../../features/CartSlice';
import { useDispatch, useSelector } from 'react-redux';
import ProductZoom from '../ProductView/ProductZoom';
import { useHistory } from "react-router-dom";
import {cloneDeep } from 'lodash';
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';

function ProductViewDescription(props) {
    const cookie = new Cookies();
    const dispatch = useDispatch();
    const history = useHistory();
    const {activePriceDisplay} = useSelector((state) => state.price);

    const [Quantity, setQuantity] = useState(1);
    const [quantityError, setQuantityError] = useState(false);

    const variation = props.ProductViewDetails.variations;

    const varieties = props.ProductViewDetails.varieties;

    const [Options, setOptions] = useState('');

    const [option1, setOption1] = useState(null);
    const [option2, setOption2] = useState(null);

    useEffect(() => {
        if (variation?.length < 2) {
            setOptions(availabilityChecker(option1, null))
        }
    }, [option1])

    const availabilityChecker = (id, id2) => {
        var fetch = varieties?.find((option) => {
            if (variation.length > 1) {
                return option.option_variety.option1_id === id && option.option_variety.option2_id === id2
            } else {
                return option.option_variety.option1_id === id && option.option_variety.option2_id === null
            }
        })

        return fetch ? fetch : false
    }

    // const computedPrice =

    const computeDiscount = (data) => {
        return(
            <div>
            {  activePriceDisplay && activePriceDisplay === 'Normal Price' ?
                <label style={{marginRight:30,paddingLeft:100, fontWeight:500, fontSize:25}}>
                        {/* {data[0]?.price? `P${data[0]?.price * Quantity}` : null} */}
                        {
                            (data?.price * Quantity || varieties?.[0]?.price) ?('P' + new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(data?.price * Quantity || varieties?.[0]?.price)): ''
                        }

                </label>
                :activePriceDisplay && activePriceDisplay === 'Discounted Price'?
                <>
                {
                    data?.discounted && (data?.discounted !== "0" && data?.discounted !== 0)?
                    <>
                    <label style={{marginRight:30, fontWeight:500, fontSize:25, color:'#747474'}}>
                        <del>
                            {data?.price? `P${data?.price * Quantity || "0.00"}` : null}
                        </del>
                    </label>
                    <label style={{fontSize:25, fontWeight:500, color:'#CF0E0E'}}>
                        {data?.discounted? `P${data?.discounted * Quantity || "0.00"}` : null}
                    </label>
                    </>
                    :
                    <label style={{marginRight:30,paddingLeft:100, fontWeight:500, fontSize:25}}>
                        P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(data?.price * Quantity || varieties?.[0]?.price)}
                    </label>
                }
                </>
                :null
            }
            </div>
        )
    }

    const checkDisable = (option, index) => {
        if (variation.length > 1) {
            if (index === 0) {
                return false
            }

            if (option1 != null) {
                return availabilityChecker(option1, option.id) ? false : true
            }
        } else {
            return availabilityChecker(option.id, null) ? false : true
        }
    }

    const checkDisableButton = () => {
        return Options ? Options.stocks > 0 ? false : true : true
    }

    const checkDisableQuantity = (type) => {
        if (type === 'plus') {
            if ((Quantity + 1) > Options.stocks) {

                return true
            }
        } else {
            if ((Quantity - 1) <= 0) {
                return true
            }
        }

        return false
    }

    const selectedActive = (option, index) => {
        if (index === 0) {
            return option.id === option1
        } else {
            return option.id === option2
        }
    }

    const selectOption = (option, index) => {
        if (index === 0) {
            setOption1(option.id)

            if (variation.length > 1) {
                setOption2(null)
            }
        } else {
            setOption2(option.id)
            setOptions(availabilityChecker(option1, option.id))
        }

        setQuantity(1)
    }

    const handleIncrement = () =>{
        setQuantity(Quantity + 1)
    }

    const handleDecrement = () =>{
        setQuantity(Quantity - 1)
    }

    const data = props.ProductViewDetails

    const handleProceedLogin = () => {
        history.push('/login');
    }

    const increase = () => {
        props.setData(props.count + 1)
        console.log('count: ',props.count)
    }

    const addToCart = (type) => {
        var payload = {
            'shop_id' : props.ProductViewDetails?.shop_to_seller?.shop?.id,
            'product_id' : props.ProductViewDetails?.id,
            'variety_id' : Options?.id,
            'price' : Options?.price,
            'quantity' : Quantity,
            'currency' : 'PHP',
            'is_buynow' : !type
        }

        dispatch(addCart(payload)).then((resp) =>
            {
                if (resp.payload.data.success) {
                    increase()
                    toast.success(resp.payload.data.message, {
                        position: "bottom-left",
                    })
                } else {
                    toast.error(resp.payload.data.message, {
                        position: "bottom-left",
                    })
                }


                if (!type) {
                    history.push('/idol_cart/');

                }

            }
        );
    }

    const addToCartHandler = () => {
        cookie.get('access_token') ? addToCart(true) : handleProceedLogin()
    }

    const buyNowHandler = () => {
        cookie.get('access_token') ? addToCart(false) : handleProceedLogin()
    }

    return (
        <Row>
            <Col md={4}>
                <ProductZoom Options={Options} ProductViewDetails={props.ProductViewDetails}/>
            </Col>
            <Col md={8}>
                <div>
                    <Container>
                        <Row>
                            <Col><h4>{data.product_name}</h4></Col>
                                <Col xs={12} sm={4}>
                                    { computeDiscount(Options) }
                                </Col>
                        </Row>
                        <Row>
                            <Col sm={6} xs={12} md={12}>
                                <label><RatingView ratingValue={data.reviews_sum_rating / data.reviews_count} size={17} /></label>
                                <label style={{marginLeft:10}}> |</label>
                                <label style={{marginLeft:10}}> { isNaN(+data.reviews_sum_rating) && isNaN(+data.reviews_count) ? data.reviews_sum_rating / data.reviews_count : 0}/5 ({data.reviews_count} reviews)</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12} md={10}>
                                {data.product_description}
                            </Col>
                        </Row>

                        <div className="mt-5">
                            {
                                variation?.map((variation, variation_index) => {
                                        return(
                                            <Row key={variation.id} className="mt-3">
                                                <Col md={2} className="mt-2">
                                                    <label>{variation.variation_name} </label>
                                                </Col>
                                                <Col md={9} xs={12} sm={12}>
                                                    <Row>
                                                    {
                                                        variation?.options?.map((option, index) => {
                                                                return(
                                                                    <Col key={option.id} className="ml-2" sm={6} md={3} xs={12}>
                                                                        <Button className="colorBtnVariant mt-2 form-control" active={selectedActive(option, variation_index)} disabled={checkDisable(option, variation_index)} onClick={() => selectOption(option, variation_index)}><span className='color-black'>{option.option}</span>
                                                                        </Button>
                                                                    </Col>
                                                                )
                                                            }
                                                        )
                                                    }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                )
                            }
                        </div>

                        <Row className="mt-2">
                            <Col md={2} className="mt-2">
                                <label>Stock</label>
                            </Col>
                            <Col md={2} xs={12} className="mt-2">
                                { Options?.stocks }
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={2} className="mt-2">
                                <label>Quantity</label>
                            </Col>
                            <Col md={10} xs={12} sm={12}>
                                <Row>
                                    <Col md={3} xs={12}>
                                        <ButtonGroup>
                                            <Button className="quantityBtnVariant" variant="secondary" disabled={checkDisableQuantity('minus')} onClick={handleDecrement}>-</Button>
                                            <Button className="quantityBtnVariantCount" variant="secondary">{Quantity}</Button>
                                            <Button className="quantityBtnVariant" variant="secondary" disabled={checkDisableQuantity('plus')} onClick={handleIncrement}>+</Button>
                                        </ButtonGroup>
                                        {quantityError ? <span className='validation'>Quantity should not be less than 1</span> : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={2} className="mt-2">
                            </Col>
                            <Col md={10} xs={12} sm={12}>
                                <Row>
                                    <Col md={4} xs={12}>
                                        <Button className="AddToCartBtn mt-2" disabled={checkDisableButton()} variant="secondary form-control" onClick={() => addToCartHandler()}>Add To Cart</Button>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <Button className="AddToCartBtn mt-2" disabled={checkDisableButton()} variant="secondary form-control" onClick={() => buyNowHandler()}>Buy Now</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
    )
}

export default ProductViewDescription
