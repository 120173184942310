import React , {useRef} from 'react'
import {Card, Form, Row, Col} from 'react-bootstrap'

function CheckOutOrder(props) {
    const checkRef = useRef(0)

    const {
        order,
        checkHandler
    } = props


    const isChecked = () => {
        return order.order_items.length === order.order_items.filter(x => x.status == 'cart_checked').length
    }

    const check = () => {
        checkHandler(order, 'shop', checkRef)
    }

    const borderBottom = {
        backgroundColor:'#FFFF',
        width:'100%',
        backgroundColor:'#FFFF',
        borderTopColor:'#FFFF',
        borderRightColor:'#FFFF',
        borderLeftColor:'#FFFF',
        borderBottomColor:'yellow',
        borderBottomWidth:  '5px',
        borderStyle:'solid'
    }

    return (
        <Card.Body style={borderBottom}>
            <Row>
                <Col sm={12}>
                    <img className="img-fluid mx-3" style={{width:40}} src="/assets/buyer/icons/ShopIcon.png"/>
                    <label className="p-3"><b>{order?.shop?.name}</b></label>
                </Col>
            </Row>
        </Card.Body>
    )
}

export default CheckOutOrder
