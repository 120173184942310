import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'; 
import NoOrder from '../../service/NoOrder';
import OrderItem from '../../common/OrderItem';

function ReturnList({returnedOrderList}) {

    return (
        <div className="mt-2">

                <div id="cancelled_container"> 
                            {returnedOrderList && returnedOrderList.length === 0 ?
                                <NoOrder/> :
                                returnedOrderList.length !== 0 && returnedOrderList.map((order)=>(
                                        <div className="productPreviewContainer" key={order.shop_id} id={"transaction_"+order.shop_id}>

                                        
                                    
                                <React.Fragment>
                                                <Container className="salesShopName">
                                                <Row>
                                                        <Col xs={6} style={{textAlign: "left"}}>
                                                                <img 
                                                                    alt=""
                                                                    src="/seller_icons/store_icon_1.png" 
                                                                    style={{
                                                                        height: "17px", 
                                                                        marginRight: "10px"}}    
                                                                        />
                                                                        {order?.shop?.name || ""}
                                                        </Col>
                                                        <Col xs={6} style={{ textAlign: "right" }}>
                                                                <Nav.Link as={Link} to={`/order_details/${order.id}`}
                                                                    style={{
                                                                        color:'#161F92', 
                                                                        cursor:'pointer'}}> 
                                                                        View &nbsp; | &nbsp;
                                                                        <label 
                                                                        style={{
                                                                            color:'#535353', 
                                                                            fontWeight:300}}
                                                                            >ORDER ID# {order.rc_id}
                                                                        </label>
                                                                </Nav.Link> 
                                                        </Col>
                                                </Row>
                                                </Container>


                                                <Container className="salesProductPreview">

                                                {order.order_items.length !== 0 && order.order_items.map((item)=>(
                                                                <OrderItem 
                                                                        item={item} 
                                                                />
                                                ))}


                                                        <div className="transactionContainer">
                                                                <Row>
                                                                        <Col xs={6} style={{textAlign: "left"}}>
                                                                                <h6>Transaction ID</h6>
                                                                        </Col>
                                                                        <Col xs={6} style={{textAlign: "right"}}>
                                                                                <h6>#{order?.transaction_id}</h6>
                                                                        </Col>
                                                                </Row>
                                                        </div>
                                                </Container>
                                                        <div className="transactionShipContainer m-2">
                                                                {/* Waiting to receive the products and make payment on delivery by June 11, 2021   */}
                                                        </div>
                                </React.Fragment>
                                        </div>
                                        )
                                    )      
                                }
                    </div>

            </div>

    )
}

export default ReturnList
