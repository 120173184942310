import React from 'react'
import SubHeader from '../components/buyer/Header/SubHeader'
import { useSelector } from 'react-redux'
import MainHeader from '../components/buyer/Header/MainHeader';
import {Card, Container} from 'react-bootstrap'
import SubSectionTitle from '../components/buyer/Section/SubSectionTitle';
import BackButton from '../components/common/Buttons/BackButton'

function AboutIsn() {

    let is_login = useSelector((state) => state.user.loggedIn);

    return (
        <div>
            
            {is_login ? (
                    <MainHeader/>
                ) :
                <SubHeader/>
            }
            
            <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30,
            }}>

                <BackButton/>

                <SubSectionTitle SubSectionTitle="About Idol Shopping Network" />
                
                <div className="mt-3">
                    <Container fluid>
                    
                        <Card>
                        
                            <Card.Body >
                                <div className="p-5" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <img src="/assets/buyer/images/ISNBoxLogo.png" width={280}/>                             
                                </div>
                                <div className="p-5">
                                    <p style={{textAlign:'justify', fontSize:25}}>

                                        Idol Shopping Network or ISN is a social e-commerce platform tailored for Filipino merchants and consumers; providing them a secure, one-stop online shopping experience. It was established and launched in the Philippines in 2021, creating a wide selection of products from local sellers.
                                        We ensure that all merchants are well-screened to guarantee that the items to be sold are authentic and of good quality. We aim to make a difference in our customers, merchants, and employee's lives.

                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </div>
            
        </div>
    )
}

export default AboutIsn
