import React from 'react';
import { Container, Row, Col, Nav, Button, Tabs, Tab } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import NoOrder from '../../../components/service/NoOrder';
import OrderItem from "../../../components/common/OrderItem"



const SalesUnpaid = ({unpaidOrderList}) => {


        return (
                <React.Fragment>
                <div id="transaction_details" style={{marginBottom: '15px'}} style={{display: "none"}}>
                        <Container className="bgTransaction1" style={{textAlign: "left"}}>
                                <Row>
                                        <Col xs={12}>
                                        <img src="/seller_icons/pinpoint_icon.png" style={{width: "25px", marginRight: "10px"}}/><span style={{fontWeight: "600"}}>Delivery Date | Default</span>
                                        
                                        </Col>

                                        <Col xs={12}>
                                        <span>Juan Dela Cruz</span><br />
                                        <span>09094485948</span><br />
                                        <span>Block 50 Lot 21 Phase 5 Sta. Ana Brgy. South Ville, Poblacion Markinina </span>
                                        </Col>
                                </Row>
                        </Container>

                        <Container className="bgTransaction2" style={{textAlign: "left"}}>
                                <Row>
                                        <Col xs={12}>
                                        <img src="/seller_icons/truck_icon.png" style={{width: "25px", marginRight: "10px"}}/><span style={{fontWeight: "600"}}>Shipping Option</span><br />
                                        </Col>

                                        <Col xs={6}>
                                        <span style={{fontWeight: "600"}}>Ninja Van</span><br />
                                        <span>Received by 20 Jun - 24 Jun</span><br />
                                        </Col>

                                        <Col xs={6} style={{textAlign: "right"}}>
                                                <h2>P40.00</h2>
                                        </Col>
                                </Row>
                        </Container>

                        <Container className="bgTransaction2" style={{textAlign: "left"}}>
                                <Row>
                                        <Col xs={12}>
                                        <img src="/seller_icons/peso_icon.png" style={{width: "25px", marginRight: "10px"}}/><span style={{fontWeight: "600"}}>Payment Details</span><hr />
                                        </Col>

                                        <Col xs={12}>
                                                <Row>
                                                        <Col xs={6} style={{fontWeight: "600", fontSize: "25px"}}>Merchandise Subtotal</Col>
                                                        <Col xs={6} style={{textAlign: "right", fontSize: "25px", fontWeight: "600", color: "#CF0E0E"}}>P750.00</Col>
                                                </Row>
                                                <Row>
                                                        <Col xs={6} style={{fontWeight: "600", fontSize: "25px"}}>Shipping Fee</Col>
                                                        <Col xs={6} style={{textAlign: "right", fontSize: "25px", fontWeight: "600", color: "#CF0E0E"}}>P40.00</Col>
                                                </Row>
                                                <Row>
                                                        <Col xs={6} style={{fontWeight: "600", fontSize: "25px"}}>Total Payment</Col>
                                                        <Col xs={6} style={{textAlign: "right", fontSize: "25px", fontWeight: "600", color: "#CF0E0E"}}>P790.00</Col>
                                                </Row>
                                                <Row>
                                                        <Col xs={6} style={{fontWeight: "600", fontSize: "25px"}}>Payment Option</Col>
                                                        <Col xs={6} style={{textAlign: "right", fontSize: "25px", fontWeight: "600"}}>Credit / Debit Card*1234</Col>
                                                </Row>
                                        </Col>
                                </Row>
                        </Container>

                        <div id="transactionOrder" />
                        </div>






            <div id="sales_container">
            {unpaidOrderList && unpaidOrderList.length === 0 ?
                                <NoOrder/> :
                                unpaidOrderList.length !== 0 && unpaidOrderList.map((order)=>(
                                <div className="productPreviewContainer" key={order.id} id={"transaction_"+order.shop_id}>
            
                                
                               
                              

                                       <React.Fragment>
                                        <Container className="salesShopName">
                                        <Row>
                                                <Col xs={6} style={{textAlign: "left"}}>
                                                        <img    
                                                                alt=""
                                                                src="/seller_icons/store_icon_1.png" 
                                                                style={{
                                                                        height: "17px", 
                                                                        marginRight: "10px"
                                                                        }} 
                                                                />
                                                                {order?.shop?.name || ""}
                                                </Col>
                                                {/* <Col xs={6} style={{textAlign: "right"}}>
                                                        ORDER ID# {order.id}
                                                </Col> */}
                                                <Col xs={6} style={{ textAlign: "right" }}>
                                                        <Nav.Link as={Link} to={`/seller_order_details/${order.id}`}
                                                            style={{
                                                                color:'#161F92', 
                                                                cursor:'pointer'}}> 
                                                                View &nbsp; | &nbsp;
                                                                <label 
                                                                style={{
                                                                    color:'#535353', 
                                                                    fontWeight:300}}
                                                                    >ORDER ID# {order.rc_id}
                                                                </label>
                                                        </Nav.Link> 
                                                </Col>
                                        </Row>
                                        
                                        </Container>


                                        <Container className="salesProductPreview">
                                                {
                                                        order.order_items.length !== 0 && order.order_items.map((item)=> (
                                                                <OrderItem key={item.id} item={item} />
                                                        ))
                                                }
                                                <div className="transactionContainer">
                                                        <Row>
                                                                <Col xs={6} style={{textAlign: "left"}}>
                                                                        <h6>Transaction ID</h6>
                                                                </Col>
                                                                <Col xs={6} style={{textAlign: "right"}}>
                                                                        <h6>#{order?.transaction_id}</h6>
                                                                </Col>
                                                        </Row>
                                                </div>
                                        </Container>
                                </React.Fragment>
                                                       
                                        
                                
                        </div>
                                )
                                )
                                
                        }

            </div>
            </React.Fragment>
            );
    }
    
    export default SalesUnpaid;