import React from 'react'
import {  Row, Col, Modal, Button,Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';


const DeleteModal = ({
    productId,
    setOpenModal,
    openModal,
    handleDelete
}) => {
    return (
                                 
            <Modal
                key={productId}
                show={openModal}
                onHide={()=>setOpenModal(false)}
                centered
                // className="cancelRequestModal"
            >
            
                <Modal.Header style={{backgroundColor:"#161f92",height:50}}>
                
                </Modal.Header>

                <Modal.Body> 
                    <div className="m-4">
                        <h4 align="center" className="">
                            Are you sure you want to delete this ?
                        </h4>
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Container>
                        <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Col md={4}>
                                <Button 
                                    className="form-control cancelRequestBtn" 
                                    onClick={()=>setOpenModal(false)}
                                    >
                                    Cancel
                                </Button> 
                            </Col>
                            <Col md={4}>
                                <Button  
                                    className="form-control confirmCancelRequestBtn"
                                    onClick={()=>handleDelete(productId)}
                                    >
                                    Confirm
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
               
    )
}

export default DeleteModal;