import { Formik, useFormikContext } from "formik";
import React, {useEffect} from 'react';

const FocusError = (props) => {
    const {setIsSaving, setIsPublishing} = props
    const { errors, isSubmitting, isValidating } = useFormikContext();
  
    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let keys = Object.keys(errors);
        if (keys.length > 0) {
          setIsSaving(false)
          setIsPublishing(false)
          const selector = `[for=${keys[0]}]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
          }
        }
        
      }
    }, [errors, isSubmitting, isValidating]);
  
    return null;
  };

  
export default FocusError;