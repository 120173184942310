import React from 'react'
import MainHeader from '../../../components/buyer/Header/MainHeader'
import AddressList from '../../../components/seller/Addresses/AddressList'
import SellerMenu from '../seller_menu';
import { Container, Row, Col } from "react-bootstrap";
// import SellerMenu from '../../../pages/seller/seller_menu';
function ShipmentSettings() {
    return (
        <div>   
            <MainHeader/>   
            <Container className="mainContainer">
                <Row>
                    <Col xs={2} className="leftContainer">
                        <SellerMenu />
                    </Col>
                    <Col xs={10} className="rightContainer" >
                        <AddressList/>
                    </Col>
                </Row>
            </Container>  
            {/* <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30}}>
                
                <AddressList/>
                
            </div> */}
        </div>
    )
}


export default ShipmentSettings
