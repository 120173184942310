import React from 'react'
import { Container, Row, Col, Nav, Button, Card, Form } from "react-bootstrap";

function BuyerChangePassword(props) {
    return (
        <div>
            <Container >
                <Row>
                    <Col >
                        <Card style={{marginBottom:10, padding:30, borderStyle:'none'}}>
                            <div>
                            <img onClick={props.cancelChangePass} src="/assets/buyer/icons/BackArrowIcon.png" style={{width:40, height:40, marginTop:10, zIndex:2222222}}/>
                                <h5>Change Password</h5>
                                <Row>
                                    <Col>
                                        <label style={{fontWeight:300, fontSize:13}}>Keep your account safe, please verify your identity by entering your password.</label>
                                    </Col>
                                </Row>
                            </div>
                            <hr/>
                            <Card.Body>
                                <Container fluid className="mt-2">
                                    <Row className="formGroup">
                                        <Col xs={4} md={3} className="formTitle">
                                            Current Password
                                        </Col>
                                        <Col xs={12} md={6} className="formDataReadOnly ">
                                            <Form.Control></Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className="formGroup">
                                        <Col xs={4} md={3} className="formTitle">
                                            New Password
                                        </Col>
                                        <Col xs={12} md={6} className="formDataReadOnly ">
                                            <Form.Control></Form.Control>
                                        </Col>
                                    </Row>

                                    <Row className="formGroup" align="center">
                                        <Col>
                                            <Button className="saveChangePassword">Save</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                        
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BuyerChangePassword
