import React,{useEffect, useState} from 'react';
import { Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import SellerMenu from '../seller_menu';
import ProductDraft from './product_draft';
import ProductApproval from './product_approval';
import ProductLive from './product_live';
import ProductSoldOut from './product_soldout';
import ProductDelisted from './product_delisted';
import MainHeader from '../../../components/buyer/Header/MainHeader';
import { useDispatch } from 'react-redux';
import { getProfileDetails } from '../../../features/UserSlice';

const Products = () => {

        const dispatch = useDispatch();
        const [sellerDetails, setSellerDetails] = useState();
       
        useEffect(() => {
            dispatch(getProfileDetails()).then(resp => {
                setSellerDetails(resp.payload)
            })

        },[]);
         
        return (
            <div>
                <MainHeader />
                <Container className="mainContainer">
                    <Row>
                        <Col xs={2} className="leftContainer">
                            <SellerMenu />
                        </Col>

                        <Col xs={10} className="rightContainer" >

                            <div className="productsTabContainer">
                                <Tabs fill variant="tabs" unmountOnExit={true} defaultActiveKey="draft" style={{display:sellerDetails?.user?.seller?.shop?.status != 0 ? '' : 'none'}}  className="productsTab">
                                    <Tab mountOnEnter={true} eventKey="draft" title="Draft">
                                        <ProductDraft 
                                            waiting_approval={sellerDetails?.seller?.shop?.status}
                                        />
                                        
                                    </Tab>
                                    <Tab mountOnEnter={true} unmountOnExit={true} eventKey="approval" title="For Approval">
                                        <ProductApproval 
                                            waiting_approval={sellerDetails?.seller?.shop?.status}
                                        />
                                    </Tab>
                                    <Tab mountOnEnter={true} unmountOnExit={true} eventKey="live" title="Live">
                                        <ProductLive 
                                            waiting_approval={sellerDetails?.seller?.shop?.status}
                                        />
                                    </Tab>
                                    <Tab mountOnEnter={true} unmountOnExit={true} eventKey="soldOut" title="Sold Out">
                                        <ProductSoldOut 
                                             waiting_approval={sellerDetails?.seller?.shop?.status}
                                        />
                                    </Tab>
                                    <Tab mountOnEnter={true} unmountOnExit={true} eventKey="delisted" title="Delisted">
                                        <ProductDelisted 
                                            waiting_approval={sellerDetails?.seller?.shop?.status}
                                        />
                                    </Tab>
                                </Tabs>
                            {/* {
                                productDraft?.length != 0 ? (
                                    <Tabs fill variant="tabs" defaultActiveKey="draft" className="productsTab">
                                        <Tab eventKey="draft" title="Draft">
                                            
                                            <ProductDraft />
                                            
                                        </Tab>
                                        <Tab eventKey="approval" title="For Approval">
                                            <ProductApproval />
                                        </Tab>
                                        <Tab eventKey="live" title="Live">
                                            <ProductLive />
                                        </Tab>
                                        <Tab eventKey="soldOut" title="Sold Out">
                                            <ProductSoldOut />
                                        </Tab>
                                        <Tab eventKey="delisted" title="Delisted">
                                            <ProductDelisted />
                                        </Tab>
                                    </Tabs>
                                ) : (
                                    <NoProduct waiting_approval={sellerDetails?.user?.seller?.shop?.status}/>
                                )
                            } */}
                            
                            </div>
                            <div className="d-grid gap-2">
                                <Button href="/seller/products/add_product" variant="primary" style={{borderRadius: "0px"}}>+ Add New Products</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
}

export default Products;