
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';
import { toast } from "react-toastify";

const initialState = {
    LogisticList : [],
    ShippingOptionList : [],
    isLoading: false,
}


export const logisticPick = createAsyncThunk(
    'view/setlogistic',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.post('/seller/shipment_settings/', _);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const getLogisticAvailable = createAsyncThunk(
    'view/viewLogistics',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.get('/shipment/methods');
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }

    }
)

export const getShippingOption = createAsyncThunk(
    'fetch/shippingOption',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const data = await api.get(`seller/shipment_settings/${_}`);
        return data.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
)

export const logisticSlice = createSlice({
    name:"address",
    initialState: initialState,
    extraReducers: (builder) => {

        builder.addCase(getShippingOption.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getShippingOption.fulfilled, (state, action) => {
            state.ShippingOptionList = action.payload
            state.isLoading = false
        })
        builder.addCase(getShippingOption.rejected, (state, action) => {
            state.isLoading = true
        })

        builder.addCase(logisticPick.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(logisticPick.fulfilled, (state, action) => {
            toast.success('Successfully Set Courier', {
                position: "bottom-left",
            });
            state.isLoading = false
        })
        builder.addCase(logisticPick.rejected, (state, action) => {
            state.isLoading = true
        })

        builder.addCase(getLogisticAvailable.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getLogisticAvailable.fulfilled, (state, action) => {
            let list = action.payload?.data
            state.LogisticList = list
            state.isLoading = false
        })
        builder.addCase(getLogisticAvailable.rejected, (state, action) => {
            state.isLoading = true
        })
    }
})

export default logisticSlice.reducer;