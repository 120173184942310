import React, { useEffect, useState } from 'react';
import MainHeader from '../../components/buyer/Header/MainHeader'
import {Row, Col, Container, Pagination} from 'react-bootstrap'
import ProductCard from '../../components/common/ProductCard'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchShopProduct, fetchPagination } from '../../features/ShopSlice';
import { RatingView } from 'react-simple-star-rating'
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function BuyerShop() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [isReadMore, setIsReadMore] = useState(true);
    const [PerPage, setPerPage] = useState('');
    const [currentPage, setCurrentPage] = useState('');
    const [products, setProducts] = useState([])
    // const [pageNumbers, setpageNumbers] = useState([]);

    useEffect(() => {

        dispatch(fetchShopProduct(id)).then((resp) => {
            setProducts(resp.payload.shop.products)
        })
    },[])

    const shopList = useSelector((state) => state.shop.Shop)

    useEffect(() => {
        if(shopList?.shop_to_seller?.id){
            dispatch(fetchPagination(shopList?.shop_to_seller?.id))
        }

    },[shopList?.shop_to_seller?.id])

    const pagination = useSelector((state) => state.shop.Pagination)
    useEffect(() => {
        if(pagination.data){
            setCurrentPage(pagination.data.current_page)
            setPerPage(pagination.data.per_page)
        }

    }, [pagination?.data])

    const indexOfLastTodo = currentPage * PerPage;
    const indexOfFirstTodo = indexOfLastTodo - PerPage;
    const currentTodos = shopList?.products?.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos && currentTodos.map((data, index) => {

      let rating = "0";

      if (data.reviews_sum_rating != null) {
        rating = data.reviews_sum_rating;
      }


        return <Col md={2} className="mt-5">
            <Link to={`/product/${data.id}`} style={{textDecoration:'none',color:"#000"}} key={index}>
                    <ProductCard
                        isIdolMall={!!data?.shop_to_seller?.shop?.is_idol_mall || false}
                        className="sliderEventDataCategory"
                        productName={data.product_name}
                        productImage={data.latest_variety?.latest_image?.path}
                        reviewsRating={rating}
                        discounted={data.latest_variety?.discounted}
                        price={data.latest_variety?.price}
                    />
            </Link>
        </Col>;
    });

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(pagination && pagination.length ? pagination.data.total ? pagination.data.total : 1/ PerPage : 1); i++) {
        pageNumbers.push(i);
    }


    const handleClickPagination = (e) =>  {
        setCurrentPage(Number(e.target.id))
    }

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    const handleNextArrowPagination = (e) => {

        if(e === currentPage && e != 1) {

        }else {
            setCurrentPage(e + 1)
        }
    }

    const handlePrevArrowPagination = (e) => {
        if(currentPage === 1) {
        }else {
            setCurrentPage(e - 1)
        }

    }

    return (
        <div>
            <MainHeader />


                <Container fluid style={{padding: '1%'}}>
                    <div style={{
                        backgroundColor:'#FFFF',
                        width:'100%',
                        padding:20,
                        borderTopColor:'yellow',
                        borderRightColor:'#FFFF',
                        borderLeftColor:'#FFFF',
                        borderBottomColor:'#FFFF',
                        borderStyle:'solid'
                    }}>
                    <Row>
                        <Col md={1} lg={1} xs={1} sm={1}>
                            <img src={shopList?.image?.path ? shopList?.image?.path : '/assets/buyer/images/noImage.jpg'} className="profilePicDisplay profilePicLG"/>
                        </Col>
                        <Col md={4} lg={4} xs={4} sm={4}>
                            <div style={{paddingTop: '15px'}}>
                                <h5>{shopList.name}</h5>

                                <p>Shop Rating
                                    <label style={{marginLeft:10}}>
                                    { isNaN(+shopList.reviews_sum_rating) && isNaN(+shopList.reviews_count)
                                        ? <><RatingView ratingValue={shopList.reviews_sum_rating / shopList.reviews_count} size={17} /> <span>{`${shopList.reviews_sum_rating}/5 (${shopList.reviews_count} reviews)`}</span></>: <><RatingView ratingValue={0} size={17} /> <span>{`0/5 (${shopList.reviews_count} reviews)`}</span></>}
                                    </label></p>
                            </div>
                        </Col>
                        <Col md={7} lg={7} xs={7} sm={7}>
                            <div style={{paddingTop: '15px'}}>
                                <h5>Shop Description</h5>
                                <span className='justify-content-center'>{isReadMore ? shopList?.description?.slice(0, 150) : shopList.description}</span>
                                <span onClick={toggleReadMore} className="read-or-hide">
                                    {isReadMore ? "...Read More" : " Show Less"}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    </div>
                    <div>
                    </div>
                </Container>
            <div className="mt-1">
                <Container fluid>
                    <Row>
                        {
                            products && products.map((data, index) => {
                                return (
                                    <Col md={2} className="my-3">
                                        <Link to={`/product/${data.id}`} style={{textDecoration:'none',color:"#000"}} key={index}>
                                            <ProductCard
                                                isIdolMall={shopList?.is_idol_mall}
                                                productName={data.product_name}
                                                productImage={data.latest_variety?.latest_image?.path}
                                                discounted={data.latest_variety?.discounted}
                                                price={data.latest_variety?.price}
                                                rating={data.reviews_sum_rating != null ? data.reviews_sum_rating : 0}
                                                />
                                        </Link>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination  style={{marginTop:'10%'}}>
                        <Pagination.Prev onClick={() => handlePrevArrowPagination(currentPage)}/>
                        <Pagination.Item
                                    style={{backgroundColor: '#161F92'}}
                                    active={true}
                                    onClick={handleClickPagination}
                                >
                                    1
                                </Pagination.Item>
                        <Pagination.Next onClick={() => handleNextArrowPagination(currentPage)} />
                    </Pagination>
                </div>
                </Container>
            </div>
        </div>
    )
}

export default BuyerShop
