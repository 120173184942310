import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';

const initialState = {
  eventsList : [],
  activeEvent : {}
}

export const getEventsList = createAsyncThunk(
    'fetch/events',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const {data} = await api.get('/event');
        return data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
)

export const getActiveEvent = createAsyncThunk(
    'fetch/event',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const {data} = await api.get('/event');
        return data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
)

export const getEvent = createAsyncThunk(
    'fetch/event',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const {data} = await api.get('/event/' + _);
        return data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
)

export const EventsListSlice = createSlice({
    name:"events",
    initialState: initialState,
    extraReducers: (builder) => {
        //LIST OF ADS DATA
        builder.addCase(getEventsList.fulfilled, (state, action) => {
            let list = action.payload?.data || []
            state.adsList = list
        })

        builder.addCase(getActiveEvent.fulfilled, (state, action) => {
            let event = action.payload.data
            state.activeEvent = event
        })
    }
})

export const activeEvent = state => state.events.activeEvent;
export const selectEventList = state => state.events.eventsList;

export default EventsListSlice.reducer;