import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

function OrderBuyerAddress({orderDetails}) {
    return (
        <div>
            <Container fluid className="mt-4">
                <div style={{
                        background:'rgba(49, 103, 233, 0.1)',
                        width:'100%',
                        padding:20,
                        boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.25)'
                    }}>
                    
                    <Row>
                        <Col md={8} lg={11} xs={12} sm={12}>
                            <img src="/assets/buyer/icons/LocationIcon.png" className="img-fluid" style={{width:20}} />
                            <label style={{marginLeft:10,fontWeight:'bold' }}>Delivery Address</label>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={10} lg={11} xs={12} sm={12}>
                            <label style={{fontWeight:400, marginTop:14}}>
                                {orderDetails.address?.name}
                            </label>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={10} lg={11} xs={12} sm={12}>
                            <label style={{fontWeight:400, fontSize:14}}>
                                {orderDetails.address?.contact_no || '' }
                            </label>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={10} lg={11} xs={12} sm={12}>
                            <div style={{fontWeight:400, fontSize:14}}>
                                {
                                    orderDetails.address?.barangay?.description && orderDetails.address?.city?.description && orderDetails?.address?.province?.description ?
                                    <>
                                        <div>
                                            {
                                                `${orderDetails.address?.address_1}, 
                                                ${orderDetails.address?.barangay?.description}
                                                , ${orderDetails.address?.city?.description},
                                                ${orderDetails.address?.province?.description}
                                                `||""
                                            }
                                        </div> 
                                    </>
                                    :
                                    
                                    null 
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default OrderBuyerAddress
