import React, { useEffect ,useState} from 'react';
import { Container, Row, Col, Form, Button, Tabs, Tab ,Pagination,FloatingLabel} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { RatingView } from 'react-simple-star-rating'
import { Doughnut } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SellerMenu from '../seller_menu';
import ShopRatings from './shop_ratings';
import ProductRatingsReviews from './product_ratings';
import {fetchRateReviews} from '../../../features/RatingsReviewsSlice'
import MainHeader from '../../../components/buyer/Header/MainHeader';


ChartJS.register(ArcElement, Tooltip, Legend);
const  ShopRatingsReviews= () => {
    const dispatch = useDispatch();
    const { shopRatings,productReviews} = useSelector((state) => state.rates_reviews);
    const [filterRate, setFilterRate] = useState("total")



     useEffect(() => {
        dispatch(fetchRateReviews())
        },[dispatch])

        const handleFilter = (e) => {
            const {value} = e.target
            setFilterRate(value)
            if (value && value !== "total") {
              dispatch(
                fetchRateReviews({
                  filter:value
                })
              );
            } else {
              dispatch(fetchRateReviews());
            }
     
        }
    
        return (
            <div>
                <MainHeader />
                <Container className="mainContainer">
                    <Row>
                        <Col xs={2} className="leftContainer">
                            <SellerMenu />
                        </Col>

                        <Col xs={10} className="rightContainer" >

                            <div className="productsTabContainer">
                                <Container>
                                    <div className='salesProductPreview'>
                                        <ShopRatings 
                                            shopRatings={shopRatings}
                                            handleFilter={handleFilter}
                                        />
                                        
                                        <ProductRatingsReviews 
                                            productReviews={shopRatings}
                                            filterRate={filterRate}
                                        />
                                    
                                   
                                        {/* <Row>
                                            <Col className="product-ratings d-flex justify-content-end me-5">
                                                <Pagination variant="primary">
                                                        <Pagination.Prev />
                                                        <Pagination.Item active>{1}</Pagination.Item>
                                                        <Pagination.Item>{2}</Pagination.Item>
                                                        <Pagination.Item>{3}</Pagination.Item>
                                                        <Pagination.Item >{4}</Pagination.Item>
                                                        <Pagination.Item>{5}</Pagination.Item>
                                                        <Pagination.Next />
                                                </Pagination>
                                            </Col>
                                        </Row>  */}
                                    </div>
                                    
                                    
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
}

export default ShopRatingsReviews;