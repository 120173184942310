import React from 'react'
import {  Row, Col, Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';


const OrderItem = ({item}) => {
        const {activePriceDisplay} = useSelector((state) => state.price);

    return (
                                 
                <div className="productListContainer">
                    <Row className="salesProduct">
                        <Col xs="2">
                            <img 
                                className="productThumb" 
                                src={item.variety?.latest_image?.path || "/sampleProduct/dress1.jpg"} 
                                alt="product_image"
                                style={{width: "100%"}}/>         
                        </Col>
                        <Col sx="6" style={{ textAlign: "left"}}>
                                <Nav.Link>{item.product?.product_name || ""}</Nav.Link>
                                <p style={{padding: "0px 16px"}}>Variation: {item.variety?.variety_name.replace(/ /g, ', ')}</p>
                        </Col>
                                                                    
                        <Col xs="2" style={{ textAlign: "right"}}>
                                <p>Quantity: {item.quantity}</p>
                        </Col>
                        <Col xs="2" style={{ textAlign: "right"}}>
                        {activePriceDisplay && activePriceDisplay === 'Normal Price' ? 
                        <div> 
                                <b style={{color:"#CF0E0E"}}>
                                        {/* P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(item.variety?.price)} */}
                                        P{item.variety?.price || "0.00"}
                                </b>
                        </div>   :
                        (
                       item.variety?.discounted && (item.variety?.discounted !== "0" && item.variety?.discounted !== 0)?
                                <b style={{
                                    textDecoration: 'line-through',
                                    color:'#747474',
                                    }}
                                    >
                                        {/* P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(item.variety?.price)} */}
                                        P{item.variety?.price || "0.00"}
                                </b>
                                :
                        <div className='mt-4'> 
                                <b style={{color:"#CF0E0E"}}>
                                        {/* P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(item.variety?.price)} */}
                                        P{item.variety?.price || "0.00"}
                                </b>
                        </div>   )
                       
                        }

                        {activePriceDisplay && activePriceDisplay === 'Discounted Price' ? 
                        (item.variety?.discounted && (item.variety?.discounted !== "0" && item.variety?.discounted !== 0) ?
                        <div className='mt-4'> 
                                <b style={{color:"#CF0E0E"}}>
                                        {/* P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(item.variety?.discounted)} */}
                                        P{item.variety?.discounted || "0.00"}
                                </b>
                        </div>
                        :
                        null)
                        :null
                        }                                            
                        </Col>
                    </Row>
                </div>
               
    )
}

export default OrderItem;
