import React from 'react'
import {Container, Nav, Tabs, Tab} from 'react-bootstrap'
import OrderList from '../Orders/OrderList';
import CanceledList from '../ToCanceled/CanceledList';
import CompleteList from '../ToComplete/CompleteList';
import ReceiveList from '../ToReceive/ReceiveList';
import ReturnList from '../ToReturn/ReturnList';
import ShippedList from '../ToShip/ShippedList';

function TabTransactionList({ 
            toPayOrderList, 
            toShipOrderList, 
            toReceiveOrderList, 
            completedOrderList, 
            cancelledOrderList, 
            returnedOrderList}) {
    return (
        <div className="tabTransactionList">
            <Container fluid>
                <Tabs fill variant="tabs" defaultActiveKey="1" className="productsTab">
                    <Tab eventKey="1" title={<span><img src="/seller_icons/wallet_icon.png"/>To Pay</span>}>
                        <OrderList
                            toPayOrderList={toPayOrderList}
                        />
                    </Tab>
                    <Tab eventKey="2" title={<span><img src="/seller_icons/box_icon.png"/>To Ship</span>}>
                        <ShippedList
                            toShipOrderList={toShipOrderList}
                        />
                    </Tab>
                    <Tab eventKey="3" title={<span><img src="/seller_icons/truck_icon.png"/>To Receive</span>}>
                        <ReceiveList
                            toReceiveOrderList={toReceiveOrderList}
                        />
                    </Tab>
                    <Tab eventKey="4" title={<span><img src="/seller_icons/completed.png"/>Completed</span>}>
                        <CompleteList
                            completedOrderList={completedOrderList}
                        />
                    </Tab>
                    <Tab eventKey="5" title={<span><img src="/seller_icons/box_x_icon.png"/>Cancelled</span>}>
                        <CanceledList
                            cancelledOrderList={cancelledOrderList}
                        />
                    </Tab>
                    <Tab eventKey="6" title={<span><img src="/seller_icons/box_arrow_left_icon.png"/>Returns</span>}>
                        <ReturnList
                            returnedOrderList={returnedOrderList}
                        />
                    </Tab>
                </Tabs>
            </Container>
        </div>
    )
}

export default TabTransactionList
