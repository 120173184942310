import React, { useEffect,useState } from 'react'
import {Navbar, Container, Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SearchMain  from './Search'
import SubHeader from './SubHeader';
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch} from 'react-redux';
import { selectUser } from '../../../features/UserSlice';
import { getCartList, fetchCart} from '../../../features/CartSlice';

function MainHeader(props) {
    const dispatch = useDispatch();  
    const { isLoggedIn } = useSelector(selectUser) || {}

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getCartList()).then((resp) => {
                if (props.count != 0 && props.setData) {
                    props.setData(props.count + resp.payload.cart_count)
                }
            })
        }
    },[])

    return (
        <div>
            
            <SubHeader/>

            <Navbar className="sub_header_custom_nav" collapseOnSelect expand="lg"  style={{
                boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.25)'
            }}>
                <Container>
                <Navbar.Brand href="#home" style={{color:'#000000'}}>
                    <Link to="/"><img
                        src="/assets/buyer/images/logo_landscape.png"
                        className="d-inline-block align-top img-fluid"
                        alt="React Bootstrap logo"
                        width="400"
                    /></Link>
                </Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                    <Navbar.Collapse id="responsive-navbar-navs">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav>
                        <div  style={{ paddingTop:10}}>
                            <div>
                                <SearchMain />
                                {/* <Button 
                                    style={{
                                        backgroundColor:'#C4C4C4', 
                                        borderColor:'#C4C4C4'}} 
                                        variant="outline-secondary" 
                                        id="button-addon2">
                                    <img
                                        alt="icons"
                                        src="/assets/buyer/icons/SearchIcon.png"
                                        className="d-inline-block align-top mt-1"
                                        width="18p"
                                    />
                                </Button> */}
                            </div>
                        </div>
                        <Nav.Link as={Link} className="mt-2" to="/idol_cart/">
                            &nbsp;&nbsp;
                            <Badge badgeContent={props.count} color="primary">
                            <img
                                src="/assets/buyer/icons/CartIcon.png"
                                className="d-inline-block align-top"
                                alt="React Bootstrap logo"
                                width="30"
                            />
                            </Badge>
                        </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default MainHeader
