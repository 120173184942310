import React,{useEffect, useState} from 'react'
import {Col, Row, Container, Nav, Button} from 'react-bootstrap';
import MainHeader from '../Header/MainHeader';
import {Link, useHistory} from 'react-router-dom';
import {selectUser, getProfileDetails} from '../../../features/UserSlice';
import {useDispatch, useSelector} from 'react-redux'

function Layout(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const {userDetails, role_mode, seller} = useSelector(selectUser);

    const [sellerDetails, setSellerDetails] = useState();
    

    useEffect(() => {
        dispatch(getProfileDetails()).then((res) => {
            setSellerDetails(res.payload)
        })
    }, []);

    const showSubmenu = () => {

        var x = document.getElementById("account-submenu");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
    }

    const routeStartSelling = (path) => {
        history.push(path);
    }


    return (
        <div>
            <MainHeader/>
            <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30,
                margin:'auto'
            }}>

                <Container fluid>
                    <Row>

                        <Col md={2}>
                            <div style={{flexDirection:'row', alignItems:'center', justifyContent:'center', display:'flex', flexDirection:'column'}}>
                                <img src={sellerDetails?.image ? sellerDetails?.image.path : '/assets/buyer/images/noImage.jpg'} className="img-fluid profilePicDisplay profilePicSM" />
                                <br/>
                                <h6>{sellerDetails?.name}</h6>
                                {
                                    sellerDetails?.user?.seller == null ? (
                                        <Button onClick={() => routeStartSelling('/start_selling')} className="startSellingButton" variant="outline-primary"><img src="/seller_icons/store_icon.png"/>Start Selling</Button>
                                    ): (
                                        <Button onClick={() => routeStartSelling('/seller/products')} className="startSellingButton" variant="outline-primary"><img src="/seller_icons/store_icon.png"/>Start Selling</Button>
                                    )
                                }
                                
                            </div>
                            
                            <div >
                              
                            </div>

                            <hr />

                            <div className="profileNavContainer">

                                <Nav defaultActiveKey="#" className="flex-column profileNav">

                                    <Nav.Link style={{fontSize:14}} onClick={showSubmenu}><img src="/seller_icons/user_icon.png"/>My Account</Nav.Link>

                                        <div id="account-submenu" style={{display: "none", paddingLeft: "30px", marginTop:5}}>
                                            <Nav.Link eventKey="link-1" as={Link} to="/my_account" style={{fontSize:14}}>My Profile</Nav.Link>
                                            <Nav.Link eventKey="link-2" as={Link} to="/my_address/" style={{fontSize:14}}>My Addresses</Nav.Link>
                                        </div>

                                    <Nav.Link style={{fontSize:14}} as={Link} to="/my_purchase" eventKey="link-5" className="mt-2"><img src="/seller_icons/products_icon.png"/>My Purchases</Nav.Link>
                                    <Nav.Link style={{fontSize:14}} as={Link} to="/notifications" eventKey="link-6" className="mt-2"><img src="/assets/buyer/icons/bell.png"/>Notification</Nav.Link>
                                    <Nav.Link style={{fontSize:14}} as={Link} to="/privacy_policy" eventKey="link-7" className="mt-2"><img src="/seller_icons/privacy_policy_icon.png"/>Privacy Policy</Nav.Link>
                                    <Nav.Link as={Link} to="/about_us" style={{fontSize:14}} eventKey="link-8" className="mt-2"><img src="/seller_icons/about_icon.png"/>About ISN</Nav.Link>
                                
                                </Nav>
                            </div>
                            
                        </Col>
                        <Col md={10}>
                            {props.children}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Layout
