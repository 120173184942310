import React from 'react'
import {Modal, Button, Row, Col, Container} from 'react-bootstrap'

function CartRemoveProductModal(props) {
    const yesDeleteItem = () => {
        props.changeDeleteStatus()
    }
    return (
        <div>
            <Modal
            {...props}
                centered
                className="modalCartRemoveItem"
            >
                <Modal.Body style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <h5 style={{fontWeight:'300'}}>Are you sure you want to remove this product?</h5>
                    <br/>
                </Modal.Body>
                <Modal.Footer >
                    <Container>
                        <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Col md={5}>
                                <Button onClick={props.onHide} className="form-control noBtnRemoveItem">No</Button>
                                
                            </Col>
                            <Col md={5}>
                                <Button onClick={()=>yesDeleteItem()} className="form-control yesBtnRemoveItem" >Yes</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CartRemoveProductModal
