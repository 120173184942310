import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Container, Form } from 'react-bootstrap'
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from '../../../features/CartSlice';
import {
    getRegion,
    getProvince,
    getCity,
    getBarangay,
    newAddressesSeller,
    updateDefaultAddresses,
    deleteAddressesSeller,
    updateAddressesSeller,
    getAddressSeller
} from '../../../features/AddressSlice';


function AddressForm(props) {
    const dispatch = useDispatch();
    const [region, setRegion] = useState(0);
    const [region_id, setRegionId] = useState(0);
    const [province, setProvince] = useState(0);
    const [province_id, setProvinceId] = useState(0);
    const [city, setCity] = useState(0);
    const [city_id, setCityId] = useState(0);
    const [barangay, setBarangay] = useState(0);
    const [barangay_id, setBarangayId] = useState(0);

    const validationAddresses = {
        full_name: Yup.string().required("Full Name is required"),
        phone_number: Yup.string()
            .required("Phone Number is Required")
            .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                "Phone number is not valid"
            ),
        postal_code: Yup.string()
            .required("Postal Code is Required")
            .matches(
                /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                "Postal Code is not valid"
            )
            .min(4, "Postal Code must not less than 3 characters")
            .max(4, "Postal Code must not more than 4 characters"),
        landmark: Yup.string().required("Landmark is required"),
        region: Yup.string().required("Region is required"),
        province: Yup.string().required("Province is required"),
        city: Yup.string().required("City is required"),
        barangay: Yup.string().required("Barangay is required"),
        detailed_address: Yup.string().required("Detailed Address is required"),
    }
    // let fetchAddress = useSelector((state) => state.address.FetchAddresses);
    const formik = useFormik({
        validationSchema: Yup.object(validationAddresses),
        enableReinitialize: true,
        initialValues: {
            full_name: props.modalShowFormNewAddress ? "" : props.fetchAddress?.name,
            phone_number: props.modalShowFormNewAddress ? "" : props.fetchAddress?.contact_no,
            postal_code: props.modalShowFormNewAddress ? "" : props.fetchAddress?.postal_code,
            landmark: props.modalShowFormNewAddress ? "" : props.fetchAddress?.landmark,
            region: props.modalShowFormNewAddress ? "" : props.fetchAddress?.region?.code,
            province: props.modalShowFormNewAddress ? "" : props.fetchAddress?.province?.code,
            city: props.modalShowFormNewAddress ? "" : props.fetchAddress?.city?.code,
            barangay: props.modalShowFormNewAddress ? "" : props.fetchAddress?.barangay?.code,
            detailed_address: props.modalShowFormNewAddress ? "" : props.fetchAddress?.address_1,
            default_address: props.modalShowFormNewAddress ? false : props.fetchAddress?.is_default === 0 ? false : true,
        },
        onSubmit: (values) => {
            if (regionList && formik.values.region) {
                const get_region_id = regionList && regionList.find((code) => { return Number(code.code) === Number(formik.values.region) })
                values.region_id = Number(get_region_id?.id)
            }

            if (provinceList && formik.values.province) {
                const get_province_id = provinceList && provinceList.find((code) => { return Number(code.code) === Number(formik.values.province) })
                values.province_id = Number(get_province_id?.id)
            }

            if (cityList && formik.values.city) {
                const get_city_id = cityList && cityList.find((code) => { return Number(code.code) === Number(formik.values.city) })
                values.city_id = Number(get_city_id?.id)
            }

            if (barangayList && formik.values.barangay) {
                const get_barangay_id = barangayList && barangayList.find((code) => { return Number(code.code) === Number(formik.values.barangay) })
                values.barangay_id = Number(get_barangay_id?.id)
            }
            if (props.modalShowFormNewAddress) {
                dispatch(newAddressesSeller(values))
                formik.resetForm()
                setTimeout(function () {
                    window.location.href = window.location;
                }, 3000);
            } else {
                values.id = props.fetchAddress.id
                dispatch(updateAddressesSeller({ values }))
                setTimeout(function () {
                    window.location.href = window.location;
                }, 3000);
            }
        },
    });

    useEffect(() => {
        dispatch(getRegion())
    }, [dispatch])

    useEffect(() => {
        if (formik.values.region) {
            dispatch(getProvince(formik.values.region))
        }
        if (formik.values.province) {
            dispatch(getCity(formik.values.province))
        }
        if (formik.values.city) {
            dispatch(getBarangay(formik.values.city))
        }

    }, [formik.values.region,
    formik.values.province,
    formik.values.city,
    formik.values.barangay])

    let regionList = useSelector((state) => state.address.RegionList);
    let provinceList = useSelector((state) => state.address.ProvinceList);
    let cityList = useSelector((state) => state.address.CityList);
    let barangayList = useSelector((state) => state.address.BarangayList);

    const handleRegionChange = (e) => {
        const get_region_id = regionList && regionList.find((code) => { return code.code === e.target.value })
        setRegionId(get_region_id.id)
        setRegion(e.target.value)
    }
    const handleProvinceChange = (e) => {
        const get_province_id = provinceList && provinceList.find((code) => { return code.code === e.target.value })
        setProvinceId(get_province_id.id)
        setProvince(e.target.value)
    }
    const handleCityChange = (e) => {

        const get_city_id = cityList && cityList.find((code) => { return code.code === e.target.value })
        setCityId(get_city_id.id)
        setCity(e.target.value)
    }
    const handleBarangayChange = (e) => {
        const get_barangay_id = barangayList && barangayList.find((code) => { return code.code === e.target.value })
        setBarangayId(get_barangay_id.id)
        setBarangay(e.target.value)
    }
    const handleOnChange = (res_default_loc) => {
        dispatch(updateDefaultAddresses({ id: res_default_loc.id, default_address: true }))
    }
    const setDeleteAddress = (id) => {
        dispatch(deleteAddressesSeller(id))
        setTimeout(function () {
            dispatch(getAddress())
        }, 1000);
    }
    return (
        <div>
            <Container fluid className="mt-4">
                <div style={{
                    backgroundColor: '#FFFF',
                    width: '100%',
                    padding: 20,
                    backgroundColor: '#FFFF',
                    borderTopColor: 'yellow',
                    borderRightColor: '#FFFF',
                    borderLeftColor: '#FFFF',
                    borderBottomColor: '#FFFF',
                    borderStyle: 'solid'
                }}>
                    <h5>{props.modalShowFormNewAddress ? 'New Address' : 'Edit Address'}</h5>
                    <hr />
                    <br />
                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Form.Label column sm={1} >Full Name</Form.Label>
                            <Col md={5}>
                                <Form.Control name="full_name" id="full_name" placeholder="Full Name" value={formik.values.full_name} onChange={formik.handleChange}></Form.Control>
                                {formik.touched.full_name && formik.errors.full_name ? <p className='validation'>{formik.errors.full_name}</p> : null}
                            </Col>
                            <Form.Label column sm={1}>Phone Number</Form.Label>
                            <Col md={5}>
                                <Form.Control type="text" name="phone_number" id="phone_number" placeholder="Phone Number" onChange={formik.handleChange} value={formik.values.phone_number}></Form.Control>
                                {formik.touched.phone_number && formik.errors.phone_number ? <p className='validation'>{formik.errors.phone_number}</p> : null}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Form.Label column sm={1} >Region</Form.Label>
                            <Col md={5}>
                                <Form.Select
                                    id="region"
                                    name="region"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.region}>
                                    <option selected>Select Region</option>
                                    {regionList && regionList.map((res => {
                                        return (
                                            <option value={res.code}>{res.description}</option>
                                        )
                                    }))}
                                </Form.Select>
                                {formik.touched.region && formik.errors.region ? <p className='validation'>{formik.errors.region}</p> : null}
                            </Col>
                            <Form.Label column sm={1}>Province</Form.Label>
                            <Col md={5}>
                                <Form.Select
                                    id="province"
                                    name="province"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.province}>
                                    <option selected>Select Province</option>
                                    {provinceList && provinceList.map((res => {
                                        return (
                                            <option value={res.code}>{res.description}</option>
                                        )
                                    }))}
                                </Form.Select>
                                {formik.touched.province && formik.errors.province ? <p className='validation'>{formik.errors.province}</p> : null}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Form.Label column sm={1}>City</Form.Label>
                            <Col md={5}>
                                <Form.Select
                                    id="city"
                                    name="city"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}>
                                    <option selected>Select City</option>
                                    {cityList && cityList.map((res => {
                                        return (
                                            <option value={res.code}>{res.description}</option>
                                        )
                                    }))}
                                </Form.Select>
                                {formik.touched.city && formik.errors.city ? <p className='validation'>{formik.errors.city}</p> : null}
                            </Col>
                            <Form.Label column sm={1}>Barangay</Form.Label>
                            <Col md={5}>
                                <Form.Select
                                    id="barangay"
                                    name="barangay"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.barangay}>
                                    <option selected value={0}>Select Barangay</option>
                                    {barangayList && barangayList.map((res => {
                                        return (
                                            <option value={res.code}>{res.description}</option>
                                        )
                                    }))}
                                </Form.Select>
                                {formik.touched.barangay && formik.errors.barangay ? <p className='validation'>{formik.errors.barangay}</p> : null}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Form.Label column sm={1}>Postal Code</Form.Label>
                            <Col md={5}>
                                <Form.Control type="text" id="postal_code" name="postal_code" placeholder="Postal Code" onChange={formik.handleChange} value={formik.values.postal_code}></Form.Control>
                                {formik.touched.postal_code && formik.errors.postal_code ? <p className='validation'>{formik.errors.postal_code}</p> : null}
                            </Col>
                            <Form.Label column sm={1}>Landmark</Form.Label>
                            <Col md={5}>
                                <Form.Control column id="landmark" name="landmark" placeholder="Landmark" onChange={formik.handleChange} value={formik.values.landmark}></Form.Control>
                                {formik.touched.landmark && formik.errors.landmark ? <p className='validation'>{formik.errors.landmark}</p> : null}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Form.Label column sm={1}>Detailed Address</Form.Label>
                            <Col md={11}>
                                <Form.Control id="detailed_address" name="detailed_address" placeholder="Detailed Address" onChange={formik.handleChange} value={formik.values.detailed_address}></Form.Control>
                                {formik.touched.detailed_address && formik.errors.detailed_address ? <p className='validation'>{formik.errors.detailed_address}</p> : null}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Form.Label column sm={1}></Form.Label>
                            <Col md={11}>
                                <div className="custom-control custom-checkbox">
                                    <Form.Group className="mb-3" id="formGridCheckbox">
                                        <Form.Check className="custom-control-checkbox" id="default_address" name="default_address" type="checkbox" label="Set as Pickup Address and Return Address" onChange={formik.handleChange} checked={formik.values.default_address} />
                                    </Form.Group>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Form.Label column sm={1} ></Form.Label>
                            <Col md={12}>
                                {/* {props.modalShowFormNewAddress
                                        ? <Button type="submit" variant="primary" className="float-end" style={{marginLeft: '5px', marginRight: '5px', width: '100px'}}>Save</Button>
                                        : <Button type="submit" variant="primary" className="float-end" style={{marginLeft: '5px', marginRight: '5px', width: '100px'}} >Edit</Button>} */}
                                <Button type="submit" variant="primary" className="float-end" style={{ marginLeft: '5px', marginRight: '5px', width: '100px' }}>Save</Button>
                                <Button type="submit"
                                    variant="btn btn-outline-primary"
                                    className="float-end"
                                    style={{ marginLeft: '5px', marginRight: '5px', width: '100px' }}
                                    onClick={() => props.CloseAddress()}>Cancel</Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Container>
            {props.addresses && props.addresses.map((res) => {
                if (res.id !== props.fetchAddress.id) {
                    return (
                        <Container fluid className="mt-4">
                            <div style={{
                                backgroundColor: '#FFFF',
                                width: '100%',
                                padding: 20,
                                backgroundColor: '#FFFF',
                                borderTopColor: 'yellow',
                                borderRightColor: '#FFFF',
                                borderLeftColor: '#FFFF',
                                borderBottomColor: '#FFFF',
                                borderStyle: 'solid'
                            }}>
                                <Row>
                                    <Col md={8} lg={11} xs={12} sm={12}>

                                    </Col>
                                    <Col>
                                        <label style={{ textAlign: 'right', color: '#3E6AE3' }}><span onClick={() => props.setUpdateAddress(res)}>Edit</span> | <span onClick={() => setDeleteAddress(res.id)}>Delete</span></label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={10} lg={11} xs={12} sm={12}>
                                        <span style={{ color: '#3E6AE3' }}>{res.is_default === 1 ? 'Default' : null}</span>
                                        {/* <Form.Check
                                            name={res.name}
                                            value={res.name}
                                            onChange={() => handleOnChange(res)}
                                            label={ <span style={{color:'#3E6AE3'}}>Default</span>} checked={res.is_default === 0 ? false : true}/> */}

                                        {/* <Form.Check label={ <span style={{color:'#3E6AE3'}}>{res.name}</span>} checked={res.is_default === 0 ? false : true}/> */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={10} lg={11} xs={12} sm={12}>
                                        <label style={{ fontWeight: 300, fontSize: 14 }}>
                                            {res.name}
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={10} lg={11} xs={12} sm={12}>
                                        <label style={{ fontWeight: 300, fontSize: 14 }}>
                                            {res.contact_no}
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={10} lg={11} xs={12} sm={12}>
                                        <label style={{ fontWeight: 300, fontSize: 14 }}>
                                            Brgy {res.barangay?.description}, City {res.city?.description}, {res.province?.description}, {res.region?.description}
                                        </label>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    )
                }

            })}
        </div>
    )
}

export default AddressForm
