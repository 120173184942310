import React, {useState} from 'react';
import { Modal, Container, Row, Col, Nav, Button, Tabs, Tab } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainHeader from '../../../components/buyer/Header/MainHeader';
import SellerMenu from '../seller_menu';

// const btnStyle = {width: "400px", height: "50px", background: "#3167E9", borderRadius: "15px !Important"};

function SalesConfirmPickup(props) {
    const [modalShowConfirm, setmodalShowConfirm] = useState(false);
    const modalAutoClose = () => {
        setTimeout(function(){setmodalShowConfirm(false)},2000);
    }
    return (
        <div>

            <Modal
            show={modalShowConfirm}
            onHide={() => setmodalShowConfirm(false)}
                centered
                size='lg'
                backdrop={false}
                className="modalConfirmPickup"
            >
                <Modal.Body > 
                    <Container>
                        <Row>
                            <Col xs={12} style={{textAlign: "center", padding: "30px"}}>
                            <img src="/seller_icons/tick_2.png" style={{marginBottom: "30px"}}/>
                            <h2>Success</h2>
                            </Col>
                        </Row>
                        </Container>
                        
                </Modal.Body>
            </Modal>

            <MainHeader />
            <Container className="mainContainer">
                    <Row>
                        <Col xs={2} className="leftContainer">
                            <SellerMenu />
                        </Col>

                        <Col xs={10} className="rightContainer" >
                        <div className="productPreviewContainer">
                        <Container className="salesShopName">
                            <Row>
                                <Col xs={12} style={{textAlign: "left"}}>
                                    <h5>Confirm Pick-Up</h5>
                                    <hr />
                                    <h6>Courier</h6> 
                                    <p>Ninjavan will collect your parcel from your pickup address.</p>
                                    <hr />
                                    <h6>Pick-up Address</h6> 
                                    <p>John Doe</p>
                                    <p>099989856989</p>
                                    <p>Block 13, Lot. 5, VIlla Belissa Subd., Brgy. Sapang Palay, Bagong Buhay Ave., San Jose Del Monte, Bulacan Sapang Palay, San Jose Del Monte City North Luzon, Bulacan 3024</p>

                                </Col>
                                <Col xs={12}>
                                    <Button onClick={() => {setmodalShowConfirm(true); modalAutoClose();}} className="btnConfirmPickup">Confirm</Button>
                                </Col>
                            </Row>
                            </Container>
                        </div>    

                        </Col>
                    </Row>
                </Container>
        </div>
    );
}

export default SalesConfirmPickup;