import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Card, Button} from 'react-bootstrap'
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails } from '../../../features/UserSlice';
import { getDefaultAddress } from '../../../features/CartSlice';


function CheckOutAddress(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [userId, setUserId] = useState('');
    const [defaultAddress, setDefaultAddress] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const {
        setAddress
    } = props

    useEffect(() => {
        setIsLoading(true)
        dispatch(getDefaultAddress()).then((resp) => {
            if (resp.payload.data.success) {
                setDefaultAddress(resp.payload.data.data)
                setAddress(resp.payload.data.data.id)
            }

            setIsLoading(false)
        })
    }, [])



    const ChangeAddressHandler = () => {
        const path = `/my_address`;
        history.push(path);
    }

    return (
        <div>
            {
                !isLoading &&
                <Container fluid className="mt-4">
                    <div style={{
                            backgroundColor:'#FFFF',
                            width:'100%',
                            padding:20,
                            backgroundColor:'#FFFF',
                            borderTopColor:'yellow',
                            borderRightColor:'#FFFF',
                            borderLeftColor:'#FFFF',
                            borderBottomColor:'#FFFF',
                            borderStyle:'solid'
                        }}>
                            {
                                defaultAddress &&
                                <div>
                                    <Row>
                                        <Col md={8} lg={11} xs={12} sm={12}>
                                            <img src="/assets/buyer/icons/LocationIcon.png" className="img-fluid" style={{width:20}} />
                                            <label style={{marginLeft:10}}>Delivery Address</label>
                                            <label style={{marginLeft:20}}>|</label>
                                            <label style={{marginLeft:20}}>Default</label>
                                        </Col>
                                        <Col>
                                            <label onClick={() => ChangeAddressHandler()} style={{textAlign:'right', color:'#3E6AE3'}}>Change</label>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={10} lg={11} xs={12} sm={12}>
                                            <label style={{fontWeight:300, fontSize:14, marginTop:15}}>
                                                { defaultAddress?.name }
                                            </label>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={10} lg={11} xs={12} sm={12}>
                                            <label style={{fontWeight:300, fontSize:14}}>
                                                {defaultAddress?.contact_no}
                                            </label>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={10} lg={11} xs={12} sm={12}>
                                            <label style={{fontWeight:300, fontSize:14}}>
                                                Brgy {defaultAddress?.barangay?.description}, City {defaultAddress?.city?.description}, {defaultAddress?.province?.description}, {defaultAddress?.region?.description}
                                            </label>
                                        </Col>
                                    </Row>

                                </div>
                            }

                        <br/>
                        <Button onClick={ChangeAddressHandler}>Add Delivery Address</Button>
                    </div>
                </Container>
            }
        </div>
    )
}

export default CheckOutAddress
