import React, { useCallback, useMemo, useEffect,useState } from 'react';
import Select,{components} from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {searchMain } from "../../../features/SearchSlice"
import { useHistory} from "react-router-dom";
import {Button} from 'react-bootstrap';
import { debounce } from 'lodash'

const SearchMain = () =>{

  const dispatch = useDispatch();
  const history = useHistory();

  const { searchProduct, isLoading, searchShop } = useSelector((state) => state.search);
  const [productOptions, setProductOptions] = useState([])
  const [shopOptions, setShopOptions] = useState([])
  const [groupOptions, setGroupOptions] = useState([])
  const [filterValue, setFilterValue] = useState("")


  // removing filter due to unresponsive effect
  // const sendQuery = query => dispatch(searchMain({'filter[search]': query}));
  const sendQuery = useCallback(query => dispatch(searchMain({'search': query})));

  const delayedQuery = useCallback(debounce(q => sendQuery(q), 1000), [sendQuery]);

useEffect(()=>{
  if(filterValue === ""){
    setProductOptions([])
    setShopOptions([])
  }
  },[filterValue])

  useEffect(() => {
    if(filterValue !== ""){
      if(searchProduct){
        const options = searchProduct.map((product) => ({
            value: product.id,
            label: product.product_name,
            search:"products"
        }));
        setProductOptions(options)
      }
  }
}, [searchProduct,filterValue])

useEffect(() => {
  if(filterValue !== ""){
      if(searchShop){
          const options = searchShop.map((shop) => ({
              value: shop.id,
              label: shop.name,
              image:shop.image ||'/seller_icons/store_icon_1.png',
              search:"shops"
          }));
          setShopOptions(options)
       }
     }
}, [searchShop,filterValue])


useEffect(() => {
  if(searchShop && searchProduct){
      const groupedOptions = [
        {
          label: 'Products',
          options: productOptions,
        },
        {
          label: 'Shops',
          options: shopOptions,
        },
      ];
      setGroupOptions(groupedOptions)
  }
}, [filterValue,searchShop,searchProduct])


  const onChange = e => {
    setFilterValue(e)
    delayedQuery(e);
  };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      };


      const customStyles = {
        control: (provided, state) => ({
          ...provided,
          background: '#fff',
          borderColor: '#9e9e9e',
          minHeight: '30px',
          height: '40px',
          width:'450px',
          boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
          ...provided,
          height: '30px',
          padding: '0 6px'
        }),

        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),
        dropdownIndicator: (provided, state)  => ({
            ...provided,
          // display: 'none',
          height:"42px",
          padding:"0",
          marginTop:"7px"
        }),
        indicatorSeparator: (provided, state)  => ({
            ...provided,
          display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: '30px',
        }),
      };
      const DropdownIndicator = props => {
        return (
          <components.DropdownIndicator {...props}>
                                <Button
                                    style={{
                                        backgroundColor:'#C4C4C4',
                                        borderColor:'#C4C4C4',
                                        width: '35px'}}
                                        variant="outline-secondary"
                                        // id="button-addon2"
                                        >
                                    <img
                                        alt="icons"
                                        src="/assets/buyer/icons/SearchIcon.png"
                                        // className="d-inline-block align-top mt-1"
                                        s
                                        width="13px"
                                        height="11px"
                                    />
                                </Button>
          </components.DropdownIndicator>
        );
      };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
      };

      const formatGroupLabel = (groupOptions) => (
        <div style={groupStyles}>
          <span>{groupOptions.label}</span>
          <span style={groupBadgeStyles}>{groupOptions.options.length}</span>
        </div>
      );

      const handleValue = (data) =>{
        console.log(handleValue)
        // history.push(`/product/${data.value}`)
        if(data && data.search === "products"){
          window.location.replace(`/product/${data.value}`)
        }else if(data && data.search === "shops"){
          window.location.replace(`/shop/${data.value}`)
        }
      }

      const formatOptionLabel = (groupLabel) =>{
          if(groupLabel.search === 'shops'){
            return (<div>
                      <img
                        alt="icon"
                        src={groupLabel.image}
                        style={{
                          paddingRight:7,
                          height:18,
                          width:25
                          }}/>
                          {groupLabel.label}
                    </div>)
          }
          return(
            <span>
              {groupLabel.label}
            </span>
          )

      }



      return (
        <Select
          onClick={handleValue}
          onInputChange={onChange}
          styles={customStyles}
          onBlurResetsInput={false}
          onChange={handleValue}
          components={{DropdownIndicator}}
          placeholder="Search..."
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={() => null}
          options={groupOptions}
          formatGroupLabel={formatGroupLabel}
        />
      );
}
export default SearchMain;
