import React, {useEffect, useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container,Row, Col, Card, Pagination } from 'react-bootstrap';
import { RatingView } from 'react-simple-star-rating'
import {Link} from 'react-router-dom'
import ProductCard from '../../common/ProductCard';

function CategoryProducts(props) {

    const [forYouItemPerPage, setForYouItemPerPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);


    // Logic for displaying current todos
    // const indexOfLastTodo = currentPage * forYouItemPerPage;
    // const indexOfFirstTodo = indexOfLastTodo - forYouItemPerPage;
    // const currentTodos = props.data?.slice(indexOfFirstTodo, indexOfLastTodo);

    const currentTodos = props.data
    useEffect(() => {
        setCurrentPage(currentTodos.current_page)
    }, [currentTodos])

    const checkImage = (result) => {
        try {
            if(result.latest_variety){
                const latest_variety = result.latest_variety
                if(latest_variety.latest_image){
                    const latest_image = result.latest_variety.latest_image
                    if(latest_image.path){
                        return true
                    }
                }
            }
            return false
        } catch(e){
            console.log(e)
            return false
        }

    }
    const renderTodos = currentTodos.data && currentTodos.data.map((data, index) => {


      let rating = "0";

      if (data.reviews_sum_rating != null) {
        rating = data.reviews_sum_rating;
      }else {

      }

        return (<Col md={2} className="mt-5" key={data.id}>
            <Link to={`/product/${data.id}`} style={{textDecoration:'none',color:"#000"}} key={index}>
                <ProductCard
                            isIdolMall={!!data?.shop_to_seller?.shop?.is_idol_mall || false}
                            className="sliderEventDataCategory"
                            productName={data.product_name}
                            productImage={data.latest_variety?.latest_image?.path}
                            reviewsRating={rating}
                            discounted={data.latest_variety?.discounted}
                            price={data.latest_variety?.price}
                        />
            </Link>
        </Col>)
    });

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(props.data.length / currentTodos.per_page); i++) {
        pageNumbers.push(i);
    }

    const handleClickPagination = (e) =>  {
        console.log(e.target.id);
        setCurrentPage(Number(e.target.id))

    }

    const handleNextArrowPagination = (e) => {

        if(e === currentPage && e != 1) {

        }else {
            setCurrentPage(e + 1)
        }
    }

    const handlePrevArrowPagination = (e) => {
        if(currentPage === 1) {
        }else {
            setCurrentPage(e - 1)
        }

    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <div key={number}>
                <Pagination.Item
                    style={{backgroundColor: currentPage == number ? '#161F92' : 'none'}}
                    key={number}
                    id={number}
                    active={currentPage == number ? true : false}
                    onClick={handleClickPagination}
                >
                    {number}
                </Pagination.Item>

            </div>
        );
    });

    return (
        <div className="mt-1">
            <Container fluid>
                   <Row>
                        {renderTodos}
                    </Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>

                        <Pagination  style={{marginTop:'10%'}}>
                            <Pagination.Prev onClick={() => handlePrevArrowPagination(currentPage)}/>
                                {renderPageNumbers}
                            <Pagination.Next onClick={() => handleNextArrowPagination(currentPage)} />
                        </Pagination>
                    </div>
            </Container>
        </div>
    )
}

export default CategoryProducts
