import React,{useState, useEffect, Fragment} from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import { RatingView } from 'react-simple-star-rating'
import moment from 'moment'

function ProductCommentSection(props) {    
    
    const [productCommentsData, setProductCommentsData] = useState([
        {
            id:1,
            image:'/assets/buyer/images/user_1.png',
            buyer_name:'Jordan James',
            buyer_rating:5,
            buyer_comment:"I received all my items na po. Superb quality, although spicy talaga yung shipping fee dito samin which is understandable naman kasi Mindanao ako eh. Btw This shop meets my expectations all items are in superb quality, tama ang variations & safe super nakabalot talaga. God bless sa shop nato.",
            seller_response:"Simple words, is full of trust, but also stable happeningss. Others can not take away also can not erase the sense of achievement, What we like is all the words about recognition that you tap on the keyboard.",
            when_created:'04/21/2021 10:18'
        },
        {
            id:2,
            image:'/assets/buyer/images/user_2.png',
            buyer_name:'jeysixd',
            buyer_rating:5,
            buyer_comment:"I received all my items na po. Superb quality, although spicy talaga yung shipping fee dito samin which is understandable naman kasi Mindanao ako eh. Btw This shop meets my expectations all items are in superb quality, tama ang variations & safe super nakabalot talaga. God bless sa shop nato.",
            seller_response:"Simple words, is full of trust, but also stable happeningss. Others can not take away also can not erase the sense of achievement, What we like is all the words about recognition that you tap on the keyboard.",
            when_created:'04/21/2021 10:18'
        },
        {
            id:3,
            image:'/assets/buyer/images/user_3.png',
            buyer_name:'Jordan James',
            buyer_rating:5,
            buyer_comment:"I received all my items na po. Superb quality, although spicy talaga yung shipping fee dito samin which is understandable naman kasi Mindanao ako eh. Btw This shop meets my expectations all items are in superb quality, tama ang variations & safe super nakabalot talaga. God bless sa shop nato.",
            seller_response:"Simple words, is full of trust, but also stable happeningss. Others can not take away also can not erase the sense of achievement, What we like is all the words about recognition that you tap on the keyboard.",
            when_created:'04/21/2021 10:18'
        },
        {
            id:4,
            image:'/assets/buyer/images/user_2.png',
            buyer_name:'jeysixd',
            buyer_rating:5,
            buyer_comment:"I received all my items na po. Superb quality, although spicy talaga yung shipping fee dito samin which is understandable naman kasi Mindanao ako eh. Btw This shop meets my expectations all items are in superb quality, tama ang variations & safe super nakabalot talaga. God bless sa shop nato.",
            seller_response:"Simple words, is full of trust, but also stable happeningss. Others can not take away also can not erase the sense of achievement, What we like is all the words about recognition that you tap on the keyboard.",
            when_created:'04/21/2021 10:18'
        },
        {
            id:5,
            image:'/assets/buyer/images/user_3.png',
            buyer_name:'Jordan James',
            buyer_rating:5,
            buyer_comment:"I received all my items na po. Superb quality, although spicy talaga yung shipping fee dito samin which is understandable naman kasi Mindanao ako eh. Btw This shop meets my expectations all items are in superb quality, tama ang variations & safe super nakabalot talaga. God bless sa shop nato.",
            seller_response:"Simple words, is full of trust, but also stable happeningss. Others can not take away also can not erase the sense of achievement, What we like is all the words about recognition that you tap on the keyboard.",
            when_created:'04/21/2021 10:18'
        }
    ])
    console.log(props.ProductViewDetails?.shop_to_seller?.shop?.reviews)
    const shop_rate = props.ProductViewDetails?.shop_to_seller?.shop
    return (
        <div className="mt-5">
            <Card className="p-3">
                <Container fluid className="mt-4">
                    <Row>
                        <Col sm={6} xs={12} md={11}>
                            <label style={{marginLeft:10, fontSize:18}}>Product Ratings</label>
                            <label style={{marginLeft:10}}>|</label>
                            <label style={{marginLeft:10, position:'relative', top:3}}><RatingView ratingValue={props.ProductViewDetails?.reviews_sum_rating / props.ProductViewDetails?.reviews_count} size={17}/></label>
                            {/* <label style={{marginLeft:10, fontSize:18}}>Product Ratings</label>
                            
                            <RatingView style={{paddingLeft: '5px'}}ratingValue={props.ProductViewDetails?.reviews_sum_rating / props.ProductViewDetails?.reviews_count} size={17}/> */}
                            {props.ProductViewDetails?.shop_to_seller?.shop?.reviews?.map((data,index) => {
                                return (
                                    <Fragment key={data.id}>
                                    <label style={{marginLeft:10}}>|</label>
                                    <label style={{marginLeft:10, position:'relative', top:3}}><RatingView ratingValue={data.rating} size={17}/></label>
                                    <label style={{marginLeft:10}}>|</label>
                                    <label style={{marginLeft:10}}> { isNaN(+shop_rate.reviews_sum_rating) && isNaN(+shop_rate.reviews_count) ? shop_rate.reviews_sum_rating / shop_rate.reviews_count : `0/5 (${shop_rate.reviews_count} reviews)` }</label>
                                    </Fragment>
                                )
                            })}
                        </Col>
                        
                    </Row>
                    <hr/>

                    {
                        props.ProductViewDetails?.reviews && props.ProductViewDetails?.reviews?.map((data,index) => {
                            return (
                                <Row key={index}>
                                    <Col sm={6} xs={12} md={1}>
                                        <img src={data.user.image ? data.user.image.path : '/assets/buyer/images/noImage.jpg'} style={{borderRadius: '50%', width: 100 }}></img>
                                    </Col>
                                    <Col md={9} >
                                        <p>{data.user.name}</p>
                                        <RatingView ratingValue={data.rating} size={17}/>
                                        <p className="mt-3" style={{fontWeight:300}}>
                                            {data.review}
                                        </p>
                                        <Card style={{backgroundColor:'#F4F4F4'}}>
                                            <Card.Body>
                                                <Card.Title style={{fontWeight:300, color:'#161F92', fontSize:14}}>
                                                    <p>Seller’s Response:</p>
                                                </Card.Title>
                                                <Card.Title style={{fontWeight:300, fontSize:14}}>
                                                    {
                                                        data.comments.map((res_com) => {
                                                            return(
                                                                <p key={res_com.id}>
                                                                    {res_com.comment}
                                                                </p>
                                                            )
                                                            
                                                        })
                                                    }
                                                    
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={6} xs={12} md={2}>
                                        <label>{moment(data.created_at).format('MMMM Do YYYY')}</label>
                                    </Col>

                                    <div className="mt-4">
                                        <hr/>
                                    </div>
                                </Row>
                            )
                        })
                    }
                </Container>
            </Card>
        </div>
    )
}

export default ProductCommentSection
