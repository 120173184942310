import React, { Component } from 'react';
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import SellerMenu from '../seller_menu';
import MainHeader from '../../../components/buyer/Header/MainHeader';
import RelatedProducts from '../../../components/buyer/ProductView/RelatedProducts';
import SectionTitle from '../../../components/buyer/Section/SectionTitle'
import EventsSection from '../../../components/buyer/Landing/EventsSection';
import LowerAds from '../../../components/common/Ads/LowerAds';

function Profile() {
        return (
            <div>
                <MainHeader />
                <Container className="mainContainer">
                    <Row>
                        <Col xs={2} className="leftContainer">
                            <SellerMenu />
                        </Col>

                        <Col xs={10} className="rightContainer">
                            <div style={{ width: "100%", border: "3px solid #3E6AE3", color: "#3E6AE3", padding: "10px", backgroundColor: "white", marginBottom: "10px" }}>
                                <h6>Waiting for Approval</h6>
                            </div>

                            <div style={{ background: "white", marginBottom: "10px", width: "100%", padding: "30px"}}>
                                <div className="profileTitle" style={{textAlign: "left"}}>
                                    <h5>My Profile</h5>
                                    <p>Manage and protect your account</p>
                                </div>
                                <hr />
                                <div className="profileDetailsContainer">
                                    <div style={{marginTop: "30px"}}>
                                        <div style={{display: "inline-block"}}>
                                        <img src="/profile_pic.png" className="profilePicDisplay profilePicLG" />
                                        </div>
                                        <div style={{ display: "inline-block", textAlign: "left", marginLeft: "25px"}}>
                                        <span>File size: maximum 1 MB</span><br />
                                        <span>File extension: .JPEG, .PNG</span>
                                        </div>
                                    </div>
                                    <div className="profileDetailsForm">
                                        <Container className="formContainer">
                                            <Row className="formGroup">
                                                <Col xs={3} className="formTitle">
                                                    Name
                                                </Col>
                                                <Col xs={6} className="formDataReadOnly formDataHighLight">
                                                    Chuwariwap Pablo
                                                </Col>
                                            </Row>
                                            <Row className="formGroup">
                                                <Col xs={3} className="formTitle">
                                                    Username
                                                </Col>
                                                <Col xs={6} className="formDataReadOnly">
                                                    ChuwariwapPablo456
                                                </Col>
                                            </Row>
                                            <Row className="formGroup">
                                                <Col xs={3} className="formTitle">
                                                    Phone Number
                                                </Col>
                                                <Col xs={6} className="formDataReadOnly">
                                                    *******9658
                                                </Col>
                                            </Row>
                                            <Row className="formGroup">
                                                <Col xs={3} className="formTitle">
                                                    Birthday
                                                </Col>
                                                <Col xs={6} className="formDataReadOnly">
                                                    30 December 2000
                                                </Col>
                                            </Row>
                                            <Row className="formGroup">
                                                <Col xs={3} className="formTitle">
                                                    Password
                                                </Col>
                                                <Col xs={2} className="formDataReadOnly">
                                                    ************ugh 
                                                </Col>
                                                <Col xs={4} className="formDataReadOnly">
                                                <Nav.Link href="/home">Change</Nav.Link>
                                                </Col>
                                                
                                            </Row>
                                        </Container>
                                    </div>
                                </div>    
                            </div>
                            
                        </Col>
                    </Row>
                    
                    <LowerAds/>
                    <SectionTitle sectionTitle="Buy Again"/>
                    <RelatedProducts/>
                </Container>
                
            </div>
        );
}

export default Profile;