import React, { useEffect } from 'react';
import MainHeader from '../../components/buyer/Header/MainHeader';
import SellerMenu from '../seller/seller_menu';
import { Container, Row, Col, Nav, Button, Table, Card } from "react-bootstrap";
import { SellerEearnings } from '../../features/EarningsSlice';
import { useDispatch, useSelector } from 'react-redux';
const Earnings = () => {

    const dispatch = useDispatch();

    const { sellerEarnings } = useSelector((state) => state.earnings);

    useEffect(() => {
        dispatch(SellerEearnings())
    }, []);

    return (
        <div>
            <MainHeader />
            <Container className="mainContainer">
                <Row>
                    <Col xs={2} className="leftContainer">
                        <SellerMenu />
                    </Col>
                    <Col xs={10} className="rightContainer" >
                        <Card>
                            <Container>

                                <Card.Body>
                                    <p><img src="/assets/seller/icons/earnings_icon.png" style={{width:30}}/> Earnings</p>
                                    <Table responsive="sm">

                                        <tbody >
                                            <tr style={{ fontSize: 14 }}>
                                                <td>Amount</td>
                                                <td>Date</td>
                                            </tr>
                                            {sellerEarnings?.map((item, i) => {
                                                return (
                                                    <tr key={i} style={{ fontSize: 13 }}>
                                                        <td>{item?.amount}</td>
                                                        <td>{item?.formattedstartdate}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Earnings;
