import React from 'react'
import GlobalProductCard  from '../../common/ProductCard'
import { Link } from 'react-router-dom';



const ProductCard =({product}) => {

        let rating = "0";
        if (product?.reviews_sum_rating != null) {
           rating = product?.reviews_sum_rating;
        }

        return (
            <Link to={`product/${product.id}` } style={{textDecoration:'none',color:"#000"}}>
                <GlobalProductCard
                            className="sliderEventDataCategory"
                            isIdolMall={true}
                            productName={product.product_name}
                            productImage={product?.latest_variety?.latest_image?.path}
                            reviewsRating={rating}
                            discounted={product?.latest_variety?.discounted}
                            price={product?.latest_variety?.price}
                        />
            </Link>
    )
}

export default ProductCard;
