import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {fetchPurchase} from '../../features/PurchaseSlice'
import TabTransactionList from '../../components/buyer/Tabs/TabTransactionList'
import Spinner from 'react-bootstrap/Spinner';
function BuyerPurchase() {

    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const { toPayOrderList,
            toShipOrderList,
            toReceiveOrderList,
            completedOrderList,
            cancelledOrderList,
            returnedOrderList} = useSelector((state) => state.purchase);


            useEffect(() => {
                setIsLoading(true)
                dispatch(fetchPurchase()).then((response) => {
                    setIsLoading(false)
                })
         },[dispatch])


    return (
        <div>

            {
                !isLoading ?
                <div>
                    <TabTransactionList
                        toPayOrderList={toPayOrderList}
                        toShipOrderList={toShipOrderList}
                        toReceiveOrderList={toReceiveOrderList}
                        completedOrderList={completedOrderList}
                        cancelledOrderList={cancelledOrderList}
                        returnedOrderList={returnedOrderList}
                    />
                    </div>

                :

                <div className='h-100 d-flex align-items-center justify-content-center' style={{paddingTop:'15%'}}>
                    <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }

        </div>
    )
}

export default BuyerPurchase
