import React,{useState} from 'react'
import {publishProduct, deleteProduct,delistProduct} from '../../../features/ProductSlice'
import { Container, Row, Col, Nav, Button} from "react-bootstrap";
import { Link,useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from '../../common/modal/deleteModal';

const ProductContainer = ({
    isLoading,
    setIsLoading,
    item,
    tabName,
    refreshData
}) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [openModal, setOpenModal] = useState(false);
    const { activePriceDisplay } = useSelector((state) => state.price);

    const handlePublish = (productId=false) => {
       if(productId){
           dispatch(publishProduct({productId})).then(() => {
            setIsLoading(true);
            dispatch(refreshData());
           })
       }
    }

    const handleDelete = (productId=false) => {
        if(productId){
            dispatch(deleteProduct({productId})).then(() => {
                setIsLoading(true);
                dispatch(refreshData());
            })
        }
        setOpenModal(false)
     }

     const handleDelist = (productId=false) => {
        if(productId){
            dispatch(delistProduct({productId})).then(()=> {
                setIsLoading(true);
                dispatch(refreshData());
            })
        }
     }

    return (
        <div>
        {
            !isLoading && (
                <Container  className="productPreview">
            <DeleteModal 
                productId={item.id}
                setOpenModal={setOpenModal}
                openModal={openModal}
                handleDelete={handleDelete}

            />
            <Row>
                <Col xs="2">
                    <div style={{height:150,width:150}}>
                        <img className="productThumb" alt="" src={item.latest_variety?.latest_image?.path} style={{ width: "100%" ,height:"100%"}} />
                    </div>
                </Col>
                <Col sx="6" style={{ textAlign: "left" }}>
                        <Nav.Link>{item.product_name}</Nav.Link>
                            <br /><br />
                        <p style={{ padding: "0px 16px" }}>
                        <img alt="" src="/stock_icon.png" style={{ width: "19px", marginRight: "20px" }} /> 
                        Stock: {item.varieties_sum_stocks}</p>
                </Col>
                <Col xs="2" style={{ textAlign: "right" }}>
                     <b style={{ color: "#CF0E0E" }}>P{item.varieties_min_price} - P{item.varieties_max_price}</b>
                </Col>
            </Row>
            <hr />
            <Row className="productPreviewBtn">
                <Col xs={12} style={{ textAlign: "right" }}>
                        <Link to={`/seller/products/update_product/${item.id}`}>
                            <Button 
                                variant="outline-primary"
                                >
                                Update
                            </Button>
                        </Link>
                        {tabName && (tabName === "Draft" || tabName === "Delisted") &&
                            <Button 
                                variant="outline-primary"
                                onClick={()=>handlePublish(item.id)}
                                >
                                Publish
                            </Button>
                        }
                        {tabName && tabName === "Active" &&
                            <Button 
                                variant="outline-primary"
                                onClick={()=>handleDelist(item.id)}
                                >
                                Delist
                            </Button>
                        }
                            <Button variant="outline-primary"
                                onClick={()=>setOpenModal(true)}
                            >
                                Delete
                            </Button>
                </Col>
            </Row>
        </Container> 

            )
        }
           
        </div>
        
               
    )
}

export default ProductContainer;
