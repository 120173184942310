import React,{useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NoProduct, WaitingApproval } from '../../../components/buyer/Card';
import ProductContainer from '../../../components/seller/SellerProducts/ProductContainer';
import { useDispatch, useSelector } from 'react-redux';
import {getProductListForApproval} from '../../../features/ProductSlice'

const ProductApproval = ({waiting_approval}) => {

        const { for_approval_product } = useSelector((state) => state.product);
        const [isLoading, setIsLoading] = useState(true);
        const dispatch = useDispatch();

        useEffect(() => {
                if (isLoading) {
                        dispatch(getProductListForApproval()).then(() => {
                                setIsLoading(false)
                        })
                }
                
        },[isLoading]);

        return (
                <div>
                {
                        !isLoading && (
                                <div className="productPreviewContainer">
                                {
                                        waiting_approval !== 1 ? (
                                        <WaitingApproval/>
                                        ): (
                                                <></>
                                        )
                                }
                                {
                                        for_approval_product.length === 0 ? (
                                        <NoProduct/>
                                        ): (
                                                for_approval_product?.map((item, i) => {
                                                return (
                                                        <div>
                                                                <ProductContainer 
                                                                        isLoading={isLoading}
                                                                        setIsLoading={setIsLoading}
                                                                        item={item}
                                                                        tabName="For Approval"
                                                                        refreshData={getProductListForApproval}
                                                                        />
                                                        </div>
                                                )
                                        })
                                        )
                                }
                                </div>
                        )
                }
                </div>
        );
}

export default ProductApproval;