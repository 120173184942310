import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import NotificationContainer from './components/notifications/NotificationContainer'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect,
  useRouteMatch
} from "react-router-dom";

import './App.css'
import Footer from './components/buyer/Footer/Footer';
import Login from './pages/Login';
import BuyerSignUp from './pages/buyer/BuyerSignUp';
import BuyerForgotPassword from './pages/buyer/BuyerForgotPassword';
import ProtectedRoute from './components/middleware/ProtectedRoute';
import BuyerHomePage from './pages/buyer/BuyerHome';
import NotFound from './components/service/NotFound';
import BuyerCategoryPage from './pages/buyer/BuyerCategory';
import BuyerEventPage from './pages/buyer/BuyerEvent';
import BuyerIdolMall from './pages/buyer/BuyerIdolMall';
import BuyerProductView from './pages/buyer/BuyerProductView';
import BuyerCart from './pages/buyer/BuyerCart';
import BuyerCheckOut from './pages/buyer/BuyerCheckOut';
import BuyerPaymentStatus from './pages/buyer/BuyerPaymentStatus';
import BuyerSuccessfulPayment from './pages/buyer/BuyerSuccessfulPayment';
import BuyerAddresses from './pages/buyer/BuyerAddresses';
import BuyerShop from './pages/buyer/BuyerShop';
import SellerAddresses from './pages/seller/addresses/SellerAddresses';
import Sales from './pages/seller/sales/sales';
import Profile from './pages/seller/profile/profile';
import Products from './pages/seller/products/products';
import ShipmentSetting from './pages/seller/setting/shipmentSetting';
import SalesConfirmPickup from './pages/seller/sales/sales_confirm_pickup';
import AddProduct from './pages/seller/products/add_product';
import UpdateProduct from './pages/seller/products/update_product';
import SalesOrderDetails from "./pages/seller/sales/sales_order_details"
import ShopSetting from "./pages/seller/setting/shopSetting"

import AboutIsn from './pages/AboutIsn';
import BuyerProfile from './pages/buyer/BuyerProfile';
import SellerProfile from './pages/seller/profile/SellerProfile';
import Layout from './components/buyer/Sidebar/Layout';
import BuyerChangePassword from './pages/buyer/BuyerChangePassword';
import ListNotification from './components/buyer/Notifications/ListNotification';
import BuyerPurchase from './pages/buyer/BuyerPurchase';
import OrderDetails from './components/buyer/Orders/OrderDetails';
import ShippedDetails from './components/buyer/ToShip/ShippedDetails';
import ReceiveDetails from './components/buyer/ToReceive/ReceiveDetails';
import CompleteDetails from './components/buyer/ToComplete/CompleteDetails';
import CanceledDetails from './components/buyer/ToCanceled/CanceledDetails';
import ReturnDetails from './components/buyer/ToReturn/ReturnDetails';


import ShopRatingsReviews from './pages/seller/shop_ratings/shop_ratings_reviews'

//Services
import ScrollToTop from './components/service/ScrollToTop';
import StartSelling from './pages/seller/start_selling';
import Earnings from './pages/seller/earnings';

import ActivePriceDisplay from './components/common/Price/activePriceDisplay';
import TermOfServices from './pages/TermOfServices';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Intercom from './components/intercom';
import Notification from './pages/Notification';
import { ToastContainer } from "react-toastify";


function App() {
  return (
    
    <Router>
      <ToastContainer />
    <NotificationContainer/>
      <div className="App">
          <Intercom>
            <ActivePriceDisplay />
            <ScrollToTop>
              <Switch>

                <Route exact path="/login" component={Login} />

                <Route exact path="/about_us" component={AboutIsn} />

                <Route exact path="/sign_up" component={BuyerSignUp} />

                <Route exact path="/forgot_password" component={BuyerForgotPassword} />

                <Route exact path="/" component={BuyerHomePage} />

                <Route exact path="/product_cat/:item" component={BuyerCategoryPage} />

                <Route exact path="/event/:type" component={BuyerEventPage} />

                <Route exact path="/product/:item" component={BuyerProductView} />

                <Route exact path="/shop/:id" component={BuyerShop} />

                <Route exact path="/idolmall" component={() => (<BuyerIdolMall page_name="Idol Mall" />)} />

                <Route exact path="/terms_of_services" component={TermOfServices} />
                <Route exact path="/privacy_policy" component={PrivacyPolicy} />

                <Route exact path="/check_out/payment_successful" component={BuyerSuccessfulPayment} />

                <ProtectedRoute exact path="/idol_cart/" component={BuyerCart} />

                <ProtectedRoute exact path="/check_out/" component={BuyerCheckOut} />
                <ProtectedRoute exact path="/check_out/payment/:transaction_id" component={BuyerPaymentStatus} />
                

                <ProtectedRoute exact path="/my_address/" component={BuyerAddresses} />
                <ProtectedRoute exact path="/start_selling" component={StartSelling} />
                <ProtectedRoute exact path="/seller/my_address" component={SellerAddresses} />
                <ProtectedRoute exact path="/seller/shop_setting" component={ShopSetting} />
                <ProtectedRoute exact path="/seller/account/profile" component={Profile} />
                <ProtectedRoute exact path="/seller/sales" component={Sales} />
                <ProtectedRoute exact path="/seller/products" component={Products} />
                <ProtectedRoute exact path="/seller/products/add_product" component={AddProduct} />
                <ProtectedRoute exact path="/seller/products/update_product/:id" component={UpdateProduct} />
                <ProtectedRoute exact path="/seller/sales/confirm_pickup/:id" component={SalesConfirmPickup} />
                <ProtectedRoute exact path="/seller/earnings" component={Earnings} />
                <ProtectedRoute exact path="/seller_order_details/:order_id" component={SalesOrderDetails} />
                <ProtectedRoute exact path="/seller/shop_ratings" component={ShopRatingsReviews} />
                <ProtectedRoute exact path="/seller/shipment_setting" component={ShipmentSetting} />
                <ProtectedRoute exact path="/seller/seller_profile" component={SellerProfile} />

                <Layout>
                  <Switch>
                    <ProtectedRoute exact path="/my_account" component={BuyerProfile} />
                    <ProtectedRoute exact path="/change_password" component={BuyerChangePassword} />
                    <ProtectedRoute exact path="/notifications" component={Notification} />
                    <ProtectedRoute exact path="/my_purchase" component={BuyerPurchase} />
                    <ProtectedRoute exact path="/order_details/:order_id" component={OrderDetails} />
                    <ProtectedRoute exact path="/shipped_details/:order_id" component={ShippedDetails} />
                    <ProtectedRoute exact path="/receive_details/:order_id" component={ReceiveDetails} />
                    <ProtectedRoute exact path="/complete_details/:order_id" component={CompleteDetails} />
                    <ProtectedRoute exact path="/canceled_details/:order_id" component={CanceledDetails} />
                    <ProtectedRoute exact path="/return_details/:order_id" component={ReturnDetails} />

                  </Switch>
                </Layout>

                <Route path='*'>
                  <NotFound />
                </Route>

              </Switch>

              <Footer />
            </ScrollToTop>
          </Intercom>
      </div>
    </Router>
  );
}

export default App;
