import React, {useState,useEffect} from 'react';
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainHeader from '../../../components/buyer/Header/MainHeader';
import SellerMenu from '../seller_menu';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import CreatableMultiSelect from '../../../components/seller/SellerProducts/CreatableMultiselect'
import VarietyContainer from '../../../components/seller/SellerProducts/VarietyContainer';
import { useHistory } from 'react-router-dom';
import {fetchCategory,getSubCategoryByCategoryIds} from '../../../features/CategoriesSlice'
import { Formik, Field, FieldArray} from "formik";
import {addProduct} from "../../../features/ProductSlice";
import {PRODUCT_VALIDATION_SCHEMA} from "../../seller/products/validationSchema";
import { toast } from "react-toastify";
import FocusError from "./focus_error";


const  AddProduct = () =>{
    const history = useHistory();
    const dispatch = useDispatch();
    const { categoryList, isLoading  ,subCategoryList} = useSelector((state) => state.categories);
    const {  isLoading:savingProduct } = useSelector((state) => state.product)
    const [optionOne, setOptionOne] = useState([])
    const [optionTwo, setOptionTwo] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [subCategoryOptions, setSubCategoryOptions] = useState([])
    const [categoryIds, setCategoryIds] = useState({ids:[]})
    const [varieties, setVarieties] = useState([])
    const [varCount, setVarCount] = useState(1)
    const [shipping, setShipping] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isPublishing,setIsPublishing]= useState(false);
    const [submitter,setSubmitter]= useState(0);
    const [loadSpecOption1, setLoadSpecOption1] = useState(false);
    const [loadSpecOption2, setLoadSpecOption2] = useState(false);

    const initialFormikState = {
        product_name: null,
        category: [],
        sub_category: [], //sub-category
        product_description: null,
        variation_name_1: '' ,//spec1,
        spec1_options: [],
        variation_name_2: null,//spec2
        spec2_options: [],
        shipping_fee: 0,
        varieties: [],
    }

    const [formState,setFormState] =  useState(
        {
            product_name: '',
            category: [],
            sub_category: [], //sub-category
            product_description: '',
            variation_name_1:'' ,//spec1,
            spec1_options: [],
            variation_name_2:'',//spec2
            spec2_options: [],
            shipping_fee: 0,
            varieties: [
            ],
        }
    )

        useEffect(() => {
                dispatch(fetchCategory())
        },[dispatch,categoryIds])
   
        useEffect(() => {
            if(categoryIds && categoryIds.ids.length > 0){
                dispatch(getSubCategoryByCategoryIds(categoryIds))
            }else{
                dispatch(getSubCategoryByCategoryIds({ids:[]}))
            }
         },[categoryIds])

         useEffect(() => {
            if(subCategoryList){
                const options = subCategoryList.map((category) => ({
                    value: category.id,
                    label: category.category_name,
                }));
                setSubCategoryOptions(options)
            }
        }, [subCategoryList,categoryList])

        

        useEffect(() => {
            if(categoryList){
                const options = categoryList.map((category) => ({
                    value: category.id,
                    label: category.category_name,
                }));
                setCategoryOptions(options)
            }
        }, [categoryList])



   
        const handleCategoryId = (value,setFieldValue)=>{
            if(value){
                const data = [{
                    id:value.value
                }]

                const arrayIds = []
                    arrayIds.push(value.value)

                setCategoryIds({ids:arrayIds})

                setFieldValue('category',data)
            }
        }

    
        const handleSubCategoryId = (value,setFieldValue)=>{
            if(value){
                const data = [{
                    id:value.value
                }]

                setFieldValue('sub_category',data)
            }
        }

        useEffect(() => {
            setFormState((prev)=> {
                const newState = {...prev}
                console.log(newState)
                console.log(varieties)
                newState.varieties = varieties
                console.log(newState)
                return newState
            })
        }, [varieties])

        
        const addVariety= (e,  values, setValues)=>{
            const newVar = {
                id: null,
                key: Math.floor(Math.random() * 100000),
                specs1_option: 0,
                specs2_option: 0,
                new_specs1_option: 0,
                new_specs2_option: 0,
                wt: null, //weight
                l: null,  //length
                h: null, //height
                w: null, //width
                stocks:  null,
                price:  null,
                discounted_price: 0,
                img: [] //images
            }

            const newValues = {...values}

            newValues.varieties = [...values.varieties, newVar]

            setValues(newValues)
        }
        
        const isChecked = (value) => {
            return value === 1 ? true : false
        }

        const removeVariety = (e, values, setValues, setFieldValue)=> {
            
            const removedVarieties = values.varieties.filter((item, index) => index === e);
            
            const removed = [...values.varieties].filter((item, index) => index !== e);

            if(removedVarieties && removedVarieties[0]?.id){
                
                setValues((prev)=> {
                    const removedArray = [...prev['deleted_variation_id']]
                    removedArray.push(removedVarieties[0]?.id)
                    prev['deleted_variation_id'] = removedArray
                    return {...prev}
                })
            }

            setFieldValue('varieties', removed)
        }

        const handleSwitch = (e, setFieldValue) => {
            const {checked,name} = e.target

            setFieldValue('shipping_fee', checked ? 1 : 0)
        };

          
        const handleSubmitProduct = (e) =>{

            const formData = new FormData();

            if(submitter === 1){
                formData.append(`status` , 'Draft')
            }else if(submitter === 2){
                formData.append(`status` , 'For Approval')
            }


            for (const field in e){
                if(field === 'product_name' 
                || field === 'product_description' 
                || field === 'variation_name_1'
                || field === 'variation_name_2'
                || field === 'shipping_fee'
                ){
                    formData.append(`${field}` , e[field])
                }
                if(field === 'category' || field === 'sub_category'){
                    e[field].forEach((item,x)=>{formData.append(`${field}[${x}][id]`,item.id)})
                }

                if(field === 'spec1_options' || field === 'spec2_options'){
                    e[field].forEach((item,x)=>{formData.append(`${field}[${x}]`,item)})
                }

                if(field === 'varieties'){
                    for(const index in e[field]){
                        for(const index1 in e[field][index]){
                            if(index1 !== "img"){
                                formData.append(`${field}[${index}][${index1}]` , e[field][index][index1])
                            }
                            if(index1 === "img"){
                                e[field][index][index1].forEach((item,x)=>{
                                formData.append(`${field}[${index}][${index1}][${x}]`,item)
                             }
                                )
                            }
                            
                        }
                    }
                }
            }
            
            if(formData){
                dispatch(addProduct(formData))
                    .then((res)=>{
                        var message = 'Saved';

                        if(submitter === 1){
                            setIsSaving(false)
                        }else if(submitter === 2){
                            message = 'Published';
                            setIsPublishing(false)
                        }

                        if (res.payload.status === 200) {

                            toast.success('Successfully ' + message + ' Product', {
                                position: "bottom-left",
                            });

                            history.push("/seller/products")
                        }

                    })
            }
        
            // e.preventDefault()
        }
        
        return (
            <div>
                <MainHeader />
                <Container className="mainContainer">
                        <Row>
                            <Col xs={2} className="leftContainer">
                                <SellerMenu />
                            </Col>
                            <Col xs={10} className="rightContainer" >
                        <Formik 
                            initialValues={initialFormikState}
                            validationSchema={PRODUCT_VALIDATION_SCHEMA}
                            onSubmit={(e)=>handleSubmitProduct(e)}
                            >
                        {( {values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            setValues,
                            isSubmitting }) => (                
                            <Form onSubmit={handleSubmit}>
                                <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                                   
                                    <Row style={{marginBottom: "15px"}}>
                                        <Col xs={12} style={{textAlign: "left"}}>
                                            <h4>Add Product</h4>
                                        </Col>
                                    </Row>
                                    
                                    <Row style={{marginBottom: "30px"}}>
                                        <Form.Label for="product_name" style={{textAlign: "right"}} column xs={2}><span style={{color: "red"}}>*</span>Product Name</Form.Label>
                                        <Col xs={10}>
                                        <Form.Control 
                                            type="text" 
                                            className={touched.product_name && errors.product_name ? "error" : null}
                                            name="product_name"
                                            onChange={(e)=>{handleChange(e)}}
                                            style={{background: "#F4F4F4"}}/>
                                        </Col>
                                        {errors && errors.product_name &&
                                            <p className="text-warning">{errors.product_name}</p>
                                        }
                                        
                                    </Row>
                                    <Row style={{marginBottom: "30px"}}>
                                        <Form.Label for="category" style={{textAlign: "right"}} column xs={2}><span style={{color: "red"}}>*</span>Product Category</Form.Label>
                                        <Col xs={10}>
                                            <Select
                                                onChange={(e)=>handleCategoryId(e,setFieldValue)}
                                                isLoading={isLoading}
                                                isDisabled={isLoading}
                                                style={{background: "#F4F4F4"}}
                                                placeholder="Categories"
                                                name="category"
                                                options={categoryOptions}
                                                className={touched.category && errors.category ? "error" : null}
                                                classNamePrefix="select"
                                            />
                                          
                                        </Col>
                                            {errors && errors.category &&
                                            <p className="text-warning">{errors.category}</p>
                                            }
                                    </Row>
                                    <Row style={{marginBottom: "30px"}}>
                                        <Form.Label for="sub_category" style={{textAlign: "right"}} column xs={2}><span style={{color: "red"}}>*</span>Lvl 2 Category</Form.Label>
                                        <Col xs={10}>
                                        <Select
                                            className={touched.category && errors.category ? "error" : null}
                                            isLoading={isLoading}
                                            isDisabled={isLoading}
                                            placeholder="Sub-categories"
                                            onChange={(e)=>handleSubCategoryId(e,setFieldValue)}
                                            style={{backGroundColor: "#F4F4F4"}}
                                            name="sub_category"
                                            options={subCategoryOptions}
                                            classNamePrefix="select"
                                        />
                                        </Col>
                                        {errors && errors.sub_category &&
                                            <p className="text-warning">{errors.sub_category}</p>
                                            }
                                    </Row>
                                    <Row style={{marginBottom: "30px"}}>
                                        <Form.Label for="product_description" style={{textAlign: "right"}} column xs={2}><span style={{color: "red"}}>*</span>Product Description</Form.Label>
                                        <Col xs={10}>
                                            <Form.Control as="textarea" 
                                                name="product_description"
                                                className={touched.product_description && errors.product_description ? "error" : null}
                                                onChange={(e)=>{handleChange(e)}}
                                                style={{ 
                                                    height: '100px', 
                                                    background: "#F4F4F4" ,
                                                    resize:'none'
                                                        }} 
                                                />
                                        </Col>
                                        {errors && errors.product_description &&
                                            <p className="text-warning">{errors.product_description}</p>
                                            }
                                    </Row>
                                </Container>
                                <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                                    <Row style={{marginBottom: "30px"}}>
                                        <Form.Label for="variation_name_1" style={{textAlign: "right"}} column xs={2}>Variation 1</Form.Label>
                                        <Col xs={10}>
                                            <Form.Control 
                                                type="text" 
                                                className={touched.product_description && errors.product_description ? "error" : null}
                                                name="variation_name_1"
                                                onChange={(e)=>{handleChange(e)}}
                                                style={{background: "#F4F4F4"}}/>
                                        </Col>
                                        {errors && errors.variation_name_1 &&
                                            <p className="text-warning">{errors.variation_name_1}</p>
                                            }
                                    </Row>
                                    <Row style={{marginBottom: "30px"}}>
                                        <Form.Label for="spec1_options" style={{textAlign: "right"}} column xs={2}>Options</Form.Label>
                                        <Col xs={10}> 
                                            <CreatableMultiSelect 
                                                name="spec1_options" 
                                                setOptions={setOptionOne}
                                                setState={setValues}
                                                setFieldValue={setFieldValue}
                                                touched={touched}
                                                errors={errors}
                                                loadOption={setLoadSpecOption1}
                                                /> 
                                        </Col>
                                        {errors && errors.spec1_options &&
                                            <p className="text-warning">{errors.spec1_options}</p>
                                            }
                                    </Row>
                                    <Row style={{marginBottom: "30px"}}>
                                        <Form.Label style={{textAlign: "right"}} column xs={2}>Variation 2 (Optional)</Form.Label>
                                        <Col xs={10}>
                                            <Form.Control 
                                                type="text" 
                                                name="variation_name_2"
                                                onChange={(e)=>handleChange(e)}
                                                placeholder="Type here" 
                                                style={{background: "#F4F4F4"}}
                                                />
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: "30px"}}>
                                        <Form.Label style={{textAlign: "right"}} column xs={2}>Options (Optional)</Form.Label>
                                        <Col xs={10}> 
                                            <CreatableMultiSelect 
                                                name="spec2_options" 
                                                setState={setValues}
                                                setOptions={setOptionTwo}
                                                setFieldValue={setFieldValue}
                                                touched={touched}
                                                errors={errors}
                                                loadOption={setLoadSpecOption2}
                                                />
                                        </Col>
                                    </Row>
                                </Container>

                                <div id="variations_container" className="variations_">
                                </div>
                                <FieldArray name="varieties">
                                        {() => (values.varieties.map((item, index) => {
                                            
                                            const ticketErrors = errors.varieties?.length && errors.varieties[index] || {};
                                            const ticketTouched = touched.varieties?.length && touched.varieties[index] || {};
                                            return(
                                                <VarietyContainer
                                                    key={item.key}
                                                    var_count={index+1} 
                                                    optionOne={optionOne}
                                                    optionTwo={optionTwo}
                                                    index={index}
                                                    varData={item}
                                                    removeVariety={removeVariety}
                                                    values={values}
                                                    setValues={setValues}
                                                    setFieldValue={setFieldValue}
                                                    ticketTouched={ticketTouched}
                                                    ticketErrors={ticketErrors}
                                                    />
                                            )
                                }))}
                                </FieldArray>
                                <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                                <Row style={{marginBottom: "15px"}}>
                                        <Col xs={24}>
                                        <Row>
                                            <Button 
                                                for="varieties"
                                                onClick={(e) => addVariety(e, values, setValues)} 
                                                className={errors.varieties && !Array.isArray(errors.varieties) ? "error addVariantBtn" : "addVariantBtn"}
                                                variant="outline-primary" 
                                                style={{
                                                    border: "3px solid #3167E9", 
                                                    borderRadius: "50px", 
                                                    width: "100%"}}>
                                                <img alt='' src="/seller_icons/add_icon.png" style={{width:"20px", marginRight: "10px"}} />
                                                Add Variety
                                            </Button>
                                            
                                        </Row>
                                        <Row align="left">

                                            { 
                                                            errors.varieties && !Array.isArray(errors.varieties) &&
                                                            <small className="text-danger">{errors.varieties}</small>
                                            }
                                        </Row>
                                        </Col>
                                        
                                    </Row>

                                    

                                </Container>
                                <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Check
                                            onChange={(e)=>handleSwitch(e, setFieldValue)}
                                            name="shipping_fee"
                                            id="default-checkbox"
                                            type='checkbox'
                                            checked={isChecked(values.shipping_fee)}
                                            label={"I will cover the Shipping Fee"}
                                        />
                                    </Col>
                                    </Row>

                                </Container>
                                <div>
                                <Row>
                                        <Col xs={12}>
                                            <Button 
                                                rounded
                                                name="Draft"
                                                type="submit"
                                                variant="outline-primary"
                                                disabled={savingProduct}
                                                onClick={()=>{setSubmitter(1);setIsSaving(true)}}
                                                size="md"
                                                style={{
                                                    borderRadius:"20px !important",
                                                    width: "100%",
                                                    fontSize:18,
                                                    fontWeight:"bold",
                                                    }}>
                                                {isSaving ?
                                                <div>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        />&nbsp;Saving Product...
                                                </div>
                                                :'Save'}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row 
                                        style={{
                                            marginTop:15,
                                            marginBottom:150
                                        }}>
                                        <Col xs={12}>
                                            <Button 
                                                name="For Approval"
                                                variant="primary" 
                                                type="submit"
                                                rounded  
                                                size="md" 
                                                disabled={savingProduct}
                                                onClick={()=>{setSubmitter(2);setIsPublishing(true)}}
                                                style={{
                                                    borderRadius:"20px !important",
                                                    width: "100%",
                                                    fontSize:18,
                                                    fontWeight:"bold"
                                                    }}>
                                                {isPublishing?
                                                <div>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        />&nbsp;Publishing Product...
                                                </div>:
                                                'Publish'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            <FocusError setIsSaving={setIsSaving} setIsPublishing={setIsPublishing}/>
                            </Form>
                                )}
                    </Formik>
                            
                            </Col>
                        </Row>
                    </Container>
                
            </div>
        );
    }

export default AddProduct;