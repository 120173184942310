import React from 'react'
import CheckOutList from '../../components/buyer/CheckOut-v2/CheckOutList'
import MainHeader from '../../components/buyer/Header/MainHeader'
import SubSectionTitle from '../../components/buyer/Section/SubSectionTitle'

function BuyerCheckOut() {
    return (
        <div>
            <MainHeader/>
        
            <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30}}>
 
                <SubSectionTitle SubSectionTitle="Check Out"/>
                
                <CheckOutList/>
                
            </div>
        </div>
    )
}

export default BuyerCheckOut
