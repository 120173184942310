import React,{useRef, useState, useEffect} from 'react';
import { Card, Col, Container, Row, Button, Form as file_form } from 'react-bootstrap'
import SubHeader from '../../components/buyer/Header/SubHeader'
import { useFormik, Formik, Form, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { getProfileDetails } from '../../features/UserSlice';
import {SellerShopRegistration, selectStartSellect} from '../../features/StartSellingSlice';

import * as Yup from "yup";

function StartSelling() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {sellerHasShop} = useSelector((state) => state.user);
  const {isLoading, response_message, isSuccess} = useSelector(selectStartSellect)
  const buttonRef = useRef(0)

  const [inpShopName, setInpShopName] = useState('');
  const [inpShopTin, setInpShopTin] = useState('');
  const [inpShopDocs, setInpShopDocs] = useState([]);
  const [isNullImageMessage , setIsNullImageMessage] = useState('');
  const [ResponseMessage , setResponseMessage] = useState(response_message?response_message:'')

        useEffect(() => {
            dispatch(getProfileDetails())
        }, [dispatch])

        useEffect(() => {
            if(sellerHasShop){
                history.push('/seller/products')
            }
        }, [sellerHasShop])

        useEffect(() => {
    
  },[inpShopDocs, isLoading, ResponseMessage, isSuccess])

  const DocsHandler = (e) => {
    if (e.target.files) {
        setInpShopDocs({ ...inpShopDocs, seller_docs: [...e.target.files] });
    }
  }

  const RemoveSingleDoc = (doc_name) => {
    let filteredArray = inpShopDocs?.seller_docs.filter(item => item.name !== doc_name)
    setInpShopDocs({ ...inpShopDocs, seller_docs: filteredArray });
  }

  const BeSellerBtnHandler = (values) => {
    console.log(values);
    if(inpShopDocs.length === '0') {
        setIsNullImageMessage('Store docs required!')
    }else {
        
        dispatch(SellerShopRegistration({store_info:values,doc_files:inpShopDocs}));

        if(!isLoading) {
            values.shop_name = ''
            values.tin =''
            setInpShopDocs([])
            setIsNullImageMessage('')
            setResponseMessage('')
        }
    }
  }

  const StoreSignup = Yup.object().shape({
    shop_name: Yup.string()
      .required('Shop name is required!'),
  });


  return (
      <div>
          <SubHeader/>
          <div className="p-4" >
          <Formik
                enableReinitialize
                initialValues={{ file: null, shop_name: '', tin: ''}}
                validationSchema={StoreSignup}
                onSubmit={(values, {resetForm}) => {
                    buttonRef.current.disabled = true;
                    const formData = new FormData();
                    if(values.file){
                        for (let i = 0; i < values.file.length; i++) { 
                            formData.append(`documents[${i}]`, 
                                values.file[i],
                                values.file[i].name
                            )
                        }
                    }
                        
                    formData.append(
                        "shop_name",
                        values.shop_name,
                    );
                    formData.append(
                        "tin",
                        values.tin,
                    );
                    dispatch(SellerShopRegistration(formData)).then(() => {
                        buttonRef.current.disabled = false;
                    });
                    }}
                    >
                        {({ errors, values, handleBlur, handleChange, handleSubmit, setFieldValue, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <div style={{margin:'auto', width:'35%'}}>
                                <div style={{backgroundColor:'#FFF', padding:40}}>
                                    <h5>Welcome Merchant</h5>
                                    <br/><br/>
                                    <div className="form-group">
                                        <input 
                                            style={{backgroundColor:'#F4F4F4', borderColor:'#F4F4F4', fontSize:14}} 
                                            type="text" 
                                            value={values.shop_name}  
                                            onChange={e => setFieldValue('shop_name',e.target.value)} 
                                            placeholder="Shop Name*" 
                                            name="shop_name"
                                            onBlur={handleBlur('shop_name')}
                                            className="form-control"/>
                                            {(errors.shop_name && touched.shop_name) &&
                                                <label style={{color:'red', fontWeight:300, fontSize:12, padding:15}}>{errors.shop_name}</label>
                                            }
                                    </div>
                                    <br/><br/>
                                    <div className="form-group">
                                        <input 
                                            style={{backgroundColor:'#F4F4F4', borderColor:'#F4F4F4', fontSize:14}} 
                                            type="text" 
                                            value={values.tin}
                                            name="tin" 
                                            onChange={e => setFieldValue('tin',e.target.value)} 
                                            placeholder="TIN" 
                                            className="form-control"
                                        />
                                    </div>
                                    <br/>
                                    <br/>
                                    <div className="form-group">
                                        <input id="file" multiple name="file" type="file" onChange={(event) => {
                                            setFieldValue('file', event.target.files);
                                            }} className="form-control" />
                                        {touched.file && errors.file ? <span className='validation'>{errors.file}</span> : null}
                                        <label>Upload Business Documents</label>
                                    </div>
                                    <br/>
                                    <br/>
                                    <Button ref={buttonRef} type="submit" className="btn form-control btn-primary mt-5 upload_seller_doc_btn">DONE</Button>   
                                </div>
                            </div>
                        </Form>
                        )}
                </Formik>
          {/* <Formik
                        enableReinitialize
                        initialValues={{ file: null }}
                        validationSchema={StoreSignup}
                        onSubmit={(values, {resetForm}) => {
                            console.log(values)
                            const formData = new FormData();
                            formData.append(
                                "image",
                                values.file,
                                values.file.name,
                            );
                            // dispatch(imageUpload(formData));
                            // setTimeout(function() {
                            //     window.location.href = window.location;
                            // }, 3000);
                            // dispatch(props.refreshData());
                        }}
                    >
                        {({ errors, values, handleBlur, handleChange, handleSubmit, setFieldValue, touched }) => (
                        <Form onSubmit={handleSubmit}>
                            <div style={{margin:'auto', width:'35%'}}>
                                <div style={{backgroundColor:'#FFF', padding:40}}>
                                    <div className="form-group">
                                        <input 
                                            style={{backgroundColor:'#F4F4F4', borderColor:'#F4F4F4', fontSize:14}} 
                                            type="text" 
                                            value={values.shop_name}  
                                            onChange={e => setFieldValue('shop_name',e.target.value)} 
                                            placeholder="Shop Name*" 
                                            name="shop_name"
                                            onBlur={handleBlur('shop_name')}
                                            className="form-control"/>
                                            {(errors.shop_name && touched.shop_name) &&
                                                <label style={{color:'red', fontWeight:300, fontSize:12, padding:15}}>{errors.shop_name}</label>
                                            }
                                    </div>
                                    <br/><br/>
                                    <div className="form-group">
                                        <input 
                                            style={{backgroundColor:'#F4F4F4', borderColor:'#F4F4F4', fontSize:14}} 
                                            type="text" 
                                            value={values.tin}
                                            name="tin" 
                                            onChange={e => setFieldValue('tin',e.target.value)} 
                                            placeholder="TIN" 
                                            className="form-control"
                                        />
                                    </div>
                                    <br/><br/>
                                    <div className="form-group">
                                        <input id="file" name="file" type="file" onChange={(event) => {
                                            setFieldValue('file', event.target.files);
                                            }} className="form-control" />
                                    </div>
                                    <br/>
                                    <br/>
                                    <Button disabled={isLoading?true:false} type="button" className="btn form-control btn-primary mt-5 upload_seller_doc_btn">DONE</Button> 
                                </div>
                            </div> 
                        </Form>
                        )}
                </Formik> */}
            </div>
      </div>
  )
}

export default StartSelling;
