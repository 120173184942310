import React,{useState,useEffect} from 'react'
import { Container,  Form, Button, Row, Col, Modal, Nav} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import MainHeader from '../../../components/buyer/Header/MainHeader';
import SellerMenu from '../seller_menu';
import OrderBuyerAddress from '../../../components/buyer/Orders/OrderBuyerAddress';
import {useParams,useHistory} from 'react-router-dom'
import {fetchSales,cancelRequest,fetchOrderById} from "../../../features/SalesSlice"
import { number } from 'yup';

const SalesOrderDetails = () => {
    const { order_id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const {orderDetails ,orderList} = useSelector((state) => state.sales);
    const {activePriceDisplay} = useSelector((state) => state.price);
    const [modalCancelRequest, setModalCancelRequest] = useState(false);
    const [modalData, setModalData] = useState({
        reason:"",
        email:""
    });
    const [subTotal, setSubTotal] = useState(0);
    const [isOpenNewModal , setOpenNewModal] = useState(false);
    const [orderId , setOrderId] = useState(false);


    const handleClose = () => setOpenNewModal(false);

    const handleSuccessCancelRequest = () => {
        if(orderDetails && orderDetails.id){
            dispatch(cancelRequest(
                {orderId,
                 reason: modalData.reason,
                 email:modalData.email
                }
            )).then((res)=>{
                history.push("/seller/sales")
            })
        } 
        setOpenNewModal(true);
        setModalCancelRequest(false)
    }
    const handleChange = (e)  =>{
        const {name,value} = e.target
        setModalData((prev)=>{
            prev[name] = value
            return {...prev}
        })
    }
    useEffect(() => {
        console.log(orderDetails)
        setOrderId(order_id)
        },[order_id])

    useEffect(() => {
        if(orderList.length === 0) {
                dispatch(fetchSales()) 
        }
         
        },[dispatch,orderList])

    useEffect(() => {
        var sub_total = 0;
        console.log(orderDetails)
        orderDetails?.order_items?.map((x) => {
                sub_total += x.price
        })
            
        setSubTotal(sub_total)

            },[orderDetails])

    useEffect(() => {
        if(orderList.length > 0) {
            dispatch(fetchOrderById(orderId))
        }
        },[dispatch,orderId,orderList])

    return (

        <div>
            <MainHeader/>
                <Container className="mainContainer">
                    <Row>
                        <Col xs={2} className="leftContainer">
                            <SellerMenu />
                        </Col>

                        <Col xs={10} className="rightContainer" >

                            <div className="productsTabContainer">
                                <Modal
                                    show={isOpenNewModal}
                                    onHide={handleClose}
                                    size='md'
                                    className="SuccessRequestModal"
                                >
                                    <Modal.Body> 
                                        <div style={{display:'flex', alignItems:'center', color:'#161F92', justifyContent:'center'}}>
                                            <img 
                                                alt=""
                                                src="/assets/buyer/icons/SuccessIcon.png" 
                                                className="img-fluid" 
                                                style={{width:100}} />
                                            
                                        </div>
                                        <h4 align="center" className="mt-4 text-white">
                                            Success! Your request has been placed!
                                            Please wait for confirmation from our
                                            Customer Service within 24-48 hrs.
                                        </h4>
                                    </Modal.Body>
                                </Modal>

                                <Modal
                                    show={modalCancelRequest}
                                    size='md'
                                    className="cancelRequestModal">
                                    <Modal.Body> 

                                        <div style={{display:'flex', alignItems:'center', color:'#161F92', justifyContent:'center'}}>
                                            <h5>Cancel Request</h5>
                                        </div>
                                    
                                        <Container>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label style={{color:'#747474', fontWeight:300, fontSize:14}}>Reason</Form.Label>
                                                <Form.Control 
                                                    name="reason"
                                                    as="textarea" 
                                                    required
                                                    style={{
                                                    resize:"none"
                                                        }} 
                                                    rows={3} 
                                                    onChange={handleChange}

                                                    />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label style={{color:'#747474', fontWeight:300, fontSize:14}}>Email Address</Form.Label>
                                                <Form.Control 
                                                    name="email" 
                                                    type="email"
                                                    required
                                                    onChange={handleChange}
                                                    />
                                            </Form.Group>
                                        </Container>
                                        
                                    </Modal.Body>
                                    <Modal.Footer >
                                        <Container>
                                            <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                <Col md={5}>
                                                    <Button onClick={(e) => handleSuccessCancelRequest(e)} className="form-control confirmCancelRequestBtn">Confirm</Button>
                                                    
                                                </Col>
                                                <Col md={5}>
                                                    <Button onClick={()=>setModalCancelRequest(false)} className="form-control cancelRequestBtn" >Cancel</Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Modal.Footer>
                                    <br/>
                                </Modal>
                                <Container fluid>
                                    <div id="to_pay_container">
                                        <div className="productPreviewContainer" key={orderDetails.shop_id} id={"transaction_"+orderDetails.shop_id}>
                                            {
                                                Object.keys(orderDetails).length >0 && orderDetails?.order_items?.length !== 0 && orderDetails?.order_items.map((item)=> (

                                                    <React.Fragment>
                                                        <Container className="salesShopName">
                                                            <Row>
                                                                <Col xs={6} style={{textAlign: "left"}}>
                                                                        <img 
                                                                            alt=""
                                                                            src="/seller_icons/store_icon_1.png" 
                                                                            style={{
                                                                                height: "17px", 
                                                                                marginRight: "10px"}} 
                                                                                />
                                                                                {orderDetails?.shop?.name || ""}
                                                                </Col>
                                                                <Col xs={6} style={{ textAlign: "right" }}>
                                                                    <label style={{color:'#535353', fontWeight:300}}>ORDER ID# {orderDetails.rc_id}</label>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                        <Container className="salesProductPreview">
                                                            <div className="productListContainer">
                                                                <Row className="salesProduct">
                                                                    <Col xs="2">
                                                                        <img
                                                                            className="productThumb" 
                                                                            src={item.variety?.latest_image?.path || "/sampleProduct/dress1.jpg"} 
                                                                            alt="product_image"
                                                                            style={{width: "100%"}}/>               
                                                                    </Col>
                                                                    <Col sx="6" style={{ textAlign: "left"}}>
                                                                            <Nav.Link>{item.product.product_name}</Nav.Link>
                                                                            <p style={{padding: "0px 16px"}}>Variation: {item.variety?.variety_name.replace(/ /g, ', ')}</p>
                                                                    </Col>
                                                                                            
                                                                    <Col xs="2" style={{ textAlign: "right"}}>
                                                                        <p>Quantity: {item.quantity}</p>
                                                                    </Col>
                                                                    
                                                                    <Col xs="2" style={{ textAlign: "right"}}>
                                                                        {
                                                                            activePriceDisplay && activePriceDisplay === 'Normal Price' ? 
                                                                            <div> 
                                                                                    <b style={{color:"#CF0E0E"}}>
                                                                                            {/* P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(item.variety?.price)} */}
                                                                                            P{item.variety?.price || "0.00"}
                                                                                    </b>
                                                                            </div>   
                                                                            
                                                                            :

                                                                            (
                                                                                item.variety?.discounted && (item.variety?.discounted !== "0" && item.variety?.discounted !== 0) ?
                                                                                    <b style={{ textDecoration: 'line-through', color:'#747474', }}>
                                                                                            {/* P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(item.variety?.price)} */}
                                                                                            P{item.variety?.price || "0.00"}
                                                                                    </b>

                                                                                :
                                                                                    <div className='mt-4'> 
                                                                                            <b style={{color:"#CF0E0E"}}>
                                                                                                    {/* P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(item.variety?.price)} */}
                                                                                                    P{item.variety?.price || "0.00"}
                                                                                            </b>
                                                                                    </div>   
                                                                            )
                                                                                            
                                                                            }

                                                                                {activePriceDisplay && activePriceDisplay === 'Discounted Price' ? 
                                                                                    (
                                                                                        item.variety?.discounted && (item.variety?.discounted !== "0" && item.variety?.discounted !== 0) ?
                                                                                            <div className='mt-4'> 
                                                                                                <b style={{color:"#CF0E0E"}}>
                                                                                                    {/* P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(item.variety?.discounted)} */}
                                                                                                    
                                                                                                    P{item.variety?.discounted || "0.00"}
                                                                                                </b>
                                                                                            </div>
                                                                                        :
                                                                                        
                                                                                        null
                                                                                    )

                                                                                    :

                                                                                    null
                                                                            }             
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        
                                                            <div className="transactionContainer">
                                                            <Row>
                                                                <Col xs={6} style={{textAlign: "left"}}>
                                                                    <h6>Transaction ID</h6>
                                                                </Col>
                                                                <Col xs={6} style={{textAlign: "right"}}>
                                                                    <h6>#{orderDetails?.transaction_id}</h6>
                                                                </Col>
                                                            </Row>
                                                            </div>
                                                        </Container>
                                                    </React.Fragment>
                                                ))   
                                            }
                                        </div>
                                    </div>
                                </Container>

                                <OrderBuyerAddress orderDetails={orderDetails}/>

                                <br/>

                                <Container fluid>
                                        <div style={{
                                            backgroundColor:'#FFFF',
                                            width:'100%',
                                        }}>
                                            <div style={{
                                                background:'#FFFFFF',
                                                width:'100%',
                                            }}>
                                                <Row className="p-3">
                                                    <Col md={8} lg={9} xl={9} xs={12} sm={12}>
                                                        <img alt="" src="/assets/buyer/icons/DeliveryIcon.png" className="img-fluid" style={{width:25}} />
                                                        <label style={{marginLeft:10 ,fontWeight:'bold' }}>Shipping Options</label>
                                                    </Col>
                                                </Row>

                                                <Row className="p-3">
                                                    <Col md={10} lg={11} xs={12} sm={12}>
                                                        <label style={{fontWeight:'bold', fontSize:15, marginBottom:0, position:'relative', bottom:15, marginLeft:38}}>
                                                            {orderDetails?.shipping_method?.name}
                                                        </label>
                                                    </Col>
                                                    <Col md={9} lg={9} xs={12} sm={12}>
                                                        <label style={{fontWeight:300, fontSize:15, marginBottom:0, position:'relative', bottom:15, marginLeft:38,paddingTop:5}}>
                                                            Receive by {orderDetails?.formattedplaceorderdate?.estimated_date_from } - {orderDetails?.formattedplaceorderdate?.estimated_date_to }
                                                        </label>
                                                    </Col>
                                                    <Col>
                                                        <h4 style={{fontWeight:400, fontSize:18, marginBottom:0, position:'relative', bottom:15, textAlign: 'center', marginRight:55}}>
                                                        P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(orderDetails.sf || 0) }
                                                        </h4>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Container>
                                    <br/>

                                    <Container fluid className="">
                                        <div style={{
                                            backgroundColor:'#FFFF',
                                            width:'100%',
                                            padding:5,
                                            backgroundColor:'#FFFF',
                                            borderTopColor:'#FFFF',
                                            borderRightColor:'#FFFF',
                                            borderLeftColor:'#FFFF',
                                            borderBottomColor:'#FFFF',
                                            borderStyle:'solid',
                                            
                                        }}>
                                            <Row className="mt-3">
                                                <Col md={8} lg={9} xl={9} xs={12} sm={12}>
                                                    <div className="d-flex mx-1">
                                                        <img  
                                                            alt=""
                                                            style={{
                                                            width:20,
                                                            height:20,
                                                        }} 
                                                        src="/assets/buyer/icons/PaymentOptionsIcon.png" 
                                                        className="img-fluid m-1" />
                                                        <h4 style={{
                                                            fontWeight:'bold',
                                                            marginLeft:7,
                                                            }}>Payment Details</h4>
                                                    </div>
                                                </Col>
                                            </Row>          
                                            <hr style={{width:"100%"}}></hr>
                                            <Form.Group as={Row} className="px-5">
                                                <Form.Label column md={10}>
                                                </Form.Label>
                                                <Form.Label column md={10}>
                                                    <h6 style={{fontWeight:'300'}}>Merchandise Subtotal</h6>
                                                </Form.Label>
                                                <Col>
                                                    <h6 style={{color:'#535353'}}>
                                                        P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(subTotal || 0)}
                                                    </h6>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="pt-3 px-5" style={{position:'relative',bottom:25}}>
                                                <Form.Label column md={10}>
                                                    <h6 style={{fontWeight:'300'}}>Shipping Subtotal</h6>
                                                </Form.Label>
                                                <Col>
                                                    <h6 style={{color:'#535353'}}>
                                                        P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(orderDetails.sf || 0)}
                                                    </h6>
                                                </Col>
                                            </Form.Group>
                                            
                                            <Form.Group as={Row} className="pt-3 px-5" style={{position:'relative',bottom:50}}>
                                                <Form.Label column md={10}>
                                                    <h6 style={{fontWeight:'300'}}>Total Payment</h6>
                                                </Form.Label>
                                                <Col>
                                                    <h6 style={{color:'red'}}>
                                                        P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(subTotal + orderDetails.sf || 0)}
                                                    </h6>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="pt-3 px-5" style={{position:'relative',bottom:50}}>
                                                <Form.Label column md={9}>
                                                    <h5 style={{color:'#535353'}}>Payment Option</h5>
                                                </Form.Label>
                                                <Col md={3}>
                                                    <h5 style={{color:'#535353', textAlign:'center'}}>{orderDetails?.payment_method?.name}</h5>
                                                </Col>
                                            </Form.Group>
                                        </div>
                                    </Container>

                                    <br/>

                                                    

                                    <br/>

                                    <div style={{display:'flex', alignItems:'center', color:'#C4C4C4', justifyContent:'center'}}>
                                    {  
                                        (orderDetails && orderDetails.status  && orderDetails.status === "unpaid") ||
                                        (orderDetails && orderDetails.status  && orderDetails.status === "to_ship") ||
                                        (orderDetails && orderDetails.status  && orderDetails.status === "for_pickup") ?
                                            <h5 onClick={() => setModalCancelRequest(true)}>Cancel Request</h5>  
                                        :
                                        null
                                    }   
                                        
                                </div>
                                                        
                            </div>                        
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SalesOrderDetails;
