import React from 'react'
import { Container } from 'react-bootstrap'
import SubHeader from '../components/buyer/Header/SubHeader'

function TermOfServices() {
  return (
    <div>
        <SubHeader/>
        <div className="p-5" style={{backgroundColor:'white'}}>
            <Container>
                
                <p className="page1 pt-5 col-md-9" style={{float:'none', margin:'auto', textAlign:'justify'}}>
                    <div className="page-header"><h1>Terms Of Use</h1></div>
                    <br/>
                    <b style={{fontSize:19}}>Idol Shopping Network Terms of Use</b> <br/>
                    <p>
                        Please read these Terms of Use ("Terms of Use") carefully before using 
                        the https://idolshoppingnetwork.com website and the Idol Shopping 
                        Network mobile application ("Service") operated by Idol Network 
                        Philippines Inc, and developed by Sun Moon Tech Solutions Inc.
                        <br/><br/>
                        Please read this carefully together with our Privacy Policy and other terms 
                        referenced in this terms of use. These Terms of Use and those found in our 
                        Privacy Policy set forth the entire understanding and agreement between 
                        you and Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc., 
                        websites, mobile applications, and services ("Service").
                        <br/> <br/>
                        
                        Your access to and use of the Service is conditioned on your acceptance of 
                        and compliance with these Terms. These Terms apply to all visitors, users 
                        and others who access or use the Service.
                        <br/> <br/>
                        
                        When you access the Service, you expressly accept and agree, without 
                        limitation or qualification, to be bound by the Terms of Use set forth below 
                        and any additional terms of use set forth in any related website. Idol 
                        Network Philippines Inc. and Sun Moon Tech Solutions Inc. reserves the 
                        right to modify or update these Terms of Use at any time as it deems fit and 
                        such changes or modifications will become immediately effective five days 
                        upon notice of posting or actual posting whichever comes earlier. Your 
                        continued use of the Service will bind you to such changes and you agree 
                        that it shall be your responsibility to regularly visit this page to review the 
                        latest Terms of Use.
                        <br/><br/>
                        If you do not agree to these Terms of Use, please do not continue to use 
                        the Service. 
                        <br/><br/>
                        The Service and other related websites accessible through the Service are 
                        published and maintained by affiliates and/or related entities of Idol 
                        Network Philippines Inc. and Sun Moon Tech Solutions Inc. When 
                        accessing any related website and/or mobile application through the 
                        Service, please note that the related website and/or mobile application may 
                        have its own terms of use that are specific to that website or mobile 
                        application. 
                    </p><br/>

                    <b style={{fontSize:19}}>PURCHASES</b> <br/>
                    <p>
                        If you wish to purchase any product or service made available through the 
                        Service ("Purchase"), you may be asked to supply certain information 
                        relevant to your Purchase.
                    </p>
                    <br/>
                    <b style={{fontSize:19}}>USER REPRESENTATIONS AND WARRANTIES</b> <br/>
                    <p>
                        By accessing and/or using the Service, you signify your agreement to these 
                        Terms of Use and any amendments hereto and you further agree, affirm, 
                        and warrant that there is and there shall be no agency, partnership, joint 
                        venture, employer-employee, licensor- licensee, or franchisor-franchisee 
                        relationship between Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc., and you. 
                        
                        You must be at least 18 years of age, or that you possess legal parental or 
                        guardian consent if under 18 years of age, and that you are fully able and 
                        competent or possess the necessary parental or guardian consents to 
                        legally bind yourself to and abide by all of the terms, conditions, obligations, 
                        declarations, affirmations, representations, and warranties set forth in these 
                        
                        <br/><br/>
                        
                        Terms of Use. You further confirm that all the information which will provide 
                        us are true, complete, and accurate.

                        <br/><br/>

                        If you are under 18, you must ask your parents or guardian before you: E-
                        mail us, or ask us to e-mail anything to you; Send in any information; and 
                        Use the Service or enter any contest, campaign, or promotion that requires 
                        information about you or offers a prize. Any order, query, transaction or 
                        interaction through any of Idol Shopping Network’s Platforms done by a 
                        minor shall presumptively be done with the permission of his or her parents 
                        and shall only be treated otherwise upon presentation of proof otherwise.

                        <br/><br/>
                        By accessing and/or using the Service, you declare, represent, warrant, 
                        and understand that you will be exposed to content, material or information 
                        from a variety of sources, and that Idol Network Philippines Inc. and Sun 
                        Moon Tech Solutions Inc. is not responsible for the accuracy, usefulness, 
                        safety, completeness, or intellectual property rights of or relating to such 
                        content, material or information, user generated, submitted or otherwise, 
                        including those content, material or information that may be linked to it.

                        <br/><br/>
                        You understand and affirm that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. does not endorse any content or any information, 
                        opinion, recommendation, or advice expressed therein, including content, 
                        material, and information linked to its Service.
                        <br/><br/>
                        You hereby hold Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. free from any responsibility and liability arising out of your 
                        use of or reliance on such content or linked content, material or information.

                        <br/><br/>
                        You further understand and acknowledge that you may be exposed to 
                        content that may infringe on your intellectual property rights or that of your 
                        principal; or content that may be violative of your privacy, and/or publicity 
                        rights; or content that is knowingly false and/or defamatory, inaccurate,

                        <br/><br/>
                        abusive, vulgar, hateful, harassing, obscene, indecent, pornographic, 
                        profane, threatening, racist, gruesome, objectionable, offensive to public 
                        morals, invasive of another's privacy, or otherwise violative of any rule, 
                        regulation, or law; or content that contains any viruses, Trojan horses, 
                        worms, time bombs, bots, any malware, or any computer program, code, or 
                        routine that may or is intended to damage, detrimentally interfere with, 
                        surreptitiously intercept or expropriate any system, data or personal 
                        information and that you undertake, at your own risk, and you hereby agree 
                        to waive, and hereby do waive, any legal or equitable rights or remedies 
                        you have or may have against Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. with respect thereto, and agree to indemnify and hold 
                        Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. harmless 
                        to the fullest extent allowed by law regarding all matters related to or arising 
                        out of your use of the Service or any malicious activity done by other users 
                        of the platform.

                        <br/><br/>
                        By accessing and/or using the Service, you declare, undertake and affirm 
                        that you take sole responsibility for whatever consequences that may arise 
                        out of your access and/or use of the Service.

                        <br/><br/>
                        You understand that Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. shall not be liable for, and you hereby hold Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc. free from any direct, 
                        incidental, special, consequential, indirect, or punitive damages whatsoever 
                        resulting from your use of, or your inability to use, the Service. On the other 
                        hand, you declare, undertake, and affirm that you shall indemnify Idol 
                        Network Philippines Inc. and Sun Moon Tech Solutions Inc., for any direct, 
                        incidental, special, consequential, indirect, or punitive damages whatsoever 
                        resulting from your use of the Service. You further agree, undertake, and 
                        commit to indemnify Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. for any breach of its proprietary and other rights, including

                        <br/><br/>
                        breach of these Terms of Use, which you may commit in the course of or 
                        arising out of your use of the Service.

                        <br/><br/>
                        You further declare, represent, and warrant that you are aware that any 
                        content, material or information presented to you as part of the Service, 
                        including but not limited to advertisements and sponsored content, material 
                        or information within the Service may be protected by intellectual property 
                        rights which are owned by the respective sponsors, advertisers, or other 
                        persons or companies on their behalf. 
                        
                        <br/><br/>
                        You agree that such advertisements and sponsored content, material or 
                        information may be placed on the Service and/or coupled to your User 
                        Content (as defined below) without prior notice and without need of explicit 
                        consent from you anymore as you now give such consent. 
                        
                        <br/><br/>
                        You hereby declare, acknowledge, and affirm personal and sole 
                        commitment and obligation to respect uphold and honor any intellectual 
                        property rights which may cover such any content, material or information 
                        presented to you as part of the Service, including but not limited to 
                        advertisements and sponsored content, material or information within the 
                        Service. 
                        
                        <br/><br/>
                        You acknowledge that any material, information or content which you 
                        create, upload, transmit, or display while using the Service (“User Content”) 
                        may become publicly available, whether or not intended by you or by Idol 
                        Network Philippines Inc. and Sun Moon Tech Solutions Inc. for such 
                        material, information or content to be made publicly available. You further 
                        acknowledge, affirm and take sole responsibility for any consequences 
                        arising out of the use, authorized, lawful or otherwise, of any User Content 
                        using the Service. 
                        
                        
                        <br/><br/>
                        By access and/or using the Service, you hereby declare, acknowledge, and 
                        affirm to hold Idol Network Philippines Inc. and Sun Moon Tech Solutions 
                        Inc., free from any liability by law or by equity arising therefrom, including 
                        the unauthorized or unlawful use thereof by a third party, regardless of 
                        negligence, lack of foresight, or lack of skill on the part of Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc. which may be directly or 
                        indirectly contributory to such unauthorized or unlawful use. 
                        
                        <br/><br/>
                        You also acknowledge that you are free to opt out of the Service by 
                        voluntarily desisting from further use of the Service or by serving a notice of 
                        termination of use to Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. and closing your account. Closure of an account with the 
                        Service does not nullify, void, cancel, or otherwise adversely affect any 
                        and/or all of the legal obligations and liabilities you may have incurred or 
                        may still incur relative to your use of the Service, as such termination of use 
                        of the Service and/or closure of an account with the Service does not 
                        nullify, void, cancel, or otherwise adversely affect any and/or all of the 
                        rights which may have accrued in favor of Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc., including the rights, consents, permissions 
                        and licenses to use any and/or all User Content that you have created, 
                        uploaded, transmitted, or displayed using the Service.

                        <br/><br/>
                    </p>

                    <b style={{fontSize:19}}>ACCEPTABLE USE POLICY YOU AND THE SERVICES</b> <br/>

                    <p>
                        You agree and acknowledge that you will register only one account and 
                        use only one mobile number. You agree not to register multiple accounts 
                        using other mobile numbers or devices. 
                        
                        
                        <br/><br/>
                        You agree to provide true, accurate, current, and complete information as 
                        required for the Service and you will maintain and update your information 
                        promptly to keep it accurate, current, and complete at all times. 
                        
                        <br/><br/>
                        You further agree that Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. may rely on your information as accurate, true, current and 
                        complete. You further acknowledge that if any of your information were 
                        found to be untrue, inaccurate, incomplete, or not updated in any respect, 
                        Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc., may in its 
                        discretion, deny you access and terminate your account, and your use of 
                        the Service at any time with or without notice for your breach of this Terms 
                        of Use. 
                        
                        <br/><br/>
                        You agree not to incorporate any word in your name, message 
                        identification, or custom user title that is defamatory, obscene or profane, or 
                        which violates any trademark, service mark, or other intellectual property 
                        rights of any third party, including that of Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc. 
                        
                        <br/><br/>
                        You likewise agree not to use any trademark, trade name, service mark, or 
                        logo in a way that is likely or intended to cause confusion about the owner 
                        or authorized user of such marks, names or logo. 
                        
                        <br/><br/>
                        You agree not to alter, modify, or cause the alteration or modification of the 
                        Service without prior written consent from Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc. 
                        
                        <br/><br/>
                        You further agree to use the Service solely for personal use and not to use 
                        the Service for any commercial use without prior written authority from Idol 
                        Network Philippines Inc. and Sun Moon Tech Solutions Inc. 
                        
                        
                        <br/><br/>
                        You agree not to use any "deep-link," "page-scrape," "robot," "spider," or 
                        other automatic device, program, algorithm or methodology, or any similar 
                        or equivalent manual process, to access, acquire, copy or monitor any part 
                        of the Service, or in any way reproduce or circumvent its navigational 
                        structure or presentation, as well as to obtain or attempt to obtain any 
                        material, document or information through any means not purposely made 
                        available through the Service. 
                        
                        <br/><br/>
                        You agree not to gain or attempt to gain unauthorized access to any part or 
                        feature of the Service or to any other system or network connected to the 
                        Service. You also agree not to gather, harvest, or otherwise collect 
                        information about others using the Service without their explicit informed 
                        consent; nor restrict, prevent or prohibit any other party from using the 
                        Service, including but not limited to such actions which may tend to 
                        discourage others from using the Service, such as stalking, flaming or the 
                        lashing out at other parties, spamming or the sending of unsolicited 
                        information, advertisement or content, flooding or the sending of repetitive 
                        message, trolling or the use of insulting or deliberately divisive information, 
                        material or content, other forms of annoyances, and the like. 
                        
                        <br/><br/>
                        You agree not to circumvent, disable, or otherwise interfere with security-
                        related features of the Service, including those that prevent or restrict use 
                        or copying of any content, material, or information available on or through 
                        the Service, as well as those that enforce limitations on the use of the 
                        Service. 
                        
                        <br/><br/>
                        You agree not to probe, scan, or test the vulnerability of the Service or any 
                        network connected to it, and not to breach the security or authentication 
                        measures on the same.  
                        
                        <br/><br/>
                        You agree not to reverse look-up, trace or seek to trace any information on 
                        any user of or visitor to the Service, or any other customer of Idol Network 
                        
                        <br/><br/>
                        Philippines Inc. and Sun Moon Tech Solutions Inc., including any account 
                        maintained with the Service not owned by you, to its source, or exploit the 
                        Service or any information made available or offered by or through the 
                        Service, in any way where the purpose is to reveal any information, 
                        including but not limited to personal identification, other than your own 
                        information. 
                        
                        <br/><br/>
                        You agree to use or access the Service for your information and personal 
                        use solely as intended through the provided functionality of the Service. 
                        
                        <br/><br/>
                        You agree not to copy or download any material or content from or through 
                        the Service unless such copying or downloading is explicitly allowed by a 
                        visible manifestation thereof such as a "download" button or a similar link 
                        ostensibly displayed. 
                        
                        <br/><br/>
                        You further agree not to engage or attempt to engage in the use, copying, 
                        transmission, broadcast, display, distribution, or sale of any of the content, 
                        material or information available on or through the Service, including user 
                        comments and the like, other than as expressly permitted herein, or as 
                        explicitly indicated in the Service, including use thereof for commercial 
                        purposes. 
                        
                        <br/><br/>
                        You agree that you will not take any action that imposes an unreasonable 
                        or disproportionately large load on the infrastructure of the Service or its 
                        systems or networks, or any systems or networks connected to the Service. 
                        
                        <br/><br/>
                        You agree not to use any device or routine to interfere or attempt to 
                        interfere with the proper working of the Service or any transaction being 
                        conducted using the Service, or with any other person's use of the Service. 
                        
                        
                        <br/><br/>
                        You agree that you will not engage in any activity that interferes with or 
                        disrupts the Service or the servers and networks which are connected to 
                        the Service. 
                        
                        <br/><br/>
                        You shall not employ any means to defraud Idol Network Philippines Inc. 
                        and Sun Moon Tech Solutions Inc. or enrich yourself, through any means, 
                        whether fraudulent or otherwise, through any event, contest, promotion, or 
                        campaign launched by Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc.. 
                        
                        <br/><br/>
                        You agree not to use the Service for any purpose that is illegal, unlawful or 
                        prohibited by these Terms of Use, or to solicit the performance of any 
                        illegal activity or other activity which infringes on the rights of Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc. or others. 
                        
                        <br/><br/>
                        You further agree not to modify, rent, lease, loan, sell, distribute, or create 
                        derivative works based on any content, material or information, either in 
                        whole or in part, available on or through the Service, unless you have been 
                        specifically permitted to do so by Idol Network Philippines Inc. or Sun Moon 
                        Tech Solutions Inc., or by the owner of that content, material or information 
                        in a separate agreement.
                    </p>


                    <b style={{fontSize:19}}>YOU AND USER CONTENT</b> <br/>
                    <p>
                        You agree to and hereby undertake the sole responsibility and hold Idol 
                        Network Philippines Inc. and Sun Moon Tech Solutions Inc. free of liability 
                        to you or to any third party for any User Content that you create, upload, 
                        transmit, or display while using the Service. 
                        <br/><br/>
                        You agree and hereby undertake sole responsibility for the consequences 
                        of your actions and interactions using the Service and hereby stipulate 
                        admission of liability to Idol Network Philippines Inc. and Sun Moon Tech 
                        
                        <br/><br/>
                        Solutions Inc. for whatever loss or damage Idol Network Philippines Inc. 
                        and Sun Moon Tech Solutions Inc. may suffer as a consequence of your 
                        use or misuse of the Service. 
                        <br/><br/>
                        You agree not to share, create, upload, transmit, or display using the 
                        Service any material, information or User Content which is or may be 
                        covered by copyright, patent, trade secret, trademark, trade name, service 
                        mark or any property rights, including privacy and/or publicity rights, unless 
                        you have the necessary licenses, rights, consents, and permissions to use 
                        and to authorize Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. to use any and/or all User Content that you create, upload, 
                        transmit, or display using the Service. 
                        <br/><br/>
                        You agree not to use fictitious name and concealing true name for the 
                        purpose of concealing crime, evading the execution of a judgment or 
                        causing damage. 
                        <br/><br/>
                        You agree not to share, create, upload, transmit or display using the 
                        Service any material, information or User Content which: infringes on the 
                        intellectual property, privacy, and/or publicity rights of any third party; is 
                        knowingly false and/or defamatory, inaccurate, abusive, vulgar, hateful, 
                        harassing, obscene, indecent, pornographic, profane, threatening, racist, 
                        gruesome, offensive to public morals, invasive of another's privacy, or 
                        otherwise violative of any rule, regulation, or law; contains any viruses, 
                        Trojan horses, worms, time bombs, bots, any malware, or any computer 
                        program, code, or routine that may or is intended to damage, detrimentally 
                        interfere with, surreptitiously intercept or expropriate any system, data or 
                        personal information; and/or that which promotes or abets piracy. 
                        <br/><br/>
                        You further agree not to engage in pyramid, ponzi or similar schemes or 
                        other damaging schemes in the future; not to start or forward chain letters; 
                        and not to conduct or aid in the conduct of surveys, contests, petitions, and 
                        
                        <br/><br/>
                        the like, using the Service, unless the Service has been specifically 
                        designed and explicitly made available for such purpose. 
                        <br/><br/>
                        You represent, warrant, and affirm that you own or that you have the 
                        necessary licenses, rights, consents, and permissions to use and to 
                        authorize Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. 
                        to use, reproduce, adapt, modify, translate, publish, publicly perform, 
                        publicly display, distribute, or transmit over public networks and in various 
                        media any and/or all User Content that you create, upload, transmit, or 
                        display using the Service, as you hereby authorize and grant Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc., irrevocable, worldwide, 
                        royalty-free and non-exclusive right, license, consent and permission to 
                        use, reproduce, adapt, modify, translate, publish, publicly perform, publicly 
                        display, distribute, or transmit over public networks and in various media 
                        any and/or all User Content which you may create, upload, transmit, or 
                        display using the Service, in a manner Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc. may deem fit and necessary, including but 
                        not limited to making such User Content available to a third party in 
                        compliance with a requirement of law, a rule or regulation, or making such 
                        User Content available to any third party for its use, reproduction, 
                        adaptation, modification, translation, publication, public performance, public 
                        display, distribution or transmission. 
                        <br/><br/>
                        You likewise hereby irrevocably agree, affirm and warrant to hold Idol 
                        Network Philippines Inc. and Sun Moon Tech Solutions Inc. free from any 
                        liability, both under equity and the law, arising or that may arise out of its 
                        use of your User Content or the use of your User Content by a third party to 
                        which Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. 
                        made available your User Content. 
                        <br/><br/>
                        Unless otherwise agreed in writing with Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc., you hereby agree to and acknowledge sole 
                        
                        <br/><br/>
                        responsibility for protecting and enforcing any proprietary rights you or your 
                        principal may have on any User Content made available through the 
                        Service. 
                        <br/><br/>
                        You further acknowledge, agree, and admit that Idol Network Philippines 
                        Inc. and Sun Moon Tech Solutions Inc. is not responsible for the actions of 
                        other users or any third party and hereby free Idol Network Philippines Inc. 
                        and Sun Moon Tech Solutions Inc. and waive in its favor any or whatever 
                        right or claim you or your principal may have against Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc. relative to any User 
                        Content which you or a third party may create, upload, transmit or display 
                        using the Service. 
                        <br/><br/>
                        You agree to store your password on the device and our servers in an 
                        encrypted form. This form of encryption disguises your password on the 
                        server, but still allows us to authenticate you when you sign into the 
                        Service.
                    </p>

                    <b style={{fontSize:19}}>LINKS, THIRD PARTY CONTENT & SERVICES</b> <br/>
                    <p>
                        The Service may provide links to third-party websites or mobile application 
                        for your convenience and information. If you access those links, you will 
                        leave the Service and Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. has no control over those sites or their privacy practices, 
                        which may differ from Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. 
                        
                        <br/><br/>
                        Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. does not 
                        endorse or make any representations about third-party websites and are 
                        not responsible or liable for the availability of such websites and for any 
                        content, advertising, products, or other materials on or available from such 
                        websites. 
                        
                        
                        <br/><br/>
                        You further acknowledge and agree that Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc. shall not be responsible or liable, directly or 
                        indirectly, for any damage or loss caused or alleged to be caused by or in 
                        connection with use of or reliance on any such content, products or 
                        services available on or through any such third-party website or mobile 
                        application. Idol Network Philippines Inc. and Sun Moon Tech Solutions 
                        Inc. Privacy Policies do not cover the personal data you provide to 
                        unrelated third parties. 
                        
                        <br/><br/>
                        You should review the privacy policy of any company before submitting 
                        your personal information. 
                        
                        <br/><br/>
                        You may not remove, alter or obscure any copyright, trademark, service 
                        mark or other proprietary rights notices incorporated in or accompanying 
                        the application and the services or in any Third Party Service. 
                        
                        <br/><br/>
                        Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. makes no 
                        representation or warranties whatsoever about any service, content and / 
                        or any other resources provided by the Third Party.
                    </p>

                    <b style={{fontSize:19}}>CAMPAIGNS, CONTEST AND OTHER PROMOTIONS</b> <br/>
                    <p>
                        The Service may contain activities, contests, campaigns or other 
                        promotions ("Promotions") that offer prizes, points or require you to send in 
                        material or information about yourself. 
                        <br/><br/>
                        Each Promotion has its own terms and conditions, which you must read 
                        and agree to before you may enter. Please also see the Privacy Policy of 
                        Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. for further 
                        information. 

                    </p>

                    <b style={{fontSize:19}}>RESERVED RIGHTS <br/>
                    Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc.</b> <br/>
                    <p>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole and absolute discretion, to 
                        suspend your access, deny your access or disable, delete and terminate 
                        your account when we determine that you have violated this Terms of Use, 
                        with or without prior notice to you. 
                        
                        <br/><br/>
                        You hereby irrevocably agree, affirm and warrant to hold Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc. free from any liability, 
                        both under equity and the law, arising or that may arise out of any such 
                        denial of access to or the discontinuance of the Service. 
                        
                        <br/><br/>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole discretion but without 
                        obligation, to deny access to and/or discontinue the Service or any 
                        component thereof to anyone at any time, temporarily or permanently, 
                        without prior notice. 
                        
                        <br/><br/>
                        You hereby irrevocably agree, affirm and warrant to hold Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc. free from any liability, 
                        both under equity and the law, arising or that may arise out of any such 
                        denial of access to or the discontinuance of the Service. 
                        
                        <br/><br/>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole discretion but without 
                        obligation, to collect, screen, review, flag, filter, modify, block, refuse or 
                        remove any and/or all information provided by any user, explicitly or 
                        implicitly to and through the Service, including but not limited to hardware 
                        
                        <br/><br/>
                        information, IP address, browser-type related information, cookies and the 
                        like. 
                        
                        <br/><br/>
                        You hereby irrevocably agree, affirm and warrant to hold Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc. free from any liability, 
                        both under equity and the law, arising or that may arise out of any such 
                        collection, screening, review, flagging, filtering, modification, blocking, 
                        refusal or removal of any and/or all information provided by any user to and 
                        through the Service. 
                        
                        <br/><br/>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole discretion but without 
                        obligation, to enhance, improve, develop and introduce new features and 
                        functionalities to the Service at anytime and without prior notice. 
                        
                        <br/><br/>
                        You hereby understand, agree, and affirm that any such enhancement, 
                        improvement, development, new feature and/or new functionality to the 
                        Service shall form part of the Service as defined herein and thus shall 
                        likewise be covered by these Terms of Use and its subsequent revisions or 
                        amendments, as applicable. 
                        
                        <br/><br/>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole discretion but without 
                        obligation, to send you or cause to send you service updates and/or 
                        messages, including SMS, email and/or any data message transmission, 
                        informing you of enhancements, improvements, developments, features, 
                        functionalities, products, promotions, offers, advertisement and/or any other 
                        information relative to the Service, Idol Network Philippines Inc. and Sun 
                        Moon Tech Solutions Inc. 
                        
                        <br/><br/>
                        Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. makes no 
                        warranty of any kind, express or implied, for such service updates and/or 
                        
                        <br/><br/>
                        messages but you hereby agree to receive such service updates and/or 
                        messages and hold Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. free from any liability and/or claims for indemnification or 
                        damages that may arise therefrom. 
                        
                        <br/><br/>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole discretion but without 
                        obligation, to set limitations to and charge fees and applicable taxes for the 
                        use of the Service, at any time and without prior notice. 
                        
                        <br/><br/>
                        You further understand and agree that Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc. reserves the right, at its sole discretion and 
                        under no obligation, to change the applicable fees, taxes and charges 
                        levied for the use of the Service, at any time and without prior notice. 
                        
                        <br/><br/>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole discretion but without 
                        obligation, to change, modify or amend these Terms of Use without prior 
                        notice. 
                        
                        <br/><br/>
                        You hereby understand, agree, and affirm that it shall be your sole 
                        responsibility to be aware of any such change, modification or amendment, 
                        which shall take effect immediately upon publication the same way as this 
                        original Terms of Use has been published, or upon its availability through 
                        the Service and which shall bind you as soon as you use or access the 
                        Service regardless of whether or not you are already aware of such 
                        change, modification or amendment. 
                        
                        <br/><br/>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole discretion but without 
                        obligation, to enforce the provisions of these Terms of Use. Non-
                        enforcement of any of the rights of Idol Network Philippines Inc. and Sun 
                        
                        <br/><br/>
                        Moon Tech Solutions Inc. under these Terms of Use, under the law or 
                        under principles of equity shall not be construed as a waiver thereof. 
                        Likewise, no subsequent course of action by Idol Network Philippines Inc., 
                        Sun Moon Tech Solutions Inc., by you, and/or by any third party, 
                        individually or collectively, shall not operate and shall not be construed to 
                        operate as abandonment, amendment, or modification of these Terms of 
                        Use. 
                        
                        <br/><br/>
                        You likewise hereby declare, affirm and undertake the sole obligation to 
                        indemnify Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. 
                        or any third party for any damage Idol Network Philippines Inc. and Sun 
                        Moon Tech Solutions Inc. or said third party may sustain as a result of your 
                        use of the Service. 
                        
                        <br/><br/>
                        You understand and agree that Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. reserves the right, at its sole discretion but without 
                        obligation, to limit the provision, availability, quantity and quality of any 
                        feature, product or service to any person or to anyone within the same 
                        geographic area, demographic profile, or any other market, commercial, 
                        and/or trading segments. 
                        
                        <br/><br/>
                        You likewise understand and agree that Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc. reserves the right, at its sole discretion but 
                        without obligation, to administer and operate any and/or all of the Service 
                        from any or various locations outside the Republic of the Philippines. 
                        
                        <br/><br/>
                        You further understand, agree and hold Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc. free from any liability arising therefrom, that 
                        not all features, products, or services discussed, referenced, provided or 
                        offered through or in the Service are available to all persons or in all 
                        geographic locations, or are appropriate or available for use outside the 
                        
                        <br/><br/>
                        Republic of the Philippines. Any part or the whole of the Service or these 
                        Terms of Use are void where prohibited. 
                        
                        <br/><br/>
                        You hereby understand, agree, and undertake sole responsibility for your 
                        continued access to or use of the Service, as well as the results or 
                        consequences of such access and use, including the responsibility for 
                        compliance with applicable local laws and the sole liability for non-
                        compliance or breach thereof. 

                    </p>

                    <b style={{fontSize:19}}>PROPRIETARY RIGHTS</b> <br/>
                    <p>
                        You understand, acknowledge, and agree that Idol Network Philippines Inc. 
                        and Sun Moon Tech Solutions Inc. owns and retains all proprietary rights in 
                        the Service. 
                        <br/><br/>
                        You understand, acknowledge and agree that all content, material and 
                        information, including but not limited to text, graphics, user interfaces, 
                        visual interfaces, photographs, trademarks, logos, sounds, music, artwork, 
                        computer code, design, structure, selection, coordination, expression, look 
                        and feel and arrangement of such content, material or information 
                        contained in or available through and from the Service is owned, controlled 
                        or licensed by or to Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. and is protected by trade dress, copyright, patent and 
                        trademark laws, and various other intellectual property rights and unfair 
                        competition laws. 
                        
                        <br/><br/>
                        You further acknowledge that the Service may contain information which is 
                        designated confidential by Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc. and that you shall not disclose such information without 
                        prior written consent from Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. 
                        
                        
                        <br/><br/>
                        You understand, acknowledge, and agree that the Service are provided to 
                        you AS IS for your personal use only. Except as expressly provided in 
                        these Terms of Use, no part of the Service and no content, material or 
                        information therefrom may be copied, reproduced, adapted, modified, 
                        translated, encoded, republished, uploaded, posted, published, publicly 
                        performed, publicly displayed, distributed, or transmitted over public 
                        networks and in various media, to any other computer, server, website or 
                        other medium for publication or distribution or for any commercial 
                        enterprise, without Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc. express prior written consent. 
                        
                        <br/><br/>
                        You understand, acknowledge, and agree that unless you have agreed 
                        otherwise in writing with Idol Network Philippines Inc. and Sun Moon Tech 
                        Solutions Inc., nothing in these Terms of Use gives you a right to use any 
                        of the trade names, trademarks, service marks, logos, domain names, and 
                        other distinctive brand features of Idol Network Philippines Inc. and Sun 
                        Moon Tech Solutions Inc. 
                        
                        <br/><br/>
                        You may use information on Idol Network Philippines Inc. and Sun Moon 
                        Tech Solutions Inc., products and services (such as data sheets, 
                        knowledge base articles, and similar materials) purposely made available 
                        by Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. for 
                        downloading from or through the Service, provided that you (1) do not 
                        remove any proprietary notice language in all copies of such documents, 
                        (2) use such information only for your personal, non- commercial 
                        informational purpose and do not copy or post such information on any 
                        networked computer or broadcast it in any media, (3) make no 
                        modifications to any such information, and (4) do not make any additional 
                        representations or warranties relating to such documents. 
                        
                        <br/><br/>
                        You understand, acknowledge, and agree that additional terms and 
                        conditions may apply to purchases of goods or services and to specific 
                        
                        <br/><br/>
                        portions or features of the Service, including contests, promotions or other 
                        similar features, all of which terms are made part of these Terms of Use by 
                        this reference. 
                        
                        <br/><br/>
                        You agree to abide by such other terms and conditions, including where 
                        applicable representing that you are of sufficient legal age to use or 
                        participate in such service or feature. In case of conflict between these 
                        Terms of Use and the terms posted for or applicable to a specific portion of 
                        the Service, the latter terms shall control with respect to your use of that 
                        portion of the Service. The obligations of Idol Network Philippines Inc. and 
                        Sun Moon Tech Solutions Inc. if any, with regard to its products and 
                        services are governed solely by the agreements pursuant to which they are 
                        provided and nothing in these Terms of Use should be construed to alter 
                        such agreements.

                    </p>

                    <b style={{fontSize:19}}>DISCLAIMER</b> <br/>
                    <p>
                        Your use and browsing of the Service is at your risk. The Service (including 
                        all content and functions made available on or accessed through the 
                        Service) is provided "AS IS." To the fullest extent permissible by law, with 
                        regard to the content in the services, Idol Network Philippines Inc. and Sun 
                        Moon Tech Solutions Inc. makes no representations or warranties of any 
                        kind whatsoever  
                        <br/><br/>
                        (1) For the accuracy, merchantability, fitness for a particular purpose or 
                        non-infringement of any content published on or available through the 
                        Service.
                        <br/><br/> 
                        (2) That the server that makes the services available is free of viruses or 
                        other components that may infect, harm or cause damage to your 
                        computer equipment or any other property when you access, browse, 
                        download from, or otherwise use of the Service. 
                        
                        <br/><br/>
                        (3) Idol Network Philippines Inc. and Sun Moon Tech Solutions Inc. also 
                        assumes no responsibility, and will not be liable for any damage to or 
                        viruses that may infect your computer equipment or other property, or 
                        for any loss or corruption of data on account of your access to, use of, 
                        or browsing the services, or your downloading of any materials, data, 
                        text, images, video, or audio from the Service.
                        <br/><br/> 
                        (4) In no event Idol Network Philippines Inc. and Sun Moon Tech Solutions 
                        Inc. total liability to you for all damages, losses and causes of action 
                        (whether in contract, tort or otherwise) exceed the amount paid by 
                        circumstances, including but not limited to Idol Network Philippines Inc. 
                        and Sun Moon Tech Solutions Inc. negligence, will Idol Network 
                        Philippines Inc. and Sun Moon Tech Solutions Inc., or any other party 
                        involved in creating, hosting or delivering the services, be liable for any 
                        direct, indirect, incidental, consequential, special, punitive, or other 
                        damages whatsoever arising out of your access to, use of, the Service, 
                        whether or not such access or use was authorized or whether it was due 
                        to any act or omission on your part.

                    </p>

                    <b style={{fontSize:19}}>CHANGES</b> <br/>
                    <p>
                        We reserve the right, at our sole discretion, to modify or replace these 
                        Terms of Use at any time. If a revision is material we will try to provide at 
                        least 30 days notice prior to any new terms taking effect. What constitutes 
                        a material change will be determined at our sole discretion. 

                    </p>

                    <b style={{fontSize:19}}>CONTACT US</b> <br/>
                    <p>
                        If you have any questions about these Terms of Use, please contact us.
                    </p>

                    <b style={{fontSize:19, fontWeight:400}}>Last updated Oct 28, 2021</b> <br/>
                </p>

            </Container>
        </div>
    </div>
  )
}

export default TermOfServices