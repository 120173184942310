import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/Api';
import { ToastContainer, toast } from "react-toastify";


const initialState = {
    cartItem: localStorage.getItem("cartItems")
        ? JSON.parse(localStorage.getItem("cartItems"))
        : [],
    CartTotalQuantity: 0,
    CartTotalAmt: 0,
    checkOutItem: localStorage.getItem("checkOutItems")
        ? JSON.parse(localStorage.getItem("checkOutItems"))
        : [],
    isPlaceOrder: [],
    cartItems: [],
}

export const placeOrder = createAsyncThunk(
    'buyer/placeOrder',
    async (values, { rejectWithValue, dispatch }) => {
        const { checkOut, defaultAddressId, paymentOption } = values;

        let setPlaceOrder = {
            shop_id: [],
            shipping_method_id: [],
            payment_method_id: 4,
            sf: [],
            pt: [],
            address_id: null,
            total: [],
            status: [],
            items: []
        }

        let count = 0
        let total = 0
        let price = 0
        let itemList = [];

        checkOut.map(({ shop }) => {
            setPlaceOrder.shop_id.push(shop.shop_id)

            shop.product.map((
                { product_id, variety_id, discounted_price, quantity, original_price }
            ) => {
                if (discounted_price) {
                    price = total = total + discounted_price * quantity
                } else {
                    price = total = total + original_price * quantity
                }
                itemList.push({ product_id, quantity, variety_id, price, discounted_price, original_price });
                count = count += 1
            })
            setPlaceOrder.shipping_method_id.push(1)
            setPlaceOrder.sf.push(40)
            setPlaceOrder.pt.push(paymentOption.typeDescription)
            setPlaceOrder.total.push(total)
            if (paymentOption.typePayment === 1 || paymentOption.typePayment === 2 || paymentOption.typePayment === 4) {
                setPlaceOrder.status.push('to_ship')
            }
            setPlaceOrder.items.push(itemList)
        })
        setPlaceOrder.address_id = defaultAddressId;

        try {
            const response = await api.post('/buyer/order', setPlaceOrder)
            dispatch(placeOrderItem(response))
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }

    }
)

export const placeOrderV2 = createAsyncThunk(
    'buyer/place_order',
    async (values, { rejectWithValue, dispatch }) => {
        try {

            const data = await api.post('buyer/order', values);
            
            return data.data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const addCart = createAsyncThunk(
    'buyer/addcart',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            const data = await api.post('buyer/add/cart', values);

            return data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const removeItem = createAsyncThunk(
    'buyer/addcart',
    async (value, { rejectWithValue, dispatch }) => {
        try {

            var { id } = value
            const data = await api.delete('buyer/delete/orderItem/' + id);

            return data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const getCartList = createAsyncThunk(
    'buyer/getcart',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            const data = await api.get('buyer/add/cart');
            
            return data.data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const quantityRequest = createAsyncThunk(
    'buyer/quantity',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            const data = await api.post('buyer/'+ values.req +'/quantity/'+ values.id);
            
            return data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const checkRequest = createAsyncThunk(
    'buyer/quantity',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            const data = await api.post('buyer/status', values)
            
            return data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const shippingFee = createAsyncThunk(
    'buyer/quantity',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            const data = await api.post('buyer/shippingFee', values)
            
            return data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const fetchCart = createAsyncThunk(
    'buyer/addcart',
    async (values, { rejectWithValue, dispatch }) => {
        try {

            const data = await api.post('buyer/cart/checkout', values);
            
            return data.data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const fetchPaymentMethods = createAsyncThunk(
    'buyer/payment/methods',
    async (values, { rejectWithValue, dispatch }) => {
        try {

            const data = await api.get('payment/methods');
            
            return data.data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const fetchShippingFee = createAsyncThunk(
    'buyer/ship/fee',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            console.log(values)
            const data = await api.post('buyer/shippingFee', values);
            
            return data.data
            
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const updateCart = createAsyncThunk(
    'buyer/updatecart',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            dispatch(updateToCart(values))
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const checkOutListItem = createAsyncThunk(
    'buyer/checkout',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            dispatch(addCheckOut(values))


        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteCart = createAsyncThunk(
    'buyer/checkout',
    async (values, { rejectWithValue, dispatch }) => {
        try {
            dispatch(deleteACart(values))
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const getDefaultAddress = createAsyncThunk(
    'fetch/events',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const data = await api.get('/buyer/default_address');
            
            return data
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const getAddress = createAsyncThunk(
    'fetch/events',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const data = await api.get('/buyer/addresses');
            dispatch(getAddresses(data.data.data))
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)


export const getCheckoutPaymentStatus = createAsyncThunk(
    'cart/getCheckoutPaymentStatuses',
    async (transaction_id, { rejectWithValue, dispatch }) => {
        try {
            const data = await api.get(`/order/transaction/status/${transaction_id}`);
            dispatch(getCheckoutPaymentStatuses(data.data))
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const CartSlice = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        addToCart: (state, action) => {
            const payload = action.payload
            const itemIndex = state.cartItem.findIndex(
                (item) => item.variety_id === payload.variety_id
            );
            const shopIndex = state.cartItem.findIndex(
                (item) => item.shop_id === payload.shop_id
            );
            const tempProductItem = () => {
                const tempProductItem = { ...payload, Quantity: Number(payload.Quantity) }
                state.cartItem.push(tempProductItem);
                toast.success("Item has been added to your shopping cart", {
                    position: "bottom-left",
                });
            }
            if (shopIndex >= 0) {
                if (itemIndex >= 0) {
                    state.cartItem[itemIndex].CartTotalQuantity += Number(payload.Quantity)
                    toast.success("Item has been increased product quantity to your shopping cart", {
                        position: "bottom-left",
                    });
                } else {
                    tempProductItem()
                }
            } else {
                tempProductItem()
            }

            localStorage.setItem("cartItems", JSON.stringify(state.cartItem))
        },
        addCheckOut: (state, action) => {
            state.checkOutItem = []
            state.checkOutItem = action?.payload || []
        },
        getCart: (state, action) => {
            state.cartItems = action?.payload?.data?.data || []
        },
        clearCart(state, action) {
            state.cartItem = [];
            localStorage.setItem("cartItems", JSON.stringify(state.cartItem));
        },
        getAddresses(state, action) {
            state.addressesList = action.payload
        },
        getCheckoutPaymentStatuses(state, action) {
            state.checkoutPaymentData = action.payload
        },
        updateToCart(state, action) {
            const payload = action.payload
            const varietyIndex = state.cartItem.findIndex(
                (item) => item.variety_id === payload.variety_id
            );
            const productIndex = state.cartItem.findIndex(
                (item) => item.product_id === payload.product_id
            );
            if (varietyIndex >= 0 && productIndex >= 0) {
                if (payload.status === 1) {
                    state.cartItem[varietyIndex].Quantity -= 1
                    toast.success("Item has been decreased product quantity to your shopping cart", {
                        position: "bottom-left",
                    });
                } else {
                    state.cartItem[varietyIndex].Quantity += 1
                    toast.success("Item has been increased product quantity to your shopping cart", {
                        position: "bottom-left",
                    });
                }
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItem));
        },
        placeOrderItem(state, action) {
            if (action.payload.status === 200) {
                toast.success(`You will be redirect to a payment method in 5 seconds`, {
                    autoClose: 3000,
                    position: "bottom-left",
                });
                setTimeout(() => {
                    window.open(action.payload.data.redirectUrl, '_blank');
                }, 3000)
                // state.isPlaceOrder.push({ status: action.payload.status, id: action.payload.data.id })
                // state.checkOutItem = []
                // localStorage.setItem("checkOutItems", JSON.stringify(state.checkOutItem));
                // const nextCartItems = state.cartItem.filter(
                //     (item) => item.variety_id !== action.payload.variety_id
                // );
                // state.cartItem = nextCartItems;
                // localStorage.setItem("cartItems", JSON.stringify(state.cartItem));
            }
        },
        deleteACart(state, action) {
            const nextCartItems = state.cartItem.filter(
                (item) => item.variety_id !== action.payload.variety_id
            );
            state.cartItem = nextCartItems;
            toast.error("Product removed from cart", {
                position: "bottom-left",
            });
            localStorage.setItem("cartItems", JSON.stringify(state.cartItem));
        },
        deleteItemPlacedOrder(state, action) {
            const itemsList = action.payload
            const cartList = eval(localStorage.getItem('cartItems'))
            cartList.map((res_cart) => {
                const newList = itemsList.filter((item) => item.variety_id !== res_cart.variety_id)
                state.cartItem = newList;
                localStorage.setItem("cartItems", JSON.stringify(state.cartItem));
            })
        }


    }
})

export const { addToCart , getCart, clearCart, addCheckOut, updateToCart, getAddresses, getCheckoutPaymentStatuses, placeOrderItem, deleteACart, deleteItemPlacedOrder } = CartSlice.actions;
export const ViewCart = (state) => state.cart.addToCart;

export default CartSlice.reducer;
