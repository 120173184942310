import React from 'react'
import {Card, Container} from 'react-bootstrap'

function SubSectionTitle(props) {

    return (
        <div className="mt-4">
           
            <Container fluid>
                <Card style={{
                    border: '1px solid #FFFFFF',
                    borderRadius: 0
                }}>
                    <Card.Body style={{
                        // borderLeft:'13px solid #161F92', 
                        // borderTop:'3px solid #FFD700',
                        backgroundColor:'#FFFFFF',
                        color:'#161F92',
                        fontWeight:'700',
                        fontSize:30,
                        borderRadius: '0px !important'
                    }}>
                        
                        {props.SubSectionTitle}

                    </Card.Body>
                </Card>
            </Container>
        </div>
    )
}

export default SubSectionTitle
