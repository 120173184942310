import React , { useEffect, useState } from 'react'
import {Modal, Button} from 'react-bootstrap'
import {isMobile, isAndroid, isIOS} from 'react-device-detect';

function MobileAccess(props) {
    const AppDownload = () => {
        if (isMobile) {
            if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.isnmobapp"
            } else if (isIOS) {
                window.location.href = "https://apps.apple.com/us/app/isn-idol-shopping-network/id1593227983"
            }
        }
    }

    useEffect(() => {
        if (isMobile) {
            if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.isnmobapp"
            } else if (isIOS) {
                window.location.href = "https://apps.apple.com/us/app/isn-idol-shopping-network/id1593227983"
            }
        }
    }, []);

    return (
        <div>
            <Modal
            {...props}
                centered
                size='md'
                backdrop="static"
            >
                <Modal.Body>
                    <center>
                        Available in Play Store and App Store
                        <Button variant="primary" onClick={() => AppDownload()}>
                            Download Application ISN
                        </Button>
                    </center>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MobileAccess
