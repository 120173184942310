import { configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import userReducer from '../features/UserSlice';
import categoriesReducer from '../features/CategoriesSlice';
import productReducer from '../features/ProductSlice';
import adsReducer from '../features/AdsSlice';
import eventsReducer from '../features/EventsSlice';
import cartReducer from '../features/CartSlice';
import startSellingReduce from '../features/StartSellingSlice';
import searchMainReducer from '../features/SearchSlice'
import salesSliceReducer from '../features/SalesSlice';
import earningsSliceReducer from '../features/EarningsSlice';
import purchaseReducer from '../features/PurchaseSlice'
import addressReducer from '../features/AddressSlice';
import ratingsReviewsReducer from '../features/RatingsReviewsSlice';
import logisticReviewsReducer from '../features/logisticSlice'
import priceReducer from '../features/PriceSlice';
import bankReducer from '../features/BankSlice';
import shopReducer from '../features/ShopSlice';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const userDataPersistConfig = {
  key: 'login',
  storage: storage,
};

export default configureStore({
    reducer: {
        user: persistReducer(userDataPersistConfig, userReducer),
        categories: categoriesReducer,
        product: productReducer,
        ads: adsReducer,
        events: eventsReducer,
        cart: cartReducer,
        start_selling: startSellingReduce,
        search:searchMainReducer,
        sales:salesSliceReducer,
        earnings:earningsSliceReducer,
        purchase:purchaseReducer,
        address:addressReducer,
        rates_reviews:ratingsReviewsReducer,
        logistic:logisticReviewsReducer,
        price:priceReducer,
        bank:bankReducer,
        shop:shopReducer,
    },
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    })
  }
);