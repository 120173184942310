import React,{ useState, useEffect } from 'react'
import { Card, Col, Container, Row, Modal} from 'react-bootstrap'
import { RatingView } from 'react-simple-star-rating'
import { useHistory } from "react-router-dom";
import { Button } from 'bootstrap';
import ShareSocialMediaOptions from '../ProductView/ShareSocialMediaOptions';

function ProductStoreRatings(props) {
    const shopDetail = props.ProductViewDetails
    const history = useHistory();
    
    const [open_modal, setOpenModalShare] = useState(false);
    const DisplayShopDetails = () => {
        if(shopDetail.shop_to_seller){
            const shopToSeller = shopDetail.shop_to_seller
            if(shopToSeller.shop){
                const shop = shopToSeller.shop
                if(shop.name){
                    return {
                        name: shop.name,
                        reviews_count: shop.reviews_count,
                        reviews_sum_rating: shop.reviews_sum_rating
                    }
                }
            }
            
        }
        return null
    }
    const handleShare = () => {
        setOpenModalShare(true)
    }

    const handleShareClose = () => {
        setOpenModalShare(false)
    }
    
    const redirectToShop = () => {
        history.push('/shop/' + shopDetail?.shop_to_seller?.shop?.id)
    }

    return (
        <div className="mt-5">
            <Card className="p-3">
                <Container fluid>
                    <Row>
                        <Col sm={6} xs={12} md={11}>
                            <img src="/assets/buyer/icons/ShopIcon.png" style={{width:30}} className="img-fluid"/>
                            <label style={{marginLeft:10}} className="pointer" onClick={() => redirectToShop()}>{DisplayShopDetails()?.name}</label>
                            <label style={{marginLeft:10}}>|</label>
                            <label style={{marginLeft:10, position:'relative', top:3}}><RatingView ratingValue={DisplayShopDetails()?.reviews_sum_rating / DisplayShopDetails()?.reviews_count} size={17}/></label>
                        </Col>
                        <Col sm={6} xs={12} md={1}>
                            <img src="/assets/buyer/icons/ShareIcon.png" onClick={() => handleShare()} style={{width:30, position:'relative', top:3}} className="img-fluid"/>
                        </Col>
                    </Row>
                </Container>
            </Card>
            <ShareSocialMediaOptions
                show={open_modal}
                onHide={() => setOpenModalShare(false)}
                handleShareClose={handleShareClose}
            />
        </div>
    )
}

export default ProductStoreRatings
