import React,{useState, useMemo, useRef, createRef , useEffect} from 'react'
import update from 'react-addons-update';
import { Card, Row, Col, Container, Table, Form, ButtonGroup, Button } from 'react-bootstrap'
import CheckOutItem from './CheckOutItem';
import CheckOutOrder from './CheckOutOrder';
import CheckOutAddress from './CheckOutAddress';

import ShippingOption from './ShippingOption';
import PaymentOption from './PaymentOption';

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { placeOrderV2,  fetchCart } from '../../../features/CartSlice';

function CheckOutList() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [address, setAddress] = useState();
    const [paymentMethodId, setPaymentMethodId] = useState(1);
    const [shippingTotal, setShippingTotal] = useState([])
    const [total, setTotal] = useState([])
    const [shipStatus, setShipStatus] = useState(true);
    const { activePriceDisplay } = useSelector((state) => state.price);

    useEffect(() => {
            getData()
    } ,[])

    useEffect(() => {
        computeTotal(data)
    } ,[data, paymentMethodId])

    const getData = () => {
        var values = {
            checkout: 1,
        }

        var promise = dispatch(fetchCart(values)).then((resp) => {
            setData(resp.payload.data)
            computeTotal(resp.payload.data)
        })

        return promise
    }

    const handleUpdateObject = (id, object, type) => {

        var newData = [...data];

        var data_index = data.findIndex(function(f) {
            return f.id == id;
        })

        if (data_index != -1) {

            var fetch = null;
            switch (type) {
                case 'sf':
                    fetch = object.price
                    break;
                case 'status':
                    fetch = object.status
                    break;
                case 'disabled':
                    fetch = object.status
                break;
                default:
                    fetch = object.quantity
            }

            var new_object = update(
                    newData[data_index], { [type]: {$set: fetch } }
                )

            var returnData = update(
                newData, {
                        [data_index] : {$set: new_object}
                    }
                )

            setData(returnData)
        }
    }

    const computeTotal = (orders) => {
        var total = 0;
        var sf = 0;

        orders?.map((item) => {
            sf += item.sf

            item?.order_items?.map((i) => {
                if (activePriceDisplay && activePriceDisplay === "Discounted Price" && i?.variety?.discounted !== 0) {
                    total += i?.variety?.discounted * i.quantity;
                } else {
                    total += i?.variety?.price * i.quantity;
                }
            })
        })

        setShippingTotal(sf)
        setTotal(total)
    }

    const checkOut = () => {
        var values = {
            address_id : address,
            payment_method_id : paymentMethodId
        }

        dispatch(placeOrderV2(values)).then((resp) => {
                if (resp.payload.success) {
                    paymentResponseHandler(resp)
                }
            }
        )
    }

    const paymentResponseHandler = (data) => {
        console.log('data', data)
        console.log('payment method id',paymentMethodId)
        switch (paymentMethodId) {
            case 2:
            case 5:
                window.location.href = data.payload.data.data.cashierUrl
            break;
            case 3:
            case 4:
                window.location.href = data.payload.data.pay_url
            break;
            // case 5:
            // break;
            default:
                history.push('my_purchase');
        }
    }

    return (
        <div className="mt-2">
            <div>
                <CheckOutAddress setAddress={setAddress}/>
            </div>
            <div>
                {
                    data?.map((items, i) => {
                        return(
                            <Container key={items.id} hidden={items?.order_items?.length === 0} className="mt-4" fluid>
                                <Card>
                                    <CheckOutOrder order={items}></CheckOutOrder>
                                    {
                                        items?.order_items?.map((item, index) => {
                                            return (
                                                    <CheckOutItem activePriceDisplay={activePriceDisplay} key={item.id} order_index={i} order={items} item={item} index={index} setSelectedItem={setSelectedItem}></CheckOutItem>
                                                )
                                            }
                                        )
                                    }
                                </Card>
                                {
                                    address &&
                                        <ShippingOption setShipStatus={setShipStatus} handleUpdateObject={handleUpdateObject} id={items.id} address_id={address} order={items} paymentMethodId={paymentMethodId}></ShippingOption>
                                }

                            </Container>
                        )
                    })
                }

                {
                    address &&
                    <Container className="mt-4" fluid>
                        <PaymentOption setPaymentMethodId={setPaymentMethodId} paymentMethodId={paymentMethodId}></PaymentOption>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col sm={9}>
                                        <h4 className="p-3"><b>Merchandise Subtotal: </b></h4>
                                    </Col>
                                    <Col sm={3} className="d-flex justify-content-center">
                                        <h4 style={{ color: '#CF0E0E' }} className="p-3">P {total}</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={9}>
                                        <h4 className="p-3"><b>Shipping Subtotal: </b></h4>
                                    </Col>
                                    <Col sm={3} className="d-flex justify-content-center">
                                        <h4 style={{ color: '#CF0E0E' }} className="p-3">P { shippingTotal }</h4>
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col sm={9}>
                                        <h4 className="p-3"><b>Total Payment: </b></h4>
                                    </Col>
                                    <Col sm={3} className="d-flex justify-content-center">
                                        <h4 style={{ color: '#CF0E0E' }} className="p-3">P { Math.round((shippingTotal + total) * 100) / 100}</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={6}>
                                            </Col>
                                            <Col sm={3}>
                                            </Col>
                                            <Col sm={3} className="d-flex justify-content-center align-items-center">
                                                <Col sm={8}>
                                                    <Button onClick={() => checkOut()} disabled={shipStatus} className="form-control rounded" style={{position:'relative'}}>Place Order</Button>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>
                }

            </div>
        </div>
    )
}

export default CheckOutList
