import React, { useEffect, useState } from 'react'
import MainHeader from '../../components/buyer/Header/MainHeader'
import SubSectionTitle from '../../components/buyer/Section/SubSectionTitle'
import {useParams} from 'react-router-dom'
import EventSlider from '../../components/buyer/Event/EventSlider'
import CategorySlider from '../../components/buyer/Category/CategorySlider'
import SectionTitle from '../../components/buyer/Section/SectionTitle'
import EventProducts from '../../components/buyer/Event/EventProducts'
import { activeEvent, EventsListSlice } from '../../features/EventsSlice';
import { useSelector, useDispatch } from 'react-redux'

function BuyerEventPage(props) {
    const { type } = useParams()
    const [selectedSubCategory, setSelectedSubCategory] = useState('')

    let event = useSelector(activeEvent);


    return (
        <div>
            <MainHeader/>
                <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30,
            }}>
                <SubSectionTitle SubSectionTitle={type}/>
                <CategorySlider onChange={setSelectedSubCategory} data={event.sub_categories}/>
                <SectionTitle sectionTitle="All Products"/>

                {event && event?.sub_categories && event?.sub_categories.length > 0 &&
                    <EventProducts
                    productDetails={event?.sub_categories[0]?.products || []}
                />
                }

            </div>

        </div>
    )
}

export default BuyerEventPage
