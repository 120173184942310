
import React from 'react'
import { Container, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import api from '../services/Api';
import { getNotifications } from '../features/UserSlice';

function Notification() {
    const dispatch = useDispatch();

    const notifications = useSelector((state) => state.user.notifications);

    const renderType = (type) => {

        switch (type) {
            case 'App\\Notifications\\CancelOrderSeller':
                return 'Order requested to be cancelled by Seller';
                break;
            case 'App\\Notifications\\CancelOrderBuyer':
                return 'Order requested to be cancelled by Buyer';
                break;
            case 'App\\Notifications\\CancelledOrderSeller':
                return 'Order cancelled by Seller was approved';
                break;
            case 'App\\Notifications\\CancelledOrderBuyer':
                return 'Order cancelled by Buyer was approved';
                break;
            case 'App\\Notifications\\AddOrder':
                return 'Order Added';
                break;
            case 'App\\Notifications\\DeliveredOrder':
                return 'Order Successfully Delivered';
                break;
            case 'App\\Notifications\\ShopApproval':
                return 'Shop has been Approved';
                break;
            case 'App\\Notifications\\ProductApproval':
                return 'Product has been Approved';
                break;
            default:
                return type;
        }
    }

    const renderNotfied = (readAt) => {
        if (readAt == null) {
            return (
                <label>
                    <img src="/assets/buyer/icons/StatusCircleIcon.png" style={{ width: 12, marginLeft: 15, marginBottom: 3 }} className="img-fluid" />
                </label>
            )
        }
    }

    async function readNotification(id, readAt) {
        if (readAt == null) {
            var data = await api.put('/readNotif', { id: id }).then((data) => {
                dispatch(getNotifications())
            });
        }
    }

    return (
        <div>
            <Container fluid>
                <Col>
                    <Card style={{ marginBottom: 10, padding: 20, borderStyle: 'none' }}>
                        <Card.Title>Notification</Card.Title>
                        {
                            notifications.map((notif) => {
                                return (
                                    <div key={notif.id}>
                                        <Row style={{ cursor: 'pointer' }} onClick={() => readNotification(notif.id, notif.read_at)}>
                                            <Col md={2} lg={2} xs={12} sm={12} xl={1}>
                                                <img
                                                    src={notif.data?.display_image} style={{ width: 130 }} />
                                            </Col>
                                            <Col md={9}>
                                                <Row>
                                                    <Col md={{ span: 12, offset: 1 }}>
                                                        <p className="mt-2">{renderType(notif.type)}
                                                            {renderNotfied(notif.read_at)}
                                                        </p>
                                                        <div>
                                                            <p style={{ fontSize: 13, fontWeight: 300 }}>{notif.data_message}</p>
                                                            <p style={{ color: '#535353', fontWeight: 300, fontSize: 13 }}>{moment(notif.created_at).format('M/D/YYYY: H:mm')}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </Card>
                </Col>
            </Container>
        </div>
    )
}

export default Notification
