
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';
import { toast } from "react-toastify";

const initialState = {
    BankList : [],
}

export const deleteBank = createAsyncThunk(
    'delete/deleteBank',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.delete(`/seller/bank/delete/${_}`);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const newBankSeller = createAsyncThunk(
    'add/newBankSeller',
    async (_, {rejectWithValue, dispatch}) => {

        try {
            const {data} = await api.post('/seller/bank',_);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const editBankSeller = createAsyncThunk(
    'add/editBankSeller',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.post(`/seller/bank/update/${_.id}`,_);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const getBank = createAsyncThunk(
    'view/viewBank',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.get('/seller/banklist');
            console.log(data)
            return data.data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }

    }
)

export const addressSlice = createSlice({
    name:"address",
    initialState: initialState,
    extraReducers: (builder) => {
        
        builder.addCase(deleteBank.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(deleteBank.fulfilled, (state, action) => {
            state.BankList = []
            toast.success('Successfully Deleted Bank', {
                position: "bottom-left",
            });
            state.isLoading = false
        })
        builder.addCase(deleteBank.rejected, (state, action) => {
            state.isLoading = true
        })


        builder.addCase(editBankSeller.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(editBankSeller.fulfilled, (state, action) => {
            toast.success('Successfully Updated Bank', {
                position: "bottom-left",
            });
            state.isLoading = false
        })
        builder.addCase(editBankSeller.rejected, (state, action) => {
            state.isLoading = true
        })

        builder.addCase(newBankSeller.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(newBankSeller.fulfilled, (state, action) => {
            toast.success('Successfully Inserted Bank', {
                position: "bottom-left",
            });
            state.isLoading = false
        })
        builder.addCase(newBankSeller.rejected, (state, action) => {
            state.isLoading = true
        })

        builder.addCase(getBank.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getBank.fulfilled, (state, action) => {
            state.BankList = action.payload ? action.payload[0] : []
            state.isLoading = false
        })
        builder.addCase(getBank.rejected, (state, action) => {
            state.isLoading = true
        })
    }
})

// export const selectWebAds = state => state.ads.adsList;

export default addressSlice.reducer;