import React, {useState} from 'react'
import CartList from '../../components/buyer/Cart/CartList'
import MainHeader from '../../components/buyer/Header/MainHeader'
import SubSectionTitle from '../../components/buyer/Section/SubSectionTitle'

function BuyerCart() {
    const [data, setData] = useState(0);
    return (
        <div>
            <MainHeader count={data} setData={setData}/>
        
            <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30}}>
 
                <SubSectionTitle SubSectionTitle="Shopping Cart"/>
                
                <CartList count={data} setData={setData}/>
            </div>
        </div>
    )
}

export default BuyerCart
