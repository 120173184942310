import React from 'react'
import {Modal, Button, Row, Col, Container,Form, FloatingLabel} from 'react-bootstrap'


function NewAddressModal(props) {
    return (
        <div>
            <Modal
            {...props}
                size='md'
            >
                <Modal.Body> 
                    <Container fluid>
                        <h5>New Address</h5>
                        <div className="mt-4 formNewAddress">
                            <Row>
                                <Col md={6}>
                                    <Form.Control placeholder="Full Name" type="text">

                                    </Form.Control>
                                </Col>
                                <Col md={6}>
                                    <Form.Control placeholder="Phone Number">

                                    </Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col md={6}>
                                    <Form.Select>
                                        <option>Barangay</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Col>
                                <Col md={6}>
                                    <Form.Select>
                                        <option>City</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col md={6}>
                                    <Form.Select>
                                        <option>Province</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Col>
                                <Col md={6}>
                                    <Form.Select>
                                        <option>Region</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col md={12}>
                                    <Form.Control placeholder="Postal Code"></Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col md={12}>
                                    <Form.Control placeholder="Landmark"></Form.Control>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col md={12}>
                                    
                                    <Form.Control as="textarea" placeholder="Unit number, house number, building street name" />
                                    
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col md={12}>
                                    
                                    <div className="custom-control custom-checkbox">
                                        <Form.Group className="mb-3" id="formGridCheckbox">
                                            <Form.Check class="custom-control-checkbox" type="checkbox" label="Set as Default Address" />
                                        </Form.Group>
                                    </div>

                                    
                                    
                                </Col>
                            </Row>

                        
                            <Row className="p-4">
                                <Col md={12} xs={12}>
                                    
                                    <Button className="mt-2 btnSaveNewAddress" variant=" form-control">Save</Button>
                                    
                                </Col>
                                
                            </Row>
                       
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NewAddressModal
