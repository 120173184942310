import React, { useEffect } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { getProfileDetails } from '../../features/UserSlice';
import { useDispatch, useSelector } from 'react-redux'
import { INTERCOM } from '../../config';
import IntercomIcon from './intercomIcon';
const Intercom = ({ children }) => {
    const profileDetail = useSelector((state) => state.user.detailsProfile)
    const isIntercomHidden = useSelector((state) => state.user.isIntercomHidden)
    const dispatch = useDispatch();

    useEffect(() => {
        if (profileDetail.length == 0) {
            dispatch(getProfileDetails())
        }
    }, [dispatch, profileDetail])

    if (profileDetail.length != 0 && !isIntercomHidden) {
        const { id, name, email } = profileDetail;

        // Uncomment this if intercom is activated (starter pack account)
        const IntercomProps = {
            id,
            email,
            name
        };

        return (
            <IntercomProvider
                api_base={INTERCOM.API_BASE}
                appId={INTERCOM.APP_ID}
                user_hash={INTERCOM.USER_HASH}
                autoBoot
                autoBootProps={IntercomProps}
            >
                {children}
                <IntercomIcon />
            </IntercomProvider >
        )
    }

    return children;

}

export default Intercom;
