import React, { useEffect,useState } from 'react'
import { getMainProductAds } from '../../../features/AdsSlice';
import { useDispatch, useSelector } from 'react-redux';

function MainProductAds({variantCounts}) {

    const dispatch = useDispatch();
    const [adsPosition, setAdsPosition] = useState("25%")
    const [ad, setAd] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        dispatch(getMainProductAds()).then((resp) => {
            setAd(resp.payload.data)
            setIsLoading(false)
        })
        
    }, [dispatch])

    return (
        <>
            {
            !isLoading  && ad &&

                <img
                style={{
                    maxWidth:"inherit"
                }}
                src={ad?.image?.path}
                alt=""
            />
            }
        </>
    )
}

export default MainProductAds;
