import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Modal, Button, Row, Col, Container, Form, Card, Table} from 'react-bootstrap'
import Cookies from 'universal-cookie';

import { 
    getLogisticAvailable,
    availableLogistic,
    logisticPick,
    getShippingOption
} from '../../../features/logisticSlice';

function ShippingOption() {
    const dispatch = useDispatch();
    const [logistic, setLogistic] = useState([]);    
    const [shipping_met, setShippingOptionList] = useState([]);
    const [choose_shipping, setChooseShippingOption] = useState([]);
    const cookies = new Cookies();
    
    let DeliveryMethod = useSelector((state) => state.logistic.ShippingOptionList);

    useEffect(() => {
        dispatch(getShippingOption(cookies.get('shop_id')))
        dispatch(getLogisticAvailable())
    }, [])

    useEffect(() => {
        setShippingOptionList(DeliveryMethod?.data)
    }, [DeliveryMethod?.data])

    
    let LogisticList = useSelector((state) => state.logistic.LogisticList);
    
    const sumitShippingOption = () => {
        let shipping_method = []
        shipping_met && shipping_met.map((res) => {
            if(res.checked){
                shipping_method.push(res.id)
            }
        })
        dispatch(logisticPick({shipping_method, shop_id : cookies.get('shop_id')}))
        dispatch(getLogisticAvailable())
    }

    const handleOnChange = (res_ship_opt) => {
        
        const updatedShippingMethod= shipping_met.map((res) => 
            res_ship_opt.id === res.id ? { name: res.name, id: res.id, checked: !res.checked } : { name: res.name, id: res.id, checked: res.checked }
        
        )
        setShippingOptionList(updatedShippingMethod)
    }

    return (
        <div>
            <Container fluid className="mt-4">
                <div style={{
                    backgroundColor:'#FFFF',
                    width:'100%',
                    padding:20,
                    backgroundColor:'#FFFF',
                    borderRightColor:'#FFFF',
                    borderLeftColor:'#FFFF',
                    borderBottomColor:'#FFFF',
                }}>
                    <Row>
                        <Col md={12} xs={12}>
                            <label style={{marginLeft:10}}><b>Select Shipping Options</b></label>
                        </Col>
                        <Col md={12} xs={12}>
                            <small style={{marginLeft:10}}>Please select the shipping options that you would like to have for your shop.</small>
                        </Col>
                    </Row>
                    <hr/>
                    {  DeliveryMethod.data &&  DeliveryMethod.data.map((res) =>{
                        return(
                            <div className='space-top-bottom'>
                                <Row>
                                    <Col md={12}>
                                        <Col md={7} lg={10} xs={11} sm={11}>
                                            { shipping_met && shipping_met.map((res_checked) => {
                                                if(res.id === res_checked.id){
                                                    if(res.name === 'Ninja Van'){
                                                        return(
                                                            <Form.Check id={`custom-checkbox-${res.id}`}
                                                                name={res.name}
                                                                value={res.name}
                                                                checked={true}
                                                                label={res.name}
                                                                onChange={() => handleOnChange(res)}/>
                                                        )
                                                    }else{
                                                        return(
                                                            <Form.Check id={`custom-checkbox-${res.id}`}
                                                                name={res.name}
                                                                value={res.name}
                                                                checked={res_checked.checked}
                                                                label={res.name}
                                                                onChange={() => handleOnChange(res)}/>
                                                        )
                                                    }
                                                    
                                                }
                                            })}
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}

                    <Row>
                        <Col md={12} style={{textAlign: "right"}}>
                            <Button 
                                className="mx-2 rounded-2 px-5"
                                size="sm"
                                variant="outline-primary"
                            >
                                Cancel
                            </Button>
                            <Button 
                                className="mx-2 rounded-2 px-5"
                                size="sm"
                                variant="primary"
                                onClick={sumitShippingOption}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                
                    {/* <hr/>
                            <Row>
                                <Col md={12} xs={12}>
                                    <label style={{marginLeft:10}}><b>Area of Coverage</b></label>
                                </Col>
                                <Col md={12} xs={12}>
                                    <small style={{marginLeft:10}}>Please select the the area of coverage.</small>
                                </Col>
                            </Row>
                    <hr/>
                            <div>

                                <Row>
                                    <Col md={12}>
                                        <Col md={7} lg={10} xs={11} sm={11}>
                                            <Form.Check label="NCR"/>
                                        </Col>
                                    </Col>
                                </Row>

                            </div>
                            <br/>
                            <div>

                                <Row>
                                    <Col md={12}>
                                        <Col md={7} lg={10} xs={11} sm={11}>
                                            <Form.Check label="North Luzon"/>
                                        </Col>
                                    </Col>
                                </Row>

                                </div>
                                <br/>
                            <div>

                                <Row>
                                    <Col md={12}>
                                        <Col md={7} lg={10} xs={11} sm={11}>
                                            <Form.Check label="South Luzon"/>
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                            <br/>
                            <div>

                                <Row>
                                    <Col md={12}>
                                        <Col md={7} lg={10} xs={11} sm={11}>
                                            <Form.Check label="Visayas"/>
                                        </Col>
                                    </Col>
                                </Row>

                                </div>
                                <br/>
                            <div>

                                <Row>
                                    <Col md={12}>
                                        <Col md={7} lg={10} xs={11} sm={11}>
                                            <Form.Check label="Mindanao"/>
                                        </Col>
                                    </Col>
                                </Row>
                            </div> */}
                
                </div>
            </Container>
        </div>
    )
}

export default ShippingOption
