import React, { useEffect ,useState} from 'react';
import { Row, Col, Form, Button} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { RatingView } from 'react-simple-star-rating'
import {addSellerComment} from '../../../../features/RatingsReviewsSlice'
import moment from "moment"




const  ReviewsSection = ({reviewData}) => {
    const dispatch = useDispatch();
    const [sellerComment, setSellerComment] = useState(false)
    const [onReply, setOnReply] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const { isLoading } = useSelector((state) => state.rates_reviews);


    

    const handleSellerComment = () =>{
        if(sellerComment){
            sellerComment.reviewId = reviewData?.id
            dispatch(addSellerComment(sellerComment)).then((res)=>{
                setShowComment(true)
                setOnReply(false)
            })
        }
    }

    const onChangeComment = (e) =>{
        const {name ,value} = e.target
        setSellerComment({[name]:value})
    }
            
 
   
    return (
        <div>{
                reviewData && Object.keys(reviewData).length > 0 ?
                    <div className="review">
                                        <Row>
                                            <Col className="d-flex align-items-center gap-3 mb-2">
                                                <img alt="" src={reviewData.user?.image?.path || "/profile_pic.png"} className="buyer-img" />
                                                <div>
                                                    <h6>{`${reviewData?.user?.name}`}</h6>
                                                    <RatingView 
                                                        fillColor="#FFD700"
                                                        ratingValue={reviewData.rating} 
                                                        size={15}/>
                                                        
                                                </div>
                                                </Col>
                                                <Col className="d-flex justify-content-end f-gray">
                                                    <h6>{moment.utc(reviewData.created_at).format('MM/DD/YYYY')}</h6>
                                                </Col>
                                        </Row>
                                        <Row className="ms-5 me-1 mb-2">
                                            <Col>
                                                <p>{reviewData.review || null}</p>
                                                    {(reviewData.comments && reviewData.comments.length === 0) && !onReply && !showComment?
                                                        <Button
                                                            className="rounded-2"
                                                            variant="outline-primary"
                                                            size="sm"
                                                            onClick={()=>setOnReply(true)}
                                                            style={{
                                                                float:"right",
                                                                padding:"2px 10px"
                                                            
                                                                }}
                                                        >
                                                            Reply
                                                        </Button>
                                                     :null
                                                    }
                                                    
                                            </Col>
                                        </Row>

                                    {onReply && reviewData.comments && reviewData.comments.length === 0  ? 
                                        <Row className="response-box">
                                            <Col style={{width:"100%"}}>
                                                    
                                                <div 
                                                    style={{
                                                        color:"#161F92",
                                                        fontSize:14,
                                                        marginBottom:10
                                                        }}
                                                >
                                                Seller’s Response:</div>
                                                    <Form.Control
                                                    as="textarea"
                                                    placeholder="Type here.."
                                                    disabled={isLoading}
                                                    name="comment"
                                                    onKeyPress={(e)=>{
                                                        if(e.key === "Enter"){
                                                        handleSellerComment()
                                                        }
                                                    }}
                                                    onChange={(e)=>onChangeComment(e)}
                                                    style={{ 
                                                        height: '100px',
                                                        resize:"none" ,
                                                        width:"100%",
                                                        backgroundColor:'#F4F4F4'
                                                        }}
                                                    />
                                            </Col>
                                        </Row>
                                        :(reviewData.comments && reviewData.comments.length > 0)   ?
                                        <Row className="seller-response">
                                            <Col>
                                                    
                                                <div 
                                                    style={{
                                                        color:"#161F92",
                                                        fontSize:14,
                                                        marginBottom:10
                                                        }}
                                                >
                                                Seller’s Response:</div>
                                                <p>{reviewData.comments[0]?.comment ||""}</p>
                                            </Col>
                                        </Row>
                                        :null
                                    
                                    }
                                    
                                        
                            <hr></hr>
            </div>
            :null}
         </div>                                         
        );
}

export default ReviewsSection;