import React, { useEffect, useState } from "react";
import { onMessageListener } from "../../services/firebase";
import Notifications from "./notifications";
import NotificationComponent from "./NotificationComponent";
import { useDispatch, useSelector} from 'react-redux';
import { getNotifications,setFireBaseToken }  from "../../features/UserSlice";
import { ToastContainer, toast } from "react-toastify";
import { getTokens } from "../../services/firebase";
// import {pushNotifications} from '../../features/DashboardSlice'

function NotificationContainer() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch()
  const details = useSelector((state) => state.user.userDetails);
  const [notification, setNotification] = useState({ title: "", body: "" });
  
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getTokens(setTokenFound);
      if (data) {
        dispatch(setFireBaseToken(data));
        console.log("Token is", data);
      }
      return data;
    }

    tokenFunc();
  }, [setTokenFound]);

  onMessageListener()
    .then((payload) => {
      setShow(true);

      console.log(payload)
      if (payload?.data?.id === details.data.id) {
        toast.success(payload.notification.title, {
          position: "bottom-left",
      });

      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });

        dispatch(getNotifications())
      }
    })
    .catch((err) => console.log("failed: ", err));


  return (
    <div>
      {
        show ? (
        <NotificationComponent data={notification}
        />
      ) : (
        <></>
        )
      }
    </div>
  );
}

export default NotificationContainer;