import React, { Component,useEffect,useState } from 'react';
import { Container, Row, Col, Nav, Button, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SellerMenu from '../seller_menu';
import SalesUnpaid from './sales_unpaid';
import SalesShip from './sales_ship';
import SalesShipping from './sales_shipping';
import SalesCompleted from './sales_completed';
import SalesCancelled from './sales_cancelled';
import SalesReturns from './sales_returns';
import {fetchSales} from '../../../features/SalesSlice'
import MainHeader from '../../../components/buyer/Header/MainHeader';
import Spinner from 'react-bootstrap/Spinner';

function showSales(){
    document.getElementById('sales_container').style.display = 'block';
    document.getElementById('transaction_details').style.display = 'none';
}

function Sales() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)

    const { unpaidOrderList,
            toShipOrderList,
            shippingOrderList,
            completedOrderList,
            cancelledOrderList,
            returnedOrderList} = useSelector((state) => state.sales);


     useEffect(() => {
        setIsLoading(true);
        dispatch(fetchSales()).then((response) => {
            setIsLoading(false)
        })
    },[dispatch])


        return (
            <div>
                <MainHeader />
                <Container className="mainContainer">
                    <Row>
                        <Col xs={2} className="leftContainer">
                            <SellerMenu />
                        </Col>

                        <Col xs={10} className="rightContainer" >

                        {
                            !isLoading ?
                            <div className="productsTabContainer">
                            <Tabs fill variant="tabs" defaultActiveKey="unpaid" className="productsTab" onSelect={showSales}>
                                <Tab eventKey="unpaid" title={<span><img src="/seller_icons/wallet_icon.png"/>Unpaid</span>}>
                                    <SalesUnpaid
                                        unpaidOrderList={unpaidOrderList}
                                    />
                                </Tab>
                                <Tab eventKey="to_ship" title={<span><img src="/seller_icons/box_icon.png"/>To Ship</span>}>
                                    <SalesShip
                                        toShipOrderList={toShipOrderList}
                                     />
                                </Tab>
                                <Tab eventKey="shipping" title={<span><img src="/seller_icons/truck_icon.png"/>Shipping</span>}>
                                    <SalesShipping
                                        shippingOrderList={shippingOrderList}
                                    />
                                </Tab>
                                <Tab eventKey="completed" title={<span><img src="/seller_icons/completed.png"/>Completed</span>}>
                                    <SalesCompleted
                                        completedOrderList={completedOrderList}
                                    />
                                </Tab>
                                <Tab eventKey="cancelled" title={<span><img src="/seller_icons/box_x_icon.png"/>Cancelled</span>}>
                                    <SalesCancelled
                                    cancelledOrderList={cancelledOrderList}
                                     />
                                </Tab>
                                <Tab eventKey="returns" title={<span><img src="/seller_icons/box_arrow_left_icon.png"/>Returns</span>}>
                                    <SalesReturns
                                        returnedOrderList={returnedOrderList}
                                    />
                                </Tab>
                            </Tabs>
                            </div>
                            :
                            <div className='h-100 d-flex align-items-center justify-content-center' style={{paddingTop:'15%'}}>
                                <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
}

export default Sales;
