import React,{useState, useRef, useEffect} from 'react'
import update from 'react-addons-update';
import { Card, Row, Col, Container, Table, Form, ButtonGroup, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import CartRemoveProductModal from './CartRemoveProductModal';
import CartItem from './CartItem';
import CartOrder from './CartOrder';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { checkRequest, quantityRequest, fetchCart, updateCart, deleteCart, removeItem } from '../../../features/CartSlice';
import { render } from '@testing-library/react';
import { toast } from "react-toastify";

function CartList(props) {
    
    const history = useHistory();
    const dispatch = useDispatch();
    const [remove, setRemove] = useState(false);
    const [modalShowDelete, setModalShowDelete] = useState(false);
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const checkRef = useRef(0)
    const { activePriceDisplay } = useSelector((state) => state.price);

    const checkOutHandler = () => {
        let path = '/check_out';
        history.push(path);
    }

    useEffect(() => {
            getData()
    } ,[])

    const getData = () => {
        var values = {
            checkout: 0,
        }

        var promise = dispatch(fetchCart(values)).then((resp) => {
            setData(resp.payload.data)
        })

        return promise
    }

    const handleUpdateObject = (id, object, type) => {
        
        var newData = [...data];

        var data_index = data.findIndex(function(f) { 
            return f.id == id; 
        })
        
        if (data_index != -1) {

            var objectIndex = newData[data_index].order_items.findIndex(function(c) { 
                return c.id == object.id; 
            });
            
            if (objectIndex != -1) {

                var fetch = null;
                switch (type) {
                    case 'quantity':
                        fetch = object.quantity
                        break;
                    case 'status':
                        fetch = object.status
                        break;
                    case 'disabled':
                        fetch = object.status
                    break;
                    default:
                        fetch = object.quantity
                }

                var new_object = update(
                        newData[data_index], { order_items: { [objectIndex]: { [type]: {$set: fetch } } } }
                    ) 

                var returnData = update(
                    newData, {
                            [data_index] : {$set: new_object}
                        }
                    )

                setData(returnData)
            }
        }
    }

    const deleteItem = () => {
        deleteHandler().then((resp) => {
            getData().then(() => {
                setModalShowDelete(false)
                toast.success('Successfully Removed the Item', {
                    position: "bottom-left",
                })
            })
            // window.location.reload(false);
        })
    }

    const quantityHandler = async (value) => {

        var req = {
            req: value.action,
            id : value.order_item_id,
            order_id: value.order_id
        }

        var promise = dispatch(quantityRequest(req)).then((resp) => {
            handleUpdateObject(req.order_id, resp.payload.data.data, 'quantity')

            return resp
        })

       return promise;
    }

    const checkHandler = (value, type, element) => {
        var checked = !element.current.checked

        var status = 'cart_checked'

        if (checked) {
            status = 'cart_unchecked'
        } 
        
        var id  = value.id

        var req = {
            type,
            id,
            status
        }

        var promise = dispatch(checkRequest(req)).then((resp) => {
            if (type === 'shop' || type === 'all') {
                getData()
            } else {
                handleUpdateObject(value.order_id, resp.payload.data.data, 'status')
            }
        })
        
        return promise
    }

    const deleteHandler = () => {

        var value = {
            req: selectedItem.action,
            id : selectedItem.order_item_id
        }
        
        var promise = null

        if (remove) {
            promise = dispatch(removeItem(value))
        } else {
            promise = dispatch(quantityRequest(value))
        }
        
        
        return promise
    }

    const check = () => {
        var value = {
            id: null
        }

        checkHandler(value, 'all', checkRef)
    }

    const isChecked = () => {
        var checker = data?.map((item) => {
            return item.order_items.filter(x => x.status !== 'cart_checked').length > 0 ?  false :  true;
        })

        return checker?.filter(x => x === false).length > 0 ? false : true
    }

    const computeTotal = () => {
        var total = 0;
        var checker = data?.map((item) => {
            return item.order_items.filter(x => x.status === 'cart_checked');
        })
        
        
        checker?.map((item) => {
            item?.map((i) => {
                if (i?.variety?.stocks > 0) {
                    if (activePriceDisplay && activePriceDisplay === "Discounted Price" && i?.variety?.discounted !== 0) {
                        total += i?.variety?.discounted * i.quantity;
                    } else {
                        total += i?.variety?.price * i.quantity;
                    }
                }
            })
        })

        return total;
    }

    return (
        <div className="mt-2">
            <CartRemoveProductModal
                show={modalShowDelete}
                onHide={() => setModalShowDelete()}
                changeDeleteStatus={deleteItem}
            />
            <div>
                {
                    data?.map((items, i) => {
                        return(
                            <Container key={items.id} hidden={items?.order_items?.length === 0} className="mt-4" fluid>
                                <Card>
                                    <CartOrder order={items} checkHandler={checkHandler}></CartOrder>
                                    {
                                        items?.order_items?.map((item, index) => {
                                            return (
                                                    <CartItem setRemove={setRemove} activePriceDisplay={activePriceDisplay} key={item.id} order_index={i} checkHandler={checkHandler} order={items} setModalShowDelete={setModalShowDelete} item={item} index={index} setSelectedItem={setSelectedItem} quantityHandler={quantityHandler} count={props.count} setData={props.setData}></CartItem>
                                                )
                                            }
                                        )
                                    }
                                </Card>
                            </Container>
                        )
                    })
                }

                <Container className="mt-4" fluid>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={2}>
                                    <Form.Check inline
                                                        className=" mx-4"
                                                        style={{fontSize: '24px'}}
                                                        id='All'
                                                        name='all_check'
                                                        ref={checkRef}
                                                        checked={isChecked()}
                                                        onChange={() => check()}
                                                    />
                                                
                                    <label className="p-3"><b>All</b></label>
                                </Col>
                                <Col sm={10}>
                                    <Row>
                                        <Col sm={6}>
                                        </Col>

                                        <Col sm={3}>
                                            <Row>
                                                <Col sm={6} className="d-flex justify-content-end align-items-center">
                                                    <label className="p-3"><b>Subtotal: </b></label>
                                                </Col>
                                                <Col sm={6} className="d-flex justify-content-end align-items-center">
                                                    <label className="p-3"><b>P {computeTotal()}</b></label>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={3} className="d-flex justify-content-center align-items-center">
                                            <Col sm={10}>
                                                <Button onClick={() => checkOutHandler()} className="form-control" style={{position:'relative'}}>Check Out</Button>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </div>            
        </div>
    )
}

export default CartList
