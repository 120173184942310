import React,{useState, useEffect} from 'react'
import {Container, Row, Col, Card, Form, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import SubSectionTitle from '../../../components/seller/Section/SubSectionTitle'
import ShippingOption from '../../../components/seller/Shipping/ShippingOption'
import AddressForm from '../Addresses/AddressFormSeller'
import { 
    getAddressSeller,
    deleteAddressesSeller
} from '../../../features/AddressSlice';


function AddressList() {
    const dispatch = useDispatch();
    const [modalShowFormUpdateAddress, setModalShowFormUpdateAddress] = useState(false);
    const [modalShowFormNewAddress, setModalShowFormNewAddress] = useState(false);
    const [ShowAddress, setShowAddress] = useState(false);
    const [fetchAddress, setFetchAddress] = useState([]);
    let addressesList = useSelector((state) => state.address.FetchAddressesSellerList);

    useEffect(() => {
        dispatch(getAddressSeller())
    }, [dispatch])
    
    const setUpdateAddress = (res_address) => {
        setModalShowFormUpdateAddress(true)
        setModalShowFormNewAddress(false)
        setFetchAddress(res_address)
        // setAddressesDetail(res_address)
        // if(!modalShowFormUpdateAddress){
        //     
        // }
        // dispatch(getAddressDetail(res_address))

    }
    const OpenAddAddress = () => {
        setModalShowFormUpdateAddress(false)
        setModalShowFormNewAddress(true)
    }

    const CloseAddress = () => {
        setModalShowFormUpdateAddress(false)
        setModalShowFormNewAddress(false)
    }
    
    const viewAddress = () => {
        setShowAddress(true)
    }
    
    const addAddress = () => {
        setShowAddress(true)
        setModalShowFormNewAddress(true)
    }
    
    const setDeleteAddress = (id) => {
        dispatch(deleteAddressesSeller(id))
        setTimeout(function() {
            dispatch(getAddressSeller())
        }, 1000);
    }

    return (
        <div>

                { ShowAddress 
                
                    ?
                    <div>
                    <SubSectionTitle SubSectionTitle="Addresses" />
                    { modalShowFormNewAddress || modalShowFormUpdateAddress
                        ? 
                        <div>
                            <AddressForm 
                                modalShowFormNewAddress={modalShowFormNewAddress}
                                CloseAddress={CloseAddress}
                                fetchAddress={fetchAddress}/>
                        </div>
                        :
                        null
                    }
                        <Container fluid className="mt-4">
                                <div style={{
                                    backgroundColor:'#FFFF',
                                    width:'100%',
                                    padding:20,
                                    backgroundColor:'#FFFF',
                                    borderTopColor:'yellow',
                                    borderRightColor:'#FFFF',
                                    borderLeftColor:'#FFFF',
                                    borderBottomColor:'#FFFF',
                                    borderStyle:'solid'
                                }}> 
                                {addressesList && addressesList.map((res) => {
                                    if(fetchAddress.id !== res.id){
                                        return(
                                            <div>
                                                <Row>
                                                    <Col md={7} lg={10} xs={11} sm={11}>
                                                        { res.is_pickup === 1 && res.is_return === 1 ? <label>{res.name} <span style={{ color:'#3E6AE3', fontWeight: '700'}}>(Pickup Address and Return Address)</span></label> : <label>{res.name}</label> }
                                                    </Col>
                                                    <Col >
                                                        <label style={{textAlign:'right', color:'#3E6AE3'}}><span onClick={() => setUpdateAddress(res)}>Edit</span> | <span onClick={() => setDeleteAddress(res.id)}>Delete</span></label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} lg={11} xs={12} sm={12}>
                                                        <label style={{fontWeight:300, fontSize:14}}>
                                                            {res.contact_no}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} lg={11} xs={12} sm={12}>
                                                        <label style={{fontWeight:300, fontSize:14}}>
                                                            Brgy {res.barangay?.description}, City {res.city?.description}, {res.province?.description}, {res.region?.description}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <br/>
                                            </div>
                                            
                                        )
                                    }
                                    
                                })}
                                    
                                </div>
                            </Container>
                            <div className="mt-5">
                                <Container fluid>
                                    <Card style={{
                                        border: '1px solid #FFFFFF',
                                        borderRadius: 0
                                    }}>
                                        <Card.Body style={{
                                            backgroundColor:'#FFFFFF',
                                            color:'#161F92',
                                            fontWeight:'700',
                                            fontSize:30,
                                            borderRadius: '0px !important'
                                        }}>
                                            
                                            <p onClick={() => OpenAddAddress()}>Add New Address<img onClick={() => OpenAddAddress()} className="float-end" src="/assets/buyer/icons/plus3.jpg" style={{width:20, height:20, marginTop: 15, zIndex:2222222}}/></p>

                                        </Card.Body>
                                    </Card>
                                </Container>
                            </div>
                    </div>
                    : 
                    <div>
                    <SubSectionTitle SubSectionTitle="Shipment Settings" />
                    <br/>
                    <Container fluid className="mt-4">
                            <div style={{
                                backgroundColor:'#FFFF',
                                width:'100%',
                                padding:20,
                                backgroundColor:'#FFFF',
                                borderTopColor:'yellow',
                                borderRightColor:'#FFFF',
                                borderLeftColor:'#FFFF',
                                borderBottomColor:'#FFFF',
                                borderStyle:'solid'
                            }}>
                                {addressesList && addressesList.map((res) => {
                                    if(res.is_pickup === 1 && res.is_return === 1){
                                        return(
                                            <div>
                                                <Row>
                                                    <Col md={7} lg={10} xs={11} sm={11}>
                                                        <label>{res.name}</label>
                                                        {/* <Form.Check label="Juan Dela Cruz"/> */}
                                                    </Col>
                                                    <Col >
                                                        <label style={{textAlign:'right', color:'#3E6AE3'}}><span onClick={viewAddress}>view</span></label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} lg={11} xs={12} sm={12}>
                                                        <label style={{fontWeight:300, fontSize:14}}>
                                                            {res.contact_no}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={10} lg={11} xs={12} sm={12}>
                                                        <label style={{fontWeight:300, fontSize:14}}>
                                                            Brgy {res.barangay?.description}, City {res.city?.description}, {res.province?.description}, {res.region?.description}
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <br/>
                                            </div>
                                        )
                                    }
                                    
                                })}
                                <div>
                                    <Row>
                                        <Col md={10} lg={11} xs={12} sm={12}>
                                            <Button onClick={addAddress}>Add New Addresses</Button>
                                            
                                        </Col>
                                        
                                    </Row>
                                </div>
                            </div>
                            
                        </Container>
                        
                        <ShippingOption/> 
                    </div>}
                        
        </div>
    )
}

export default AddressList
