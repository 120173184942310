import React, { useEffect, useState } from 'react'
import AdsSection from '../../components/buyer/Landing/AdsSection'
import EventsSection from '../../components/buyer/Landing/EventsSection'
import EventsSectionSlider from '../../components/buyer/Landing/EventsSectionSlider'
import ForYouItems from '../../components/buyer/Landing/ForYouItems'
import MainHeader from '../../components/buyer/Header/MainHeader'
import ShopCategorySlider from '../../components/buyer/Landing/ShopCategorySlider'
import MobileAccess from '../../components/buyer/Mobile/MobileAccess'
import {isMobile, isAndroid, isIOS} from 'react-device-detect';

// import { hideIntercom } from '../../features/UserSlice'
// import { useDispatch } from 'react-redux'

function BuyerHomePage() {
    /*  Test function to hide intercom, trigger this if the icon of intercom block the content display 
        ex: register form, the intercom icon block the submit button, so in the initial loading or useEffect of
        register form, trigger hideIntercom, but after registration, you can hideIntercom(false) to show again the 
        intercom
        -- Jap
    */
    const [show, setShow] = useState(false);
    const [data, setData] = useState(0);
    // const dispatch = useDispatch();
    const handleHideIntercom = (status) => {

    };
    
    useEffect(() => {
        if (isMobile) {
            if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.isnmobapp"
            } else if (isIOS) {
                window.location.href = "https://apps.apple.com/us/app/isn-idol-shopping-network/id1593227983"
            }
        }
    }, []);

    // const dispatch = useDispatch();
    // const handleHideIntercom = (status) => {
    //     dispatch(hideIntercom(status))
    // };
    return (
        <div>
            {/* Test Button of Intercom Show or Hide */}
            {/* <button onClick={() => handleHideIntercom(true)}>Hide 11</button>
            <button onClick={() => handleHideIntercom(false)}>Show 11</button> */}
            <MainHeader  count={data} setData={setData}/>
            <div style={{
                backgroundColor: '#F4F4F4',
                minHeight: '100vh',
                padding: 30,
            }}>
                <AdsSection />
                <ShopCategorySlider />
                <EventsSection />
                <EventsSectionSlider />
                <ForYouItems />
            </div>
            <MobileAccess show={show}/>
        </div>

    )
}

export default BuyerHomePage
