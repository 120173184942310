import React, { useState, useEffect } from 'react'
import {Modal, Button, Row, Col, Container} from 'react-bootstrap'
import { FacebookShareButton, 
    FacebookMessengerShareButton, 
    PinterestShareButton, 
    TwitterShareButton, 
    TwitterIcon, 
    FacebookIcon, 
    PinterestIcon } from 'react-share'

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

function CheckOutShippingOptions(props) {
    const link = window.location.toString();
    const [value_data, setvalueData] = useState(link);
    const [clipboard, setClipboard] = useState(false);

    useEffect(() => {
        if(clipboard){
            toast.success('Successfully Link Copied', {
                position: "bottom-left",
            })
        }
    }, [clipboard])
    
    return (
        <div>
            <Modal
            {...props}
                centered
                size='md'
            >
                <Modal.Header closeButton>
                <Modal.Title>Select Social Media</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={4} xs={4} md={4}>
                            <center>
                            <CopyToClipboard text={value_data}
                                onCopy={() => setClipboard(true)}>
                                <img style={{width:40}} src={'/assets/buyer/icons/copy-link-icon-27.jpg'} />
                            </CopyToClipboard>
                            
                                
                            
                                {/* <FacebookMessengerShareButton url={link}>
                                
                                </FacebookMessengerShareButton> */}
                            </center>
                        </Col>  
                        <Col sm={4} xs={4} md={4}>
                            <center>
                                <FacebookShareButton url={link}>
                                    <FacebookIcon size={40}/>
                                </FacebookShareButton>
                            </center>
                        </Col>
                        {/* <Col sm={3} xs={3} md={3}>
                            <center>
                                <PinterestShareButton media="/favicon.ico" url={link}>
                                    <PinterestIcon size={40}/>
                                </PinterestShareButton>
                            </center>
                        </Col> */}
                        <Col sm={4} xs={4} md={4}>
                            <center>
                            <TwitterShareButton url={link}>
                                <TwitterIcon size={40}/>
                            </TwitterShareButton>
                            </center>
                        </Col>
                    </Row>  
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={props.handleShareClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CheckOutShippingOptions
