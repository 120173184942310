import React from 'react'
import { RatingView } from 'react-simple-star-rating'
import { Card, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';



const ProductCard = ({
    className,
    isIdolMall,
    productName,
    productImage,
    reviewsRating,
    discounted,
    price
}) => {

    const {activePriceDisplay} = useSelector((state) => state.price);

    const truncate_with_ellipsis = (s) => {
        if (s.length > 60) {
           return s.substring(0, 60) + '...';
        }

        return s;
    };

    return (
        <Card className="sliderEventDataCategory" style={{height:'100%',width:"100%" }}>
                {isIdolMall ?
                    <div
                        style={{
                            border:"4px solid #161F92",
                            position:"relative",
                            height: '18rem'
                        }}

                        className="d-flex justify-content-center align-items-center"
                    >
                        <div
                            style={{
                                position:"absolute",
                                top:10,
                                right:10,
                                zIndex:1,
                            }}
                        >
                            <img
                                alt=''
                                src="/assets/buyer/images/ISNBoxLogo.png"
                                style={{

                                    maxHeight:40,
                                    marginTop:10
                                    }}
                                    />
                        </div>
                        <div>
                            <Card.Img variant="top"
                                className="img-fluid height-full"
                                src={productImage || "/assets/buyer/images/noImage.jpg"}
                            />
                        </div>
                        <div
                            className="idol-mall-product-name"
                            style={{
                                position:"absolute",
                                bottom:3,
                                left:0,
                                width:'50%',
                                height:"20%",
                                zIndex:1,
                                color:"#ffff",
                                fontSize:12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems:"center",
                                textOverflow: 'ellipsis'

                            }}
                        >
                            <p style={{margin:'auto'}} >
                                { truncate_with_ellipsis(productName) }
                            </p>
                        </div>

                    </div>
                    :
                    <div style={{
                            border:"4px solid #161F92",
                            position:"relative",
                            height: '18rem'
                        }} className="d-flex align-items-center justify-content-center">
                        <div>
                            <Card.Img variant="top"
                                    className="img-fluid height-full"
                                    src={productImage || "/assets/buyer/images/noImage.jpg"}
                                />
                        </div>
                    </div>
                }
                <Card.Body className="d-flex flex-column" style={{margin: 15}}>
                        <Card.Title align="left" style={{
                            fontSize:15,
                            height: 30,
                            }}>
                            { truncate_with_ellipsis(productName) }
                        </Card.Title>
                        <Card.Title align="right" style={{height: 20, fontSize:12}}>

                                <div style={{
                                        textDecoration: 'line-through',
                                        height:20,
                                        color:'#747474',
                                        fontWeight:'bold'
                                        }}
                                        >
                                        { activePriceDisplay && activePriceDisplay === 'Discounted Price' &&
                                            discounted && (discounted !== "0" && discounted !== 0) ?
                                            <>

                                                P { new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(price || 0)}
                                            </>
                                            :
                                            null
                                        }

                                </div>

                        </Card.Title>
                        <div style={{height: 20}} className="d-flex justify-content-between">
                                <RatingView ratingValue={reviewsRating || 5} size={16} />
                                {activePriceDisplay && activePriceDisplay === 'Discounted Price' ?
                                discounted && (discounted !== "0" && discounted !== 0)?
                                    <span  style={{
                                        fontSize:12,
                                        color:"#CF0E0E",
                                        fontWeight:'bold'
                                        }}>
                                            {/* P{discounted  || "0.00"} */}
                                            P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(discounted || 0)}
                                    </span>
                                : <span  style={{
                                        fontSize:12,
                                        fontWeight:'bold'
                                        }}>
                                            {/* P{price  || "0.00"} */}
                                            P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(price || 0)}
                                    </span>
                                :
                                    <span  style={{
                                        fontSize:12,
                                        fontWeight:'bold'
                                        }}>
                                            {/* P{price  || "0.00"} */}
                                            P{new Intl.NumberFormat('en-PH',{minimumFractionDigits: 2}).format(price || 0)}
                                    </span>
                                }
                        </div>

                </Card.Body>
            </Card>

    )
}

export default ProductCard;
