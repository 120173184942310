/* eslint-disable symbol-description */
import axios from 'axios';
import { apiURL } from '../config';
import Cookies from 'universal-cookie';

const singleton = Symbol();
const singletonEnforcer = Symbol();

class ApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    const cookies = new Cookies();

    this.session = axios.create({
      baseURL: apiURL,
      headers: {
        Pragma: 'no-cache',
        Authorization: 'Bearer ' +  cookies.get('access_token')
      },
      params: {},
    });
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }

  get = (...params) => this.session.get(...params);

  post = (...params) => this.session.post(...params);

  put = (...params) => this.session.put(...params);

  patch = (...params) => this.session.patch(...params);

  delete = (...params) => this.session.delete(...params);
}

export default ApiService.instance;
