import React,{useEffect, useState} from 'react'
import { Container, Row, Col, Nav, Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from 'moment';
import Cookies from 'universal-cookie';

import { newBankSeller,
    editBankSeller
} from '../../../features/BankSlice';
function BankForm(props) {
    const dispatch = useDispatch();
    const cookie = new Cookies();
    const [bank, setBank] = useState(false);
    const [open_modal, setOpenModalChangeProfilePic] = useState(false);

    const handleChangeProfilePic = () => {
        setOpenModalChangeProfilePic(true)
    }

    const handleChangeProfilePicClose = () => {
        setOpenModalChangeProfilePic(false)
    }
    const validationProfile = {
        bank_name: Yup.string().required("Bank Name is required"),
        account_name: Yup.string().required("Account Name is required"),
        account_number: Yup.string()
        .required("Account Number is Required")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Account Number is not valid"
        ),
        account_type: Yup.string().required("Account Type is required"),
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues:{
            bank_name: props.edit_bank ? props.edit_bank_list.bank_name  : "",
            account_name: props.edit_bank ? props.edit_bank_list.account_name : "",
            account_number: props.edit_bank ? props.edit_bank_list.account_number  : "",
            account_type: props.edit_bank ? props.edit_bank_list.account_type === "Current Account" ? 1 : 0 : "",
        },
        validationSchema: Yup.object(validationProfile),
        onSubmit: (values) => {
            values.shop_id = cookie.get('shop_id')
            if(values.account_type === 0){
                values.account_type = 'Savings Account'
            }else{
                values.account_type = 'Current Account'
            }
            if(props.edit_bank){
                values.id = props.edit_bank_list.id
                dispatch(editBankSeller(values))
                props.setEditOff()
            }else{
                dispatch(newBankSeller(values))
                props.setAddOff()
            }
            setTimeout(function() {
                window.location.href = window.location;
            }, 3000);
        },
    });

    const setAddOff = () => {
        setTimeout(function() {
            window.location.href = window.location;
        }, 1000);
        // setBank(false)
        // setViewBank(false)
        // dispatch(getBank())
        // dispatch(getShop())
    }

    return (
        <div> 
            <Container >
                <Row>
                    <Col >
                        <Card style={{marginBottom:10, padding:30, borderStyle:'none'}}>

                                <form onSubmit={formik.handleSubmit}>
                                <div >
                                    
                                    <Row>
                                        <Col>
                                            <h5><img onClick={setAddOff} src="/assets/buyer/icons/BackArrowIcon.png" style={{width:40, height:40, zIndex:2222222}}/>{props.edit_bank ? 'Edit Bank Account': 'New Bank Account'}</h5>
                                        </Col>
                                        <Col>
                                            <Button className="float-end btn-space" type="submit" variant="primary">Save</Button>
                                        </Col>
                                    </Row>
                                </div>

                                <Card.Body>
                                <Container fluid className="mt-3">
                                
                                    <Row className="formGroup">
                                        <Col xs={4} md={3} className="formTitle">
                                            Bank Name
                                        </Col>
                                        <Col xs={12} md={6} className="formDataReadOnly">
                                            <Form.Control className='customInput form-control' 
                                                id='bank_name' 
                                                name='bank_name' 
                                                type='text' 
                                                placeholder='Bank Name' 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.bank_name} 
                                            />
                                            {formik.touched.bank_name && formik.errors.bank_name ? <p className='validation'>{formik.errors.bank_name}</p> : null}
                                        </Col>
                                    </Row>
                                    <Row className="formGroup">
                                        <Col xs={6} md={3} className="formTitle">
                                            Account Name
                                        </Col>
                                        <Col xs={12} md={6} className="formDataReadOnly">
                                            <Form.Control className='customInput form-control' 
                                                id='account_name' 
                                                name='account_name' 
                                                type='text' 
                                                placeholder='Account Name' 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.account_name} 
                                            />
                                            {formik.touched.account_name && formik.errors.account_name ? <p className='validation'>{formik.errors.account_name}</p> : null}
                                        </Col>
                                    </Row>
                                    <Row className="formGroup">
                                        <Col xs={6} md={3} className="formTitle">
                                            Account Number
                                        </Col>
                                        <Col xs={12} md={6} className="formDataReadOnly">
                                            <Form.Control className='customInput form-control' 
                                                id='account_number' 
                                                name='account_number' 
                                                type='text' 
                                                placeholder='Account Number' 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.account_number} 
                                            />
                                            {formik.touched.account_number && formik.errors.account_number ? <p className='validation'>{formik.errors.account_number}</p> : null}
                                        </Col>
                                    </Row>
                                    <Row className="formGroup">
                                        <Col xs={6} md={3} className="formTitle">
                                            Account Type
                                        </Col>
                                        <Col xs={12} md={6} className="formDataReadOnly">                                            
                                            <Form.Select 
                                                id="account_type" 
                                                name="account_type"
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.account_type} >
                                                <option selected>Select Bank Account</option>
                                                <option value={0}>Savings Account</option>
                                                <option value={1}>Current Account</option>
                                            </Form.Select>
                                            {formik.touched.account_type && formik.errors.account_type ? <p className='validation'>{formik.errors.account_type}</p> : null}
                                        </Col>
                                    </Row>
                                </Container>
                                </Card.Body>
                            </form>
                                
                        </Card>
                        
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BankForm
