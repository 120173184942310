import React, {useEffect, useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container,Row, Col, Card, Pagination } from 'react-bootstrap';
import ProductCard from './ProductCard';
function IdollMallProducts({idolMallList}) {

    const [forYouItemPerPage, setForYouItemPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);

    const SlideData = [
        {
            id:1,
            item_image: '/assets/buyer/images/barleySampleImage.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 4
        },
        {
            id:2,
            item_image: '/assets/buyer/images/MeterSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '400.00',
            item_rating: 3
        },
        {
            id:3,
            item_image: '/assets/buyer/images/OppoSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
        {
            id:4,
            item_image: '/assets/buyer/images/SandoSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 1
        },
        {
            id:5,
            item_image: '/assets/buyer/images/ShineSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '',
            item_discount_price: '500.00',
            item_rating: 5
        },
        {
            id:6,
            item_image: '/assets/buyer/images/VapeSampleImage.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: ''
        },
        {
            id:7,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 3
        },
        {
            id:8,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
        {
            id:9,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '',
            item_discount_price: '500.00',
            item_rating: 1
        },
        {
            id:10,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
        {
            id:10,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
        {
            id:10,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
    ]


    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * forYouItemPerPage;
    const indexOfFirstTodo = indexOfLastTodo - forYouItemPerPage;
    const currentTodos = SlideData.slice(indexOfFirstTodo, indexOfLastTodo);



    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(SlideData.length / forYouItemPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleClickPagination = (e) =>  {
        setCurrentPage(Number(e.target.id))
    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <Pagination.Item
                style={{backgroundColor: currentPage == number ? '#161F92' : 'none', fontSize:24}}
                key={number}
                id={number}
                active={currentPage == number ? true : false}
                onClick={handleClickPagination}
            >
                {number}
            </Pagination.Item>
        );
    });

    return (
        <div className="mt-5">
            <Container fluid>
                   <Row>
                        {
                            idolMallList && idolMallList.map((data, index) => {
                                return <Col md={2} className="my-3" key={data.id}>
                                 <ProductCard
                                        product={data}
                                        />
                                </Col>;

                            })
                        }
                    </Row>
                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <Pagination  style={{marginTop:'10%'}}>
                            {renderPageNumbers}
                        </Pagination>
                    </div> */}
            </Container>
        </div>
    )
}

export default IdollMallProducts;
