import React, {useEffect} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container,Row, Col, Card, Button } from 'react-bootstrap';
import { RatingView } from 'react-simple-star-rating'
import SectionTitle from '../Section/SectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getBuyAgainProduct } from '../../../features/ProductSlice'
import {Link} from 'react-router-dom'
import ProductCard from '../../common/ProductCard'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img onClick={onClick} className={className} src="/assets/buyer/icons/NextArrowIcon.png" style={{width:40, height:40, marginTop:10}}/>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img onClick={onClick} className={className} src="/assets/buyer/icons/BackArrowIcon.png" style={{width:40, height:40, marginTop:10, zIndex:2222222}}/>
  );
}


function BuyAgainItem() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBuyAgainProduct())
    }, [])

    let bugAgainProduct = useSelector((state) => state.product);

    const settings = {
      infinite: true,
      slidesToShow: 1,
      speed: 300,
      slidesPerRow: 5,
      dots:false,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
              slidesPerRow: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
              slidesPerRow: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 2,
              initialSlide: 2,
              slidesPerRow: 2,
            }
          }
      ]
  };
          
    return (
      <div className="mt-1">
        <Slider className="mb-5" {...settings}>
                {
                        bugAgainProduct?.buyAgain?.data && bugAgainProduct?.buyAgain?.data?.map((data) => {
                            return (
                                <div className="space-cat">
                                
                                <Link to={`/product/${data.id}`} style={{textDecoration:'none',color:"#000"}}>
                                    <ProductCard
                                        productName={data.product_name}
                                        productImage={data.latest_variety?.latest_image?.path}
                                        reviewsRating={data.reviews_sum_rating}
                                        discounted={data.latest_variety?.discounted}
                                        price={data.item_original_price}
                                    />
                                    </Link>
                                </div>
                            )
                        })
                    }
                </Slider>
        </div>
    )
}

export default BuyAgainItem
