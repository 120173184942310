import React, {useEffect, useState} from 'react'
import MainHeader from '../../components/buyer/Header/MainHeader'
import SubSectionTitle from '../../components/buyer/Section/SubSectionTitle'
import { useDispatch, useSelector } from 'react-redux';
import SectionTitle from '../../components/buyer/Section/SectionTitle'
import IdolMallProducts from '../../components/buyer/IdolMall/IdolMallProducts'
import {getIdolMallProducts} from '../../features/ProductSlice';
import { Pagination } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function BuyerIdolMall(props) {
    const dispatch = useDispatch();
    const { idolMallList} = useSelector((state) => state.product);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(null);
    const [pageNumbers, setPageNumbers] = useState([]);

    useEffect(() => {
        dispatch(getIdolMallProducts({currentPage})).then((resp)=> {
            setLastPage(resp.payload.last_page)
        })
    },[currentPage])

    useEffect(() => {
        const page_array = [];
        for (let i = 1; i <= lastPage; i++) {
            page_array.push(i);
        }

        setPageNumbers(page_array)
    },[lastPage])

    // useEffect(() => {
    //     console.log(idolMallList)
    // },[idolMallList])

    const handlePrevArrowPagination = () => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
    }

    const handleNextArrowPagination = () => {
        setCurrentPage(lastPage == currentPage ? lastPage : currentPage + 1)
    }

    const handleClickPagination = (e) =>  {
        console.log(e.target.id);
        setCurrentPage(Number(e.target.id))

    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <div key={number}>

                <Pagination.Item
                    style={{backgroundColor: currentPage == number ? '#161F92' : 'none'}}
                    key={number}
                    id={number}
                    active={currentPage == number ? true : false}
                    onClick={handleClickPagination}
                >
                    {number}
                </Pagination.Item>

            </div>
        );
    });

    return (
        <div>
            <MainHeader/>
                <div style={{
                    backgroundColor:'#F4F4F4',
                    minHeight:'100vh',
                    padding:"0 100px"
                }}>
                    <SubSectionTitle SubSectionTitle={props.page_name}/>
                    <div className="mx-2">
                        <SectionTitle sectionTitle="All Products"/>
                    </div>

                    <IdolMallProducts
                        idolMallList={idolMallList}
                        />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Pagination style={{marginTop:'10%'}}>
                            <Pagination.Prev onClick={() => handlePrevArrowPagination(currentPage)}/>
                                {renderPageNumbers}
                            <Pagination.Next onClick={() => handleNextArrowPagination(currentPage)} />
                        </Pagination>
                    </div>
                </div>
        </div>
    )
}

export default BuyerIdolMall
