import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import {priceDisplay} from '../../../features/PriceSlice'

const ActivePriceDisplay = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(priceDisplay())
    },[dispatch])
    
    return null;
}

export default ActivePriceDisplay;
