import React , { useState, useEffect }from 'react'
import { Form, Row, Col, Container } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { fetchPaymentMethods, } from '../../../features/CartSlice';

const grayBackground = {
    backgroundColor: '#FFFF',
    width: '100%',
    backgroundColor: '#FFFF',
}

const borderShip = {
    border: '1px solid rgba(0,0,0,.125)',
    background: 'rgba(49, 103, 233, 0.1)',
    width: '100%',
}

const fontShip = {
    fontWeight: '300',
    fontSize: '1 rem', 
}

const isChecked = (value, id) => {
    if (value === id) {
        return true
    }
}

function PaymentOption(props) {
    const {
        paymentMethodId,
        setPaymentMethodId,
    } = props

    const [paymentMethods, setPaymentMethods] = useState([])

    const dispatch = useDispatch();

    useEffect(() => {
        getData()
    } ,[])

    const getData = () => {
        var promise = dispatch(fetchPaymentMethods()).then((resp) => {
            setPaymentMethods(resp.payload.data)
        })

        return promise
    }

    const getSelectedOptionName = () => {
        return paymentMethods.filter( x => x.id == paymentMethodId).map(x => x.name)
    }

    const isChecked = (id) => {
        if (paymentMethodId === id) {
            return true
        } else {
            return false
        }
    }

    const onChangePaymentMethod = (id) => {
        setPaymentMethodId(id)
    }

    return (
        <div style={grayBackground}>
            <div style={borderShip}>
                <Row className="p-3">
                    <Col className="ms-5" md={8} lg={9} xl={9} xs={12} sm={12}>
                        <img src="/assets/buyer/icons/DeliveryIcon.png" className="img-fluid" style={{ width: 25 }} />
                        <label style={{ marginLeft: 10 }}>Payment Options</label>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col className="ms-5" md={9} lg={9} xs={9} sm={9}>
                        <h6 className="ms-3">
                            { getSelectedOptionName() }
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col className="ms-5 mb-2" md={10} lg={10} xs={12} sm={12}>
                        {
                            paymentMethods?.map((pm) => {
                              return (
                                    <Form.Check
                                        key={'pm_' + pm.id}
                                        className='mt-1 ms-3'
                                        type='radio'
                                        id={'payment_' + pm.id}
                                        label={pm.name}
                                        value={pm.id}
                                        checked={isChecked(pm.id)}
                                        onChange={() => onChangePaymentMethod(pm.id)}
                                    />
                                )
                            })
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default PaymentOption
