import { Button } from 'react-bootstrap'
import {useHistory} from 'react-router-dom';

function BackButton() {

    const history = useHistory();

    const backHandler = () => {
        history.goBack()
    }

    return (
        <>
            <Button onClick={() => backHandler()} className="ms-3" variant="primary">Back</Button>
        </>
    )
}

export default BackButton;
