import React , {useRef, useState} from 'react'
import {Card, Form, Button, Row, Col, ButtonGroup} from 'react-bootstrap'

function CartItem(props) {
    const addRef = useRef(0)
    const minusRef = useRef(0)
    const checkRef = useRef(0)
    const [errorMessage, setErrorMessage] = useState(true);

    const {
        index,
        order,
        item,
        quantityHandler,
        setSelectedItem,
        setModalShowDelete,
        checkHandler,
        activePriceDisplay,
        setRemove
    } = props

    const productTitle = {
        color: 'rgb(22, 31, 146)'
    }

    const visibilityChecker = () => {
        if (index === 0) {
            return false
        } else {
            return true
        }
    }

    const ifChecked = () => {
       return item.status === 'cart_checked' && item?.variety?.stocks > 0
    }

    const decrease = () => {
        if(props.count > 0){
            props.setData(props.count - 1)
        }

        console.log('count: ',props.count)
    }

    const actionQuantity = (action) => {
        addRef.current.disabled = true
        minusRef.current.disabled = true

        var select = {
            'action' : action,
            'order_item_id' : item.id,
            'order_id' : order.id,
            'quantity' : item.quantity
        }

        setSelectedItem(select)

        if (action === 'sub' && item.quantity === 1) {
            setModalShowDelete(true)
            decrease()
            setRemove(false)
            addRef.current.disabled = false
            minusRef.current.disabled = false
        } else {
            var request = quantityHandler(select)

            request.then((resp) => {
                console.log(resp)
                if (!resp.payload.data.success) {
                    setErrorMessage(false)
                } else {
                    setErrorMessage(true)
                }

                addRef.current.disabled = false
                minusRef.current.disabled = false
            })
        }
    }

    const errorMessageChecker = () => {
        return item.variety.stocks != 0 && errorMessage
    }

    const check = () => {
        checkHandler(item, 'item', checkRef)
    }

    const removeItemHandler = () => {
        var select = {
            'order_item_id' : item.id,
        }

        setSelectedItem(select)
        setRemove(true)
        setModalShowDelete(true)
    }

    const priceDisplay = () => {

        return (
            <>
            {
                activePriceDisplay && activePriceDisplay === "Discounted Price" ?
                item.variety.discounted && item.variety.discounted !== 0 && item.variety.discounted !== '0'
                    ? <>
                            <label><del>P{item.variety.price || "0.00"}</del> </label>
                            <label className="ms-3 mt-5">P{item.variety.discounted || "0.00"}</label>
                    </>
                    :

                    item.variety.discounted == 0 ?
                    <>
                        <label>P{item.variety.price}</label>
                    </>
                    :
                    <>
                        <label><del>P{item.variety.price}</del></label>
                    </>
                :
                <>
                        <label>P{item.variety.price} </label>
                </>
            }
            </>
        )
    }

    const optionDisplay = () => {
        return (
            <div>
            {
                item?.variety?.stocks > 0 && item?.variety?.stocks > 0 ?
                <ButtonGroup>
                    <Button ref={addRef} className="quantityBtnVariant minus" onClick={() =>  actionQuantity('sub') } variant="secondary">-</Button>
                    <Button className="quantityBtnVariantCount" variant="secondary">{ item.quantity }</Button>
                    <Button ref={minusRef} className="quantityBtnVariant add" onClick={() => actionQuantity('add')} variant="secondary">+</Button>
                </ButtonGroup>
                :
                    <Button onClick={() =>  removeItemHandler() } variant="secondary">Remove Item</Button>
            }
                <div className="d-flex justify-content-center" style={{color:'red'}}>
                    <label hidden={errorMessageChecker()}>Sorry, we do not have enough item available</label>
                </div>

            </div>
        )
    }

    return (
        <Card.Body className="pt-3">
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={2}>
                            <Row>
                                <Col sm={3} className="">
                                    <Form.Check inline
                                        style={{fontSize: '24px'}}
                                        className="mx-4"
                                        id='shop_id_here'
                                        name='Shop name here'
                                        checked={ifChecked()}
                                        disabled={item?.variety?.stocks == 0}
                                        ref={checkRef}
                                        onChange={() => check()}
                                    />
                                </Col>
                                <Col sm={9} className="d-flex justify-content-center">
                                    <img className="img-fluid mx-3" style={{maxHeight: '150px'}} src={item?.variety?.latest_image?.path}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={10} className="">
                            <Row className="mt-2">
                                <Col sm={6} className="d-flex justify-content-start">
                                    <Card.Title className="" as="h5" style={productTitle}>{item.product.product_name}</Card.Title>
                                </Col>
                                <Col sm={2} className="d-flex justify-content-center">
                                    <Card.Title className="" hidden={visibilityChecker(index)} as="h5" style={productTitle}>Price</Card.Title>
                                </Col>
                                <Col sm={1} className="d-flex justify-content-center">
                                    <Card.Title className="" hidden={visibilityChecker(index)} as="h5" style={productTitle}>Quantity</Card.Title>
                                </Col>
                                <Col sm={3} className="d-flex justify-content-center">
                                    <Card.Title className="" hidden={visibilityChecker(index)} as="h5" style={productTitle}>Item Subtotal</Card.Title>
                                </Col>
                            </Row>
                            <Row style={{ height: '6rem'}}>
                                <Col sm={6} className="d-flex align-items-end justify-content-start">
                                    { optionDisplay() }
                                </Col>
                                <Col sm={2} className="d-flex align-items-end justify-content-center">
                                    { priceDisplay()}
                                </Col>
                                <Col sm={1} className="d-flex align-items-end justify-content-center">
                                    <label>
                                        { item.quantity }
                                    </label>
                                </Col>
                                <Col sm={3} className="d-flex align-items-end justify-content-center">
                                    <label>
                                        { activePriceDisplay && activePriceDisplay === "Discounted Price" ? item.variety.discounted * item.quantity : item.variety.price * item.quantity }
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card.Body>
    )
}

export default CartItem
