import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {cloneDeep, findIndex} from 'lodash'
import api from '../services/Api';

const initialState = {
  productDetails:{
    data:[]
  },
  viewProduct:{
    data:[]
  },
  productList:[],
  isLoading:false,
  draft_product:[],
  for_approval_product:[],
  live_product:[],
  sold_out_product:[],
  delisted_product:[],
  relatedProductList:[],
  eventProductList:[],
  idolMallList:[],
  productUpdateDetails:{},
  buyAgain: []
}



export const getBuyAgainProduct = createAsyncThunk(
  'fetch/buy_again',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/buyer/buyAgain');
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const getOptions = createAsyncThunk(
  'fetch/options',
  async (values, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.post('/option_variety', values);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const getProductList = createAsyncThunk(
  'fetch/products',
  async ( page, {rejectWithValue, dispatch}) => { 
    try {
      const response = await api.get('/products?page=' + page);
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const ProductView = createAsyncThunk(
  'product/product_view',
  async (values, {rejectWithValue, dispatch}) => {
    try {
        const response = await api.get(`/product/view/${values}`)
        return response.data.data;
    } catch(err){
        if(!err.response){
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})


export const getRelatedProducts = createAsyncThunk(
  'product/related-products',
  async (id, {rejectWithValue, dispatch}) => {
    try {
        const response = await api.get(`/category/${id}/products`)
        return response.data.data;
    } catch(err){
        if(!err.response){
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})


//Add Product
export const addProduct = createAsyncThunk(
  'product/product_add',
  async (formData, {rejectWithValue, dispatch}) => {
    try {
        const response = await api.post(`/seller/seller_products/add`,formData)
        return response
    } catch(err){
        if(!err.response){
            throw err
        }
        return rejectWithValue(err.response.data)
    }
  })


  //Product Product
export const updateProduct = createAsyncThunk(
  'product/product_update',
  async ({productId,formData}, {rejectWithValue, dispatch}) => {
    try {
        console.log(formData)
        const response = await api.post(`/seller/seller_products/edit/${productId}`,formData, {
          headers: {
            "Content-type": "multipart/form-data",
          }
        })
        return response
    } catch(err){
        if(!err.response){
            throw err
        }
        return rejectWithValue(err.response.data)
    }
  })



//my product api
export const getProductListDraft = createAsyncThunk(
  'fetch/products/draft',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/seller/my_products?status=Draft');
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const getProductListForApproval = createAsyncThunk(
  'fetch/products/for_approval',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/seller/my_products?status=For Approval');
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const getProductListLive = createAsyncThunk(
  'fetch/products/live',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/seller/my_products?status=Active');
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const getProductListSoldOut = createAsyncThunk(
  'fetch/products/sold_out',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/seller/my_products?status=Sold Out');
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const getProductListDelisted = createAsyncThunk(
  'fetch/products/delisted',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/seller/my_products?status=Delisted');
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const getIdolMallProducts = createAsyncThunk(
  'fetch/products/idol-mall',
  async ({currentPage}, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get(`/improducts?page=` + currentPage + '&limit=36');
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)


export const getEventProducts = createAsyncThunk(
  'fetch/event-products',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get(`/event`);
      return response.data.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

  export const publishProduct = createAsyncThunk(
    'seller/publish-product',
    async ({productId}, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.post(`/seller/seller_products/update/${productId}`,
        {status:"For Approval"}
        );
        return response.data.data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )
  export const delistProduct = createAsyncThunk(
    'seller/delist-product',
    async ({productId}, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.post(`/seller/seller_products/update/${productId}`,
        {status:"Delisted"}
        );
        return response.data.data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )

  export const deleteProduct = createAsyncThunk(
    'seller/delete-product',
    async ({productId}, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.get(`/seller/seller_products/delete/${productId}`);
        return response.data.data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )


  export const getProductbyId = createAsyncThunk(
    'seller/get-product-by-id',
    async ({productId}, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.get(`/seller/seller_products/view/${productId}`);
        return response.data.data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )




export const ProductSlice = createSlice({
  name:"product",
  initialState: initialState,
  extraReducers: (builder) => {
          builder.addCase(getBuyAgainProduct.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getBuyAgainProduct.fulfilled, (state, action) => {
              state.buyAgain = action?.payload || []
              state.isLoading = false
          })
          builder.addCase(getBuyAgainProduct.rejected, (state, action) => {
              state.isLoading = false
          })


          builder.addCase(addProduct.pending, (state, action) => {
              state.isLoading = true
          })
          builder.addCase(addProduct.fulfilled, (state, action) => {
            // state.productList = action?.payload || []
            state.isLoading = false
          })
          builder.addCase(addProduct.rejected, (state, action) => {
              state.isLoading = false
          })  

          builder.addCase(ProductView.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(ProductView.fulfilled, (state, action) => {
              state.viewProduct = action?.payload || []
              state.isLoading = false
          })
          builder.addCase(ProductView.rejected, (state, action) => {
              state.isLoading = false
          })
          
          builder.addCase(getProductListDraft.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getProductListDraft.fulfilled, (state, action) => {
              state.draft_product = action.payload
              state.isLoading = false
          })
          builder.addCase(getProductListDraft.rejected, (state, action) => {
              state.isLoading = false
              
          })

          builder.addCase(getProductListForApproval.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getProductListForApproval.fulfilled, (state, action) => {
              state.for_approval_product = action.payload
              state.isLoading = false
          })
          builder.addCase(getProductListForApproval.rejected, (state, action) => {
              state.isLoading = false
          })

          builder.addCase(getProductListLive.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getProductListLive.fulfilled, (state, action) => {
              state.live_product = action.payload
              state.isLoading = false
          })
          builder.addCase(getProductListLive.rejected, (state, action) => {
              state.isLoading = false
          })


          builder.addCase(getProductListSoldOut.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getProductListSoldOut.fulfilled, (state, action) => {
              state.sold_out_product = action.payload
              state.isLoading = false
          })
          builder.addCase(getProductListSoldOut.rejected, (state, action) => {
              state.isLoading = false
          })

          builder.addCase(getProductListDelisted.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getProductListDelisted.fulfilled, (state, action) => {
              state.delisted_product = action.payload
              state.isLoading = false
          })
          builder.addCase(getProductListDelisted.rejected, (state, action) => {
              state.isLoading = false
          })



          //Get Idol Mall List
          builder.addCase(getIdolMallProducts.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getIdolMallProducts.fulfilled, (state, action) => {
              state.idolMallList = action.payload.data || []
              state.isLoading = false
          })
          builder.addCase(getIdolMallProducts.rejected, (state, action) => {
              state.isLoading = false
          })



          //Get Related Product List
          builder.addCase(getRelatedProducts.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getRelatedProducts.fulfilled, (state, action) => {

              state.relatedProductList = action.payload.data || []
              state.isLoading = false
          })
          builder.addCase(getRelatedProducts.rejected, (state, action) => {
              state.isLoading = false
          })



           //Get Event Product List
           builder.addCase(getEventProducts.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getEventProducts.fulfilled, (state, action) => {
              state.eventProductList = action.payload.data || []
              state.isLoading = false
          })
          builder.addCase(getEventProducts.rejected, (state, action) => {
              state.isLoading = false
          })



           //Publish Product
           builder.addCase(publishProduct.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(publishProduct.fulfilled, (state, action) => {
              const productClone = cloneDeep(state.draft_product)
              const updatedIndex = findIndex(productClone,
                (product)=>product.id === action.payload[0].id)
              state.draft_product = productClone.splice(0,updatedIndex)

              //update for approval state
              const forApprovalClone = cloneDeep(state.for_approval_product)
              state.for_approval_product = [...forApprovalClone,action.payload[0]]
              state.isLoading = false
          })
          builder.addCase(publishProduct.rejected, (state, action) => {
              state.isLoading = false
          })


          //Delist Product
          builder.addCase(delistProduct.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(delistProduct.fulfilled, (state, action) => {
              const productClone = cloneDeep(state.live_product)
              const updatedIndex = findIndex(productClone,
                (product)=>product.id === action.payload.id)
              state.live_product= productClone.splice(0,updatedIndex)

              state.isLoading = false
          })
          builder.addCase(delistProduct.rejected, (state, action) => {
              state.isLoading = false
          })
          //Delete Product
           builder.addCase(deleteProduct.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(deleteProduct.fulfilled, (state, action) => {
              const productClone = cloneDeep(state.draft_product)
              const updatedIndex = findIndex(productClone,
                (product)=>product.id === action.payload.id)
              state.draft_product = productClone.splice(0,updatedIndex)
              state.isLoading = false
          })
          builder.addCase(deleteProduct.rejected, (state, action) => {
              state.isLoading = false
          })



           //Fetch Product by Id
           builder.addCase(getProductbyId.pending, (state, action) => {
            state.isLoading = true
          })
          builder.addCase(getProductbyId.fulfilled, (state, action) => {
              state.productUpdateDetails = action.payload || {}
              state.isLoading = false
          })
          builder.addCase(getProductbyId.rejected, (state, action) => {
              state.isLoading = false
          })
          
          

    
    
    
}
})
export const {productDetails, viewProduct} = ProductSlice.actions;
export const ProductDetail = (state) => state.product.productDetails.data;
export default ProductSlice.reducer;