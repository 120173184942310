import React from 'react'
import CheckOutPaymentStatus from '../../components/buyer/CheckOut/CheckOutPaymentStatus'
import MainHeader from '../../components/buyer/Header/MainHeader'
import SubSectionTitle from '../../components/buyer/Section/SubSectionTitle'
import { Container, Row } from 'react-bootstrap'

function BuyerSuccessfulPayment() {

    const style_container = {
        backgroundColor: '#FFFF',
        width: '100%',
        height: '90vh',
        padding: 5,
        backgroundColor: '#FFFF',
        borderTopColor: '#FFFF',
        borderRightColor: '#FFFF',
        borderLeftColor: '#FFFF',
    }

    return (
        <div style={{
            backgroundColor: '#F4F4F4',
            minHeight: '100vh',
            padding: 30
        }}
        className="d-flex">
            <Container fluid style={style_container} className="d-flex flex-column justify-content-center align-items-center">
                <Row>
                    <img style={{maxHeight: '40vh', width: '100%'}}src="/assets/buyer/icons/SuccessIcon.png"></img>
                </Row>

                <Row className="mt-3">
                    <h1 className="text-success">Payment Successful</h1>
                </Row>

                <Row className="mt-3">
                    <h3>You can safely close this tab</h3>
                </Row>
            </Container>
        </div>
    )
}

export default BuyerSuccessfulPayment
