import React, { Component } from 'react';
import { Container, Row, Col, Nav, Button, Tabs, Tab } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import NoOrder from '../../../components/service/NoOrder';
import OrderItem from "../../../components/common/OrderItem"


function SalesCancelled({cancelledOrderList}) {
        return (
        <div id="sales_container">
                {cancelledOrderList && cancelledOrderList.length === 0 ?
                                <NoOrder/> :
                        cancelledOrderList.length !== 0 && cancelledOrderList.map((order)=>(
                        <div className="productPreviewContainer" key={order.id} id={"transaction_"+order.shop_id}>
    
                                                
                                                <React.Fragment>
                                                        <Container className="salesShopName">
                                                        <Row>
                                                                <Col xs={6} style={{textAlign: "left"}}>
                                                                        <img 
                                                                        alt="" 
                                                                        src="/seller_icons/store_icon_1.png" 
                                                                        style={{
                                                                                height: "17px", 
                                                                                marginRight: "10px"}} 

                                                                                />
                                                                {order?.shop?.name || ""}
                                                                </Col>
                                                                <Col xs={6} style={{textAlign: "right"}}>
                                                                        ORDER ID# {order.rc_id}
                                                                </Col>
                                                        </Row>
                                                        </Container>


                                                        <Container 
                                                                className="salesProductPreview" 
                                                        // onClick={() => openTransaction(data.id)}
                                                        >
                                                
                                                {order.order_items.length !== 0 && order.order_items.map((item)=>(
                                                                <OrderItem 
                                                                        key={item.id}
                                                                        item={item} 
                                                                />
                                                                ))}
                                                        
                                                
                                
                                                
                                
                                                <div className="transactionContainer">
                                                <Row>
                                                <Col xs={6} style={{textAlign: "left"}}>
                                                <h6>Transaction ID</h6>
                                                </Col>
                                                <Col xs={6} style={{textAlign: "right"}}>
                                                <h6>#{order?.transaction_id}</h6>
                                                </Col>
                                                </Row>
                                                </div>
                                                </Container>
                                                </React.Fragment>
                                                </div>
                                                )
                                        )
                                                
                                        }

        </div>
            );
    }
    
    export default SalesCancelled;