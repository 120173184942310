import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';

const initialState = {
  adsList : [],
  webHomeAdsList:[],
  isSuccess:true,
  mainProductAds:null,
  lowerAdsList:[],
  isLoading: false,
  status:null,
  errorMessage:null,
}

export const getAdsList = createAsyncThunk(
    'fetch/ads',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const {data} = await api.get('/ads');
        return data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
)

export const getMainProductAds = createAsyncThunk(
  'fetch/main-ads',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/mainAds');
      return response;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)


export const getLowerAdsList = createAsyncThunk(
  'fetch/lower-ads',
  async (_, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.get('/lowerAds');
      return response;
    } catch (err) {
      if (!err.response) {
        throw err
      }
    }
  }
)

export const AdsListSlice = createSlice({
    name:"ads",
    initialState: initialState,
    extraReducers: (builder) => {

        //HOMEPAGE ADS
        builder.addCase(getAdsList.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getAdsList.fulfilled, (state, action) => {
            let list = action.payload|| []
            state.adsList = list
            state.webHomeAdsList = list.web_home
            state.isLoading = false
        })
        builder.addCase(getAdsList.rejected, (state, action) => {
            state.isLoading = false
        })



        //MAIN PRODUCT ADS
        builder.addCase(getMainProductAds.pending, (state, action) => {
          state.isLoading = true
      })
        builder.addCase(getMainProductAds.fulfilled, (state, action) => {
            let adsPath = null
            const mainAds = action.payload?.data || null 
            if(mainAds && mainAds.device === "web"){
              adsPath = action.payload?.data?.image?.path || null
            }
            state.mainProductAds = adsPath
            state.isLoading = false
        })
        builder.addCase(getMainProductAds.rejected, (state, action) => {
            state.isLoading = false
        })


        //GET LOWER ADS
        builder.addCase(getLowerAdsList.pending, (state, action) => {
          state.isLoading = true
      })
        builder.addCase(getLowerAdsList.fulfilled, (state, action) => {
            let list = action?.payload?.data || []
            const lowerWebAdsList = list.filter((ads)=>ads.device === "web")
            state.lowerAdsList = lowerWebAdsList || []
            state.isLoading = false
        })
        builder.addCase(getLowerAdsList.rejected, (state, action) => {
            state.isLoading = false
        })  
    }
})

export const selectWebAds = state => state.ads.adsList;

export default AdsListSlice.reducer;