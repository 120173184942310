import React, {useEffect, useState} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container,Row, Col, Card, Pagination } from 'react-bootstrap';
import GlobalProductCard from '../../common/ProductCard';
import { RatingView } from 'react-simple-star-rating'
import {Link} from 'react-router-dom'

function EventProducts({productDetails}) {

    const [forYouItemPerPage, setForYouItemPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);
    
    const SlideData = [
        {
            id:1,
            item_image: '/assets/buyer/images/barleySampleImage.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 4
        },
        {
            id:2,
            item_image: '/assets/buyer/images/MeterSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '400.00',
            item_rating: 3
        },
        {
            id:3,
            item_image: '/assets/buyer/images/OppoSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
        {
            id:4,
            item_image: '/assets/buyer/images/SandoSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 1
        },
        {
            id:5,
            item_image: '/assets/buyer/images/ShineSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 5
        },
        {
            id:6,
            item_image: '/assets/buyer/images/VapeSampleImage.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: ''
        },
        {
            id:7,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 3
        },
        {
            id:8,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
        {
            id:9,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 1
        },
        {
            id:10,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
    ]


    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * forYouItemPerPage;
    const indexOfFirstTodo = indexOfLastTodo - forYouItemPerPage;
    const currentTodos = SlideData.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((data, index) => {
        return <Col md={2} className="mt-5">
            <Card >
                    <Card.Img variant="top"  className="img-fluid" src={data.item_image}  />
                    <Card.Body>
                        <Card.Title align="center" style={{color:'#000000', fontWeight:'400'}}>{data.item_title}</Card.Title>
                        <Card.Title align="right" style={{fontSize:12}}>{data.item_discount_price != '' ? <del style={{color:'#747474', fontWeight:'700'}}>{data.item_original_price}</del> : ''}</Card.Title>
                        <div style={{flexDirection:'row', display:'flex', marginTop:0, justifyContent:'space-between'}}>
                            <RatingView ratingValue={data.item_rating} size={17} />
                            <p  style={{fontSize:12, color:'#CF0E0E', fontWeight:'700'}}>{data.item_original_price}</p>
                        </div>

                    </Card.Body>
                </Card>
        </Col>;
    });

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(SlideData.length / forYouItemPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleClickPagination = (e) =>  {
        setCurrentPage(Number(e.target.id))
    }

    const renderPageNumbers = pageNumbers.map(number => {
        return (
            <Pagination.Item
                style={{backgroundColor: currentPage == number ? '#161F92' : 'none', fontSize:24}}
                key={number}
                id={number}
                active={currentPage == number ? true : false}
                onClick={handleClickPagination}
            >
                {number}
            </Pagination.Item>
        );
    });

    return (
        <div className="mt-1">
            <Container fluid>
                   <Row>
                        {
                            productDetails.map((product, index) => {
                               
                                return (
                                        
                                            <Col md={2} className="mt-5">
                                                <div className="sliderEventDataCategory" >
                                                        <Link to={`/product/${product.id}`} style={{textDecoration:'none',color:"#000"}}>
                                                                    <GlobalProductCard
                                                                            isIdolMall={!!product?.shop_to_seller?.shop?.is_idol_mall || false}
                                                                            productName={product.product_name}
                                                                            productImage={product?.latest_variety?.latest_image?.path}
                                                                            reviewsRating={product?.reviews_sum_rating}
                                                                            discounted={product?.latest_variety?.discounted}
                                                                            price={product?.latest_variety?.price}
                                                                        />
                                                        </Link>     
                                                </div>    
                                            </Col>
                                           
                                            )
                                })
                        }
                    </Row>
                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <Pagination  style={{marginTop:'10%'}}>
                            {renderPageNumbers}
                        </Pagination>
                    </div> */}
            </Container>
        </div>
    )
}

export default EventProducts
