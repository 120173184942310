import React, {useState, forwardRef, useEffect} from 'react'
import {Form, Row, Col, Container} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { fetchShippingFee, } from '../../../features/CartSlice';

const grayBackground = {
    backgroundColor: '#FFFF',
    width: '100%',
    backgroundColor: '#FFFF',
}

const borderShip = {
    border: '1px solid rgba(0,0,0,.125)',
    background: 'rgba(49, 103, 233, 0.1)',
    width: '100%',
}

const fontShip = {
    fontWeight: '300',
    fontSize: '1 rem', 
}

function ShippingOption (props) {
    const dispatch = useDispatch();
    const [shippingFee, setShippingFee] = useState();
    const [total, setTotal] = useState();
    const [deliveryDateFrom, setDeliveryDateFrom] = useState();
    const [deliveryDateTo, setDeliveryDateTo] = useState();
    const [canShip, setCanShip] = useState(false);
    const [errMessage, setErrMessage] = useState(null);

    const {
        id,
        ref,
        setShipStatus,
        handleUpdateObject,
        paymentMethodId,
        address_id,
        order,
    } = props

    useEffect(() => {
        setShipStatus(true)
        getData()
    } ,[paymentMethodId])

    const getData = () => {
        var values = {
            id,
            address_id,
            payment_method_id: paymentMethodId
        }

        var promise = dispatch(fetchShippingFee(values)).then((resp) => {
            if (resp.payload.success) {

                setShippingFee(resp.payload.price)
                handleUpdateObject(order.id, resp.payload, 'sf')

                setDeliveryDateFrom(resp.payload.delivery_date_from);
                setDeliveryDateTo(resp.payload.delivery_date_to);

                setCanShip(true)
                setShipStatus(false)
            }
            else {
                setCanShip(false)
                setShipStatus(true)
                setErrMessage((resp.payload.message))
            }
        })

        return promise
    }

    const isChecked = (value, id) => {
        if (value === id) {
            return true
        }
    }

    const computeTotal = () => {
        var total = 0;

        order?.order_items?.map((i) => {
            total += i?.variety?.price * i.quantity;
        })

        return total
    }

    return (
        <div style={grayBackground}>
            <div style={borderShip}>
                <Row className="p-3">
                    <Col className="ms-5" md={8} lg={9} xl={9} xs={12} sm={12}>
                        <img src="/assets/buyer/icons/DeliveryIcon.png" className="img-fluid" style={{ width: 25 }} />
                        <label style={{ marginLeft: 10 }}>Shipping Options</label>
                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col className="ms-5" md={9} lg={9} xs={9} sm={9}>
                        <h6 className="ms-3">
                            Ninja Van
                        </h6>
                        {
                            canShip &&
                            <label className="ms-3" style={fontShip}>
                            Receive by {deliveryDateFrom} - {deliveryDateTo}
                            </label>
                        }
                        
                    </Col>
                    {
                        canShip && canShip ?
                        <Col className="d-flex justify-content-center">
                            <label>
                                P { shippingFee }
                            </label>
                        </Col>
                        : 
                        <Col className="d-flex justify-content-center">
                            <label style={{color: 'red'}}>
                                { errMessage }
                            </label>
                        </Col>
                    }
                    
                </Row>
                <Row>
                    <Col className="ms-5" md={10} lg={10} xs={12} sm={12}>
                        <Form.Check
                            className='m-3'
                            type='radio'
                            id='ninja_van_radio'
                            label='Ninja Van'
                            defaultChecked={isChecked(1, 1)}
                        />
                    </Col>
                </Row>
            </div>
            {
                canShip &&
                <>
                    <Form.Group as={Row} className="pt-3 p-1 ms-5">
                        <Form.Label column sm={2}>
                            Additional Notes:
                        </Form.Label>
                        <Col md={9}>
                            <Form.Control style={{ borderStyle: 'none', textAlign: 'right', position: 'relative', left: 0, right: 10 }} type="text" placeholder="Please leave a note....." />
                        </Col>
                    </Form.Group>
                    <hr/>
                    <Form.Group as={Row} className="p-1 ms-5">
                        <Form.Label column md={9}>
                            Order Total ( { order?.order_items?.length } Items ) :
                        </Form.Label>
                        
                        <Col className="d-flex justify-content-center">
                        </Col>
                    </Form.Group>
                </>
            }
        </div>
    )
}

export default ShippingOption
