import React, { useEffect, useState } from 'react'
import { Container, Card, Button } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { getActiveEvent } from '../../../features/EventsSlice';
import { useDispatch, useSelector } from 'react-redux';

function EventsSection() {

    const [event, setEvent] = useState({})

    const dispatch = useDispatch();

    const [ isLoading, setLoading ] = useState(false);

    useEffect(() => {
        dispatch(getActiveEvent()).then(resp => {
            setEvent(resp.payload?.data || {})
            setLoading(true);
        })
    }, [dispatch])

    const checkImage = () => {
        try {
            if(event.image){
                const image = event.image
                if(image.path){
                    return true
                }
            }
            return false  
        } catch(e) {
            console.log(e)
            return false
        }
        
    }

    return (
        <div className="mt-5">
            <Container fluid>
                <Card className="text-white" >
                    {event && Object.keys(event).length >0 && checkImage() 
                        ? <Link to={`/event/${event.name}`}><Card.Img src={event.image.path} style={{width:'100%'}} alt="Card image"/></Link>
                        : null
                    }
                    {/* <Card.ImgOverlay>
                        <Card.Text>
                            <Button md={6} xs={3} className="EventsViewMoreBtn"><Link to={`/event/${event.name}`}>VIEW MORE</Link></Button>
                        </Card.Text>
                    </Card.ImgOverlay> */}
                </Card>
                {/* <Link to={`/event/${event_type}`}><img className="img-fluid" src="/assets/buyer/images/FathersDayEvent.png"/></Link> */}
            </Container>
        </div>
    )
}

export default EventsSection
