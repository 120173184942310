import React, {useEffect ,useState} from 'react'
import MainHeader from '../../components/buyer/Header/MainHeader'
import SubSectionTitle from '../../components/buyer/Section/SubSectionTitle'
import {useParams} from 'react-router-dom'
import CategorySlider from '../../components/buyer/Category/CategorySlider'
import SectionTitle from '../../components/buyer/Section/SectionTitle'
import CategoryProducts from '../../components/buyer/Category/CategoryProducts'
import { fetchCategory, fetchCategoryProducts, fetchSubCategories } from '../../features/CategoriesSlice';
import { useDispatch, useSelector } from 'react-redux';

function BuyerCategoryPage(props) {
    
    const { item } = useParams()
    const [selectedSubCategory, setSelectedSubCategory] = useState(item)
    const [products, setProducts] = useState([])
    const [subCategories, setSubCategories] = useState([])

    const dispatch = useDispatch();

    const [ isLoading, setLoading ] = useState(false);
    const [ name, setName ] = useState('');

    useEffect(() => {
        setLoading(true);
        dispatch(fetchSubCategories(item)).then(resp => {
            console.log(resp.payload)
            if(resp.payload){
                setSubCategories(resp.payload)
                setName(resp.payload.category_name)
            }
            
            setLoading(false);
        })
    }, [dispatch])

    useEffect(() => {
        setLoading(true);
        dispatch(fetchCategoryProducts(selectedSubCategory)).then(resp => {
            setProducts(resp.payload)
            setLoading(false);
        })
    }, [selectedSubCategory])
    return (
        <div>
            <MainHeader/>
                <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30,
            }}>

                <SubSectionTitle SubSectionTitle={name}/>
                <br/>

                <CategorySlider onChange={setSelectedSubCategory} data={subCategories}/>

                <SectionTitle sectionTitle="All Products"/>
                
                <CategoryProducts data={products}/>
          
            </div>

        </div>
    )
}

export default BuyerCategoryPage
