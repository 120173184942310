import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';

const InitialState = {
  isLoading: false,
  sellerEarnings:[],
}

export const SellerEearnings = createAsyncThunk(
    '/seller/earnings',
    async (query, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.get(`/seller/earnings`)
  
        return response.data.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )




export const EarningsSlice = createSlice({
    name:"earnings",
    initialState: InitialState,
    extraReducers: (builder) => {

        builder.addCase(SellerEearnings.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(SellerEearnings.fulfilled, (state, action) => {
            state.sellerEarnings = action.payload
            state.isLoading = false
        })
        builder.addCase(SellerEearnings.rejected, (state, action) => {
            state.isLoading = false
        })  

    }
})



export default EarningsSlice.reducer;