import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from 'react-router-dom'
import GlobalProductCard from '../../common/ProductCard';
import { Container,Row, Col, Card, Button } from 'react-bootstrap';
import { RatingView } from 'react-simple-star-rating'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img onClick={onClick} className={className} src="/assets/buyer/icons/NextArrowIcon.png" style={{width:40, height:40, marginTop:10}}/>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img onClick={onClick} className={className} src="/assets/buyer/icons/BackArrowIcon.png" style={{width:40, height:40, marginTop:10, zIndex:2222222}}/>
  );
}


function RelatedProducts({productDetails}) {

    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 300,
        slidesPerRow: 6,
        dots:true,
        centerPadding: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2,
                slidesPerRow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2,
                slidesPerRow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 2,
                slidesPerRow: 2,
              }
            }
        ]
    };
    const SlideData = [
        {
            id:1,
            item_image: '/assets/buyer/images/barleySampleImage.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 4
        },
        {
            id:2,
            item_image: '/assets/buyer/images/MeterSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '400.00',
            item_rating: 3
        },
        {
            id:3,
            item_image: '/assets/buyer/images/OppoSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
        {
            id:4,
            item_image: '/assets/buyer/images/SandoSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 1
        },
        {
            id:5,
            item_image: '/assets/buyer/images/ShineSampleImage.png',
            item_title: 'Women Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 5
        },
        {
            id:6,
            item_image: '/assets/buyer/images/VapeSampleImage.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: ''
        },
        {
            id:7,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 3
        },
        {
            id:8,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
        {
            id:9,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 1
        },
        {
            id:10,
            item_image: '/assets/buyer/icons/MenFashionIcon.png',
            item_title: 'Men Fashion',
            item_original_price: '900.00',
            item_discount_price: '',
            item_rating: 2
        },
    ]

    return (
        <div className="mt-2">

                <Slider style={{paddingTop:20,textDecoration:'none',color:"#000"}} {...settings}>
                    {
                        productDetails.map((product) => {

                          let rating = "0";
                          if (product?.reviews_sum_rating != null) {
                            rating = product?.reviews_sum_rating;
                          }

                            return (
                                <div className="m-3" key={product.id}>
                                    <Link to={`/product/${product.id}`} style={{textDecoration:'none',color:"#000"}}>
                                            <GlobalProductCard
                                                isIdolMall={!!product?.shop_to_seller?.shop?.is_idol_mall || false}
                                                productName={product.product_name}
                                                productImage={product?.latest_variety?.latest_image?.path}
                                                reviewsRating={rating}
                                                discounted={product?.latest_variety?.discounted}
                                                price={product?.latest_variety?.price}
                                            />
                                    </Link>
                                </div>
                            )
                        })
                    }
                </Slider>


        </div>
    )
}

export default RelatedProducts
