import React,{useEffect, useState} from 'react'
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import { getProfileDetails, editProfile } from '../../../features/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from 'moment';
import MainHeader from '../../../components/buyer/Header/MainHeader';
import SellerMenu from '../seller_menu';
import ChangePassword from '../../../components/buyer/Profile/ChangePassword';
import ChangeProfilePic from '../../../components/buyer/Profile/ChangeProfilePic';
function BuyerProfile() {
    const dispatch = useDispatch();
    const [changePass, setChangePass] = useState(false);
    const [edit, setEdit] = useState(false);
    const [open_modal, setOpenModalChangeProfilePic] = useState(false);

    const statusEditProfile = useSelector((state) => state.user.userEditProfile);

    useEffect(() => {
        dispatch(getProfileDetails())
    }, [dispatch])

    const calculateAge = (birthday) => {
        var ageDifMs = Date.now() - birthday;
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const profileDetail = useSelector((state) => state.user.detailsProfile)
    const validationProfile = {
        firstname: Yup.string().required("First Name is required"),
        lastname: Yup.string().required("Last Name is required"),
        birthday: Yup.date().required(`Birthday is required`).test("birthday", "User must be above the age 13", function(val) {
            return calculateAge(new Date(val)) >= 13;
        })
    }
    const formik = useFormik({
        initialValues:{
            firstname: profileDetail.first_name,
            lastname: profileDetail.last_name,
            birthday: profileDetail.buyer?.birthday,
        },
        validationSchema: Yup.object(validationProfile),
        onSubmit: (values) => {
            dispatch(editProfile(values))
            dispatch(getProfileDetails())
            if(Boolean(statusEditProfile)){
                setEdit(false)
            }
            // setTimeout(function() {
            //     window.location.href = window.location;
            // }, 3000);
        },
    });

    const profileFirstName = () => {
        if(edit){
            return(<Form.Control className='customInput form-control'
                id='firstname'
                name='firstname'
                type='text'
                placeholder='First Name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstname}
            />)
        }else{

            if(profileDetail.name !== " "){
                return(profileDetail.name)
            }else{
                return('N/A')
            }

        }
    }
    const profileLastName = () => {
        if(edit){
            return(<Form.Control className='customInput form-control'
                id='lastname'
                name='lastname'
                type='text'
                placeholder='Last Name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastname}
            />)
        }
    }
    const profileBirthday = () => {
        if(edit){
            return(<Form.Control
                type="date"
                name="birthday"
                placeholder="Birthday"
                max="9999-12-31"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.birthday}
              />)
        }else{
            if(profileDetail.buyer){
                return(moment(profileDetail.buyer.birthday).format('LL'))
            }else{
                return('N/A')
            }

        }
    }
    const setEditOff = () => {
        setEdit(false)
    }
    const setEditOn = () => {
        setEdit(true)
    }
    const handleChangeProfilePic = () => {
        setOpenModalChangeProfilePic(true)
    }

    const handleChangeProfilePicClose = () => {
        setOpenModalChangeProfilePic(false)
    }

    const refreshData = () => {
        dispatch(getProfileDetails())
    }
    const changePassword = () => {
        setChangePass(true)
    }
    const cancelChangePass = () => {
        setChangePass(false)
    }
    return (
        <div>
            <MainHeader />
            <Container className="mainContainer">
                <Row>
                    <Col xs={2} className="leftContainer">
                        <SellerMenu />
                    </Col>
                    <Col >
                        <Card style={{marginBottom:10, padding:30, borderStyle:'none'}}>
                        { changePass
                                ?   <ChangePassword cancelChangePass={cancelChangePass}/>
                                :
                                <div>
                                    <div >
                                        <h5>My Profile</h5>
                                        <Row>
                                            <Col md={11}>
                                                <label style={{fontWeight:300, fontSize:13}}>Manage and protect your account</label>
                                            </Col>
                                            <Col >
                                                {edit ? <label style={{color:'#3167E9'}} onClick={setEditOff}>Cancel</label>:<label style={{color:'#3167E9'}} onClick={setEditOn}>Edit</label>}
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr />
                                    <div style={{display:'flex', flexDirection:'row', alignItems:'center', textAlign:'center', justifyContent:'center', marginTop:20}}>
                                        <img src={profileDetail.image ? profileDetail.image.path : '/assets/buyer/images/noImage.jpg'} className="profilePicDisplay profilePicLG" onClick={() => handleChangeProfilePic()}/>
                                        <div style={{ display: "inline-block", textAlign: "left", marginLeft: "25px", fontWeight:'300', fontSize:13, lineHeight:0.8}}>
                                            <p>File size: maximum 1 MB</p>
                                            <p>File extension: .JPEG, .PNG</p>
                                        </div>
                                    </div>

                                    <Card.Body>
                                    <Container fluid className="mt-3">
                                        <form onSubmit={formik.handleSubmit}>
                                            <Row className="formGroup">
                                                <Col xs={4} md={3} className="formTitle">
                                                    {edit ? null : 'Name'}
                                                </Col>
                                                <Col xs={12} md={6} className="formDataReadOnly ">
                                                    {profileFirstName()}
                                                    { edit ? formik.touched.firstname && formik.errors.firstname ? <p className='validation'>{formik.errors.firstname}</p> : null : null}
                                                </Col>
                                            </Row>
                                            {
                                                edit ?
                                                <Row className="formGroup">
                                                    <Col xs={6} md={3} className="formTitle">
                                                    </Col>
                                                    <Col xs={12} md={6} className="formDataReadOnly">
                                                        {profileLastName()}
                                                        { edit ? formik.touched.lastname && formik.errors.lastname ? <p className='validation'>{formik.errors.lastname}</p> : null : null}
                                                    </Col>
                                                </Row>
                                                : null

                                            }
                                            {
                                                edit ?
                                                null :
                                                <Row className="formGroup">
                                                    <Col xs={6} md={3} className="formTitle">
                                                        Username
                                                    </Col>
                                                    <Col xs={12} md={6} className="formDataReadOnly">
                                                        {profileDetail.username ? profileDetail.username : 'N/A'}
                                                    </Col>
                                                </Row>
                                            }
                                            {
                                                edit ?
                                                null :
                                                <Row className="formGroup">
                                                    <Col xs={6} md={3} className="formTitle">
                                                        Phone Number
                                                    </Col>
                                                    <Col xs={12} md={6} className="formDataReadOnly">
                                                        *********{profileDetail.buyer?.mobile_no?.toString().substr(-2)}
                                                    </Col>
                                                </Row>
                                            }
                                            <Row className="formGroup">
                                                <Col xs={6} md={3} className="formTitle">
                                                    {edit ? null : 'Birthday'}
                                                </Col>
                                                <Col xs={12} md={6} className="formDataReadOnly">
                                                    {profileBirthday()}
                                                    { edit ? formik.touched.birthday && formik.errors.birthday ? <p className='validation'>{formik.errors.birthday}</p> : null : null}
                                                </Col>
                                            </Row>
                                            <Row className="formGroup">
                                                <Col xs={6} md={3} className="formTitle">
                                                    Password
                                                </Col>
                                                <Col xs={12} md={6} className="formDataReadOnly">
                                                    <span style={{color: 'Blue', fontWeight: 'bold'}} onClick={changePassword}>Change Password?</span>
                                                </Col>
                                            </Row>
                                            <Row className="formGroup">
                                                <Col xs={12} md={6}>
                                                    {edit ? <div><Button onClick={setEditOff} type="submit" variant="primary" className="btn-space">Cancel</Button><Button type="submit" variant="primary" className="btn-space">Save</Button></div> : ''}
                                                </Col>
                                            </Row>

                                        </form>
                                    </Container>
                                    </Card.Body>
                                </div>
                        }
                        </Card>

                    </Col>
                </Row>
            </Container>
            <ChangeProfilePic
                show={open_modal}
                onHide={() => setOpenModalChangeProfilePic(false)}
                handleChangeProfilePicClose={handleChangeProfilePicClose}
                refreshData={refreshData}/>
        </div>
    )
}

export default BuyerProfile
