import React,{useState, useEffect} from 'react'
import MainHeader from '../Header/MainHeader'
import SubSectionTitle from '../Section/SubSectionTitle'
import {Container, Row, Col, Card, Form, Button} from 'react-bootstrap'
import NewAddressModal from './NewAddressModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from '../../../features/CartSlice';
import { getAddressDetail, updateDefaultAddresses, deleteAddresses } from '../../../features/AddressSlice';
import AddressForm from './AddressForm'

function AddressList() {
    const dispatch = useDispatch();
    const [modalShowFormNewAddress, setModalShowFormNewAddress] = useState(false);
    const [modalShowFormUpdateAddress, setModalShowFormUpdateAddress] = useState(false);
    const [addressDetail, setAddressesDetail] = useState([]);
    const [fetchAddress, setFetchAddress] = useState([]);

    useEffect(() => {
        dispatch(getAddress())
    }, [dispatch])

    let addresses = useSelector((state) => state.cart.addressesList);

    const setUpdateAddress = (res_address) => {
        setModalShowFormUpdateAddress(true)
        setModalShowFormNewAddress(false)
        setFetchAddress(res_address)
    }

    const setDeleteAddress = (id) => {
        dispatch(deleteAddresses(id))

        setTimeout(function() {
            dispatch(getAddress())
        }, 1000);
    }

    const OpenAddAddress = () => {
        setModalShowFormUpdateAddress(false)
        setModalShowFormNewAddress(true)
    }

    const OpenUpdateAddress = () => {
        setModalShowFormUpdateAddress(true)
        setModalShowFormNewAddress(false)
    }

    const CloseAddress = () => {
        setModalShowFormUpdateAddress(false)
        setModalShowFormNewAddress(false)
    }

    const handleOnChange = (res_default_loc) => {
        dispatch(updateDefaultAddresses({id: res_default_loc.id, default_address: true}))
    }


    return (
        <div>
            <div style={{
                backgroundColor:'#F4F4F4',
                minHeight:'100vh',
                padding:30}}>

                <SubSectionTitle SubSectionTitle="My Addresses" />
                <br/>
                { modalShowFormNewAddress || modalShowFormUpdateAddress
                    ?
                    <AddressForm addresses={addresses}
                        OpenUpdateAddress={OpenUpdateAddress}
                        setUpdateAddress={setUpdateAddress}
                        OpenAddAddress={OpenAddAddress}
                        CloseAddress={CloseAddress}
                        modalShowFormNewAddress={modalShowFormNewAddress}
                        fetchAddress={fetchAddress}/>
                    : addresses && addresses.map((res) => {
                        return(
                            <Container fluid className="mt-4">
                                <div style={{
                                    backgroundColor:'#FFFF',
                                    width:'100%',
                                    padding:20,
                                    backgroundColor:'#FFFF',
                                    borderTopColor:'yellow',
                                    borderRightColor:'#FFFF',
                                    borderLeftColor:'#FFFF',
                                    borderBottomColor:'#FFFF',
                                    borderStyle:'solid'
                                }}>
                                    <Row>
                                        <Col md={7} lg={10} xs={11} sm={11}>
                                            <span style={{ color:'#3E6AE3' }}>{res.is_default === 1 ? 'Default' : null}</span>
                                            {/* <Form.Check
                                                name={res.name}
                                                value={res.name}
                                                onChange={() => handleOnChange(res)}
                                                label={ <span style={{color:'#3E6AE3'}}>Default</span>} checked={res.is_default === 0 ? false : true}/> */}
                                        </Col>
                                        <Col >
                                            <label style={{textAlign:'right', color:'#3E6AE3'}}><span onClick={() => setUpdateAddress(res)}>Edit</span> | <span onClick={() => setDeleteAddress(res.id)}>Delete</span></label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={10} lg={11} xs={12} sm={12}>
                                            <label style={{fontWeight:300, fontSize:14, marginTop:15}}>
                                                {res.name}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={10} lg={11} xs={12} sm={12}>
                                            <label style={{fontWeight:300, fontSize:14}}>
                                                {res.contact_no}
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={10} lg={11} xs={12} sm={12}>
                                            <label style={{fontWeight:300, fontSize:14}}>
                                                Brgy {res.barangay?.description}, City {res.city?.description}, {res.province?.description}, {res.region?.description}
                                            </label>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        )
                    })
                }
            <div className="mt-5">
                <Container fluid>
                    <Card style={{
                        border: '1px solid #FFFFFF',
                        borderRadius: 0
                    }}>
                        <Card.Body style={{
                            backgroundColor:'#FFFFFF',
                            color:'#161F92',
                            fontWeight:'700',
                            fontSize:30,
                            borderRadius: '0px !important'
                        }}>

                            <p onClick={OpenAddAddress}>Add New Delivery Address
                                <img className="float-end" src="/assets/buyer/icons/plus3.jpg" style={{width:20, height:20, marginTop: 15, zIndex:2222222}}/>
                            </p>

                        </Card.Body>
                    </Card>
                </Container>
            </div>


            </div>

        </div>
    )
}

export default AddressList
