import React from 'react'
import {Container,  Row, Col, Card, Button} from 'react-bootstrap';
import NotificationItem from './NotificationItem';

function ListNotification(props) {
    const {
        data,
    } = props;

    return (
        <div className="p-2">
                { data.slice(0,7).map((notif) => {
                    return (
                        <NotificationItem key={notif?.id} notif={notif}/>
                    )
                })
                }
        </div>
    )
}

export default ListNotification
