import React, {useState, useEffect} from 'react'
import { Card, Col, Container, Row, Form, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from 'react-router-dom';
import Footer from '../../components/buyer/Footer/Footer';
import SubHeader from '../../components/buyer/Header/SubHeader';
import { SignUp, ClearSignUpErrorMessage } from '../../features/UserSlice';
import { useDispatch, useSelector } from 'react-redux';

function BuyerSignUp() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const statusCode = useSelector((state) => state.user.userSignUp).status_code;
    const validationSignUp = {
        username: Yup.string().required("Username is required"),
        phone_number: Yup.string().required("Phone Number is required").matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            "Phone Number is not valid"
        ),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string().min(4, 'Minimum of 4 character').required("Password is required"),
        password_confirmation: Yup.string().required("Confirm Password is required").oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }
    const formik = useFormik({
        initialValues:{
            username: "",
            phone_number: "",
            email: "",
            password: "",
            password_confirmation: "",
        },
        validationSchema: Yup.object(validationSignUp),
        onSubmit: (values) => {
            dispatch(SignUp({...values, role_id: 3}))
            // if(Number(statusCode) === 201){
            //     history.push('/login');
            // }
        },
    });
    const DisplayErrorUserName = useSelector((state) => state.user).catchErrorSignup.username
    const DisplayErrorPhoneNum = useSelector((state) => state.user).catchErrorSignup.phone_number
    const DisplayErrorEmail = useSelector((state) => state.user).catchErrorSignup.email

    useEffect(() => {
        dispatch(ClearSignUpErrorMessage())
    },[])

    return (
        <div>
            <SubHeader/>
            <div className="p-5">
                <Container>
                    <Row>
                        <Col md={1}></Col>
                        
                        <Col md={5} lg={4}>
                            <img className="img-fluid"  src="/assets/buyer/images/ISNBoxLogo.png"/>
                        </Col>
                        <Col lg={2}></Col>
                        <Col md={12} lg={5} sm={12}>
                            <Card className="shadow">
                                <Card.Body className="p-5">
                                    <Card.Title>Sign Up</Card.Title>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className='input-container mb-3 mt-5'>
                                            <input className='customInput form-control' 
                                                id='username' 
                                                name='username' 
                                                type='text' 
                                                placeholder='Username' 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.username} 
                                                style={{borderRadius:'0', backgroundColor:'#F4F4F4 !important', border:'none'}}
                                            />
                                            {formik.touched.username && formik.errors.username ? <p className='validation'>{formik.errors.username}</p> : null}
                                            {DisplayErrorUserName ? <p className='validation'>{DisplayErrorUserName}</p> : null}                                            
                                        </div>
                                        <div className='input-container mb-3 mt-5'>
                                            <input className='customInput form-control' 
                                                id='phone_number' 
                                                name='phone_number' 
                                                type='text' 
                                                placeholder='Mobile Number' 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.phone_number}
                                            />
                                            {formik.touched.phone_number && formik.errors.phone_number ? <p className='validation'>{formik.errors.phone_number}</p> : null}
                                            {DisplayErrorPhoneNum ? <p className='validation'>{DisplayErrorPhoneNum}</p> : null}
                                        </div>
                                        <div className='input-container mb-3 mt-5'>
                                            <input className='customInput form-control' 
                                                id='email' 
                                                name='email' 
                                                type='text' 
                                                placeholder='Email Address' 
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.email}
                                            />
                                            {formik.touched.email && formik.errors.email ? <p className='validation'>{formik.errors.email}</p> : null}
                                            {DisplayErrorEmail ? <p className='validation'>{DisplayErrorEmail}</p> : null}
                                        </div>
                                        <div className='input-container mb-3 mt-5'>
                                            <input className='customInput form-control' id='password' name='password' type='password' placeholder='Password' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}/>
                                            {formik.touched.password && formik.errors.password ? <p className='validation'>{formik.errors.password}</p> : null}
                                        </div>
                                        <div className='input-container mb-3 mt-5'>
                                            <input className='customInput form-control' id='password_confirmation' name='password_confirmation' type='password' placeholder='Re-enter Password' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password_confirmation}/>
                                            {formik.touched.password_confirmation && formik.errors.password_confirmation ? <p className='validation'>{formik.errors.password_confirmation}</p> : null}
                                        </div>
                                        <div className="">
                                            {checked ? 
                                                <Button type="submit" variant="primary" className="mt-3 default-btn form-control">SIGN UP</Button>
                                            :
                                                <Button type="submit" variant="primary" className="mt-3 default-btn form-control" disabled>SIGN UP</Button>
                                            }
                                            
                                        </div>
                                        <br/>
                                        <div className="mb-3 p-2" controlId="formBasicCheckbox">
                                            <input type="checkbox" checked={checked} onChange={e => setChecked(e.target.checked)}/> By signing up, you agree to <Link to="/terms_of_services" style={{textDecoration:'none',color:'#3167E9',fontWeight:'300'}}>ISN Terms of Services</Link> & <Link to="/privacy_policy"  style={{textDecoration:'none',color:'#3167E9',fontWeight:'300'}}>Privacy Policy</Link>
                                        </div>
                                        <br/>
                                        <p style={{textAlign:'center'}}>
                                            I’m already a member, <Link style={{textDecoration:'none',color:'#161F92',fontWeight:'700'}}>Log In</Link>
                                        </p>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default BuyerSignUp
