import React,{useState} from 'react'
import { Card, Container, Table, Form, ButtonGroup, Button, Row, Col, Modal, Nav} from 'react-bootstrap'
import OrderBuyerAddress from '../Orders/OrderBuyerAddress';
import {Link} from 'react-router-dom'

function CanceledDetails() {

    

    return (
        <div>

            <OrderBuyerAddress/>

           <br/>

           <Container fluid>
                <div style={{
                    backgroundColor:'#FFFF',
                    width:'100%',
                    backgroundColor:'#FFFF',
                }}>
                    <div style={{
                        background:'#FFFFFF',
                        width:'100%',
                    }}>
                        <Row className="p-3">
                            <Col md={8} lg={9} xl={9} xs={12} sm={12}>
                                <img src="/assets/buyer/icons/DeliveryIcon.png" className="img-fluid" style={{width:25}} />
                                <label style={{marginLeft:10}}>Shipping Options</label>
                            </Col>
                        </Row>

                        <Row className="p-3">
                            <Col md={10} lg={11} xs={12} sm={12}>
                                <label style={{fontWeight:300, fontSize:14, marginBottom:0, position:'relative', bottom:15, marginLeft:38}}>
                                    Ninja Van
                                </label>
                            </Col>
                            <Col md={10} lg={10} xs={12} sm={12}>
                                <label style={{fontWeight:300, fontSize:14, marginBottom:0, position:'relative', bottom:15, marginLeft:38}}>
                                    Receive by 20 Jun - 24 Jun
                                </label>
                            </Col>
                            <Col>
                                <h4 style={{fontWeight:400, fontSize:18, marginBottom:0, position:'relative', bottom:15, marginLeft:40, marginRight:35}}>
                                    P40.00
                                </h4>
                            </Col>
                        </Row>

                    </div>

                </div>
            </Container>
            <br/>

            <Container fluid className="">
                <div style={{
                    backgroundColor:'#FFFF',
                    width:'100%',
                    padding:5,
                    backgroundColor:'#FFFF',
                    borderTopColor:'#FFFF',
                    borderRightColor:'#FFFF',
                    borderLeftColor:'#FFFF',
                    borderBottomColor:'#FFFF',
                    borderStyle:'solid',
                    
                }}>
                    <Form.Group as={Row} className="p-3">
                        <Form.Label column md={10}>
                            <h4 style={{fontWeight:'300'}}>Merchandise Subtotal</h4>
                        </Form.Label>
                        <Col>
                            <h4 style={{color:'#535353'}}>P790.00</h4>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="p-3" style={{position:'relative',bottom:25}}>
                        <Form.Label column md={10}>
                            <h4 style={{fontWeight:'300'}}>Shipping Subtotal</h4>
                        </Form.Label>
                        <Col>
                            <h4 style={{color:'#535353'}}>P80.00</h4>
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} className="p-3" style={{position:'relative',bottom:50}}>
                        <Form.Label column md={10}>
                            <h4 style={{fontWeight:'300'}}>Total Payment</h4>
                        </Form.Label>
                        <Col>
                            <h4 style={{color:'#CF0E0E'}}>P1,230.00</h4>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="p-3" style={{position:'relative',bottom:50}}>
                        <Form.Label column md={8}>
                            <h4 style={{color:'#535353'}}>Payment Options</h4>
                        </Form.Label>
                        <Col md={4}>
                            <h4 style={{color:'#535353', textAlign:'right'}}>Credit / Debit Card*1234</h4>
                        </Col>
                    </Form.Group>

                </div>
            </Container>

            <br/>

           <Container fluid>
                
                    <div className="productPreviewContainer">

                        <Container className="salesShopName">
                            <Row>
                                <Col xs={6} style={{ textAlign: "left" }}>
                                    <img src="/seller_icons/store_icon_1.png" style={{ height: "17px", marginRight: "10px" }} />House of Dress Store
                            </Col>
                            
                                <Col xs={6} style={{ textAlign: "right" }}>
                                    <label style={{color:'#535353', fontWeight:300}}>ORDER ID# 12367</label>
                                </Col>
                            
                            </Row>
                        </Container>
                    

                        <Container className="salesProductPreview ">

                            <div className="productListContainer" >

                                <Row className="salesProduct">
                                    <Col xs="2">
                                        <img className="productThumb" src="/sampleProduct/dress1.jpg" style={{ width: "100%" }} />
                                    </Col>
                                    <Col sx="6" style={{ textAlign: "left" }}>
                                        <Nav.Link>Tanya Dress</Nav.Link>
                                        <p style={{ padding: "0px 16px" }}>Variation: Pink, Small</p>
                                    </Col>
                                    <Col xs="2" style={{ textAlign: "right" }}>
                                        <p>Quantity: 1</p>
                                    </Col>
                                    <Col xs="2" style={{ textAlign: "right" }}>
                                        <b style={{ color: "#CF0E0E" }}>P300.00</b>
                                    </Col>
                                </Row>

                                <Row className="salesProduct">
                                    <Col xs="2">
                                        <img className="productThumb" src="/sampleProduct/dress1.jpg" style={{ width: "100%" }} />
                                    </Col>
                                    <Col sx="6" style={{ textAlign: "left" }}>
                                        <Nav.Link>Tanya Dress 2</Nav.Link>
                                        <p style={{ padding: "0px 16px" }}>Variation: Pink, Small</p>
                                    </Col>
                                    <Col xs="2" style={{ textAlign: "right" }}>
                                        <p>Quantity: 1</p>
                                    </Col>
                                    <Col xs="2" style={{ textAlign: "right" }}>
                                        <b style={{ color: "#CF0E0E" }}>P300.00</b>
                                    </Col>
                                </Row>

                            </div>

                            <div className="transactionContainer">
                                <Row>
                                    <Col xs={6} style={{ textAlign: "left" }}>
                                        <h6>Transaction ID</h6>
                                    </Col>
                                    <Col xs={6} style={{ textAlign: "right" }}>
                                        <h6>#1234567890</h6>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>

                        
                    
            </Container>

            <br/>

            
            
        </div>
    )
}

export default CanceledDetails
