import React, { useEffect } from 'react'
import {Carousel} from 'react-bootstrap'
import { getLowerAdsList } from '../../../features/AdsSlice';
import { useDispatch, useSelector } from 'react-redux';

function LowerAds() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLowerAdsList())
    }, [dispatch])

    const { lowerAdsList,isLoading} = useSelector((state) => state.ads);

    return (
        <div>
            {!isLoading && lowerAdsList.length > 0 &&
                
                        <Carousel variant="dark" style={{ marginTop:30}}>
                            {
                                lowerAdsList && lowerAdsList.length > 0 && lowerAdsList.map((data) => {
                                        if(data?.image && data?.image?.path){
                                            return (
                                            <Carousel.Item style={{height:200}}>
                                                <img alt="" style={{width:'100%'}}  src={data?.image?.path}/>
                                            </Carousel.Item>
                                        )
                                    }     
                                })
                            }
                        </Carousel>
            }
        </div>
    )
}

export default LowerAds;
