import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../services/Api';
import { toast } from "react-toastify";
import { apiURL } from '../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const InitialState = {
  userDetails: [],
  userSignUp: [],
  userForgotPass: [],
  userEditProfile: false,
  isLoading: false,
  isLoggedIn: false,
  catchErrorSignup: { phone_number: '', username: '', email: '' },
  catchErrorLogin: '',
  catchErrorForgotPass: '',
  catchErrorChangePass: false,
  catchErrorChangePassMessage: '',
  sellerHasShop: false,
  role_mode: 1,
  detailsProfile: [],
  isIntercomHidden: false,
  firebaseGeneratedToken: '',
  notifications: [],
  
}

export const setFireBaseToken = createAsyncThunk(
  'firebase/setFireBaseToken',
  async (values, { rejectWithValue, _ }) => {
    try {
      return values;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
})

export const getNotifications = createAsyncThunk(
  'user/notifications',
  async (values, { rejectWithValue, _ }) => {
    try {
      const response = await api.get('/notifList')

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
})

export const hideIntercom = createAsyncThunk(
  'intercom/hideIntercom',
  async (values, { rejectWithValue, _ }) => {
    try {
      
      return values;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }

  })

export const changePass = createAsyncThunk(
  'buyer_changePass/changePass',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post('/change_password', {
        current_password: values.current_password,
        new_password: values.new_password
      })
      return response.data
    } catch (err) {
      console.log(err)
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const LogOut = createAsyncThunk(
  'buyer_log_out/log_out',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/logout')
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  })

export const getProfileDetails = createAsyncThunk(
  'profile/profile_view',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.get('/profile')
      return response.data.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  })

export const editProfile = createAsyncThunk(
  'profile/edit_profile',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.put('/profile', {
        first_name: values.firstname,
        last_name: values.lastname,
        birthday: values.birthday,
      })
      return response.data.success
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  })

export const ForgotPassword = createAsyncThunk(
  'buyer_forgot_password/forgot_password',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post('/password/sms', {
        phone_number: values.phone_number,
      })
      if (response.status === 201) {
        return { ...response.data, status_code: 201 }
      }
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  })

export const LogIn = createAsyncThunk(
  'buyer_log_in/log_in',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post('/user/login', {
        username: values.phone_number,
        password: values.password,
        device_token: values.device_token
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  })


export const SignUp = createAsyncThunk(
  'buyer_sign_up/sign_up',
  async (value, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post('/register', {
        username: value.username,
        email: value.email,
        password: value.password,
        password_confirmation: value.password_confirmation,
        phone_number: value.phone_number
      })
      console.log(response.data);
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)
export const imageUpload = createAsyncThunk(
  'buyer_upload_image/upload_image',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      return fetch(`${apiURL}/profile/pic`, {
        'method': 'POST',
        headers: {
          'Authorization': 'Bearer ' + cookies.get('access_token')
        },
        body: values
      })
        .then(reps => reps.json())
        .then(reps => {
          return reps
        })
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  })

export const ClearLoginErrorMessage = createAsyncThunk(
  'buyer_log_in/clear_error_message',
  async (value, { rejectWithValue, dispatch }) => {
    return true;
  }
)

export const ClearSignUpErrorMessage = createAsyncThunk(
  'buyer_sign_up/clear_error_message',
  async (value, { rejectWithValue, dispatch }) => {
    return true;
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState: InitialState,
  extraReducers: (builder) => {

    builder.addCase(changePass.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(changePass.fulfilled, (state, action) => {
      state.isLoading = false
      state.catchErrorChangePass = false
      toast.success(action.payload.message, {
        position: "bottom-left",
      })
    })
    builder.addCase(changePass.rejected, (state, action) => {
      state.catchErrorChangePassMessage = action.payload.message
      state.isLoading = false
      state.catchErrorChangePass = true
      toast.error(action.payload.message, {
        position: "bottom-left",
      })
    })

    builder.addCase(imageUpload.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(imageUpload.fulfilled, (state, action) => {
      state.isLoading = false
      toast.success("Successfully Upload Profile Picture", {
        position: "bottom-left",
      })
    })
    builder.addCase(imageUpload.rejected, (state, action) => {
      
      state.isLoading = false
      toast.error(action.error.message)
    })
    builder.addCase(LogIn.pending, (state, action) => {
      state.isLoading = true
      state.isLoggedIn = false
    })
    builder.addCase(LogIn.fulfilled, (state, action) => {

      state.isLoading = false
      console.log(action.payload?.data)
      console.log(action.payload?.message)

      if (action.payload?.message) {
        state.catchErrorLogin = action.payload.message
        state.isLoggedIn = false
      }

      if (action.payload.data && action.payload?.message == 'Successfully logged in!') {
        state.catchErrorLogin = ''
        state.userDetails = action.payload
        state.isLoggedIn = true
      }
    })
    builder.addCase(LogIn.rejected, (state, action) => {
      state.isLoading = false
      state.isLoggedIn = false
    })
    builder.addCase(LogOut.fulfilled, (state, action) => {
      if (action.payload === undefined) {
        state.isLoading = false
        state.isLoggedIn = true
      } else {
        state.isLoading = false
        state.isLoggedIn = false
        cookies.remove('access_token');
        state.userDetails = []
        state.userSignUp = []
      }
    })
    builder.addCase(SignUp.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(SignUp.fulfilled, (state, action) => {
      state.isLoading = false

      if (action.payload?.data && action.payload.message == 'Successfuly registered.') {

        toast.success('Successfuly registered.', {
          position: "top-center",
        });

        state.userSignUp = action.payload
        state.userDetails = action.payload
        state.isLoggedIn = true
        cookies.set('access_token', action.payload?.data?.token, { path: '/', maxAge: 100000000 })
        window.location.replace('/');
      }
      state.catchErrorSignup = { username: '', phone_number: '', email: '' }
    })
    builder.addCase(SignUp.rejected, (state, action) => {
      state.isLoading = false
      let error_message = action.payload.errors
      if (error_message.phone_number !== 'undefine' && error_message.username !== 'undefine' && error_message.email !== 'undefine') {
        state.catchErrorSignup = { username: error_message.username, phone_number: error_message.phone_number, email: error_message.email }
      } else if (error_message.username && error_message.phone_number === 'undefine' || error_message.email === 'undefine') {
        state.catchErrorSignup = { username: error_message.username, phone_number: '' }
      } else if (error_message.phone_number && error_message.username === 'undefine' || error_message.email === 'undefine') {
        state.catchErrorSignup = { username: '', phone_number: error_message.phone_number }
      } else if (error_message.email && error_message.username === 'undefine' || error_message.phone_number === 'undefine') {
        state.catchErrorSignup = { username: '', phone_number: '', email: error_message.email }
      }else {
        state.catchErrorSignup = { username: '', phone_number: '', email: '' }
      }
    })
    builder.addCase(ForgotPassword.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(ForgotPassword.fulfilled, (state, action) => {
      state.isLoading = false
      state.userForgotPass = action.payload
      state.catchErrorForgotPass = ''
    })
    builder.addCase(ForgotPassword.rejected, (state, action) => {
      state.isLoading = false
      state.userForgotPass = {
        message: action.payload.message,
        status: 'failed',
        status_code: 401
      }
    })
    builder.addCase(editProfile.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(editProfile.fulfilled, (state, action) => {
      state.isLoading = false
      state.userEditProfile = action.payload
      toast.success('Successfully Updated Profile', {
        position: "bottom-left",
      });
    })

    builder.addCase(ClearLoginErrorMessage.fulfilled, (state, action) => {
      if (action.payload == true) {
        state.catchErrorLogin = ''
      }
    });

    builder.addCase(ClearSignUpErrorMessage.fulfilled, (state, action) => {
      if (action.payload == true) {
        state.catchErrorSignup = { phone_number: '', username: '' }
      }
    });
    //Fetch Seller Details and check if user has a shop
    builder.addCase(getProfileDetails.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(getProfileDetails.fulfilled, (state, action) => {
      const hasShop = action?.payload?.seller ? true : false
      state.sellerHasShop = hasShop || false
      state.detailsProfile = action?.payload
      state.isLoading = false
    })
    builder.addCase(getProfileDetails.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(setFireBaseToken.fulfilled, (state, action) => {
      state.firebaseGeneratedToken = action?.payload
    })
    builder.addCase(hideIntercom.fulfilled, (state, action) => {
      state.isIntercomHidden = action?.payload
    })
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action?.payload?.data
    })
  },
})

export const selectUser = state => state.user;
export default userSlice.reducer;