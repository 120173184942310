import React from 'react';
import { Row, Col, } from "react-bootstrap";
import { RatingView } from 'react-simple-star-rating'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReviewsSection from './components/ReviewsSection';
import NoOrder from '../../../components/service/NoOrder';


const  ProductRatingsReviews = ({productReviews,filterRate}) => {
    
        return (
            
                                    
            <div className="product-ratings">
                                        <div className="product-rating-title">
                                            <Row>
                                                <hr></hr>
                                                <Col className="d-flex align-items-center gap-4 mb-2">
                                                    <h5>Product Ratings</h5>
                                                    <h5>|</h5>
                                                    <RatingView 
                                                        fillColor="#FFD700"
                                                        ratingValue={filterRate === "total" ?5:filterRate } 
                                                        size={20}/>
                                                        <h5>|</h5>
                                                     <h5>{filterRate === "total"? `All (${parseInt(productReviews['total'])|| 0} reviews)`:`${filterRate}/5 (${parseInt(productReviews['total'])|| 0} reviews)`}</h5>
                                                </Col>
                                                    
                                                <hr></hr>
                                            </Row>
                                            {
                                                (productReviews && 
                                                Object.keys(productReviews).length > 0) &&
                                                productReviews?.reviews.length > 0 ?
                                                productReviews.reviews.map((review)=>
                                                    (
                                                    <ReviewsSection 
                                                        reviewData={review}
                                                    />
                                                ))
                                                :
                                                <NoOrder text={"No Reviews Yet"}/>
                                            }
                                            
                                       
                                        </div>
                                    
            </div>
                                    
                                    
                                        
                                    
        );
}

export default ProductRatingsReviews;