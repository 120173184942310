import React from 'react';

const NoOrder = ({text}) =>
        <div className="no-data d-flex flex-column justify-content-center align-items-center">
            <div>
                <img src="/seller_icons/no_data.png" 
                    alt=""
                    style={{ 
                        height: "150px", 
                        marginRight: "10px",  
                        marginBottom: "30px"
                        }} />
            </div> 
            <div>
            {text ?
                <h2>{text}</h2>
                :
                <h2>No Orders Yet</h2>
            }
                
            </div>
        </div>

export default NoOrder;