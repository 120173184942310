import React,{useState,useEffect} from 'react'
import { Container,  Form, Button, Row, Col, Modal, Nav} from 'react-bootstrap'
import { Rating } from 'react-simple-star-rating'
import { useDispatch, useSelector } from 'react-redux';
import {rateAndReview} from "../../../features/PurchaseSlice"


function RateAndReviewModal({
    openRateModal,
    setOpenRateModal,
    productId,
}) {
    const dispatch = useDispatch();
    const {isLoading} = useSelector((state) => state.purchase);
    const [rating, setRating] = useState(5)
    const [modalData, setModalData] = useState({
        review:"",
        rate:5
    });
    const [successModal, setSuccessModal] = useState(false)
        
    const handleRating = (rate) => {
        setRating(rate)
         setModalData((prev)=>{
            prev['rate'] = rate
            return {...prev}
        })
      }

      const handleChange = (e)  =>{
        const {name,value} = e.target
        setModalData((prev)=>{
            prev[name] = value
            return {...prev}
        })
    }


    const handleSubmit = (e) =>{
        if(modalData){
            dispatch(rateAndReview({
                productId,
                review:modalData.review,
                rating:modalData.rate
            })).then((res)=>{
                setSuccessModal(true);
                setOpenRateModal(false)
            })

        }
        setTimeout(()=>{setSuccessModal(false)},1500)
    }

    return (
        <div>
            <Modal
                show={successModal}
                onHide={successModal}
                size='md'
                className="SuccessRequestModal"
            >
                <Modal.Body> 
                    <div style={{display:'flex', alignItems:'center', color:'#161F92', justifyContent:'center'}}>
                        <img src="/assets/buyer/icons/SuccessIcon.png" className="img-fluid" style={{width:100}} />
                        
                    </div>
                    <h4 align="center" className="mt-4 text-white">
                        Success! Rate and Review Sent
                    </h4>
                </Modal.Body>
            </Modal>

            <Modal
                show={openRateModal}
                size='md'
                centered
            >
                <Modal.Body> 

                    <div style={{display:'flex', alignItems:'center', color:'#161F92', justifyContent:'center'}}>
                        <h5>Rate and Review</h5>
                    </div>
                    
                    <Container>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                                style={{
                                    color:'#747474', 
                                    fontWeight:'bold', 
                                    fontSize:16
                                    }}
                                    >
                                    Review
                            </Form.Label>
                            <Form.Control 
                                name="review"
                                as="textarea" 
                                disabled={isLoading}
                                required
                                style={{
                                resize:"none"
                                    }} 
                                rows={4} 
                                onChange={handleChange}
                                />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label 
                                style={{
                                    color:'#747474', 
                                    fontWeight:'bold', 
                                    fontSize:16}}
                                    >
                                    Rate
                            </Form.Label>
                            <Col className="d-flex gap-5 ms-5">
                                    
                                   <Rating
                                       name="rate"
                                       fillColor="#FFD700"
                                       onClick={handleRating} 
                                       ratingValue={rating}
                                 size={35}/>
                            </Col>
                        </Form.Group>
                    </Container>
                    
                </Modal.Body>
                <Modal.Footer >
                    <Container>
                        <Row style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Col md={5}>
                                <Button 
                                    className="mx-2 rounded-2 px-5"
                                    size="sm"
                                    variant="outline-primary"
                                    onClick={()=>setOpenRateModal(false)} 
                                >
                                    Cancel
                                </Button>
                                
                            </Col>
                            <Col md={5}>
                                <Button 
                                           className="mx-1 rounded-2 px-5"
                                           size="sm"
                                           variant="primary"
                                            onClick={(e) => handleSubmit(e)} 
                                               >
                                                Submit
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
                <br/>
            </Modal>
          </div>  
         

    )
}

export default RateAndReviewModal;
