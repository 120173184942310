import React, {useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container,Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SectionTitle from '../Section/SectionTitle';
import CategoryProducts from './CategoryProducts';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img onClick={onClick} className={className} src="/assets/buyer/icons/NextArrowIcon.png" style={{width:40, height:40, marginTop:10}}/>
    );
  }
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "none"}}
        onClick={onClick}
        />
    );
}



function CategorySlider(props) {

    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 300,
        rows: 1,
        slidesPerRow: 5,
        dots:true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const [active, setActive] = useState(false);


    const HandleChangeSubCat = (value) => {
        props.onChange(value);
        
        const items = document.querySelectorAll('h6');
        for (let i = 0; i < items.length; i++) {
            items[i].className = '';
        }

        setActive(value);
        // document.getElementById('productBoxDesign'+value).classList.add("active_cat") 
    }
    const checkImage = (result) => {
        try {
            if(result.image){
                const image = result.image
                if(image){
                    if(image.path){
                        return true
                    }
                }
            }
            return false  
        } catch(e) {
            console.log(e)
            return false  
        }
        
    }

    const classActive = (id) => {
        return active === id ? 'active_cat' : '';
    }

    return (
        <div>
            <Container fluid>
                <Slider style={{paddingTop:20, margin: '0 auto'}} {...settings}>
                    {
                        props.data && props.data.subcategories && props.data.subcategories?.map((data) => {
                            return (
                                <div key={data.id}>
                                    <div  className="sliderDataCategory">
                                        <h6 id={ `productBoxDesign${data.id}` } class={classActive(data.id)} onClick={() => HandleChangeSubCat(data.id)}>
                                            <Container >
                                                <Row>
                                                    <Col md={3}>
                                                        {checkImage(data) 
                                                        ? <img className="img-fluid" src={data.image.path}/> 
                                                        : <img className="img-fluid" src='/assets/buyer/images/noImage.jpg'/> 
                                                        }
                                                    </Col>
                                                    <Col >
                                                        <label style={{cursor:'pointer'}}  onClick={() => HandleChangeSubCat(data.id)}>{data.category_name}</label>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </h6>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </Container>
            
        </div>
    )
}

export default CategorySlider
