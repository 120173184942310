import React,{useEffect, useState} from 'react'
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom'
import {Link} from 'react-router-dom';
// import {getProfileDetails} from '../../../features/UserSlice';
import {getProfileDetails} from '../../features/UserSlice';
import {useDispatch, useSelector} from 'react-redux'
function showSubmenu() {

  var x = document.getElementById("account-submenu");
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }

}

const SellerMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const routeStartSelling = (path) => {
    history.push(path);
  }
  useEffect(() => {
    dispatch(getProfileDetails())
  }, []);

  const sellerDetails = useSelector((state) => state.user.detailsProfile);
  return (
    <React.Fragment>
      <img style={{paddingLeft: '5px', paddingRight: '5px'}} src={sellerDetails?.image ? sellerDetails?.image.path : '/assets/buyer/images/noImage.jpg'} className="profilePicDisplay profilePicSM" />
      <img style={{paddingLeft: '5px', paddingRight: '5px'}} src={sellerDetails?.seller?.shop?.image ? sellerDetails?.seller?.shop?.image.path : '/assets/buyer/images/noImage.jpg'} className="profilePicDisplay profilePicSM" />
     
      <h6>{sellerDetails?.name}</h6>
      <Button onClick={() => routeStartSelling('/')} className="startSellingButton" variant="outline-primary"><img src="/seller_icons/store_icon_v3.png" />Start Buying</Button>
      <hr />
      <div className="profileNavContainer">
        <Nav defaultActiveKey={window.location.pathname} className="flex-column profileNav">
          <Nav.Link onClick={showSubmenu}><img src="/seller_icons/user_icon.png" />My Account</Nav.Link>
          <div id="account-submenu" style={window.location.pathname == "/seller/account/profile" ? { paddingLeft: "30px" } : { display: "none", paddingLeft: "30px" }}>
            <Nav.Link as={Link} to="/seller/seller_profile">My Profile</Nav.Link>
            <Nav.Link as={Link} to="/seller/my_address" eventKey="link-2" >My Addresses</Nav.Link>
            <Nav.Link as={Link} to="/seller/shop_setting"eventKey="link-4" >Shop Settings</Nav.Link>
          </div>
          <Nav.Link as={Link} to="/seller/sales"><img src="/seller_icons/sales_icon.png" />My Sales</Nav.Link>
          <Nav.Link as={Link} to="/seller/products"><img src="/seller_icons/products_icon.png" />My Products</Nav.Link>
          <Nav.Link as={Link} eventKey="link-7" to="/seller/shipment_setting"><img src="/seller_icons/shipment_icon.png" />Shipment Settings</Nav.Link>
          <Nav.Link as={Link} to="/seller/earnings" eventKey="link-8"><img src="/seller_icons/earnings_icon.png" />Earnings</Nav.Link>
          <Nav.Link as={Link} to="/seller/shop_ratings"><img src="/seller_icons/shop_ratings_icon.png" />Shop Ratings</Nav.Link>
          {/* <Nav.Link eventKey="link-10"><img src="/seller_icons/customer_service_icon.png" />Customer Service</Nav.Link> */}
          <Nav.Link as={Link} to="/privacy_policy" eventKey="link-11"><img src="/seller_icons/privacy_policy_icon.png" />Privacy Policy</Nav.Link>
          <Nav.Link as={Link} to="/about_us" eventKey="link-3"><img src="/seller_icons/about_icon.png" />About ISN</Nav.Link>
        </Nav>
      </div>
    </React.Fragment>
  );
}

export default SellerMenu;
