export const apiURL = process.env.REACT_APP_ISN_SECRET_BASEURL + process.env.REACT_APP_VERSION || 'http://127.0.0.1:8000/v1/';
export const apiKey = process.env.API_KEY || "AIzaSyC1qoovY75zIVDCraGZ30AmexvzKkAWZUo"
export const authDomain = process.env.AUTH_DOMAIN || "idol-shopping-network-98424.firebaseapp.com"
export const databaseURL = process.env.DATABSE_URL || "https://idol-shopping-network-98424-default-rtdb.firebaseio.com"
export const projectId = process.env.PROJECT_ID || "idol-shopping-network-98424"
export const storageBucket = process.env.STORAGE_BUCKET || "idol-shopping-network-98424.appspot.com"
export const messagingSenderId = process.env.MESSAGING_SENDER_ID || "479195974674" 
export const appId = process.env.APP_ID || "1:479195974674:web:2ba1c0f1b801cc2bfd1ccb"
export const measurementId = process.env.MEASUREMENT_ID || "G-WLPY6NJZVW"
export const publicKey = process.env.PUBLIC_KEY || "BIrvQi3e6Q41yht2oZMHuKZ_wkO1fpaSSVGURV00E2s6g7DOIdr9zGSk7BymLlHCG6q8VXdwgZayOtPUmH87uw0"
export const INTERCOM = {
    APP_ID: 't9vi5jbr',
    API_BASE: 'https://api-iam.intercom.io',
    USER_HASH: 'ozHpd_rJ_EsdQYPST7TJ5zx0nw6GE73kSRa2x8LG',
};



