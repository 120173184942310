import React from 'react'
import { Row, Col } from 'react-bootstrap';

function NotificationItem(props) {
    const {
        notif,
    } = props;

    const renderText = () => {
        return notif?.data_message
    }

    const renderNotfied = () => {
        if (notif?.read_at == null) {
            return (
                <label>
                    <img src="/assets/buyer/icons/StatusCircleIcon.png" style={{ width: 12, marginLeft: 15, marginBottom: 3 }} className="img-fluid" />
                </label>
            )
        }
    }

    const renderType = () => {

        switch (notif?.type) {
            case 'App\\Notifications\\CancelOrderSeller':
                return 'Order requested to be cancelled by Seller';
                break;
            case 'App\\Notifications\\CancelOrderBuyer':
                return 'Order requested to be cancelled by Buyer';
                break;
            case 'App\\Notifications\\CancelledOrderSeller':
                return 'Order cancelled by Seller was approved';
                break;
            case 'App\\Notifications\\CancelledOrderBuyer':
                return 'Order cancelled by Buyer was approved';
                break;
            case 'App\\Notifications\\AddOrder':
                return 'Order Added';
                break;
            case 'App\\Notifications\\DeliveredOrder':
                return 'Order Successfully Delivered';
                break;
            case 'App\\Notifications\\ShopApproval':
                return 'Shop has been Approved';
                break;
            case 'App\\Notifications\\ProductApproval':
                return 'Product has been Approved';
                break;
            default:
                return notif?.type;
        }
    }

    return (
        <div>
            <Row>
                <Col md={3} xs={3} sm={3} lg={3}>
                    <img
                        src={notif?.data?.display_image} id="dropdown-basic" className="img-fluid" />
                </Col>
                <Col md={9}>
                   <Row>
                        <span
                            title={renderType()}
                            style={{ cursor: 'pointer', textDecoration: 'none' }}>
                            {renderType()}
                            {renderNotfied()}
                        </span>
                    </Row>
                    <Row>
                        <span style={{ fontSize: 13, fontWeight: 300 }}>{renderText()}</span>
                    </Row>
                </Col>
            </Row>

            <hr />
        </div >
    )
}

export default NotificationItem
