
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';
import { toast } from "react-toastify";

const initialState = {
    FetchAddresses : [],
    FetchAddressesSellerList : [],
    NewAddressesList : {},
    NewAddressesListSeller : [],
    UpdateAddressesList : [], 
    RegionList : [],
    ProvinceList : [],
    CityList : [],
    BarangayList : [],
    isLoading: false,
}

export const getAddressDetail = createAsyncThunk(
    'view/viewAddress',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.get(`/buyer/address/${_.id}`);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }

    }
)

export const newAddressesSeller = createAsyncThunk(
    'add/newAddressSeller',
    async (_, {rejectWithValue, dispatch}) => {
        const addAddress = {
            region_id: _.region_id,
            province_id: _.province_id,
            city_id: _.city_id,
            barangay_id: _.barangay_id,
            is_pickup: _.default_address ? 1 : 0,
            is_return: _.default_address ? 1 : 0,
            postal_code: String(_.postal_code),
            contact_no: String(_.phone_number),
            name: _.full_name,
            landmark: _.landmark,
            detailed_address: _.detailed_address,
            is_default: 0,
        }
        try {
            const {data} = await api.post('/seller/address/',addAddress);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const newAddresses = createAsyncThunk(
    'add/newAddress',
    async (_, {rejectWithValue, dispatch}) => {
        const addAddress = {
            region_id: _.region_id,
            province_id: _.province_id,
            city_id: _.city_id,
            barangay_id: _.barangay_id,
            is_default: _.default_address ? 1 : 0,
            postal_code: String(_.postal_code),
            contact_no: String(_.phone_number),
            name: _.full_name,
            landmark: _.landmark,
            detailed_address: _.detailed_address
        }
        try {
            const {data} = await api.post('/buyer/address/',addAddress);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const getAddressSeller = createAsyncThunk(
    'fetch/events',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const data = await api.get('/seller/addresses');
        return data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
)

export const updateAddressesSeller = createAsyncThunk(
    'update/updateAddressSeller',
    async (_, {rejectWithValue, dispatch}) => {
        const id = _.values.id
        const updateAddress = {
            region_id: _.values.region_id,
            province_id: _.values.province_id,
            city_id: _.values.city_id,
            barangay_id: _.values.barangay_id,
            is_pickup: _.values.default_address ? 1 : 0,
            is_return: _.values.default_address ? 1 : 0,
            postal_code: String(_.values.postal_code),
            contact_no: String(_.values.phone_number),
            name: _.values.full_name,
            landmark: _.values.landmark,
            detailed_address: _.values.detailed_address,
            is_default: 0,
        }
        console.log(updateAddress)
        try {
            const {data} = await api.post(`/seller/address/${id}`, updateAddress);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const updateAddresses = createAsyncThunk(
    'update/updateAddress',
    async (_, {rejectWithValue, dispatch}) => {
        const id = _.id
        const updateAddress = {
            region_id: _.region_id,
            province_id: _.province_id,
            city_id: _.city_id,
            barangay_id: _.barangay_id,
            is_default: _.default_address ? 1 : 0,
            postal_code: String(_.postal_code),
            contact_no: String(_.phone_number),
            name: _.full_name,
            landmark: _.landmark,
            detailed_address: _.detailed_address
        }
        try {
            const {data} = await api.post(`/buyer/address/${id}`, updateAddress);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const updateDefaultAddresses = createAsyncThunk(
    'update/updateDefaultAddress',
    async (_, {rejectWithValue, dispatch}) => {
        const id = _.id
        const updateDefaultAddress = {
            is_default: _.default_address ? 1 : 0,
        }
        try {
            const {data} = await api.post(`/buyer/address/default/${id}`, updateDefaultAddress);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)


export const deleteAddressesSeller = createAsyncThunk(
    'delete/deleteAddressSeller',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.delete(`/seller/address/${_}`);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const deleteAddresses = createAsyncThunk(
    'delete/deleteAddress',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.delete(`/buyer/address/${_}`);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const getRegion = createAsyncThunk(
    'fetch/regions',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const {data} = await api.get('/regions');
        return data;
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
)

export const getProvince = createAsyncThunk(
    'fetch/province',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.get(`/provinces/${_}`);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const getCity = createAsyncThunk(
    'fetch/city',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.get(`/cities/${_}`);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const getBarangay = createAsyncThunk(
    'fetch/barangay',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await api.get(`/barangays/${_}`);
            return data;
        } catch (err) {
            if (!err.response) {
                throw err
            }
        }
    }
)

export const addressSlice = createSlice({
    name:"address",
    initialState: initialState,
    extraReducers: (builder) => {

        builder.addCase(updateAddressesSeller.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(updateAddressesSeller.fulfilled, (state, action) => {
            toast.success('Successfully Updated Address', {
                position: "bottom-left",
            });
            state.isLoading = false
        })
        builder.addCase(updateAddressesSeller.rejected, (state, action) => {
            state.isLoading = true
        })
        
        builder.addCase(getAddressSeller.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getAddressSeller.fulfilled, (state, action) => {
            let list = action.payload?.data?.data
            state.FetchAddressesSellerList = list
            state.isLoading = false
        })
        builder.addCase(getAddressSeller.rejected, (state, action) => {
            state.isLoading = true
        })

        builder.addCase(deleteAddressesSeller.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(deleteAddressesSeller.fulfilled, (state, action) => {
            let list = action.payload
            state.isLoading = false
            toast.success('Successfully Deleted Address', {
                position: "bottom-left",
            });
        })
        builder.addCase(deleteAddressesSeller.rejected, (state, action) => {
            state.isLoading = true
            toast.success('Rejected request Delete Address', {
                position: "bottom-left",
            });
        })

        builder.addCase(deleteAddresses.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(deleteAddresses.fulfilled, (state, action) => {
            let list = action.payload
            state.isLoading = false
            toast.success('Successfully Deleted Address', {
                position: "bottom-left",
            });
        })
        builder.addCase(deleteAddresses.rejected, (state, action) => {
            state.isLoading = true
            toast.success('Rejected request Delete Address', {
                position: "bottom-left",
            });
        })

        builder.addCase(updateDefaultAddresses.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(updateDefaultAddresses.fulfilled, (state, action) => {
            let list = action.payload
            state.FetchAddresses = list
            state.isLoading = false
            toast.success('Successfully Change Default Address', {
                position: "bottom-left",
            });
            
        })
        builder.addCase(updateDefaultAddresses.rejected, (state, action) => {
            state.isLoading = false
            state.FetchAddresses = []
            toast.error('Rejected request Change Default Address', {
                position: "bottom-left",
            });
        })

        builder.addCase(getAddressDetail.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getAddressDetail.fulfilled, (state, action) => {
            let list = action.payload
            state.FetchAddresses = list
            state.isLoading = false
        })
        builder.addCase(getAddressDetail.rejected, (state, action) => {
            state.isLoading = false
            state.FetchAddresses = []
        })

        builder.addCase(newAddressesSeller.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(newAddressesSeller.fulfilled, (state, action) => {
            let list = action.payload
            state.NewAddressesListSeller = list
            state.isLoading = false
            toast.success('Successfully Added Address', {
                position: "bottom-left",
            });
        })
        builder.addCase(newAddressesSeller.rejected, (state, action) => {
            state.isLoading = false
            state.NewAddressesListSeller = []
            toast.error('Rejected request Add Address', {
                position: "bottom-left",
            });
        })

        builder.addCase(newAddresses.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(newAddresses.fulfilled, (state, action) => {
            let list = action.payload
            state.NewAddressesList = list
            state.isLoading = false
            toast.success('Successfully Added Address', {
                position: "bottom-left",
            });
        })
        builder.addCase(newAddresses.rejected, (state, action) => {
            state.isLoading = false
            state.NewAddressesList = {}
            toast.error('Rejected request Add Address', {
                position: "bottom-left",
            });
        })

        builder.addCase(updateAddresses.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(updateAddresses.fulfilled, (state, action) => {
            let list = action.payload
            state.UpdateAddressesList = list
            state.isLoading = false
            toast.success('Successfully Updated Address', {
                position: "bottom-left",
            });
        })
        builder.addCase(updateAddresses.rejected, (state, action) => {
            state.isLoading = false
            state.UpdateAddressesList = []
            toast.error('Rejected request Update Address', {
                position: "bottom-left",
            });
        })

        builder.addCase(getRegion.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getRegion.fulfilled, (state, action) => {
            let list = action.payload.data
            state.RegionList = list
            state.isLoading = false
        })
        builder.addCase(getRegion.rejected, (state, action) => {
            state.isLoading = false
            state.RegionList = []
        })

        builder.addCase(getProvince.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getProvince.fulfilled, (state, action) => {
            let list = action.payload.data
            state.ProvinceList = list
            state.isLoading = false
        })
        builder.addCase(getProvince.rejected, (state, action) => {
            state.isLoading = false
            state.ProvinceList = []
        })

        builder.addCase(getCity.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getCity.fulfilled, (state, action) => {
            let list = action.payload.data
            state.CityList = list
            state.isLoading = false
        })
        builder.addCase(getCity.rejected, (state, action) => {
            state.isLoading = false
            state.CityList = []
        })

        builder.addCase(getBarangay.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(getBarangay.fulfilled, (state, action) => {
            let list = action.payload.data
            state.BarangayList = list
            state.isLoading = false
        })
        builder.addCase(getBarangay.rejected, (state, action) => {
            state.isLoading = false
            state.BarangayList = []
        })
    }
})

// export const selectWebAds = state => state.ads.adsList;

export default addressSlice.reducer;