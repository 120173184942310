import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';
import {cloneDeep } from 'lodash';
import qs from 'qs';

const initialState = {
  isLoading: false,
  orderList:[],
  toPayOrderList: [],
  toShipOrderList: [],
  toReceiveOrderList: [],
  completedOrderList: [],
  cancelledOrderList: [],
  returnedOrderList: [],
  orderDetails:{},
}

export const fetchPurchase = createAsyncThunk(
    'buyer/my_purchase',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.get(`/buyer/my_orders`)
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )

  export const fetchOrderById = createAsyncThunk(
    'users/fetchById',
    async (userId, { getState, requestId }) => {
      const { currentRequestId, loading } = getState().purchase
      if (loading !== 'pending' || requestId !== currentRequestId) {
        return userId;
      }
      const response = await api.get(`/buyer/order/${userId}`)
      return response.data
    },
  )
  
  export const rateAndReview = createAsyncThunk(
    'buyer/rate-and-review',
    async ({
        productId, 
        review,
        rating },{rejectWithValue, dispatch}) => {
      try {
        const response = await api.post(`/buyer/review/product/${productId}`,
        {
          review,
          rating 
        })
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )


  export const cancelRequest = createAsyncThunk(
    'buyer/cancel-request',
    async ({
        orderId, 
        reason,
        email },{rejectWithValue, dispatch}) => {
      try {
        const response = await api.post(`/buyer/order/cancel/${orderId}`,
        {
          reason,
          email,
        })
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )


  export const returnRefundRequest = createAsyncThunk(
    'buyer/return-request',
    async ({
        orderId, 
        reason,
        email },{rejectWithValue, dispatch}) => {
      try {
        const response = await api.post(`/buyer/order/return/${orderId}`,
        {
          reason,
          email,
        })
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )





export const PurchaseSlice = createSlice({
    name:"purchase",
    initialState: initialState,
    extraReducers: (builder) => {

              builder.addCase(fetchPurchase.pending, (state, action) => {
                  state.isLoading = true
              })
              builder.addCase(fetchPurchase.fulfilled, (state, action) => {
                const orders =  action.payload || []
                state.orderList = orders
                state.toPayOrderList = orders.filter((order)=>order.status === "unpaid") || []

                state.toShipOrderList = orders.filter((order)=>order.status === "to_ship" || order.status === "for_pickup")
                state.toReceiveOrderList = orders.filter((order)=>order.status === "shipping") || []
                state.completedOrderList = orders.filter((order)=>order.status === "completed") || []
                state.cancelledOrderList = orders.filter((order)=>order.status === "cancelled_buyer" || order.status === "cancelled_seller") || []
                state.returnedOrderList = orders.filter((order)=>order.status === "returned") || []
                state.isLoading = false
              })
              builder.addCase(fetchPurchase.rejected, (state, action) => {
                  state.isLoading = false
              })  




            //Fetch Order by id in state
            builder.addCase(fetchOrderById.pending, (state, action) => {
                state.isLoading = true
            })
            builder.addCase(fetchOrderById.fulfilled, (state, action) => {
              const orderListClone = cloneDeep(state.orderList);
              const orderData = orderListClone.filter((order)=>order.id === action.payload)
              state.orderDetails = orderData[0] || {}
              state.isLoading = false
            })
            builder.addCase(fetchOrderById.rejected, (state, action) => {
                state.isLoading = false
            })




            //Rate and Review per product
            builder.addCase(rateAndReview.pending, (state, action) => {
                state.isLoading = true
            })
            builder.addCase(rateAndReview.fulfilled, (state, action) => {
              state.isLoading = false
            })
            builder.addCase(rateAndReview.rejected, (state, action) => {
                state.isLoading = false
            })

    }
})



export default PurchaseSlice.reducer;
