import React, { useEffect } from 'react';
import { Container, Row, Col, Nav, Button,Accordion } from "react-bootstrap";
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import NoOrder from '../../../components/service/NoOrder';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {confirmPickup, generateWaybill, fetchSales} from '../../../features/SalesSlice'
import OrderItem from "../../../components/common/OrderItem"
import { saveAs } from 'file-saver';
import { propTypes } from 'react-bootstrap/esm/Image';


function SalesShip({toShipOrderList}) {
        const dispatch = useDispatch();
        const { isLoading} = useSelector((state) => state.sales);
        

        const ColoredLine = () => (
                <hr
                    style={{
                        height: 1,
                    }}
                />
            )
        
        const handleConfirm = (id)=>{
                if(id){
                        dispatch(confirmPickup({
                                orderId:id,         
                        })).then((resp) => {
                                console.log(resp, 'yo')
                                dispatch(fetchSales())
                        })
                }
                
        }

        const checkDisabled = (order) => {
                console.log(order.shop?.seller?.seller_pickup_address)
                return order.shop?.seller?.seller_pickup_address != null ? false : true;
        }

        const generatePdf = (order) => {
                dispatch(generateWaybill({
                        orderId:order.id,
                })).then((response) => {
                        saveAs(new Blob([response.payload.data], {type: "application/pdf"}), order.tracking_number+'.pdf')
                        
                        // console.log(response)
                        // saveAs(response.data, "Test.pdf");
                })
        }

        return (
        <React.Fragment>
                <div id="to_ship_container">
                       {
                               toShipOrderList && toShipOrderList.length === 0 ?
                                <NoOrder/> : 
                        toShipOrderList.length !== 0 && toShipOrderList.map((order)=>(
                              
                                <div className="productPreviewContainer" key={order.id}>
                                        <React.Fragment>
                                                        <Container className="salesShopName">
                                                        <Row>
                                                                <Col xs={6} style={{textAlign: "left"}}>
                                                                        <img 
                                                                        alt='store_icon'
                                                                        src="/seller_icons/store_icon_1.png"
                                                                        style={{height: "17px", marginRight: "10px"}} />

                                                                        {order?.shop?.name || ""}
                                                                </Col>
                                                                <Col xs={6} style={{ textAlign: "right" }}>
                                                                        <Nav.Link as={Link} to={`/seller_order_details/${order.id}`}
                                                                        style={{
                                                                                color:'#161F92', 
                                                                                cursor:'pointer'}}> 
                                                                                View &nbsp; | &nbsp;
                                                                                <label 
                                                                                style={{
                                                                                color:'#535353', 
                                                                                fontWeight:300}}
                                                                                >ORDER ID# {order.rc_id}
                                                                                </label>
                                                                        </Nav.Link> 
                                                                </Col>
                                                        </Row>
                                                        </Container>
                                                        
                                                        </React.Fragment>
                                                        
                                                        <Container 
                                                        className="salesProductPreview" 
                                                        >
                                                               
                                                                {order.order_items.length !== 0 && order.order_items.map((item)=>(
                                                                       <OrderItem 
                                                                            key={item.id}
                                                                            item={item} 
                                                                       />
                                                                ))}
                                     
                                                <Accordion>
                                                <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                <Col md="10">
                                                        <span 
                                                                style={{
                                                                fontWeight:"bold"
                                                                }}>
                                                        
                                                        {`Transaction ID   #${order?.transaction_id}`}
                                                        </span>
                                                </Col>
                                                <Col>
                                                        <span className="float-right"
                                                                style={{
                                                                        color:'#3E6AE3',
                                                                        fontWeight:'bold',
                                                                }}>{order.status === 'for_pickup'?'Pick-Up Details':'Confirm Pick-Up'}
                                                        </span>
                                                </Col>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                        <div className="transactionShipContainer">
                                                                {order && order.status === "for_pickup" && 
                                                                order.delivery_details && order.delivery_details.length >0 ?
                                                                        <>
                                                                        <span>{`Products will be picked up on ${moment(order.delivery_details.created_at).add(1, 'd').format('LL')}`}</span>
                                                                        <ColoredLine/>
                                                                        </>
                                                                :null
                                                                }
                                                                
                                                                
                                                                <div>
                                                                        <label 
                                                                        style={{
                                                                                fontWeight:'bold'
                                                                        }}>Courier</label>
                                                                        <div className="m-2">{`${order.shipping_method?.name || ""} will collect your parcel from your pickup address.`} </div>
                                                                </div>
                                                                <ColoredLine/>
                                                                {order && order.status === "for_pickup" && order.tracking_number?
                                                                <>
                                                                        <div className="float-end">
                                                                                <Button className="rounded" onClick={() => generatePdf(order)}>Download Waybill</Button>
                                                                        </div>
                                                                        
                                                                        <div>
                                                                                
                                                                                <label 
                                                                                style={{
                                                                                        fontWeight:'bold'
                                                                                }}>Tracking #</label>
                                                                                <div className="mt-2">{order.tracking_number}</div>
                                                                                <ColoredLine/>
                                                                        </div>
                                                                </>
                                                                :null
                                                                }
                                                                  
                                                                <div 
                                                                        style={{
                                                                                marginBottom:30
                                                                        }}
                                                                >
                                                                        <label 
                                                                                style={{
                                                                                        fontWeight:'bold'
                                                                                }}>Pickup Address
                                                                        </label>
                                                                        <div>
                                                                                {
                                                                                        order.shop?.seller?.seller_pickup_address?
                                                                                        <>
                                                                                                <div className="mt-2">{order.shop?.seller?.seller_pickup_address?.name || ""}</div>
                                                                                                <div className="mt-2"> { order.shop?.seller?.seller_pickup_address?.contact_no || '' }</div>
                                                                                                {
                                                                                                        order.shop?.seller?.seller_pickup_address?.barangay && 
                                                                                                        order.shop?.seller?.seller_pickup_address.city && 
                                                                                                        order.shop?.seller?.seller_pickup_address?.province ?
                                                                                                        
                                                                                                        <>
                                                                                                                <div className="mt-2">
                                                                                                                {`${order.shop?.seller?.seller_pickup_address?.address_1}, 
                                                                                                                ${order.shop?.seller?.seller_pickup_address?.barangay?.description}
                                                                                                                , ${order.shop?.seller?.seller_pickup_address.city?.description},
                                                                                                                `||""}
                                                                                                                </div> 
                                                                                                                <div className="mt-2">
                                                                                                                {
                                                                                                                        `${order.shop?.seller?.seller_pickup_address?.province?.description}, ${order.shop?.seller?.seller_pickup_address?.province?.code} `
                                                                                                                }
                                                                                                                </div>  
                                                                                                        </>
                                                                                                        :
                                                                                                        
                                                                                                        null 
                                                                                                }
                                                                                        </>

                                                                                                :

                                                                                        <>
                                                                                                <span class="text-danger">You have not yet added and/or selected an address, please go to My Addresses</span>
                                                                                        </>
                                                                                        
                                                                                }
                                                                                
                                                                        </div>
                                                                
                                                                        {order && order.status === "to_ship"?
                                                                                <Button 
                                                                                        type="primary"
                                                                                        disabled={!isLoading && checkDisabled(order)}
                                                                                        style={{
                                                                                        fontSize:12,
                                                                                        float:'right',
                                                                                        width:140,
                                                                                        padding:'0 10',
                                                                                        borderRadius:'20%',
                                                                                        height:30
                                                                                                }}
                                                                                        onClick={()=>handleConfirm(order.id)}
                                                                                >
                                                                                Confirm
                                                                                </Button>
                                                                        :
                                                                        null      
                                                                        }
                                                                        
                                                                </div>
                                                        </div>
                                                        </Accordion.Body>
                                                </Accordion.Item>
                                                </Accordion>
                                                        </Container>
                                                                        
                                </div>
                                )
                        )   }        
                        {/* } */}
                        </div>
        </React.Fragment>
            );
    }
    
    export default SalesShip;