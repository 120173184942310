import React,{useEffect, useState} from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainProductAds from '../../common/Ads/MainProductAds';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img onClick={onClick} className={className} src="/assets/buyer/icons/NextArrow2.png" style={{marginTop:10, width:8.5, marginRight:20}}/>
    );
  }
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img onClick={onClick} className={className} src="/assets/buyer/icons/LeftArrow2.png" style={{ marginTop:10, width:8.5, marginLeft:20}}/>
    );
}

function ProductZoom(props) {
    const [DefaultZoomImage, setDefaultZoomImage] = useState('');
    const [ListProductImage, setListProductImage] = useState([])

    const checkImageOption = (result) => {
        try{
            if(result.images){
                const image = result.images[0]
                if(image){
                    if(image.path){
                        return true
                    }
                }
            }
            return false  
        } catch(e) {
            return false
        }
        
    }

    const checkImageProductView = (result) => {
        try{
            if(result.data){
                return false
            }else{
                if(result.varieties &&  result.varieties[0]){
                    const varieties = result.varieties[0]
                    if(varieties.images){
                        const images = varieties.images[0]
                        if(images.path){
                            return true
                        }
                    }
                }
                return false
            }
        } catch(e) {
            console.log(e)
            return false 
        }
        
    }

    useEffect(() => {
        if(props.Options) {
            if(checkImageOption(props.Options)){
                setDefaultZoomImage(props.Options.images[0].path)
                const collect_image = []
                props.Options.images.map((res_path)=>{
                    collect_image.push({
                        'path': res_path.path
                    })
                })
                setListProductImage(collect_image)
            }
        }
    }, [props.Options])

    useEffect(() => {
            const res = props.ProductViewDetails
            let collect_image = []

            if(checkImageProductView(res)){
                setDefaultZoomImage(res.varieties[0].images[0].path)
                res.varieties[0].images.map((res_path)=>{
                    collect_image.push({
                        'path': res_path.path
                    })
                })

                setListProductImage(res.varieties[0]?.images ?? [])
            } else{
                setDefaultZoomImage('/assets/buyer/images/noImage.jpg')
            }
    }, [props.ProductViewDetails])
    
    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 300,
        rows: 1,
        slidesPerRow: 4,
        dots:false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const handleChangeZoomData = (path) => {
        setDefaultZoomImage(path);
    }

    return (
        <>
            <Row className="d-flex justify-content-center align-items-center">
                <img
                    src={DefaultZoomImage}
                    alt=""
                    style={{width:'100%'}}
                    className="hover-zoom"
                />
            </Row>
                
            <Row>
                <MainProductAds/>
            </Row>
                
                <Slider style={{paddingTop:20, margin: '0 auto'}} {...settings}>
                    {
                        ListProductImage.map((data,index) => {
                            return (
                                <div key={index}  
                                className="sliderZoomData">
                                  
                                    <img  style={{
                                    cursor:'pointer'
                                }} className="img-fluid" onClick={() => handleChangeZoomData(data.path)} src={data.path} style={{padding:5}} /> 
                                          
                                </div>
                            )
                        })
                    }
                </Slider>
        </>
    )

}

export default ProductZoom
