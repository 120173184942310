import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Button, Form,  } from "react-bootstrap";
import { checkResolution } from '../../../helper/image-helper'
import { Field } from 'formik';

const VarietyContainer = (props) =>{

    const { 
        optionOne,
        optionTwo,
        var_count,
        varData,
        index,
        values,
        setValues,
        removeVariety,
        setFieldValue,
        ticketErrors,
        ticketTouched
    } = props

        const [imageFiles, setImageFiles] = useState([])
        
        useEffect(() => {
            if (typeof optionTwo[varData.specs2_option] == 'undefined' ) {
                setFieldValue('varieties['+index+'].specs2_option', 0)
            }
            
        },[optionTwo])

        useEffect(() => {
            if (typeof optionOne[varData.specs1_option] == 'undefined' ) {
                setFieldValue('varieties['+index+'].specs1_option', 0)
            }
            
        },[optionOne])

        const imageUpload = async (e)=>{
            const {files} = e.target
            const fileArray = []

            for (let i = 0; i < files.length; i++) {
                const image = await checkResolution(files[i]);

                fileArray.push(
                {
                    image:image,
                    image_path: URL.createObjectURL(image),
                    image_name:image.name
                })
                   
            }

            setImageFiles([...fileArray])
            setFieldValue('varieties['+index+'].img', [...files])
        }

          return (
            <Container style={{padding: "30px", background: "white", marginBottom: "20px"}}>
                <Row style={{marginBottom: "15px"}}>
                    <Col xs={2} style={{textAlign: "left"}}>
                        <h5 id={'variation_'+var_count}>Variety {var_count}</h5>
                        <Field as='select'
                        className="form-control"
                            name={`varieties.${index}.specs1_option`}
                            size="sm"
                            >
                            {
                                varData ? 
                            <>
                                {
                                    optionOne && optionOne.map((option,i)=>{
                                        if(i === parseInt(varData.specs1_option)){
                                            return(<option selected={true} value={i}>{option.label}</option>)
                                        } else {
                                            return(<option value={i}>{option.label}</option>)
                                        }
                                    })
                                } 
                            </> 
                            : 
                            <>
                                {
                                    optionOne && optionOne.map((option,i)=>{
                                        return(<option value={i}>{option.label}</option>)
                                })
                                } 
                            </> 
                            }
                        </Field>
                    </Col>
                    <Col style={{textAlign: "center"}}>
                        <h7 id={'variation_'+var_count}>Wt (kg)</h7>
                        <Field as="input"
                            data-attr="wt"
                            className={ticketTouched.wt && ticketErrors.wt ? 'error form-control' : 'form-control' }
                            name={`varieties.${index}.wt`}
                            initialValue={varData && varData.wt}
                            style={{
                                background: "#F4F4F4" ,
                                marginTop:8}}/>
                            {
                                ticketErrors.wt &&
                                <small className="text-danger">{ticketErrors.wt}</small>
                            }

                    </Col>
                    <Col style={{textAlign: "center"}}>
                        <h7 id={'variation_'+var_count}>L (in)</h7>
                        <Field as="input" 
                            className={ticketTouched.l && ticketErrors.l ? 'error form-control' : 'form-control' }
                            type="text"
                            name={`varieties.${index}.l`}
                            initialValue={varData && varData.l}
                            style={{
                                background: "#F4F4F4" ,
                                marginTop:8}}
                                />
                            {
                                ticketErrors.l &&
                                <small className="text-danger">{ticketErrors.l}</small>
                            }
                    </Col>
                    <Col style={{textAlign: "center"}}>
                        <h7 id={'variation_'+var_count}>H (in)</h7>
                        <Field as="input" 
                            className={ticketTouched.h && ticketErrors.h ? "error form-control" : "form-control"}
                            type="text" 
                            name={`varieties.${index}.h`}
                            initialValue={varData && varData.h}
                            style={{
                                background: "#F4F4F4" ,
                                marginTop:8
                                }}/>
                            {
                                ticketErrors.h &&
                                <small className="text-danger">{ticketErrors.h}</small>
                            }
                    </Col>
                    <Col style={{textAlign: "center"}}>
                        <h7 id={'variation_'+var_count}>W (in)</h7>
                        <Field as="input" 
                            className={ticketTouched.w && ticketErrors.w ? "error form-control" : "form-control"}
                            type="text"
                            name={`varieties.${index}.w`}
                            initialValue={varData && varData.w}
                            style={{
                                background: "#F4F4F4" ,
                                marginTop:8
                                }}/>
                            {
                                ticketErrors.w &&
                                <small className="text-danger">{ticketErrors.w}</small>
                            }
                    </Col>
                    <Col style={{textAlign: "center"}}>
                        <h7 id={'variation_'+var_count}>Stock</h7>
                        <Field as="input" 
                            className={ticketTouched.stocks && ticketErrors.stocks ? "error form-control" : "form-control"}
                            type="text"
                            name={`varieties.${index}.stocks`}
                            initialValue={varData && varData.stocks}
                            style={{
                                background: "#F4F4F4" ,
                                marginTop:8
                                }}/>
                            {
                                ticketErrors.stocks &&
                                <small className="text-danger">{ticketErrors.stocks}</small>
                            }
                    </Col>
                    <Col style={{textAlign: "center"}}>
                        <h7 id={'variation_'+var_count}>Price</h7>
                        <Field as="input" 
                            className={ticketTouched.price && ticketErrors.price ? "error form-control" : "form-control"}
                            type="text"
                            name={`varieties.${index}.price`}
                            initialValue={varData && varData.price}
                            style={{
                                background: "#F4F4F4" ,
                                marginTop:8
                                }}/>
                            {
                                ticketErrors.price &&
                                <small className="text-danger">{ticketErrors.price}</small>
                            }
                    </Col>
                    <Col style={{textAlign: "center"}}>
                        <h7 id={'variation_'+var_count}>Discounted &#8369; </h7>
                        <Field as="input" 
                            className={ticketTouched.discounted_price && ticketErrors.discounted_price ? "error form-control" : "form-control"}
                            type="text"
                            name={`varieties.${index}.discounted_price`}
                            initialValue={varData && varData.discounted}
                            style={{
                                background: "#F4F4F4" ,
                                marginTop:8
                                }}/>
                            {
                                ticketErrors.discounted_price &&
                                <small className="text-danger">{ticketErrors.discounted_price}</small>
                            }
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} style={{marginTop:10}}>
                    <Field as="select" 
                    className="form-control"
                    name={`varieties.${index}.specs2_option`}
                        >
                        {
                            varData?
                        <>
                            {
                                optionTwo && optionTwo.map((option, index)=>{
                                if(index === parseInt(varData.specs2_option)){
                                    return(<option selected={true} value={index}>{option.label}</option>)
                                }else{
                                    return(<option value={index}>{option.label}</option>)
                                }
                                
                            })
                            } 
                        </> 
                        : 
                        <>
                            
                            {
                                optionTwo && optionTwo.map((option,index)=>{
                                    return(<option value={index}>{option.label}</option>)
                            })
                            } 
                        </> 
                        }
                    </Field>
                    
                    </Col>
                    <Col xs={2}
                        style={{
                            marginTop:10,
                        }}>
                            <div style={{
                                    display:' flex',
                                    alignItems:'center',
                                    justifyContent:'center'
                                }}
                                className="image-upload">
                                    <label className={(ticketTouched.img || ticketErrors.img ? "error-dashed " : " ") + "label_variety_btn d-flex justify-content-center align-items-center"} for={"variety_btn_"+var_count}>
                                        <img alt='seller' src="/seller_icons/add_icon.png" 
                                        style={{
                                            position:'absolute',
                                        }} onChange={imageUpload}
                                    />
                                    </label>
                                    <input
                                    multiple
                                    id={"variety_btn_"+var_count}
                                    type="file"
                                    onChange={imageUpload}
                                    />
                                </div>
                            {
                                ticketErrors && ticketErrors.img &&
                                <small className="text-danger">{ticketErrors.img}</small>
                            }  
                            <p style={{marginTop:3}}>Add Photo</p> 
                             
                            <p style={{
                                width: '282px',
                                position: 'relative',
                                right: '172px',
                                fontSize: '12px'
                            }}>Note: Preferred photo size is 500x500px</p>                                  
                    </Col>

                    {
                        imageFiles.map((file)=>{
                            return (
                                <Col xs={2} style={{marginTop:10,}}>
                                    <div style={{
                                            display:' flex',
                                            alignItems:'center',
                                            justifyContent:'center'
                                        }}>
                                            <img alt="products" src={file.image_path} style={{ height:120, width:120}}/>
                                    </div>
                                    <p style={{marginTop:3,fontSize:12}}>{file.image_name}</p>                                       
                                </Col>
                        )
                    })
                    }

                    {
                        imageFiles.length < 1 &&
                            varData.defaultImages?.map((file)=>{
                            return(
                                <Col xs={2}
                                        style={{
                                            marginTop:10,
                                        }}>
                                            <div style={{
                                                    display:' flex',
                                                    alignItems:'center',
                                                    justifyContent:'center'
                                                }}>
                                                    <img alt="products" src={file.path}
                                                        style={{
                                                            height:120,
                                                            width:120
                                                        }}
                                                    />

                                            </div>                         
                                    </Col>
                                )
                            })
                    }
                   
                            <Col
                                    style={{
                                        marginTop:10,
                                    }}>
                                        <div style={{
                                                 float:"right",
                                            }}>
                                            <Button 
                                                style={{
                                                    border:"none",
                                                    background:"none"
                                                    }}
                                                onClick={()=>removeVariety(index, values, setValues, setFieldValue)}
                                                    >
                                                
                                                <img alt="products"  src="/seller_icons/trash_bin.png"
                                                    style={{
                                                        height:25,
                                                        width:25,
                                                    }}
                                                />
                                            </Button>

                                        </div>                           
                                </Col>
                </Row>    
            </Container>
            
            )
}

export default VarietyContainer;
