import React,{useEffect} from 'react'
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({component: Component, ...rest}) => {

    let {isLoggedIn} = useSelector(state => state.user);
    useEffect(() => {
    },[isLoggedIn])

    return (
        <Route
          {...rest}
          render={(props) => {
            if(isLoggedIn) {
                return  <Component {...props}  />;
            }else {
                return <Redirect to={{ pathname: '/login', state: { from: props.location} }} />
            }
          }}
        />
    );
}

export default ProtectedRoute

