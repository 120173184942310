import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';
import {cloneDeep } from 'lodash';

const initialState = {
  isLoading: false,
  orderList:[],
  unpaidOrderList: [],
  toShipOrderList: [],
  shippingOrderList: [],
  completedOrderList: [],
  cancelledOrderList: [],
  returnedOrderList: [],
  orderDetails:{},
}

export const fetchSales = createAsyncThunk(
    'seller/sales',
    async (_, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.get(`/seller/orders`)
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )


  export const updateStatus = createAsyncThunk(
    'seller/sales/id',
    async ({id,status}, {rejectWithValue, dispatch}) => {
      try {
        const response = await api.put(`/seller/order/${id}`,
        { status }
        )
        return response.data.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )

  export const fetchOrderById = createAsyncThunk(
    'users/fetchById',
    async (userId, { getState, requestId }) => {
      const { currentRequestId, loading } = getState().purchase
      if (loading !== 'pending' || requestId !== currentRequestId) {
        return userId;
      }
      const response = await api.get(`/seller/order/${userId}`)
      return response.data
    },
  )

  export const cancelRequest = createAsyncThunk(
    'buyer/cancel-request',
      async ({
          orderId, 
          reason,
          email },{rejectWithValue, dispatch}) => {
        try {
          const response = await api.post(`/seller/order/cancel/${orderId}`,
          {
            reason,
            email,
          })
          return response.data
        } catch (err) {
          if (!err.response) {
            throw err
          }
        }
      }
  )

  export const generateWaybill = createAsyncThunk(
    'seller/generate-waybill',
      async ({
        orderId
      },{rejectWithValue, dispatch}) => {
        try {
          const response = await api.get(`/seller/order/waybill/${orderId}`, {
            responseType: 'blob'
          })
          return response
        } catch (err) {
          if (!err.response) {
            throw err
          }
        }
      }
  )

  export const confirmPickup = createAsyncThunk(
      'buyer/confirm-pickup',
      async ({
          orderId
        },{rejectWithValue, dispatch}) => {
        try {
          const response = await api.post(`/seller/order/pickup/${orderId}`)
          return response.data
        } catch (err) {
          if (!err.response) {
            throw err
          }
        }
      }
  )

 



export const SalesSlice = createSlice({
    name:"sales",
    initialState: initialState,
    extraReducers: (builder) => {

              builder.addCase(fetchSales.pending, (state, action) => {
                  state.isLoading = true
              })
              builder.addCase(fetchSales.fulfilled, (state, action) => {
                const orders =  action.payload || []
                state.orderList = orders
                state.unpaidOrderList = orders.filter((order)=>order.status === "unpaid") || []
                state.toShipOrderList = orders.filter((order)=>order.status === "to_ship" || order.status === "for_pickup") || []
                state.shippingOrderList = orders.filter((order)=>order.status === "shipping") || []
                state.completedOrderList = orders.filter((order)=>order.status === "completed") || []
                state.cancelledOrderList = orders.filter((order)=>order.status === "cancelled_seller" || order.status === "cancelled_buyer" || order.status === "Cancelled") || []
                state.returnedOrderList = orders.filter((order)=>order.status === "returned") || []
                state.isLoading = false
              })
              builder.addCase(fetchSales.rejected, (state, action) => {
                  state.isLoading = false
              })  




            //Confirm Pick-up
            builder.addCase(confirmPickup.pending, (state, action) => {
                state.isLoading = true
            })
            builder.addCase(confirmPickup.fulfilled, (state, action) => {
              const orders =  action.payload || {}
              const toShipOrderListClone = cloneDeep(state.toShipOrderList);
                const index = toShipOrderListClone.findIndex(
                  (item) => item.id === orders.id
                );
                if(index < 0 ) return
                state.toShipOrderList[index].status = "for_pickup" ;
                state.isLoading = false
            })
            builder.addCase(confirmPickup.rejected, (state, action) => {
                state.isLoading = false
            })  



          //Fetch Order by id in state
          builder.addCase(fetchOrderById.pending, (state, action) => {
              state.isLoading = true
          })
          builder.addCase(fetchOrderById.fulfilled, (state, action) => {
            const orderListClone = cloneDeep(state.orderList);
            const orderData = orderListClone.filter((order)=>order.id === action.payload)
            state.orderDetails = orderData[0] || {}
            state.isLoading = false
          })
          builder.addCase(fetchOrderById.rejected, (state, action) => {
              state.isLoading = false
          })


    }
})



export default SalesSlice.reducer;