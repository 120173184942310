import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Nav, Button, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { RatingView } from 'react-simple-star-rating'
import { Doughnut } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


ChartJS.register(ArcElement, Tooltip, Legend);
const  ShopRatings= ({shopRatings,handleFilter}) => {
    const dispatch = useDispatch();
    const history = useHistory();


const data = {
    labels: [ ],
    datasets: [
      {
        label: '# of Stars',
        data: [shopRatings.ratingsAverage, shopRatings.differenceAverage],
        backgroundColor: [
          'rgba(255, 215, 0, 0.8)',
          'rgba(235,235,235, 0.8)',
        ],
        borderColor: [
          'rgba(255, 215, 0, 1)',
          'rgba(235,235,235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
    const [chartData, setChartData] = useState(data)




        return (
                    <div id="shop-rating">
                                            <div className="shop-rating-title">
                                                <h6>Shop Ratings</h6>
                                                <hr></hr>
                                            </div>
                                        <Row className="justify-content-md-center">
                                            <Col>

                                            </Col>
                                            <Col md="auto" style={{display:'flex'}}>
                                                <Doughnut
                                                        options={{cutout:130}}
                                                        data={data}
                                                        />
                                                    <div className="doughnut">

                                                        <div className="donut-inner">
                                                            <h5>{shopRatings.ratingsAverage || null}</h5>
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        margin:"10px 20px",
                                                        alignContent:"center"
                                                    }}>
                                                        <div className="star-rating">
                                                            <span style={{paddingRight:5}}>5</span>
                                                            <RatingView
                                                                fillColor="#FFD700"
                                                                ratingValue={5} size={20}/>
                                                            <span className="rating-text">{parseInt(shopRatings['5_star']) || 0}</span>
                                                        </div>
                                                        <div className="star-rating">
                                                            <span style={{paddingRight:5}}>4</span>
                                                            <RatingView
                                                                fillColor="#FFD700"
                                                                ratingValue={4} size={20}/>
                                                           <span className="rating-text">{parseInt(shopRatings['4_star']) || 0}</span>
                                                        </div>
                                                        <div className="star-rating">
                                                            <span style={{paddingRight:5}}>3</span>
                                                            <RatingView
                                                                fillColor="#FFD700"
                                                                ratingValue={3} size={20}/>
                                                            <span className="rating-text">{parseInt(shopRatings['3_star']) || 0}</span>
                                                        </div>
                                                        <div className="star-rating">
                                                            <span style={{paddingRight:5}}>2</span>
                                                            <RatingView
                                                                fillColor="#FFD700"
                                                                ratingValue={2} size={20}/>
                                                            <span className="rating-text">{parseInt(shopRatings['2_star']) || 0}</span>
                                                        </div>
                                                        <div className="star-rating">
                                                            <span style={{paddingRight:5}}>1</span>
                                                            <RatingView
                                                                fillColor="#FFD700"
                                                                ratingValue={1} size={20}/>
                                                            <span className="rating-text f-gray">{parseInt(shopRatings['1_star']) || 0}</span>
                                                        </div>


                                                    </div>
                                            </Col>

                                                <Col>

                                                </Col>

                                        </Row>




                                        <Row className="justify-content-md-center">
                                                <Col md="auto">
                                                    <div className="star-rating"
                                                        style={{marginTop:40}}
                                                        >
                                                                    <RatingView
                                                                        fillColor="#FFD700"
                                                                        ratingValue={parseInt(shopRatings['total'])} size={20}/>
                                                                    <span style={{
                                                                        marginLeft:10
                                                                    }}>{`( ${parseInt(shopRatings['total'])|| 0} reviews )`}</span>

                                                    </div>
                                                </Col>
                                        </Row>


                                <div className="shop-ratings-btn">
                                        <Row className="justify-content-md-center mt-4">
                                            <Col md="auto">
                                                    <Button
                                                        className="mx-1 rounded-2"
                                                        variant="primary"
                                                        value={"total"}
                                                        onClick={(e)=>handleFilter(e)}
                                                    >

                                                    {`All (${parseInt(shopRatings['total'])|| 0})`}
                                                    </Button>

                                                    <Button
                                                        className="mx-2 rounded-2"
                                                        variant="outline-primary"
                                                        value={5}
                                                        onClick={(e)=>handleFilter(e)}
                                                    >
                                                         {`5 stars (${parseInt(shopRatings['5_star'])|| 0})`}
                                                    </Button>


                                                    <Button
                                                        className="mx-2 rounded-2"
                                                        variant="outline-primary"
                                                        value={4}
                                                        onClick={(e)=>handleFilter(e)}
                                                    >
                                                        {`4 stars (${parseInt(shopRatings['4_star'])|| 0})`}
                                                    </Button>


                                                    <Button
                                                        className="mx-2 rounded-2"
                                                        variant="outline-primary"
                                                        value={3}
                                                        onClick={(e)=>handleFilter(e)}
                                                    >
                                                    {`3 stars (${parseInt(shopRatings['3_star'])|| 0})`}
                                                    </Button>


                                                    <Button
                                                        className="mx-2 rounded-2"
                                                        variant="outline-primary"
                                                        value={2}
                                                        onClick={(e)=>handleFilter(e)}
                                                    >
                                                        {`2 stars (${parseInt(shopRatings['2_star'])|| 0})`}
                                                    </Button>


                                                    <Button
                                                        className="mx-2 rounded-2"
                                                        variant="outline-primary"
                                                        value={1}
                                                        onClick={(e)=>handleFilter(e)}
                                                    >
                                                        {`1 star (${parseInt(shopRatings['1_star'])|| 0})`}
                                                    </Button>
                                            </Col>
                                        </Row>
                                    </div>

                    </div>

        );
}

export default ShopRatings;
