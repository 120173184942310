export const useStyles = () => ({
    intercomContainer: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        margin: '0 10px 10px 0',
        cursor: 'pointer',
    },
    intercomIcon: {
        textAlign: 'center',
        width: '65px',
        height: '65px',
        float: 'left',
        borderRadius: '50%',
        background: '#3047ec',
        boxShadow: '2px 2px 3px #d3d3d3',

    },
    downIcon: {
        transform: 'rotate(45deg)',
        '-webkit-transform': 'rotate(45deg)',
    },
    icon: {
        fontSize: '25px',
        color: 'white',
        marginTop: '19px',
    }
});
