import React, { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { getProfileDetails, selectUser } from '../../features/UserSlice';
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './styles';

const IntercomIcon = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLogin, setIsLogin] = useState(false)
    const { isLoggedIn } = useSelector(selectUser) || {}
    const profileDetail = useSelector((state) => state.user.detailsProfile)
    const isIntercomHidden = useSelector((state) => state.user.isIntercomHidden)

    const {
        showNewMessages,
        hide,
        shutdown
    } = useIntercom();

    useEffect(() => {
        setIsLogin(isLoggedIn);
        if (!isLoggedIn) {
            shutdown();
        }

    }, [isLoggedIn])


    useEffect(() => {
        if (profileDetail.length == 0) {
            dispatch(getProfileDetails())
        }
    }, [dispatch, profileDetail])

    const handleNewMessages = () => {
        hide();
        showNewMessages();
    }

    if (profileDetail.length != 0 && isLogin && !isIntercomHidden) {
        return (
            <>
                <div style={classes.intercomContainer} onClick={handleNewMessages}>
                    <div style={classes.intercomIcon}>
                        <i style={classes.icon} className="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                </div>
            </>
        )
    }

    return null;

}

export default IntercomIcon;
