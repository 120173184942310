import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';
import qs from 'qs';

const InitialState = {
  isLoading: false,
  searchProduct:[],
  searchShop: [],
}

export const searchMain = createAsyncThunk(
    'main/search',
    async (query, {rejectWithValue, dispatch}) => {
      const queryString = qs.stringify({...query});
      try {
        const response = await api.get(`/searched?${queryString}`)
        return response.data.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )




export const SearchSlice = createSlice({
    name:"search",
    initialState: InitialState,
    extraReducers: (builder) => {

              builder.addCase(searchMain.pending, (state, action) => {
                  state.isLoading = true
              })
              builder.addCase(searchMain.fulfilled, (state, action) => {
                  state.searchProduct = action?.payload?.product || []
                  state.searchShop = action?.payload?.shop || []
                  state.isLoading = false
              })
              builder.addCase(searchMain.rejected, (state, action) => {
                  state.isLoading = false
              })  

    }
})



export default SearchSlice.reducer;