import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../services/Api';

const InitialState = {
  isLoading: false,
  activePriceDisplay:null,
}

export const priceDisplay = createAsyncThunk(
    'main/price',
    async (_, {rejectWithValue, dispatch}) => {
      
      try {
        const response = await api.get(`/setting/price`)
        return response.data
      } catch (err) {
        if (!err.response) {
          throw err
        }
      }
    }
  )




export const PriceSlice = createSlice({
    name:"price",
    initialState: InitialState,
    extraReducers: (builder) => {

              builder.addCase(priceDisplay.pending, (state, action) => {
                  state.isLoading = true
              })
              builder.addCase(priceDisplay.fulfilled, (state, action) => {
                console.log(action?.payload?.data[0]?.active_category[0]?.categ_desc,474747)
                  state.activePriceDisplay = action?.payload?.data[0]?.active_category[0]?.categ_desc ||null
                  state.isLoading = false
              })
              builder.addCase(priceDisplay.rejected, (state, action) => {
                  state.isLoading = false
              })  

    }
})



export default PriceSlice.reducer;