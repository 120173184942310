import React from 'react';
import { Container, Row, Col, Nav } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import NoOrder from '../../../components/service/NoOrder';
import OrderItem from "../../../components/common/OrderItem"


function SalesShipping({shippingOrderList}) {
                return (
        
                <div id="sales_container">
                        {shippingOrderList && shippingOrderList.length === 0 ?
                                <NoOrder/> :
                            shippingOrderList.length !== 0 && shippingOrderList.map((order)=>(
                                <div className="productPreviewContainer" key={order.id} id={"transaction_"+order.shop_id}>
            
                                
                               

                                <React.Fragment>
                                        <Container className="salesShopName">
                                        <Row>
                                                <Col xs={6} style={{textAlign: "left"}}>
                                                        <img 
                                                                alt=""
                                                                src="/seller_icons/store_icon_1.png" 
                                                                style={{
                                                                        height: "17px", 
                                                                        marginRight: "10px"
                                                                        }}    
                                                                        />
                                                       {order?.shop?.name || ""}
                                                </Col>
                                                <Col xs={6} style={{textAlign: "right"}}>
                                                        ORDER ID# {order.rc_id}
                                                </Col>
                                        </Row>
                                        </Container>


                                        <Container 
                                        className="salesProductPreview" 
                                        >
                                
                                {order.order_items.length !== 0 && order.order_items.map((item)=>(
                                        <OrderItem 
                                                key={item.id}
                                                item={item} 
                                        />
                                        ))}
                                
                    
                                
                    
                                <div className="transactionContainer">
                                <Row>
                                <Col xs={6} style={{textAlign: "left"}}>
                                        <h6>Transaction ID</h6>
                                </Col>
                                <Col xs={6} style={{textAlign: "right"}}>
                                        <h6>#{order?.transaction_id}</h6>
                                </Col>
                                </Row>
                                </div>
                                <div className="transactionShipContainer">
                                Waiting to receive the products and make payment on delivery by June 11, 2021  
                                </div>
                                </Container>
                                </React.Fragment>
                                </div>
                                )
                                )
                                
                        }

            </div>)
    }
    
    export default SalesShipping;